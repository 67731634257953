export const colors = {
    main: {
        wall: "#000000",
        wallStroke: "#000000",
        floor: "#FFFFFF",
        floorStroke: "#FFFFFF",
        point: "#ff3535",
        beigeFill: "#d9c3a3",
        greyFill: "#b6b6b6",
        hatch: "#000000",
        patternBG: "#FFFFFF",
        objects: "#FFFFFF",
    },
    estimated: {
        wall: "#565656",
        wallStroke: "#565656",
        floor: "#B4B4B4",
        floorStroke: "#B4B4B4",
        patternBG: "#B4B4B4",
        module: "#7C7C7C74",
        objects: "#B4B4B4",
    },
    selected: "#59DA28",
    selectedStroke: "#3ea516",
    text: "#000000",
}

export const columnParams = {
    width: 100,
    depth: 100,
    minWidth: 5,
    minDepth: 5,
}

export const keys = {
    Delete: 46,
    Backspace: 8,
    C: 67,
    V: 86,
    Z: 90,
    Y: 89
}
