import React, { useMemo } from 'react';
import ModalWrapper from '../../../ModalWrapper';
import style from './Estimate.module.scss';
import { actionsState as projectState } from '../../../../Redux/project';
import { useDispatch } from 'react-redux';
import { Table } from '../../../UI';
import { useEstimate } from './hooks';

const columns = [
    {
        size: 330,
        header: 'наименование',
        accessorKey: 'name',
    },
    {
        size: 160,
        header: 'цена за ед.',
        accessorKey: 'cost',
    },
    {
        size: 160,
        header: 'объем',
        accessorKey: 'volume',
        cell: ({ getValue, row }) => getValue() + ` ${row.original.unit}`,
    },
    {
        size: 160,
        header: 'стоимость, руб.',
        accessorKey: 'amount',
        cell: ({ getValue }) => {
            const value = Math.round(getValue());
            return (
                <p className={style?.["table-summary"]}>
                    {value?.toLocaleString('fr-FR')}
                </p>
            );
        },
    },
];

export const EstimateObject = ({ object }) => {
    const dispatch = useDispatch();

    const { estimate } = useEstimate({ object });

    const onClose = () => {
        dispatch(projectState.setModal(''));
    }

    return (
        <ModalWrapper
            isSideMenu={true}
            title={'Список работ'}
            onClose={onClose}
            width={800}
        >
            <div className={style?.["table-info"]}>
                <Table
                    data={estimate}
                    columns={columns}
                />
            </div>
        </ModalWrapper>
    )
}
