import React from 'react'
import ModalWrapper from "./ModalWrapper";
import { useDispatch } from "react-redux";
import { actionsState as ProjectStore } from "../Redux/project";

const PagesInfo = () => {
    const dispatch = useDispatch();

    return (
        <div>
            <div className='modal-bg' onClick={()=>dispatch(ProjectStore.setModal(''))}></div>
            <div className='modal-info'>
                <ModalWrapper
                    title={"Внимание!"}
                    onClose={()=>dispatch(ProjectStore.setModal(''))}
                >
                    <div className={"modal-info-block"}>
                        <p>
                            Вы открыли несколько страниц в одном браузере.
                        </p>
                        <p>
                            Пожалуйста, закройте оставшиеся страницы.
                        </p>
                        <p>
                            Автосохранения будут работать только в активном окне!
                        </p>
                    </div>
                </ModalWrapper>
            </div>
        </div>
    )}

export default PagesInfo
