/**
 * Рисует замок на плане
 * @param {object} ctx ctx рисования
 * @param {Node} point точка рисования замка
 * @param {boolean} locked замок открыт или закрыт
 */
export const drawLock = (ctx, point, locked) => {
    ctx.save();
    ctx.translate(point.x, point.y);
    const zoom = 0.35;
    const lock = new Path2D();
    ctx.fillStyle = 'green';
    ctx.lineWidth = 3;
    lock.arc(0, 5 * zoom, 10 * zoom, 0, 2 * Math.PI);
    lock.moveTo(-30 * zoom, 30 * zoom);
    lock.lineTo(-30 * zoom, -30 * zoom);
    lock.lineTo(30 * zoom, -30 * zoom);
    lock.lineTo(30 * zoom, 30 * zoom);
    lock.lineTo(-30 * zoom, 30 * zoom);
    const ear = new Path2D();
    ear.moveTo(-25 * zoom, -30 * zoom);
    ear.lineTo(-25 * zoom, -40 * zoom);
    ear.arc(0, -40 * zoom, 25 * zoom, Math.PI, 0, false);
    ear.lineTo(25 * zoom, -30 * zoom);
    if (locked) {
        ctx.strokeStyle = 'red';
        ctx.stroke(lock);
        ctx.stroke(ear);
    } else {
        ctx.strokeStyle = 'blue';
        ctx.stroke(lock);
        ctx.translate(50 * zoom, 0);
        ctx.stroke(ear);
    }
    ctx.stroke(ear);
    ctx.restore();
}