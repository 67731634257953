import { Vector2 } from 'three';
import { checkMatchNode, checkPointLiesOnSegment } from '../../../../PlanEditor/helpers';

const distanceRayToModule = (origin, direction, m) => {
    const p1 = m.position.clone().add(new Vector2(-m.size.pivot.x, -m.size.pivot.y)).rotateAround(m.position,-m.angle);
    const p2 = m.position.clone().add(new Vector2(m.size.width - m.size.pivot.x, -m.size.pivot.y)).rotateAround(m.position,-m.angle);
    const p3 = m.position.clone().add(new Vector2(m.size.width - m.size.pivot.x, m.size.height - m.size.pivot.y)).rotateAround(m.position,-m.angle);
    const p4 = m.position.clone().add(new Vector2(-m.size.pivot.x, m.size.height - m.size.pivot.y)).rotateAround(m.position,-m.angle);
    let d = Infinity;

    const dist = (origin, direction, a, b) => {
        const a1 = -direction.y;
        const b1 = direction.x;
        const c1 = origin.x * (origin.y + direction.y) - (origin.x + direction.x) * origin.y;

        const a2 = a.y - b.y;
        const b2 = b.x - a.x;
        const c2 = a.x * b.y - b.x * a.y;

        const det = (a, b, c, d) => a * d - b * c;

        const zn = det(a1, b1, a2, b2)

        if (Math.abs(zn) < 0.0000001) return Infinity;
        else {
            const v = new Vector2(
                - det(c1, b1, c2, b2) / zn,
                - det(a1, c1, a2, c2) / zn
            );
            if (v.clone().sub(origin).dot(direction) < 0) return Infinity;
            if (a.x < b.x && (v.x < a.x || v.x > b.x)) return Infinity;
            if (a.x > b.x && (v.x > a.x || v.x < b.x)) return Infinity;
            if (a.x === b.x && a.y < b.y && (v.y < a.y || v.y > b.y)) return Infinity;
            if (a.x === b.x && a.y > b.y && (v.y > a.y || v.y < b.y)) return Infinity;
            return v.sub(origin).length();
        }
    }
    let _d = dist(origin, direction, p1, p2);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p2, p3);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p3, p4);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p4, p1);
    if (_d < d) d = _d;

    return d;
}

const distanceRayToWall = (origin, direction, wall) => {
    const p1 = new Vector2(wall.innerLink.a.x, wall.innerLink.a.y);
    const p2 = new Vector2(wall.innerLink.b.x, wall.innerLink.b.y);
    const p3 = new Vector2(wall.parallelLink.a.x, wall.parallelLink.a.y);
    const p4 = new Vector2(wall.parallelLink.b.x, wall.parallelLink.b.y);

    let d = Infinity;

    const dist = (origin, direction, a, b) => {
        const a1 = -direction.y;
        const b1 = direction.x;
        const c1 = origin.x * (origin.y + direction.y) - (origin.x + direction.x) * origin.y;

        const a2 = a.y - b.y;
        const b2 = b.x - a.x;
        const c2 = a.x * b.y - b.x * a.y;

        const det = (a, b, c, d) => a * d - b * c;

        const zn = det(a1, b1, a2, b2)

        if (Math.abs(zn) < 0.0000001) return Infinity;
        else {
            const v = new Vector2(
                - det(c1, b1, c2, b2) / zn,
                - det(a1, c1, a2, c2) / zn
            );
            if (v.clone().sub(origin).dot(direction) < 0) return Infinity;
            if (!checkPointLiesOnSegment(a, b, v)) return Infinity;
            return v.sub(origin).length();
        }
    }
    let _d = dist(origin, direction, p1, p2);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p2, p4);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p3, p4);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p1, p3);
    if (_d < d) d = _d;

    return d;
}

export const calculateLocation = (plan, module, modules) => {
    let d;
    let origin = new Vector2();
    let direction = new Vector2();
    const zeroVector = new Vector2(0, 0);
    let w = module.size.width;
    let h = module.size.height;
    let pivot = module.size.pivot;
    let angle = module.angle;

    const drawRay = (directionSide) => {
        d = Infinity;

        switch (directionSide) {
            case 'up': {
                origin.x = 0;
                origin.y = -pivot.y;
                direction.x = 0;
                direction.y = -1;
                break
            }
            case 'bottom': {
                origin.x = 0;
                origin.y = h - pivot.y;
                direction.x = 0;
                direction.y = 1;
                break
            }
            case 'left': {
                origin.x = -pivot.x;
                origin.y = 0;
                direction.x = -1;
                direction.y = 0;
                break
            }
            case 'right': {
                origin.x = w - pivot.x;
                origin.y = 0;
                direction.x = 1;
                direction.y = 0;
                break
            }
            default: { break }
        }

        origin.rotateAround(zeroVector, -angle);
        origin.add(new Vector2(module.position.x, module.position.y));
        direction.rotateAround(zeroVector, -angle);

        const checkDotEntersRoom = (point, cycles) => {
            const x = point.x;
            const y = point.y;

            let inside = false;
            for (let i = 0, j = cycles._points.length - 1; i < cycles._points.length; j = i++) {
                let xi = cycles._points[i].x, yi = cycles._points[i].y;
                let xj = cycles._points[j].x, yj = cycles._points[j].y;
                let intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                if (intersect) {
                    inside = !inside;

                }
            }
            return inside;
        }

        for (let c = 0; plan.cycles.length > c; c++) {
            if (checkDotEntersRoom(origin, plan.cycles[c])) {
                for (let i = 0; plan.cycles[c]._links.length > i; i++) {
                    for (let j = 0; plan.bWalls.length > j; j++) {
                        if (checkMatchNode(plan.cycles[c]._links[i].a, plan.bWalls[j].mainLink.a)
                            && checkMatchNode(plan.cycles[c]._links[i].b, plan.bWalls[j].mainLink.b)) {
                            const d2w = distanceRayToWall(origin, direction, plan.bWalls[j]);
                            if (d2w < d) {
                                d = d2w;
                            }
                        }
                    }
                }
            }
        }

        modules.forEach(m => {
            if (m !== module) {
                const _d = distanceRayToModule(origin, direction, m);
                if (_d < d) d = _d;
            }
        });

        if (d !== Infinity && d > 0) {
            d = Math.round(d);
            locationInRooms[directionSide] = d;
        }
    }

    const locationInRooms = { up: null, right: null, bottom: null, left: null };

    drawRay('up');
    drawRay('right');
    drawRay('bottom');
    drawRay('left');

    module.locationInRooms = locationInRooms;
}
