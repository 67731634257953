import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsState as projectState } from '../../../../Redux/project';
import { is_touch_device } from '../../../../Helpers/functions';
import style from './Estimate.module.scss';
import { Button } from '../../../UI';

export const EstimateFormatterMode = ({ canvas }) => {
    const dispatch = useDispatch();
    const formatMode = useSelector(state => state.project.formatMode);

    useEffect(() => {
        if (canvas) {
            canvas.style.cursor = formatMode ? 'cell' : 'auto';
        }

        const disableFormatMode = (event) => {
            if (event.type === 'mousedown' && event.button === 2) {
                dispatch(projectState.setFormatMode(false));
            }

            if (event.type === 'keydown' && event.key === 'Escape') {
                dispatch(projectState.setFormatMode(false));
            }
        }

        document.body.addEventListener('mousedown', disableFormatMode);
        document.body.addEventListener('keydown', disableFormatMode);
        return () => {
            document.body.removeEventListener('mousedown', disableFormatMode);
            document.body.removeEventListener('keydown', disableFormatMode);
        }
    }, [formatMode]);

    if (!formatMode) return null;

    return (
        <div className={style?.['formatter-window']}>
            <p>
                Режим копирования сметы активирован{is_touch_device() ? '' : '. Для выхода нажмите ESC или правую кнопку мыши'}
            </p>
            <Button size="medium" onClick={() => dispatch(projectState.setFormatMode(false))}>Закрыть</Button>
        </div>
    )
}
