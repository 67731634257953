import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { actionsState as projectState } from "../Redux/project";
import { actionsState as modulesState } from "../Redux/modules";
import VariantDropBoxSimple from "./widgets/VariantDropBoxSimple";
import { convertQuotes } from './Utils';

const Modules = ({ activeSection, setActiveSection }) => {
    const [isBigCards, setIsBigCards] = useState(false);

    const propsActiveSection = useSelector(store => store.project.section);

    if (!activeSection) {
        activeSection = propsActiveSection;
    }


    const dispatch = useDispatch();
    const allModules = useSelector(store => store.modules.allModules);
    const canvasCenter = useSelector(store => store.project.plan.canvasCenter);
    const sections = useSelector(store => store.modules.sections);



    // const [activeSection,setActiveSection] = useState(sections.length>0?sections[0]:null);
    // setActiveSection(_activeSection);
    // const modules = activeSection?allModules.filter(m=>m.SECTION===activeSection.ID && m.SHOW):allModules.filter(m=>m.SECTION>0 && m.SHOW);
    const modules = activeSection ? allModules.filter(m => m.SECTION === activeSection.ID) : allModules.filter(m => m.SECTION > 0);


    const addModule = (module) => {
        module.canvasCenter = canvasCenter
        dispatch(modulesState.addModule(module));
        dispatch(projectState.setModal(''));
        dispatch(projectState.setMenuOpened(0));
    }
    // console.log('allModules',allModules)
    // console.log('modules',modules)
    // console.log(activeSection.NAME)



    return <div className="app__fullmodal">
        <div className="fullmodal-menu">
            <h2 className="fullmodal-menu_title">Каталог</h2>
            <ul>
                {sections.map(s => <li
                    className={activeSection === s ? 'active' : ''}
                    key={s.ID}
                    onClick={() => setActiveSection(s)}
                >{s.NAME}</li>)}
            </ul>
        </div>

        <div className="fullmodal-block">
            <div className="fullmodal-header">
                <h2 className="fullmodal-header_title hom">{activeSection.NAME}</h2>
                <h2 className="fullmodal-header_title som">Каталог мебели</h2>
                <div
                    onClick={() => setIsBigCards(!isBigCards)}
                    className="btn btn-icon"
                    style={{
                        marginRight: "15px",
                        marginLeft: "auto",
                        alignSelf: "center",
                        width: "100px",
                    }}
                >
                    {isBigCards ? "Уменьшить" : "Увеличить"}
                </div>
                <div className="fullmodal-header_btn" onClick={() => {
                    dispatch(projectState.setModal(''))
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                        <g stroke="currentColor">
                            <line x1="1" y1="1" x2="18" y2="18"></line>
                            <line x1="18" y1="1" x2="1" y2="18"></line>
                        </g>
                    </svg>
                </div>
            </div>
            <VariantDropBoxSimple
                list={sections}
                active={activeSection}
                title={false}
                setActive={setActiveSection}
                classes={'som'}
            />
            <div className={"options-title som"}>ОБЪЕКТ ДЛЯ ДОБАВЛЕНИЯ</div>
            <div className="modules-body">
                {modules.map(m => {
                    return <div key={m.ID} style={{ width: isBigCards ? "auto" : "200px" }} className="module-card">
                        <div className="card-img"><img src={m.IMAGE} alt="" /></div>
                        <span className="card-name">{convertQuotes(m.NAME)}</span>
                        {m.PRICE && <span className="card-price">{m.PRICE}</span>}
                        <span className="btn btn-add" onClick={() => addModule(m)}>Добавить</span>
                    </div>
                })}
            </div>
        </div>
    </div>
}
export default Modules;
