import { actionsState } from "../../../Redux/project";
import { bluetoothRouletteObj } from "./bluetoothRouletteObj";
import { distance } from "./distance";

export const setReConnection = async (dispatch) => {
    exponentialBackoff(5 /* max retries */, 1 /* seconds delay */,
        async function toTry() {
            dispatch(actionsState.setBluetoothDevices({ status: 'reConnection' }));
            console.log('Connecting to Bluetooth Device... ');

            const server = await bluetoothRouletteObj.device.gatt.connect();
            bluetoothRouletteObj.server = server;

            const service = await server.getPrimaryService(bluetoothRouletteObj.serviceID);
            bluetoothRouletteObj.service = service;

            const characteristic = await service.getCharacteristic(bluetoothRouletteObj.characteristicID);
            bluetoothRouletteObj.characteristic = characteristic;

            const notify = await characteristic.startNotifications();
            bluetoothRouletteObj.notify = notify;
        },
        function success() {
            console.log('> Bluetooth Device connected.');
            distance.start();
            dispatch(actionsState.setBluetoothDevices({ status: 'connected' }));
        },
        async function fail() {
            console.log('Failed to reconnect.');
            dispatch(actionsState.setBluetoothDevices({ status: 'error' }));
            await bluetoothRouletteObj.device.gatt.disconnect();
        });
}

const exponentialBackoff = async (max, delay, toTry, success, fail) => {
    try {
        const result = await toTry();
        success(result);
    } catch (error) {
        if (max === 0) {
            return fail();
        }
        console.log('Retrying in ' + delay + 's... (' + max + ' tries left)');
        setTimeout(function () {
            exponentialBackoff(--max, delay * 2, toTry, success, fail);
        }, delay * 1000);
    }
}