import React from 'react';
import ModalWrapper from "./ModalWrapper";
import ColorRBG from "./widgets/ColorRBG";

const MaterialSelect = ({onClose,list,active,setMaterial,title,rgbColor,_setRgbColor,activeRGB,colorDef})=>{
    return <ModalWrapper
        onClose={onClose}
        title={title}
        styles={{ top: 0 }}
    >
        <div className="material-select-block">
            {list.map(m=>(
                <div
                    className={m === active && !activeRGB ? 'material-item material-item_active' : 'material-item'}
                    key={m.uuid}
                    style={{backgroundImage: 'url(' + m.userData.PICT + ')'}}
                    onClick={()=>{setMaterial(m);onClose()}}
                >
                    <span>{m.userData.NAME}</span>
                </div>
            ))}
            {rgbColor && <ColorRBG _setRgbColor={_setRgbColor} active={activeRGB} colorDef={colorDef} />}
        </div>
    </ModalWrapper>
}
export default MaterialSelect
