import { useEffect, useRef } from 'react';
import { keys } from '../../Utils';
import { useSelector } from 'react-redux';

export const useShortcuts = ({
   onDelete,
   onCopy,
   onUndo,
   onRedo,
   onSubmit,
   deps = []
}) => {
    const isCopied = useRef(false);
    const tool = useSelector((state) => state.project.tool);

    useEffect(() => {
        if (tool !== 'view') {
            const handleKeyDown = (event) => {
                if (event.keyCode === keys.Delete) {
                    onDelete?.();
                } else if (event.ctrlKey && event.keyCode === keys.C) {
                    isCopied.current = true;
                } else if (event.ctrlKey && event.keyCode === keys.V && isCopied.current) {
                    onCopy?.();
                } else if (event.ctrlKey && event.keyCode === keys.Z) {
                    onUndo?.();
                } else if (event.ctrlKey && event.keyCode === keys.Y) {
                    onRedo?.();
                } else if (event.key === 'Enter') {
                    onSubmit?.();
                }
            };

            window.addEventListener('keydown', handleKeyDown, { capture: true });
            return () => {
                window.removeEventListener('keydown', handleKeyDown, { capture: true });
            };
        }
    }, [tool, ...deps]);
}
