import Node from "../../../Classes/Node";

/**
 * Сравнивает две точки
 * @param {node} node1 
 * @param {node} node2 
 * @param {boolean} full, true - полное сравнение, false - только координаты
 * 
 * @returns boolean
 */
export const checkMatchNode = (node1, node2, full = true) => {
    if (full) {
        return (JSON.stringify(node1) === JSON.stringify(node2))
    } else {
        return (node1.x + node1.y === node2.x + node2.y)
    }
}