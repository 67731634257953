import * as THREE from 'three/build/three.min';
import { Geometry, Vector2, Vector3 } from 'three';
import ObjectOnWall from './ObjectOnWall'
import Wall from './Wall';
import Hole from './Hole';
import Node from './Node';
import Link from './Link';
import Cycle from './Cycle';
import Column from './Column';
import { findCycles, getIntersectionLongLines, lineLineIntersection } from '../Helpers/functions';
import { checkMatchNode, getCommonPoint } from '../Components/PlanEditor/helpers';
import { checkColumn } from '../Components/Features/SideMenu/Estimate/utils/checkRoom';
import { Bezier } from '../Helpers/Bezier';
import { stickColumnToWall } from '../Components/Utils/columnsCalculation';

class Plan {
    constructor() {
        this.nodes = [];
        this.links = [];
        this.virtualNode = null;
        this.virtualLink = null;
        this.virtualWall = null;
        this.dragNode = null;
        this.dragPairNode = null;
        this.columns = [];
        this.bWalls = [];
        this.cycles = [];
        this.mode = 'move' // floor, roof, ruler, view
        this.cycleActive = -1;
        this.virtualColumn = null;

        this.restored = false;
        this.offsetX = 0;
        this.offsetY = 0;
        this.ratio = 1;
        this.zoom = .1;
        this.minZoom = 6;
        this.maxZoom = 0;
        this.canvasCenter = new THREE.Vector2(0, 0);
        this.activeObject = null;

        this.undoArr = [];
        this.redoArr = [];

        this.level = 'floor';

        this.time = '';

        this.magnetization = {
            nodeOnWall: undefined, //точка на стене к по которой магнитится
            nodeOnScreen:undefined,//  точка на экране
            locked: false,//есть примагничивание
        };

        this.loadedImagesURLs = []
        this.loadedImages = []
        this.queueImages = []
    }

    resetMagnetization() {
        this.magnetization.nodeOnWall = undefined;
        this.magnetization.nodeOnScreen = undefined;
        this.magnetization.locked = false;
    }

    undo() {
        if (this.undoArr.length < 2) return;

        this.redoArr.push(this.undoArr.pop());

        const action = this.undoArr[this.undoArr.length - 1];
        const actionPop = this.redoArr[this.redoArr.length - 1];

        if (actionPop && actionPop.type === 'rotateModule') {
            actionPop.opt.obj.angle = actionPop.opt.prev;
        }
        if (actionPop && actionPop.type === 'movingModule') {
            actionPop.opt.obj.position = { x: actionPop.opt.prev.x, y: actionPop.opt.prev.y };
        }
        if (action && action.type === 'plan') {
            this.loadFromOBJ(action.state);
        }
    }

    redo() {
        if (this.redoArr.length < 1) return;

        this.undoArr.push(this.redoArr.pop());

        const action = this.undoArr[this.undoArr.length - 1];
        if (action.type === 'plan') {
            this.loadFromOBJ(action.state);
        } else if (action.type === 'rotateModule') {
            action.opt.obj.angle = action.opt.angle;
        } else if (action.type === 'movingModule') {
            action.opt.obj.position = { x: action.opt.position.x, y: action.opt.position.y };
        }
    }

    setActionUndo(action) {
        if (action.type === 'plan') {
            this.undoArr.push({
                type: 'plan',
                state: this.toOBJ()
            });
        } else {
            this.undoArr.push(action);
        }
        this.redoArr = [];

        if (this.undoArr.length > 50) {
            this.undoArr.shift();
        }

        this.time = Date.now();
    }

    setActiveObject(obj) {
        this.activeObject = obj;
    }

    setCycleActive(_index) {
        this.cycleActive = _index
    }

    setPlanMode(mode) {
        this.mode = mode
    }

    putNode(node) {
        this.nodes.push(node);
    }

    putRuller() {
        this.links.push(this.virtualLink);
    }

    updateWall(changeableWall) {
        const uWall = this.bWalls.find(wall => wall.mainLink === changeableWall.mainLink);

        const vLink = uWall.mainLink;
        const v = vLink.a.clone().sub(vLink.b);
        const rotate = (vLink.lrBuild === 'left') ? 90 : -90;
        const __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180));
        const _vParallel = __v.clone().setLength(vLink.depth);
        const _vInner = __v.clone().setLength(vLink.innerDepth);

        const parallelA = new Node(_vParallel.x + vLink.a.x, _vParallel.y + vLink.a.y);
        const parallelB = new Node(_vParallel.x + vLink.b.x, _vParallel.y + vLink.b.y);
        const vLinkParallel = new Link(parallelA, parallelB);
        uWall.parallelLink = vLinkParallel;

        const innerA = new Node(-_vInner.x + vLink.a.x, -_vInner.y + vLink.a.y);
        const innerB = new Node(-_vInner.x + vLink.b.x, -_vInner.y + vLink.b.y);
        const vLinkInner = new Link(innerA, innerB);
        uWall.innerLink = vLinkInner;

        uWall.nodes.length = 0;
        uWall.nodes.push(vLink.a);
        uWall.nodes.push(vLink.b);
        uWall.nodes.push(vLinkParallel.a);
        uWall.nodes.push(vLinkParallel.b);
        uWall.nodes.push(vLinkInner.a);
        uWall.nodes.push(vLinkInner.b);

        uWall.arcLength = uWall.mainLink.length * 1.5;
    }

    extendWall(wall, link = this.virtualLink) {
        const changeableLink = link;
        const changeableWall = this.bWalls.find(w => w.mainLink === wall.mainLink);
        const commonPoint = getCommonPoint(changeableWall.mainLink, changeableLink);
        for (let q = 0; q < this.links.length; q++) {
            if (this.links[q].a === changeableWall.mainLink.a
                && this.links[q].b === changeableWall.mainLink.b) {
                if (checkMatchNode(changeableWall.mainLink.a, commonPoint, false)) {
                    if (checkMatchNode(changeableLink.a, commonPoint, false)) {
                        this.links[q].a = changeableLink.b;
                        break
                    } else {
                        this.links[q].a = changeableLink.a;
                        break
                    }
                }
                if (checkMatchNode(changeableWall.mainLink.b, commonPoint, false)) {
                    if (checkMatchNode(changeableLink.a, commonPoint, false)) {
                        this.links[q].b = changeableLink.b;
                        break
                    } else {
                        this.links[q].b = changeableLink.a;
                        break
                    }
                }
            }
        }
        this.updateWall(wall);
    }

    putWall() {
        this.links.push(this.virtualLink);

        if (this.mode === 'walls') {
            const vLink = this.virtualLink;
            const vWall = this.virtualWall;

            const wall = new Wall();
            wall.mainLink = vLink;

            const v = vLink.a.clone().sub(vLink.b);
            const rotate = (vLink.lrBuild === 'left') ? 90 : -90;
            const __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180));

            const _vParallel = __v.clone().setLength(vLink.depth);
            const parallelA = new Node(_vParallel.x + vLink.a.x, _vParallel.y + vLink.a.y);
            const parallelB = new Node(_vParallel.x + vLink.b.x, _vParallel.y + vLink.b.y);
            const vLinkParallel = new Link(parallelA, parallelB);
            wall.parallelLink = vLinkParallel;

            const _vInner = __v.clone().setLength(vLink.innerDepth);
            const innerA = new Node(-_vInner.x + vLink.a.x, -_vInner.y + vLink.a.y);
            const innerB = new Node(-_vInner.x + vLink.b.x, -_vInner.y + vLink.b.y);
            const vLinkInner = new Link(innerA, innerB);
            wall.innerLink = vLinkInner;

            wall.nodes.push(vLink.a);
            wall.nodes.push(vLink.b);
            wall.nodes.push(vLinkParallel.a);
            wall.nodes.push(vLinkParallel.b);
            wall.nodes.push(vLinkInner.a);
            wall.nodes.push(vLinkInner.b);
            wall.arcLength = wall.mainLink.length * 1.5;

            vWall && vWall.objects.forEach(_obj => {
                let flagHasError = false;
                let obj;
                if (_obj.isHole || _obj.isDoor || _obj.isWindow) {
                    obj = new Hole();
                    obj.isHole = _obj.isHole
                    obj.isDoor = _obj.isDoor
                    obj.isWindow = _obj.isWindow
                } else if (_obj.isElectricSocket || _obj.isSwitch || _obj.isHeatingBattery || _obj.isElectricPanel || _obj.isRedCube || _obj.outletElectricalWire || _obj.isCylinder) {
                    obj = new ObjectOnWall();
                    obj.isElectricSocket = _obj.isElectricSocket;
                    obj.isSwitch = _obj.isSwitch;
                    obj.isHeatingBattery = _obj.isHeatingBattery;
                    obj.isElectricPanel = _obj.isElectricPanel;
                    obj.outletElectricalWire = _obj.outletElectricalWire;
                    obj.isRedCube = _obj.isRedCube;
                    obj.isCylinder = _obj.isCylinder;
                    obj.depthFor3D = _obj.depthFor3D;
                    obj.depthIndentFor3D = _obj.depthIndentFor3D;
                    obj.lrBuild = _obj.lrBuild;
                    obj.rgb = _obj.rgb === undefined ? {
                        r: '255',
                        g: '0',
                        b: '0',
                        a: '1',
                    } : _obj.rgb;

                } else {
                    console.error(new Error("Ошибка при копировании. Такого объекта не существует!"));
                    flagHasError = true;
                }
                if (!flagHasError) {
                    obj.height = _obj.height || 100
                    obj.width = _obj.width
                    obj.position = _obj.position
                    obj.pos = _obj.pos
                    obj.padding = _obj.padding
                    obj.heightFromFloor = _obj.heightFromFloor
                    obj.inside = _obj.inside
                    obj.left = _obj.left
                    obj.depth = _obj.depth
                    obj.depthIndent = _obj.depthIndent
                    obj.len1 = _obj.len1
                    obj.len2 = _obj.len2
                    obj.id = _obj.id
                    obj.ordinalNumber = _obj.ordinalNumber


                    obj.objTitle = (_obj.objTitle) ? _obj.objTitle : '';
                    obj.objComment = (_obj.objComment) ? _obj.objComment : '';
                    obj.objImages = (_obj.objImages) ? _obj.objImages.map(img => img) : [];

                    wall.objects.push(obj)
                }
            });

            if (vLink.controlA && vLink.controlB) {
                wall.isBezier = true;
                wall.bezierControlPoint_1A = vLink.controlA;
                wall.bezierControlPoint_1B = vLink.controlB;

                const rotateOutline = (vLink.lrBuild === 'left') ? -vLink.depth : vLink.depth;
                const rotateInline = (vLink.lrBuild === 'left') ? vLink.innerDepth : -vLink.innerDepth;
                const bezier = new Bezier(
                    wall.nodes[0].x,
                    wall.nodes[0].y,
                    wall.bezierControlPoint_1A.x,
                    wall.bezierControlPoint_1A.y,
                    wall.bezierControlPoint_1B.x,
                    wall.bezierControlPoint_1B.y,
                    wall.nodes[1].x,
                    wall.nodes[1].y);

                const outlineBezier = bezier.offset(rotateOutline);
                const inlineBezier = bezier.offset(rotateInline);

                const outlineBezierStart = new Node(outlineBezier[0].points[0].x, outlineBezier[0].points[0].y);
                const outlineBezierEnd = new Node(outlineBezier[outlineBezier.length - 1].points[3].x, outlineBezier[outlineBezier.length - 1].points[3].y);
                wall.parallelLink = new Link(outlineBezierStart, outlineBezierEnd);

                const inlineBezierStart = new Node(inlineBezier[0].points[0].x, inlineBezier[0].points[0].y);
                const inlineBezierEnd = new Node(inlineBezier[inlineBezier.length - 1].points[3].x, inlineBezier[inlineBezier.length - 1].points[3].y);
                wall.innerLink = new Link(inlineBezierStart, inlineBezierEnd);

                wall.nodes[2] = outlineBezierStart;
                wall.nodes[3] = outlineBezierEnd;
                wall.nodes[4] = inlineBezierStart;
                wall.nodes[5] = inlineBezierEnd;

                wall.outlineBezier = outlineBezier;
                wall.inlineBezier = inlineBezier;
                wall.inlineBezierLUT = inlineBezier.map((bezier) => bezier.getLUT(32));
                wall.bezier = bezier;
            }

            this.bWalls.push(wall);
            this.setFloors('floor');

            return wall;
        } else if (this.mode === 'figures') {
            this.setFloors('figures');
            return this.virtualLink;
        }
    }

    moveWallColumns(wall) {
        const columns = this.columns;
        const walls = this.bWalls;
        const zoom = this.zoom;

        const moveColumns = (cid) => {
            const column = columns?.[cid];
            if (column) {
                const stickPoint = stickColumnToWall(
                    { x: column.x, y: column.y },
                    walls.map((w) => w !== wall ? null : wall),
                    column,
                    cid,
                    zoom,
                    Infinity
                );

                if (stickPoint) {
                    const { x, y, angle, wallIndex, wallSide } = stickPoint;

                    column.x = x;
                    column.y = y;
                    column.angle = angle;

                    column.setParentWallID(wallIndex, wallSide);
                    wall.setColumns(cid, wallSide);
                }
            }
        }

        if (wall.leftCols?.length) wall.leftCols.forEach(moveColumns);
        if (wall.rightCols?.length) wall.rightCols.forEach(moveColumns);
    }

    changeWallLRBuild(link) {
        const wall = this.bWalls.filter(w => w.mainLink === link)[0];

        let v = link.a.clone().sub(link.b);
        let rotate = (link.lrBuild === 'left') ? 90 : -90;
        const __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180));

        const _vParallel = __v.clone().setLength(link.depth);
        const parallelA = new Node(_vParallel.x + link.a.x, _vParallel.y + link.a.y);
        const parallelB = new Node(_vParallel.x + link.b.x, _vParallel.y + link.b.y);
        wall.parallelLink = new Link(parallelA, parallelB);

        const _vInner = __v.clone().setLength(link.innerDepth);
        const innerA = new Node(-_vInner.x + link.a.x, -_vInner.y + link.a.y);
        const innerB = new Node(-_vInner.x + link.b.x, -_vInner.y + link.b.y);
        wall.innerLink = new Link(innerA, innerB);

        wall.nodes[2] = parallelA;
        wall.nodes[3] = parallelB;
        wall.nodes[4] = innerA;
        wall.nodes[5] = innerB;

        if (wall.isBezier) {
            if (!wall.bezierControlPoint_1A && !wall.bezierControlPoint_2A) {
                link.controlA = new Node(wall.nodes[2].x, wall.nodes[2].y - 1000, 'control');
                link.controlB = new Node(wall.nodes[3].x, wall.nodes[3].y - 1000, 'control');

                wall.bezierControlPoint_1A = link.controlA;
                wall.bezierControlPoint_1B = link.controlB;

                this.putNode(link.controlA);
                this.putNode(link.controlB);
            }

            const rotateOutline = (link.lrBuild === 'left') ? -link.depth : link.depth;
            const rotateInline = (link.lrBuild === 'left') ? link.innerDepth : -link.innerDepth;

            const bezier = new Bezier(
                wall.nodes[0].x,
                wall.nodes[0].y,
                wall.bezierControlPoint_1A.x,
                wall.bezierControlPoint_1A.y,
                wall.bezierControlPoint_1B.x,
                wall.bezierControlPoint_1B.y,
                wall.nodes[1].x,
                wall.nodes[1].y);

            const outlineBezier = bezier.offset(rotateOutline);
            const inlineBezier = bezier.offset(rotateInline);

            const outlineBezierStart = new Node(outlineBezier[0].points[0].x, outlineBezier[0].points[0].y);
            const outlineBezierEnd = new Node(outlineBezier[outlineBezier.length - 1].points[3].x, outlineBezier[outlineBezier.length - 1].points[3].y);
            wall.parallelLink = new Link(outlineBezierStart, outlineBezierEnd);

            const inlineBezierStart = new Node(inlineBezier[0].points[0].x, inlineBezier[0].points[0].y);
            const inlineBezierEnd = new Node(inlineBezier[inlineBezier.length - 1].points[3].x, inlineBezier[inlineBezier.length - 1].points[3].y);
            wall.innerLink = new Link(inlineBezierStart, inlineBezierEnd);

            wall.nodes[2] = outlineBezierStart;
            wall.nodes[3] = outlineBezierEnd;
            wall.nodes[4] = inlineBezierStart;
            wall.nodes[5] = inlineBezierEnd;

            wall.outlineBezier = outlineBezier;
            wall.inlineBezier = inlineBezier;
            wall.inlineBezierLUT = inlineBezier.map((bezier) => bezier.getLUT(32));
            wall.bezier = bezier;
        }

        if (wall.isArc) {
            const cNode = wall.mainLink.a.clone().add(wall.mainLink.b.clone().sub(wall.mainLink.a).multiplyScalar(.5));
            const v = wall.mainLink.a.clone().sub(cNode)
            const rotate = (wall.mainLink.lrBuild === 'left') ? -90 : 90;
            const __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(wall.arcRadiusLine);
            const pivotPoint = new Node(__v.x + cNode.x, __v.y + cNode.y);
            wall.nodes[4] = pivotPoint;

            wall.arcRadius = pivotPoint.clone().sub(wall.mainLink.a).length();
            wall.arcRadius1 = pivotPoint.clone().sub(wall.mainLink.a).negate().normalize().setLength(500);
            wall.arcRadius2 = pivotPoint.clone().sub(wall.mainLink.b).negate().normalize().setLength(500);

            const arcCenterCorner = Math.abs(wall.arcRadius1.angle() - wall.arcRadius2.angle());

            const angle1 = wall.arcRadius1.angle();
            const angle2 = wall.arcRadius2.angle();
            if (arcCenterCorner < Math.PI)
                wall.arcCenterCorner = arcCenterCorner;
            else {
                if (angle1 > angle2) {
                    wall.arcCenterCorner = ((Math.PI * 2) - angle1) + angle2;
                } else {
                    wall.arcCenterCorner = ((Math.PI * 2) - angle2) + angle1;
                }
            }

            wall.arcLenght = wall.arcRadius * wall.arcCenterCorner;

            wall.arcRadiusPoints3D_1 = [];
            wall.arcRadiusPoints3D_2 = [];
            const arcRadiusPoints3Dtemp = [];
            const segments = 100;
            const segmentLen = Math.floor(Math.floor(wall.mainLink.length) / segments);
            // const segmentLen = Math.floor(Math.floor(wall.arcLenght)/segments);
            arcRadiusPoints3Dtemp.push(wall.mainLink.a.clone());
            wall.arcRadiusPoints3D_1.push(wall.mainLink.a.clone());

            const pointOnRadiusLineA = new Link(pivotPoint.clone(), wall.mainLink.a.clone());
            pointOnRadiusLineA.length = wall.arcRadius + wall.mainLink.depth;
            wall.arcRadiusPoints3D_2.push(pointOnRadiusLineA.b.clone());

            if (wall.arcRadiusPoints3D_1.length > 0) {
                for (let i = 0; i < 100; i++) {
                    const point = arcRadiusPoints3Dtemp[arcRadiusPoints3Dtemp.length - 1].clone();
                    const v = point.clone().sub(wall.mainLink.b);
                    const __v = v.clone().negate().normalize().setLength(segmentLen);
                    point.x = __v.x + point.x;
                    point.y = __v.y + point.y;
                    arcRadiusPoints3Dtemp.push(point);

                    const pointOnRadiusLine = new Link(pivotPoint.clone(), point.clone());
                    pointOnRadiusLine.length = wall.arcRadius;
                    // wall.arcRadiusPoints3D.push(point.clone());
                    wall.arcRadiusPoints3D_1.push(pointOnRadiusLine.b.clone());

                    pointOnRadiusLine.length = wall.arcRadius + wall.mainLink.depth;
                    wall.arcRadiusPoints3D_2.push(pointOnRadiusLine.b.clone());
                }
            }
            wall.arcRadiusPoints3D_1.push(wall.mainLink.b.clone());

            const pointOnRadiusLineB = new Link(pivotPoint.clone(), wall.mainLink.b.clone());
            pointOnRadiusLineB.length = wall.arcRadius + wall.mainLink.depth;
            wall.arcRadiusPoints3D_2.push(pointOnRadiusLineB.b.clone());
        }

        this.clearEmptyNodes();
        this.setFloors('floor');
    }

    moveDragPairNode(node = false) {
        if (!node) node = this.dragNode;

        this.bWalls.forEach(wall => {
            const index = wall.nodes.indexOf(node);

            if (wall.isBezier) {
                const bezier = new Bezier(
                    wall.nodes[0].x,
                    wall.nodes[0].y,
                    wall.bezierControlPoint_1A.x,
                    wall.bezierControlPoint_1A.y,
                    wall.bezierControlPoint_1B.x,
                    wall.bezierControlPoint_1B.y,
                    wall.nodes[1].x,
                    wall.nodes[1].y);

                const rotateOutline = (wall.mainLink.lrBuild === 'left') ? -wall.mainLink.depth : wall.mainLink.depth;
                const rotateInline = (wall.mainLink.lrBuild === 'left') ? wall.mainLink.innerDepth : -wall.mainLink.innerDepth;

                const outlineBezier = bezier.offset(rotateOutline);
                const inlineBezier = bezier.offset(rotateInline);

                const outlineBezierStart = new Node(outlineBezier[0].points[0].x, outlineBezier[0].points[0].y);
                const outlineBezierEnd = new Node(outlineBezier[outlineBezier.length - 1].points[3].x, outlineBezier[outlineBezier.length - 1].points[3].y);
                wall.parallelLink = new Link(outlineBezierStart, outlineBezierEnd);

                const inlineBezierStart = new Node(inlineBezier[0].points[0].x, inlineBezier[0].points[0].y);
                const inlineBezierEnd = new Node(inlineBezier[inlineBezier.length - 1].points[3].x, inlineBezier[inlineBezier.length - 1].points[3].y);
                wall.innerLink = new Link(inlineBezierStart, inlineBezierEnd);

                wall.nodes[2] = outlineBezierStart;
                wall.nodes[3] = outlineBezierEnd;
                wall.nodes[4] = inlineBezierStart;
                wall.nodes[5] = inlineBezierEnd;

                wall.outlineBezier = outlineBezier;
                wall.inlineBezier = inlineBezier;
                wall.inlineBezierLUT = inlineBezier.map((bezier) => bezier.getLUT(32));
                wall.bezier = bezier;
            }

            if (index > -1 && !wall.isBezier) {
                let mainLink = null;
                let parallelLink = null;
                let innerLink = null;
                let rotate = null;
                if (index === 0 || index === 1) {
                    mainLink = wall.mainLink;
                    parallelLink = wall.parallelLink;
                    innerLink = wall.innerLink;
                    rotate = (wall.mainLink.lrBuild === 'left') ? 90 : -90;
                } else if (index === 2 || index === 3) {
                    mainLink = wall.parallelLink;
                    innerLink = wall.innerLink;
                    parallelLink = wall.mainLink;
                    rotate = (wall.mainLink.lrBuild === 'left') ? -90 : 90;
                }

                const v = mainLink.a.clone().sub(mainLink.b);
                const __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180));

                const _vParallel = __v.clone().setLength(wall.mainLink.depth);
                parallelLink.a.x = _vParallel.x + mainLink.a.x;
                parallelLink.a.y = _vParallel.y + mainLink.a.y;
                parallelLink.b.x = _vParallel.x + mainLink.b.x;
                parallelLink.b.y = _vParallel.y + mainLink.b.y;

                const _vInner = __v.clone().setLength(wall.mainLink.innerDepth);
                innerLink.a.x = -_vInner.x + mainLink.a.x;
                innerLink.a.y = -_vInner.y + mainLink.a.y;
                innerLink.b.x = -_vInner.x + mainLink.b.x;
                innerLink.b.y = -_vInner.y + mainLink.b.y;

                if (wall.isArc) {
                    const cNode = wall.mainLink.a.clone().add(wall.mainLink.b.clone().sub(wall.mainLink.a).multiplyScalar(.5));
                    const v = wall.mainLink.a.clone().sub(cNode)
                    const rotate = (wall.mainLink.lrBuild === 'left') ? -90 : 90;
                    const __v = v.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(wall.arcRadiusLine);
                    const pivotPoint = new Node(__v.x + cNode.x, __v.y + cNode.y);
                    wall.nodes[4] = pivotPoint;

                    wall.arcRadius = pivotPoint.clone().sub(wall.mainLink.a).length();
                    wall.arcRadius1 = pivotPoint.clone().sub(wall.mainLink.a).negate().normalize().setLength(500);
                    wall.arcRadius2 = pivotPoint.clone().sub(wall.mainLink.b).negate().normalize().setLength(500);
                    wall.arcCenterCorner = wall.arcRadius2.angle() - wall.arcRadius1.angle();
                    wall.arcLenght = wall.arcRadius * wall.arcCenterCorner;
                }
            }
        });
        if (node?.isFigure) {
            this.setFloors('figures');
        }
        this.setFloors('floor');
        this.setFloorMaterials(this.cycles.filter((cycle) => !cycle?.image));
    }

    setFloors(mode) {
        const cycles = [];

        const thisLinks = this.links.filter((l) => (mode === 'floor' && l.isWall) || (mode === 'figures' && l.isFigure));

        findCycles(thisLinks).cycles.forEach((cycle) => {
            const points = cycle;
            const loop = [];

            points.forEach((point, index) => {
                const nextIndex = (index + 1) % points.length;
                const foundLink = thisLinks.find((link) => (
                    (link.a.x === point.x && link.a.y === point.y
                        && link.b.x === points[nextIndex].x && link.b.y === points[nextIndex].y)
                    || (link.b.x === point.x && link.b.y === point.y
                        && link.a.x === points[nextIndex].x && link.a.y === points[nextIndex].y)
                ));
                if (foundLink) {
                    loop.push(foundLink);
                 }
            })

            cycles.push(this.setFloor(points, loop, mode));
        })

        let oldCycles = [];
        let oldTargetCycles = [];
        if (mode === 'figures') {
            oldCycles = this.cycles.filter((c) => c.isFloor);
            oldTargetCycles = this.cycles.filter((c) => c.isFigure);
        } else if (mode === 'floor') {
            oldCycles = this.cycles.filter((c) => c.isFigure);
            oldTargetCycles = this.cycles.filter((c) => c.isFloor);
        }

        cycles.forEach((c1) => {
            oldTargetCycles.forEach((c2) => {
                if (c1.equals(c2)) {
                    if (c2.diagonalCenter.isMoved) c1.diagonalCenter = c2.diagonalCenter;
                    c1.material = c2.material.clone();
                    c1.height = c2.height;
                    c1.heightFromFloor = c2.heightFromFloor;
                    c1.rgbColor = c2.rgbColor;
                    c1.rgb = JSON.parse(JSON.stringify(c2.rgb));
                    c1.objTitle = c2.objTitle;
                    c1.objComment = c2.objComment;
                    c1.objImages = c2.objImages.map(img => img);
                    c1.estimate = c2.estimate;
                    c1.image = c2.image;
                    c1.rotation = c2.rotation;
                }
            });
        });

        this.cycles = oldCycles.concat(cycles);
    }

    setFloor(_points, loop, mode) {
        let type;
        if (mode === 'floor') {
            type = 'floor';
        } else if (mode === 'figures') {
            type = 'figure';
        }

        const splitLinks = [];
        const points = [];
        /* "Закрытие" стен */
        if (type === 'floor' && loop.length > 1) {
            let prevLink = loop[loop.length - 1];
            loop.forEach((link, index) => {
                const prevWall = this.bWalls.filter((w) => w.mainLink === prevLink)[0];
                const curWall = this.bWalls.filter((w) => w.mainLink === link)[0];

                const prevParallelLink = prevWall.parallelLink;
                const curParallelLink = curWall.parallelLink;

                const prevInnerLink = prevWall.innerLink;
                const curInnerLink = curWall.innerLink;

                if (prevParallelLink && curParallelLink && prevInnerLink && curInnerLink) {
                    const crossParallelPoint = getIntersectionLongLines(prevParallelLink, curParallelLink);
                    const crossInnerPoint = getIntersectionLongLines(prevInnerLink, curInnerLink);

                    if (prevLink.a.x === link.a.x && prevLink.a.y === link.a.y) {
                        if (crossParallelPoint && crossInnerPoint) {
                            prevParallelLink.a.x = crossParallelPoint.x;
                            prevParallelLink.a.y = crossParallelPoint.y;
                            curParallelLink.a.x = crossParallelPoint.x;
                            curParallelLink.a.y = crossParallelPoint.y;

                            prevInnerLink.a.x = crossInnerPoint.x;
                            prevInnerLink.a.y = crossInnerPoint.y;
                            curInnerLink.a.x = crossInnerPoint.x;
                            curInnerLink.a.y = crossInnerPoint.y;
                        } else {
                            splitLinks.push({
                                link,
                                point: {
                                    a: new Vector2(prevInnerLink.a.x, prevInnerLink.a.y),
                                    b: new Vector2(curInnerLink.a.x, curInnerLink.a.y)
                                }
                            });
                        }

                        points.push(prevInnerLink.a);
                        points.push(curInnerLink.a);
                    } else if (prevLink.a.x === link.b.x && prevLink.a.y === link.b.y) {
                        if (crossParallelPoint && crossInnerPoint) {
                            prevParallelLink.a.x = crossParallelPoint.x;
                            prevParallelLink.a.y = crossParallelPoint.y;
                            curParallelLink.b.x = crossParallelPoint.x;
                            curParallelLink.b.y = crossParallelPoint.y;

                            prevInnerLink.a.x = crossInnerPoint.x;
                            prevInnerLink.a.y = crossInnerPoint.y;
                            curInnerLink.b.x = crossInnerPoint.x;
                            curInnerLink.b.y = crossInnerPoint.y;
                        } else {
                            splitLinks.push({
                                link,
                                point: {
                                    a: new Vector2(prevInnerLink.a.x, prevInnerLink.a.y),
                                    b: new Vector2(curInnerLink.b.x, curInnerLink.b.y)
                                }
                            });
                        }

                        points.push(prevInnerLink.a);
                        points.push(curInnerLink.b);
                    } else if (prevLink.b.x === link.a.x && prevLink.b.y === link.a.y) {
                        if (crossParallelPoint && crossInnerPoint) {
                            prevParallelLink.b.x = crossParallelPoint.x;
                            prevParallelLink.b.y = crossParallelPoint.y;
                            curParallelLink.a.x = crossParallelPoint.x;
                            curParallelLink.a.y = crossParallelPoint.y;

                            prevInnerLink.b.x = crossInnerPoint.x;
                            prevInnerLink.b.y = crossInnerPoint.y;
                            curInnerLink.a.x = crossInnerPoint.x;
                            curInnerLink.a.y = crossInnerPoint.y;
                        } else {
                            splitLinks.push({
                                link,
                                point: {
                                    a: new Vector2(prevInnerLink.b.x, prevInnerLink.b.y),
                                    b: new Vector2(curInnerLink.a.x, curInnerLink.a.y)
                                }
                            });
                        }

                        points.push(prevInnerLink.b);
                        points.push(curInnerLink.a);
                    } else if (prevLink.b.x === link.b.x && prevLink.b.y === link.b.y) {
                        if (crossParallelPoint && crossInnerPoint) {
                            prevParallelLink.b.x = crossParallelPoint.x;
                            prevParallelLink.b.y = crossParallelPoint.y;
                            curParallelLink.b.x = crossParallelPoint.x;
                            curParallelLink.b.y = crossParallelPoint.y;

                            prevInnerLink.b.x = crossInnerPoint.x;
                            prevInnerLink.b.y = crossInnerPoint.y;
                            curInnerLink.b.x = crossInnerPoint.x;
                            curInnerLink.b.y = crossInnerPoint.y;
                        } else {
                            splitLinks.push({
                                link,
                                point: {
                                    a: new Vector2(prevInnerLink.b.x, prevInnerLink.b.y),
                                    b: new Vector2(curInnerLink.b.x, curInnerLink.b.y)
                                }
                            });
                        }

                        points.push(prevInnerLink.b);
                        points.push(curInnerLink.b);
                    }
                    prevLink = link;
                }
            });

            if (points.length) points.push(points[0]);
        }
        /* / "Закрытие" стен */
        const cycle = new Cycle(mode === 'floor' ? points : _points, loop, type);

        let center = { x: 0, y: 0 };
        cycle.points.forEach((point) => {
            center.x += point.x;
            center.y += point.y;
        });
        cycle.diagonalCenter = {
            x: center.x / cycle.points.length,
            y: center.y / cycle.points.length
        };

        /* Определение площади пола */
        const geometry = new Geometry();
        cycle.points.forEach((node, index) => {
            let wall = null;
            let isReverse = false;
            if (index < cycle.points.length - 1) {
                const _a = node;
                const _b = cycle.points[index + 1];
                wall = this.bWalls.find((w) => {
                    if (w?.innerLink) {
                        if (w.innerLink.a === _a && w.innerLink.b === _b) {
                            return true;
                        } else if (w.innerLink.a === _b && w.innerLink.b === _a) {
                            isReverse = true;
                            return true;
                        }
                    }
                    return false;
                });
            }

            if (wall && wall.isBezier) {
                const luts = isReverse ? [...wall.inlineBezierLUT].reverse() : [...wall.inlineBezierLUT];
                luts.flat().forEach((lut) => {
                    geometry.vertices.push(
                        new Vector3(lut.x, lut.y, 0)
                    )
                })
            } else {
                geometry.vertices.push(
                    new Vector3(node.x, node.y, 0)
                )
            }
        })
        geometry.computeBoundingBox();

        const centerVector = new Vector2(cycle.diagonalCenter.x, cycle.diagonalCenter.y);
        if (cycle._links.length > 2) {
            let lineFloor = [];
            cycle._links.forEach((link, index) => {
                if (cycle.isFigure) {
                    for (let i = 0; i < this.links.length; i++) {
                        const currentLink = this.links[i];

                        if (currentLink === link) {
                            if (!currentLink?.a) return;

                            const mla = new Vector2(currentLink.a.x, currentLink.a.y);
                            const mlb = new Vector2(currentLink.b.x, currentLink.b.y);

                            lineFloor.push({ a: mla, b: mlb });
                            break;
                        }
                    }
                } else {
                    for (let i = 0; i < this.bWalls.length; i++) {
                        const wall = this.bWalls[i];
                        if (wall.mainLink === link) {
                            if (!wall.parallelLink?.a) return;

                            const mla = new Vector2(wall.innerLink.a.x, wall.innerLink.a.y);
                            const mlb = new Vector2(wall.innerLink.b.x, wall.innerLink.b.y);
                            const lerp_ml = mla.lerp(mlb, 0.5);

                            const pla = new Vector2(wall.parallelLink.a.x, wall.parallelLink.a.y);
                            const plb = new Vector2(wall.parallelLink.b.x, wall.parallelLink.b.y);
                            const lerp_pl = pla.lerp(plb, 0.5);

                            if (!wall.isBezier) {
                                const splitLink = splitLinks.find((l) => l.link === link);
                                if (splitLink) lineFloor.push(splitLink.point);
                                if (centerVector.clone().distanceTo(lerp_ml) > centerVector.clone().distanceTo(lerp_pl)) {
                                    lineFloor.push({ a: pla, b: plb });
                                } else {
                                    lineFloor.push({ a: mla, b: mlb });
                                }
                            } else {
                                const inlineBezierLUT = wall.inlineBezierLUT;
                                inlineBezierLUT.forEach((points) => {
                                    points.forEach((point, index) => {
                                        if (index < points.length - 1) {
                                            lineFloor.push({
                                                a: new Vector2(point.x, point.y),
                                                b: new Vector2(points[index + 1].x, points[index + 1].y),
                                            });
                                        }
                                    });
                                })
                            }
                            break;
                        }
                    }
                }
            })

            const nodeFloor = [];
            for (let i = 0; i < lineFloor.length - 1; i++) {
                if (i === 0) {
                    if ((lineLineIntersection([lineFloor[i].a, lineFloor[i].b], [lineFloor[lineFloor.length - 1].a, lineFloor[lineFloor.length - 1].b])) === false) {
                        continue;
                    }
                    nodeFloor.push(lineLineIntersection([lineFloor[i].a, lineFloor[i].b], [lineFloor[lineFloor.length - 1].a, lineFloor[lineFloor.length - 1].b]));
                }
                if ((lineLineIntersection([lineFloor[i].a, lineFloor[i].b], [lineFloor[i + 1].a, lineFloor[i + 1].b])) === false) {
                    continue;
                }
                nodeFloor.push(lineLineIntersection([lineFloor[i].a, lineFloor[i].b], [lineFloor[i + 1].a, lineFloor[i + 1].b]));
            }

            const columns = this.columns.filter((column) => column.type === this.level ? checkColumn(column, cycle) : false);
            const columnsSquare = columns.reduce((acc, curr) => {
                acc += (curr.depth * curr.width) / Math.pow(10, 6);
                return acc;
            }, 0) || 0

            cycle.square = Math.abs(THREE.ShapeUtils.area(nodeFloor) / 1000000) - Math.abs(columnsSquare);
        }

        cycle.squareText = (Math.round(cycle.square * 1000) / 1000).toString() + ' м2';

        cycle.texRect = {
            x: geometry.boundingBox.min.x,
            y: geometry.boundingBox.min.y,
            w: geometry.boundingBox.max.x - geometry.boundingBox.min.x,
            h: geometry.boundingBox.max.y - geometry.boundingBox.min.y
        };

        return cycle;
    }

    setFloorMaterials(cycles) {
        const cyclesToUpd = cycles ? cycles : this.cycles;
        cyclesToUpd.forEach((cycle) => {
            if (cycle?.square > 0 && cycle?.texRect?.w && cycle?.texRect?.h) {
                const material = cycle.material.userData.IMAGE;
                const image = new Image();
                image.src = this.getFloorTexture(cycle, material);
                if (image) cycle.image = image;
            }
        })
    }

    getFloorTexture(floor, material) {
        const angle = floor?.rotation || 0;
        const texRect = floor?.texRect;
        const scaleFactor = 8; // Константа для масштаба
        let dataImage = null;

        const ratio = 1;

        const canvasFake = document.createElement('canvas');
        const canvasFake2 = document.createElement('canvas');
        const ctxFake = canvasFake.getContext('2d');
        const ctxFake2 = canvasFake2.getContext('2d');
        canvasFake.width = texRect.w * ratio / scaleFactor;
        canvasFake.height = texRect.h * ratio / scaleFactor;
        ctxFake.scale(ratio, ratio);
        canvasFake2.width = texRect.w * ratio / scaleFactor;
        canvasFake2.height = texRect.h * ratio / scaleFactor;
        ctxFake2.scale(ratio, ratio);

        const xN = texRect.x;
        const yN = texRect.y;

        const img_id = this.loadedImagesURLs.indexOf(material);

        const bgImage = this.loadedImages[img_id];
        ctxFake2.clearRect(0, 0, canvasFake2.width, canvasFake2.height);

        if (bgImage) {
            const centerX = canvasFake2.width / 2;
            const centerY = canvasFake2.height / 2;

            ctxFake2.save();
            ctxFake2.translate(centerX, centerY);
            ctxFake2.rotate((angle * Math.PI) / 180);
            ctxFake2.translate(-centerX, -centerY);

            const diagonal = Math.sqrt(bgImage.width * bgImage.width + bgImage.height * bgImage.height);
            const newWidth = diagonal / ratio;
            const newHeight = diagonal / ratio;

            const wCount = Math.ceil(canvasFake2.width / newWidth);
            const hCount = Math.ceil(canvasFake2.height / newHeight);

            for (let i = -wCount; i <= wCount; i++) {
                for (let j = -hCount; j <= hCount; j++) {
                    const x = i * bgImage.width / ratio + centerX - newWidth / 2;
                    const y = j * bgImage.height / ratio + centerY - newHeight / 2;
                    ctxFake2.drawImage(bgImage, x, y, bgImage.width / ratio, bgImage.height / ratio);
                }
            }

            ctxFake2.restore();
        }

        ctxFake.clearRect(0, 0, canvasFake.width, canvasFake.height);
        ctxFake.save();
        ctxFake.fillStyle = '#f00';
        ctxFake.strokeStyle = '#f00';

        floor.points.forEach((p, index) => {
            const x = (p.x - xN) / scaleFactor;
            const y = (p.y - yN) / scaleFactor;
            const point = new Node(x, y);

            let wall = null;
            let isReverse = false;
            if (index < floor.points.length - 1) {
                const _a = p;
                const _b = floor.points[index + 1];
                wall = this.bWalls.find((w) => {
                    if (w.innerLink.a === _a && w.innerLink.b === _b) {
                        return true;
                    } else if (w.innerLink.a === _b && w.innerLink.b === _a) {
                        isReverse = true;
                        return true;
                    }
                    return false;
                });
            }

            if (index === 0) {
                ctxFake.beginPath();
                ctxFake.moveTo(point.x, point.y);
            }

            if (wall && wall.isBezier) {
                const a = new Node((wall.nodes[0].x - xN) / scaleFactor, (wall.nodes[0].y - yN) / scaleFactor);
                const b = new Node((wall.nodes[1].x - xN) / scaleFactor, (wall.nodes[1].y - yN) / scaleFactor);
                const control_1A = new Node((wall.bezierControlPoint_1A.x - xN) / scaleFactor, (wall.bezierControlPoint_1A.y - yN) / scaleFactor);
                const control_1B = new Node((wall.bezierControlPoint_1B.x - xN) / scaleFactor, (wall.bezierControlPoint_1B.y - yN) / scaleFactor);

                if (isReverse) {
                    ctxFake.lineTo(b.x, b.y);
                    ctxFake.bezierCurveTo(control_1B.x, control_1B.y, control_1A.x, control_1A.y, a.x, a.y);
                } else {
                    ctxFake.lineTo(a.x, a.y);
                    ctxFake.bezierCurveTo(control_1A.x, control_1A.y, control_1B.x, control_1B.y, b.x, b.y);
                }
            } else {
                ctxFake.lineTo(point.x, point.y);
            }
        });

        ctxFake.closePath();
        ctxFake.stroke();
        ctxFake.fill();

        ctxFake.globalCompositeOperation = "source-in";

        ctxFake.drawImage(canvasFake2, 0, 0);

        ctxFake.restore();
        dataImage = canvasFake.toDataURL();

        return dataImage;
    }

    loadImages = (materials) => {
        materials.forEach((material) => {
            const imgUrl = material.userData.IMAGE;
            this.loadedImagesURLs.push(imgUrl);
            this.queueImages.push(imgUrl);
            const img_id = this.loadedImages.push(new Image()) - 1;
            this.loadedImages[img_id].src = imgUrl;

            this.loadedImages[img_id].addEventListener('load', () => {
                const queueImageIndex = this.queueImages.indexOf(imgUrl);
                if (queueImageIndex !== -1) {
                    this.queueImages.splice(queueImageIndex, 1);
                }
                if (this.isQueueImagesEmpty()) {
                    this.setFloorMaterials();
                }
            }, false);
        });
    }

    isQueueImagesEmpty = () => {
        let result = true;
        for (let i = 0; i < this.queueImages.length; i++){
            if (undefined !== this.queueImages[i]){
                result = false;
                break;
            }
        }
        return result;
    }

    copyActiveCycle() {
        const maxRight = this.activeObject.object.texRect.w + 1000;
        const mode = this.mode;
        this.mode = 'walls';
        for (let i = 0; this.activeObject.object.links.length > i; i++) {
            const startNode = new Node(this.activeObject.object.links[i].a.x + maxRight,
                this.activeObject.object.links[i].a.y + 1000,
                "wall")
            const endNode = new Node(this.activeObject.object.links[i].b.x + maxRight,
                this.activeObject.object.links[i].b.y + 1000,
                "wall")
            this.putNode(startNode);
            this.putNode(endNode);

            this.virtualLink = new Link(startNode, endNode, 'wall', this.activeObject.object.links[i].lrBuild);
            this.virtualLink.depth = this.activeObject.object.links[i].depth;

            this.virtualWall = this.bWalls.filter(w => w.mainLink === this.activeObject.object.links[i])[0];

            if (this.activeObject.object.links[i].controlA && this.activeObject.object.links[i].controlB) {
                const controlA = new Node(this.activeObject.object.links[i].controlA.x +  maxRight,
                    this.activeObject.object.links[i].controlA.y + 1000,
                    "control");
                const controlB = new Node(this.activeObject.object.links[i].controlB.x +  maxRight,
                    this.activeObject.object.links[i].controlB.y + 1000,
                    "control");

                this.putNode(controlA);
                this.putNode(controlB);

                this.virtualLink.controlA = controlA;
                this.virtualLink.controlB = controlB;
            }

            this.putWall();
        }
        this.mode = mode;
    }

    // Пока не востребована
    mergeNode(n1, n2, position = 'last') {
        if (this.mode === 'ruler')
            return

        let pVars = {}

        switch (position) {
            case 'last': {
                pVars.links.map(l => {
                    if (l.a === n1) l.a = n2;
                    if (l.b === n1) l.b = n2;
                });
                pVars.nodes = pVars.nodes.filter(n => n !== n1);
                break;
            }
            case 'first': {
                pVars.links.map(l => {
                    if (l.a === n2) l.a = n1;
                    if (l.b === n2) l.b = n1;
                });
                pVars.nodes = pVars.nodes.filter(n => n !== n2);
                break;
            }
            case 'center': {
                n2.setX((n1.x + n2.x) / 2);
                n2.setY((n1.y + n2.y) / 2);
                pVars.links.map(l => {
                    if (l.a === n1) l.a = n2;
                    if (l.b === n1) l.b = n2;
                });
                pVars.nodes = pVars.nodes.filter(n => n !== n1);
                break;
            }
        }

        this.clearTwins();
        this.calcGraphLoop();
    }
    ///////////////////// Удаляет дубликаты стен ///////////////////////////////////////////////////
    // Пока не востребована
    clearTwins() {
        const newLinks = []
        this.links.map(l => {
            const double = newLinks.find(lnk => {
                if (
                    (l.a === lnk.a && l.b === lnk.b) ||
                    (l.a === lnk.b && l.b === lnk.a) ||
                    (l.a.x === l.b.x && l.a.y === l.b.y)
                ) return true;
                else return false
            })
            if (!double) newLinks.push(l);
        })
        this.links = newLinks;
        this.nodes = this.nodes.filter(n => this.links.find(l => l.a === n || l.b === n));
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////
    clearEmptyNodes() {
        this.nodes = this.nodes.filter(n => {
            return this.links.find(l => {
                return l.a === n || l.b === n || l.controlA === n || l.controlB === n;
            });
        })
    }
    //////////////////////// Поиск комнат //////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    clone() {
        const plan = new Plan();
        plan.nodes = this.nodes;
        plan.links = this.links;

        plan.virtualNode = this.virtualNode;
        plan.virtualLink = this.virtualLink;
        return plan;
    }
    /////////////////////////////////////////////////////////////////////////////////////////////
    removeNode(node) {

    }

    removeWall(wall) {
        const mainLink = wall.mainLink;
        const parallelLink = wall.parallelLink;
        const innerLink = wall.innerLink;
        this.bWalls = this.bWalls.filter((wall, index) => {
            if (wall.mainLink === mainLink) {
                this.columns.forEach((column) => {
                    if (column.parentWallID > index) {
                        column.parentWallID = column.parentWallID - 1;
                    } else if (column.parentWallID === index) {
                        column.parentWallID = -1;
                        column.parentWallSide = "";
                    }
                });
                return false;
            }
            return true;
        });
        this.links = this.links.filter((l) => l !== mainLink && l !== parallelLink && l !== innerLink);
        this.clearEmptyNodes();
    }

    clearEmptyCycles() {
        this.cycles = this.cycles.filter(cycle => cycle.links !== this.activeObject.object.links);
    }

    removeWalls(wallLinks) {
        wallLinks.forEach(link => {
            for (const wall of this.bWalls) {
                if (wall.mainLink === link) {
                    this.removeWall(wall);
                    break;
                }
            }
        })
    }

    removeColumn(column) {
        this.columns = this.columns.filter((_column) => _column !== column);
    }

    copyColumn(column) {
        this.columns.push(column.clone());
    }

    removeLink(link) {
        this.links = this.links.filter(l => l !== link);
        this.clearEmptyNodes();
    }

    changeAngle(link, links, currentAngle, newAngle) {
        let node = null;
        let otherNode = null;
        let clockwise = 1;

        const checkAngle = (v1, v2) => {
            let crossProduct = new THREE.Vector3();
            crossProduct.crossVectors(v1, v2);
            if (crossProduct.z > 0) {
                return 1;
            } else {
                return -1;
            }
        }

        links.forEach((l) => {
            if (l !== link) {
                if (l.a.x === link.a.x && l.a.y === link.a.y) {
                    node = link.a;
                    otherNode = link.b;
                    clockwise = checkAngle(link.b.clone().sub(link.a), l.b.clone().sub(l.a));
                }
                if (l.a.x === link.b.x && l.a.y === link.b.y) {
                    node = link.b;
                    otherNode = link.a;
                    clockwise = checkAngle(link.a.clone().sub(link.b), l.b.clone().sub(l.a));
                }
                if (l.b.x === link.b.x && l.b.y === link.b.y) {
                    node = link.b;
                    otherNode = link.a;
                    clockwise = checkAngle(link.a.clone().sub(link.b), l.a.clone().sub(l.b));
                }
                if (l.b.x === link.a.x && l.b.y === link.a.y) {
                    node = link.a;
                    otherNode = link.b;
                    clockwise = checkAngle(link.b.clone().sub(link.a), l.a.clone().sub(l.b));
                }
            }
        })

        if (node && otherNode) {
            const angle = currentAngle - newAngle;
            otherNode.rotateAround(node, clockwise * angle * (Math.PI / 180))
            this.changeWallLRBuild(link);
        }
    }

    isFreeLink(link) {
        const isFree = this.links.filter((l) => (
            l !== link && (
                (l.a.x === link.a.x && l.a.y === link.a.y)
                || (l.b.x === link.b.x && l.b.y === link.b.y)
                || (l.a.x === link.b.x && l.a.y === link.b.y)
                || (l.b.x === link.a.x && l.b.y === link.a.y)
            )
        )).length < 2;

        if (isFree) return link;
        return false;
    }

    cutWall(wall, weight = 0.5) {
        let pVars = {}

        const objects = wall.objects
        const newNode = wall.a.clone().add(wall.b.clone().sub(wall.a).multiplyScalar(weight));
        pVars.nodes.push(newNode);
        const newLink = new Link(newNode, wall.b);
        wall.b = newNode;

        const length1 = (Math.ceil((new Vector2(wall.a.x, wall.a.y))
            .distanceTo(new Vector2(wall.b.x, wall.b.y))))

        const objects1 = []
        const objects2 = []
        objects.forEach(obj => {
            const start = obj.pos
            const end = obj.pos + obj.width
            if (start < length1 && end > length1) {
                // объект в точке деления
            } else if (start < length1 && end < length1) {
                objects1.push(obj)
            } else if (start > length1) {
                obj.pos -= length1
                objects2.push(obj)
            }
        })
        wall.objects = objects1
        newLink.objects = objects2

        pVars.links.push(newLink);


        this.clearTwins();
        this.calcGraphLoop();
    }

    toOBJ() {
        const result = {};
        result.nodes = this.nodes.map(n => ({
            x: n.x,
            y: n.y,
            isRuler: n.isRuler,
            isWall: n.isWall,
            isFigure: n.isFigure,
            isControl: n.isControl,
            isLeader: n?.isLeader || false,
        }));

        result.links = this.links.map(l => ({
            a: this.nodes.indexOf(l.a),
            b: this.nodes.indexOf(l.b),
            depth: l.depth,
            innerDepth: l.innerDepth,
            height: l.height,
            lrBuild: l.lrBuild,
            isRuler: l.isRuler,
            isWall: l.isWall,
            isFigure: l.isFigure,
            isBezier: l.isBezier,
            isLeader: l?.isLeader || false,
            leaderText: l?.leaderText || '',
            controlA: this.nodes.indexOf(l.controlA),
            controlB: this.nodes.indexOf(l.controlB),
            objTitle: l.objTitle,
            objComment: l.objComment,
            objImages: l.objImages.map(img => img),
            showCircle: l?.showCircle || false,
        }));

        result.bWalls = this.bWalls.map(w => ({
            isWall: w.isWall,
            isArc: w.isArc,
            isBezier: w.isBezier,
            bezierControlPoint_1A: this.nodes.indexOf(w.bezierControlPoint_1A),
            bezierControlPoint_1B: this.nodes.indexOf(w.bezierControlPoint_1B),
            outlineBezier: w.outlineBezier,
            inlineBezier: w.inlineBezier,
            arcRadiusLine: w.arcRadiusLine,
            arcRadius: w.arcRadius,
            arcLenght: w.arcLenght,
            arcCenterCorner: w.arcCenterCorner,
            arcRadius1: w.arcRadius1,
            arcRadius2: w.arcRadius2,
            estimate: w.estimate,
            rightCols: w.rightCols,
            leftCols: w.leftCols,
            mainLink: this.links.indexOf(w.mainLink),
            parallelLink: this.links.indexOf(w.parallelLink),
            innerLink: this.links.indexOf(w.innerLink),
            material: w.material.userData.ID,
            planMaterial: w.planMaterial,
            materialRGB: JSON.parse(JSON.stringify(w.materialRGB)),
            objTitle: w.objTitle,
            objComment: w.objComment,
            objImages: w.objImages.map(img => img),
            objects: w.objects.map((o) => ({
                height: o.height,
                width: o.width,
                isHole: o.isHole,
                isDoor: o.isDoor,
                isWindow: o.isWindow,
                isElectricSocket: o.isElectricSocket,
                isSwitch: o.isSwitch,
                isHeatingBattery: o.isHeatingBattery,
                isElectricPanel: o.isElectricPanel,
                outletElectricalWire:o.outletElectricalWire,
                isRedCube: o.isRedCube,
                isCylinder: o.isCylinder,
                rgb: o.rgb === undefined ? {
                    r: '255',
                    g: '0',
                    b: '0',
                    a: '1',
                } : o.rgb,
                position: 0,
                pos: o.pos,
                padding: o.padding,
                heightFromFloor: o.heightFromFloor,
                inside: o.inside,
                left: o.left,
                depth: o.depth,
                depthFor3D: o.depthFor3D,
                depthIndent: o.depthIndent,
                depthIndentFor3D: o.depthIndentFor3D,
                lrBuild: o.lrBuild,
                len1: o.len1,
                len2: o.len2,
                id: o.id,
                ordinalNumber: o.ordinalNumber,
                scale: o?.scale || 1,
                isCircleHole: o?.isCircleHole || false,
                ...((o?.isElectricSocket || o?.outletElectricalWire || o?.isSwitch) ? {
                    count: o.count,
                    type: o.type,
                } : {}),

                ...(o?.isWindow ? {
                    isCorner: o?.isCorner || false,
                    cornerWall: this.bWalls.indexOf(o?.cornerWall) || null,
                } : {}),

                estimate: o?.estimate || [],
                objTitle: o.objTitle,
                objComment: o.objComment,
                objImages: o.objImages.map(img => img),
            })),
        }));

        // console.log(this.links)

        result.cycles = this.cycles.map(c => {
            return {
                points: c._points.map(p => (this.nodes.indexOf(p))),
                materialID: c.material.userData.ID,
                isFloor: c.isFloor,
                isFigure: c.isFigure,
                rgbColor: c.rgbColor,
                rgb: c.rgb,
                objTitle: c.objTitle,
                objComment: c.objComment,
                objImages: c.objImages.map(img => img),
                estimate: c?.estimate || [],
                rotation: c?.rotation || 0,
                ...(c.diagonalCenter ? { diagonalCenter: c.diagonalCenter } : {})
            }
        })

        result.columns = this.columns.map((column) => {
            return {
                x: column.x,
                y: column.y,
                width: column.width,
                depth: column.depth,
                height: column.height,
                angle: column.angle,
                points: column.points.map((n) => ({ x: n.x, y: n.y })),
                parentWallID: column.parentWallID,
                parentWallSide: column.parentWallSide,
                leftSide_mID: column.leftSide.userData.ID,
                topSide_mID: column.topSide.userData.ID,
                rightSide_mID: column.rightSide.userData.ID,
                bottomSide_mID: column.bottomSide.userData.ID,
                leftSideRGB: JSON.parse(JSON.stringify(column.leftSideRGB)),
                topSideRGB: JSON.parse(JSON.stringify(column.topSideRGB)),
                rightSideRGB: JSON.parse(JSON.stringify(column.rightSideRGB)),
                bottomSideRGB: JSON.parse(JSON.stringify(column.bottomSideRGB)),
                objTitle: column.objTitle,
                objComment: column.objComment,
                objImages: column.objImages.map((img) => img),
                estimate: column.estimate || [],

                type: column?.type || 'floor',
                isFixed: column?.isFixed || false,
                maxHeight: column?.maxHeight || column?.height,

                objects: column?.objects?.map((o) => ({
                    height: o.height,
                    side: o.side,
                    width: o.width,
                    isHole: o.isHole,
                    isDoor: o.isDoor,
                    isWindow: o.isWindow,
                    isElectricSocket: o.isElectricSocket,
                    isSwitch: o.isSwitch,
                    isHeatingBattery: o.isHeatingBattery,
                    isElectricPanel: o.isElectricPanel,
                    outletElectricalWire:o.outletElectricalWire,
                    isRedCube: o.isRedCube,
                    isCylinder: o.isCylinder,
                    rgb: o.rgb === undefined ? {
                        r: '255',
                        g: '0',
                        b: '0',
                        a: '1',
                    } : o.rgb,
                    position: 0,
                    pos: o.pos,
                    padding: o.padding,
                    heightFromFloor: o.heightFromFloor,
                    inside: o.inside,
                    left: o.left,
                    depth: o.depth,
                    depthFor3D: o.depthFor3D,
                    depthIndent: o.depthIndent,
                    depthIndentFor3D: o.depthIndentFor3D,
                    lrBuild: o.lrBuild,
                    len1: o.len1,
                    len2: o.len2,
                    id: o.id,
                    ordinalNumber: o.ordinalNumber,
                    scale: o?.scale || 1,
                    isCircleHole: o?.isCircleHole || false,
                    ...((o?.isElectricSocket || o?.outletElectricalWire || o?.isSwitch) ? {
                        count: o.count,
                        type: o.type,
                    } : {}),

                    estimate: o?.estimate || [],
                    objTitle: o.objTitle,
                    objComment: o.objComment,
                    objImages: o.objImages.map(img => img),
                })),
            };
        });

        result.offsetX = this.offsetX
        result.offsetY = this.offsetY
        result.zoom = this.zoom
        result.canvasCenter = this.canvasCenter
        // console.log('this',this)
        // console.log('result',result)
        return result;
    }

    loadFromOBJ(planObj) {
        this.nodes = [];
        this.links = [];
        this.columns = [];
        this.virtualNode = null;
        this.virtualLink = null;
        this.dragNode = null;
        this.dragPairNode = null;
        this.bWalls = [];
        this.cycles = [];
        this.mode = 'move' // floor, roof, ruler
        this.cycleActive = -1;

        planObj.nodes.map((n) => {
            const node = new Node(n.x, n.y);
            node.isRuler = n.isRuler;
            node.isWall = n.isWall;
            node.isFigure = n.isFigure;
            node.isControl = n.isControl;
            node.isLeader = n?.isLeader || false;
            this.nodes.push(node);
        });

        planObj.links.map((l) => {
            if (!this.nodes[l.a] || !this.nodes[l.b]) {
                this.nodes[l.a] = new Node(0, 0);
                this.nodes[l.b] = new Node(0, 0);
            }

            const link = new Link(this.nodes[l.a], this.nodes[l.b]);
            link.depth = l.depth;
            link.innerDepth = l.innerDepth || 0;
            link.height = l.height;
            link.lrBuild = l.lrBuild;
            link.isRuler = l.isRuler;
            link.isWall = l.isWall;
            link.isFigure = l.isFigure;
            link.isBezier = l.isBezier;
            link.controlA = this.nodes[l.controlA];
            link.controlB = this.nodes[l.controlB];
            link.objTitle = (l.objTitle) ? l.objTitle : '';
            link.objComment = (l.objComment) ? l.objComment : '';
            link.objImages = (l.objImages) ? l.objImages.map(img => img) : [];
            link.showCircle = (l?.showCircle) ? l.showCircle : false;
            link.isLeader = (l?.isLeader) ? l.isLeader : false;
            link.leaderText = (l?.leaderText) ? l.leaderText : '';

            this.links.push(link);
        });

        if (planObj.columns) {
            planObj.columns.forEach((__column, i) => {
                const column = new Column(new Node(__column.x, __column.y));
                __column.points.forEach((n) => {
                    column.points.push(new Node(n.x, n.y));
                });
                column.type = __column.type;
                column.width = __column.width;
                column.depth = __column.depth;
                column.height = __column.height;
                column.angle = __column.angle;
                column.parentWallID = undefined !== __column.parentWallID ? __column.parentWallID : -1;
                column.parentWallSide = undefined !== __column.parentWallSide ? __column.parentWallSide : '';
                column.leftSide = window.materials.wall.find((m) => m.userData.ID === __column.leftSide_mID);
                column.topSide = window.materials.wall.find((m) => m.userData.ID === __column.topSide_mID);
                column.rightSide = window.materials.wall.find((m) => m.userData.ID === __column.rightSide_mID);
                column.bottomSide = window.materials.wall.find((m) => m.userData.ID === __column.bottomSide_mID);

                if (__column.leftSideRGB) column.leftSideRGB = JSON.parse(JSON.stringify(__column.leftSideRGB));
                if (__column.topSideRGB) column.topSideRGB = JSON.parse(JSON.stringify(__column.topSideRGB));
                if (__column.rightSideRGB) column.rightSideRGB = JSON.parse(JSON.stringify(__column.rightSideRGB));
                if (__column.bottomSideRGB) column.bottomSideRGB = JSON.parse(JSON.stringify(__column.bottomSideRGB));

                column.objTitle = planObj.columns[i].objTitle ? planObj.columns[i].objTitle : '';
                column.objComment = planObj.columns[i].objComment ? planObj.columns[i].objComment : '';
                column.objImages = planObj.columns[i].objImages ? planObj.columns[i].objImages.map((img) => img) : [];

                column.estimate = __column?.estimate || [];

                column.type = __column?.type || 'floor';
                column.isFixed = __column?.isFixed || false;
                column.maxHeight = __column?.maxHeight || __column?.height;

                __column?.objects?.forEach((_obj) => {
                    const obj = new ObjectOnWall();
                    obj.isElectricSocket = _obj.isElectricSocket;
                    obj.isSwitch = _obj.isSwitch;
                    obj.isHeatingBattery = _obj.isHeatingBattery;
                    obj.isElectricPanel = _obj.isElectricPanel;
                    obj.outletElectricalWire = _obj.outletElectricalWire;
                    obj.isRedCube = _obj.isRedCube;
                    obj.isCylinder = _obj.isCylinder;
                    obj.scale = _obj?.scale || 1;
                    obj.depthFor3D = _obj.depthFor3D;
                    obj.depthIndentFor3D = _obj.depthIndentFor3D;
                    obj.lrBuild = _obj.lrBuild;
                    obj.rgb = _obj.rgb === undefined ? {
                        r: '255',
                        g: '0',
                        b: '0',
                        a: '1',
                    } : _obj.rgb;

                    obj.estimate = _obj?.estimate || [];
                    obj.height = _obj.height || 100
                    obj.width = _obj.width
                    obj.position = _obj.position
                    obj.pos = _obj.pos
                    obj.padding = _obj.padding
                    obj.heightFromFloor = _obj.heightFromFloor
                    obj.inside = _obj.inside
                    obj.left = _obj.left
                    obj.depth = _obj.depth
                    obj.depthIndent = _obj.depthIndent
                    obj.len1 = _obj.len1
                    obj.len2 = _obj.len2
                    obj.id = _obj.id
                    obj.ordinalNumber = _obj.ordinalNumber
                    obj.side = _obj.side

                    obj.objTitle = (_obj.objTitle) ? _obj.objTitle : '';
                    obj.objComment = (_obj.objComment) ? _obj.objComment : '';
                    obj.objImages = (_obj.objImages) ? _obj.objImages.map(img => img) : [];

                    if (_obj.isElectricSocket || _obj.outletElectricalWire || _obj.isSwitch) {
                        obj.count = _obj.count || 1;
                        obj.type = _obj.type || 'default';
                    }

                    column.objects.push(obj)
                });


                this.columns.push(column);
            });
        }

        planObj.bWalls.map(w => {
            const wall = new Wall();
            wall.isWall = w.isWall;
            wall.isArc = w.isArc;
            wall.isBezier = w.isBezier;
            wall.bezierControlPoint_1A = this.nodes[w.bezierControlPoint_1A];
            wall.bezierControlPoint_1B = this.nodes[w.bezierControlPoint_1B];
            wall.outlineBezier = w.outlineBezier;
            wall.inlineBezier = w?.inlineBezier || [];
            wall.arcRadiusLine = w.arcRadiusLine;
            wall.arcRadius = w.arcRadius;
            wall.arcLenght = w.arcLenght;
            wall.arcCenterCorner = w.arcCenterCorner;
            wall.arcRadius1 = w.arcRadius1;
            wall.arcRadius2 = w.arcRadius2;
            wall.mainLink = this.links[w.mainLink];
            wall.planMaterial = w.planMaterial;
            wall.estimate = w?.estimate || [];
            wall.leftCols = w?.leftCols || [];
            wall.rightCols = w?.rightCols || [];

            wall.nodes.push(wall.mainLink.a);
            wall.nodes.push(wall.mainLink.b);

            wall.material = window.materials.wall.find(m => m.userData.ID === w.material);
            wall.materialRGB = window.materials.wall.find(m => m.userData.ID === w.material);
            if (w.materialRGB) wall.materialRGB = JSON.parse(JSON.stringify(w.materialRGB));
            wall.objTitle = (w.objTitle) ? w.objTitle : '';
            wall.objComment = (w.objComment) ? w.objComment : '';
            wall.objImages = (w.objImages) ? w.objImages.map(img => img) : [];

            w.objects.forEach(_obj => {
                let flagHasError = false;
                let obj;
                if (_obj.isHole || _obj.isDoor || _obj.isWindow) {
                    obj = new Hole();
                    obj.isHole = _obj.isHole
                    obj.isDoor = _obj.isDoor
                    obj.isWindow = _obj.isWindow
                    obj.isCircleHole = _obj?.isCircleHole || false
                } else if (_obj.isElectricSocket || _obj.isSwitch || _obj.isHeatingBattery || _obj.isElectricPanel || _obj.isRedCube || _obj.outletElectricalWire || _obj.isCylinder) {
                    obj = new ObjectOnWall();
                    obj.isElectricSocket = _obj.isElectricSocket;
                    obj.isSwitch = _obj.isSwitch;
                    obj.isHeatingBattery = _obj.isHeatingBattery;
                    obj.isElectricPanel = _obj.isElectricPanel;
                    obj.outletElectricalWire = _obj.outletElectricalWire;
                    obj.isRedCube = _obj.isRedCube;
                    obj.isCylinder = _obj.isCylinder;
                    obj.scale = _obj?.scale || 1;
                    obj.depthFor3D = _obj.depthFor3D;
                    obj.depthIndentFor3D = _obj.depthIndentFor3D;
                    obj.lrBuild = _obj.lrBuild;
                    obj.rgb = _obj.rgb === undefined ? {
                        r: '255',
                        g: '0',
                        b: '0',
                        a: '1',
                    } : _obj.rgb;

                } else {
                    console.error(new Error("Не правильный объект в стене, при сохранении проекта они будут удалены"));
                    flagHasError = true;
                }
                obj.estimate = _obj?.estimate || [];

                if (!flagHasError) {
                    obj.height = _obj.height || 100
                    obj.width = _obj.width
                    obj.position = _obj.position
                    obj.pos = _obj.pos
                    obj.padding = _obj.padding
                    obj.heightFromFloor = _obj.heightFromFloor
                    obj.inside = _obj.inside
                    obj.left = _obj.left
                    obj.depth = _obj.depth
                    obj.depthIndent = _obj.depthIndent
                    obj.len1 = _obj.len1
                    obj.len2 = _obj.len2
                    obj.id = _obj.id
                    obj.ordinalNumber = _obj.ordinalNumber

                    if (obj.isDoor) {
                        const depth = (wall.mainLink.depth + wall.mainLink.innerDepth);
                        const useSavedDepth = _obj?.depth && (_obj?.depth <= depth);

                        obj.depth = useSavedDepth ? _obj?.depth : depth;
                        obj.depthIndent = _obj?.depthIndent && useSavedDepth ? _obj?.depthIndent : 0;
                    }

                    if (obj.isWindow) {
                        obj.isCorner = _obj?.isCorner || false;
                        obj.cornerWall = _obj?.cornerWall || null;
                    }

                    obj.objTitle = (_obj.objTitle) ? _obj.objTitle : '';
                    obj.objComment = (_obj.objComment) ? _obj.objComment : '';
                    obj.objImages = (_obj.objImages) ? _obj.objImages.map(img => img) : [];

                    if (_obj.isElectricSocket || _obj.outletElectricalWire || _obj.isSwitch) {
                        obj.count = _obj.count || 1;
                        obj.type = _obj.type || 'default';
                    }

                    wall.objects.push(obj)
                }
            });

            this.bWalls.push(wall);
        });

        this.bWalls.forEach(w => {
            this.changeWallLRBuild(w.mainLink);
            w.objects.forEach((o) => {
                if (o.isWindow) {
                    o.cornerWall = this.bWalls[o?.cornerWall] || null;
                }
            })
        });

        this.setFloors('figures');
        this.setFloors('floor');

        this.cycles.map((l, i) => {
            if (planObj.cycles[i]) {
                const material = window.materials.floor.find(m => m.userData.ID === planObj.cycles[i].materialID);
                if (material)
                    this.cycles[i].material = material.clone();

                if (planObj.cycles[i].rgbColor)
                    this.cycles[i].rgbColor = planObj.cycles[i].rgbColor;
                if (planObj.cycles[i].rgb)
                    this.cycles[i].rgb = planObj.cycles[i].rgb;

                this.cycles[i].objTitle = (planObj.cycles[i].objTitle) ? planObj.cycles[i].objTitle : '';
                this.cycles[i].objComment = (planObj.cycles[i].objComment) ? planObj.cycles[i].objComment : '';
                this.cycles[i].objImages = (planObj.cycles[i].objImages) ? planObj.cycles[i].objImages.map(img => img) : [];

                this.cycles[i].isFloor = planObj.cycles[i].isFloor;
                this.cycles[i].isFigure = planObj.cycles[i].isFigure;
                this.cycles[i].estimate = planObj.cycles[i]?.estimate || [];
                this.cycles[i].rotation = planObj.cycles[i]?.rotation || 0;

                if (planObj.cycles[i]?.diagonalCenter) this.cycles[i].diagonalCenter = planObj.cycles[i]?.diagonalCenter;
            }
        });

        this.loadImages(window.materials.floor);

        if (planObj.canvasCenter) {
            this.canvasCenter = new Node(planObj.canvasCenter.x, planObj.canvasCenter.y);
        }

        if (this.undoArr.length < 1) {
            this.undoArr.push({
                type: 'plan',
                state: this.toOBJ()
            });
        }
    }

    getCommonPlanPosition() {
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

        if (this.nodes.length) {
            this.nodes.forEach((node) => {
                if (node.x < minX) minX = node.x;
                if (node.x > maxX) maxX = node.x;
                if (node.y < minY) minY = node.y;
                if (node.y > maxY) maxY = node.y;
            });

            const { devicePixelRatio = 1 } = window;
            const ratio = devicePixelRatio >= 1 ? devicePixelRatio - 1 : devicePixelRatio;

            const width = maxX - minX;
            const height = maxY - minY;
            const zoomX = window.innerWidth / width;
            const zoomY = (window.innerHeight - 80) / height;
            const zoom = Math.min(zoomX, zoomY) - 0.002;

            const centerX = (minX + maxX) / 2;
            const centerY = (minY + maxY) / 2;
            const offsetX = (window.innerWidth / 2) * ratio + centerX * zoom;
            const offsetY = ((window.innerHeight) / 2) * ratio - 40 + centerY * zoom;

            return { offsetY, offsetX, zoom, minX, minY, maxX, maxY };
        } else {
            return { offsetX: 0, offsetY: 0, zoom: 0.1 };
        }
    }

    getClosestWall(walls, point, snapSize, exludeWalls) {
        const closestWall = { exist: false }
        for (let i = 0; i < walls.length; i++) {
            let wallIncludeToExludeWalls = false;
            exludeWalls.forEach(eWall => {
                if (eWall === walls[i].mainLink) {
                    wallIncludeToExludeWalls = true;
                }
            })
            if (wallIncludeToExludeWalls) {
                continue
            }
            const CUT_LIMIT = 10;
            const wallStart = walls[i].parallelLink.a;
            const wallEnd = walls[i].parallelLink.b;
            const line = new THREE.Line3(
                new Vector3(wallStart.x, wallStart.y, 0),
                new Vector3(wallEnd.x, wallEnd.y, 0),
            );

            const wallLength = line.distance();
            const startLimitPoint = new Vector3();
            const endLimitPoint = new Vector3();
            line.at(CUT_LIMIT / wallLength, startLimitPoint);
            line.at((wallLength - CUT_LIMIT) / wallLength, endLimitPoint);
            const limitLine = new THREE.Line3(startLimitPoint, endLimitPoint);
            const point3 = new Vector3(point.x, point.y, 0);
            const target = new Vector3();
            limitLine.closestPointToPoint(point3, true, target);

            const distanceToWallAxis = point3.distanceTo(target);

            if (distanceToWallAxis < snapSize) {
                if (closestWall.distance) {
                    closestWall.distance = closestWall.distance > distanceToWallAxis ? distanceToWallAxis : closestWall.distance;
                } else {
                    closestWall.distance = distanceToWallAxis;
                }
                closestWall.exist = true;
                closestWall.wall = walls[i];
                closestWall.point = new Node(target.x, target.y);
                closestWall.offset = target.sub(point3);
            }
        }
        return closestWall
    }

}

export default Plan;
