import Link from './Link';

class LocalStorageParams {
    constructor() {
        this.defaultParams = {
            width: null,
            height: null,
            heightFromFloor: null,
        };
    }

    _getParams(elementType) {
        const savedParams = localStorage.getItem(elementType);
        if (savedParams) {
            const params = JSON.parse(savedParams);
            return {
                width: +params.width || this.defaultParams.width,
                height: +params.height || this.defaultParams.height,
                heightFromFloor: +params.heightFromFloor || this.defaultParams.heightFromFloor,
            };
        }
        return { ...this.defaultParams };
    }

    _saveParams(elementType, params) {
        localStorage.setItem(elementType, JSON.stringify(params));
    }

    saveParams(elementType, newParams) {
        const params = { ...this._getParams(elementType), ...newParams };
        this._saveParams(elementType, params);
    }

    getParams(elementType) {
        return this._getParams(elementType);
    }

    updateParam(elementType, key, value) {
        const params = { ...this._getParams(elementType), [key]: value };
        this._saveParams(elementType, params);
    }
}

export default LocalStorageParams;
