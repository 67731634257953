import React from 'react';
import style from './Input.module.scss';

export const Search = ({ placeholder = "", value, onChange }) => {
    return (
        <div className={style.container}>
            <span className={style?.["left-icon"]}>
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.6225 11.772C15.9601 8.94921 15.8087 4.74579 13.1663 2.10308C10.3621
                            -0.701082 5.79936 -0.701298 2.99467 2.10308C0.190253 4.90753 0.190253 9.4703
                            2.99467 12.2747C4.35092 13.631 6.15697 14.378 8.08036 14.378C9.77506 14.378
                            11.3786 13.7973 12.664 12.7313L15.7342 15.8015C15.8621 15.9297 16.0324
                            16.0002 16.2136 16.0002C16.3948 16.0002 16.5652 15.9297 16.6931 15.8015C16.9574
                            15.5372 16.9574 15.1069 16.6931 14.8426L13.6225 11.772ZM12.2074 11.3158C9.93196
                            13.5913 6.22901 13.5913 3.95358 11.3158C1.67842 9.04014 1.67842 5.33745 3.95358
                            3.06201C6.22901 0.786325 9.93144 0.786325 12.2074 3.06201C14.4826 5.33774
                            14.4826 9.04014 12.2074 11.3158Z"
                          fill="#939393"
                    />
                </svg>
            </span>
            <input
                className={style?.input}
                placeholder={placeholder}
                type="search"
                value={value}
                onChange={onChange}
            />
        </div>
    )
}