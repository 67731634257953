import { BufferAttribute, BufferGeometry, } from 'three';

/**
 * 
 * @param {*} p1 
 * @param {*} p2 
 * @param {*} p3 
 * @param {*} p4 
 * @returns 
 */
export const setPolygonFace = (p1, p2, p3, p4) => {
    const vertices = [
        { pos: p1, norm: [0, 0, 1], uv: [0, 0], }, // 0
        { pos: p2, norm: [0, 0, 1], uv: [1, 0], }, // 1
        { pos: p3, norm: [0, 0, 1], uv: [0, 1], }, // 2
        { pos: p4, norm: [0, 0, 1], uv: [1, 1], }, // 3
    ];
    const positions = [];
    const normals = [];
    const uvs = [];
    for (const vertex of vertices) {
        positions.push(...vertex.pos);
        normals.push(...vertex.norm);
        uvs.push(...vertex.uv);
    }

    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new BufferAttribute(new Float32Array(positions), 3));
    geometry.setAttribute('normal', new BufferAttribute(new Float32Array(normals), 3));
    geometry.setAttribute('uv', new BufferAttribute(new Float32Array(uvs), 2));
    geometry.setIndex([
        0, 1, 2,
        2, 1, 3,
    ]);

    // geometry.computeFaceNormals();
    geometry.computeVertexNormals();
    geometry.uvsNeedUpdate = true;
    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();

    return geometry;
}