export const rotatePoint = (point, pivot, angle) => {
    const radians = angle * Math.PI / 180;

    const x = Math.cos(radians) * (point.x - pivot.x)
        - Math.sin(radians) * (point.y - pivot.y) + pivot.x;
    const y = Math.sin(radians) * (point.x - pivot.x)
        + Math.cos(radians) * (point.y - pivot.y) + pivot.y;

    return { x, y };
}

export const segmentLine = (start, end, fraction) => ({
    x: start.x + fraction * (end.x - start.x),
    y: start.y + fraction * (end.y - start.y)
});

export const thirdRectPoint = (a, b, d) => ({
    x: b.x + (d.x - a.x),
    y: b.y + (d.y - a.y)
});

export const squaredDistance = (a, b) => (a.x - b.x) ** 2 + (a.y - b.y) ** 2;
export const calculateAngle = (a, b) => {
    const deltaX = a.x - b.x;
    const deltaY = a.y - b.y;

    let angle = Math.round(Math.atan2(deltaY, deltaX) * (180 / Math.PI));
    if (Object.is(angle, -0)) angle = 0;

    let rotation;
    if (angle > 90) {
        rotation = angle - 180;
    } else if (angle < -90) {
        rotation = angle + 180;
    } else {
        rotation = angle;
    }

    return { angle, rotation }
}

export const extrudeLine = (start, end, width) => {
    const direction = {
        x: end.x - start.x,
        y: end.y - start.y
    };

    const magnitude = Math.sqrt(direction.x * direction.x + direction.y * direction.y);
    const unitDirection = {
        x: direction.x / magnitude,
        y: direction.y / magnitude
    };

    const normal = { x: unitDirection.y, y: -unitDirection.x };

    const startOffset = {
        x: start.x + width * normal.x,
        y: start.y + width * normal.y
    };

    const endOffset = {
        x: end.x + width * normal.x,
        y: end.y + width * normal.y
    };

    return [start, end, endOffset, startOffset];
}

export const movePoint = (point, angle, distance) => {
    const angleInRadians = angle * (Math.PI / 180);

    const x = point.x + distance * Math.cos(angleInRadians);
    const y = point.y + distance * Math.sin(angleInRadians);

    return { x, y };
}

export const getCirclePoints = (cx, cy, r, n) => {
    const points = [];
    const angleStep = 2 * Math.PI / n;

    for (let i = 0; i < n; i++) {
        const theta = i * angleStep;
        const x = cx + r * Math.cos(theta);
        const y = cy + r * Math.sin(theta);
        points.push({ x, y });
    }

    return points;
}

export const splitText = (text) => {
    const words = text.split(' ');
    let result = '';

    for (let i = 0; i < words.length; i++) {
        result += words[i] + ' ';
        if ((i + 1) % 3 === 0) {
            result += '\n';
        }
    }

    return result.trim().split('\n').reverse();
}
