import { setPolygonFace } from './setPolygonFace'
export const setSide = (a, b, wallHeight) => {

    const rB1 = a
    const rB2 = b

    return setPolygonFace(
        [rB1.x / 100, 0.0, rB1.y / 100],
        [rB1.x / 100, wallHeight, rB1.y / 100],
        [rB2.x / 100, 0.0, rB2.y / 100],
        [rB2.x / 100, wallHeight, rB2.y / 100],
    )

}