import React from 'react';
import ModalWrapper from "../ModalWrapper";
import {useDispatch, useSelector} from "react-redux";
import {actionsState as projectState} from "../../Redux/project";

const VariantsInfo = ()=>{

    const dispatch = useDispatch();
    const variants = useSelector(state=>state.project.variants);
    const hash = useSelector(state=>state.project.saveResult.hash);

    const openVariant = (variant) => {
        close();
        dispatch(projectState.setMenuOpened(0));
        dispatch({ type: 'LOAD_PROJECT_VARIANT', hash: hash, variant: variant.XML_ID });
    }

    const close = ()=>{
        dispatch(projectState.setModal(''));
    }

    return  <ModalWrapper
            onClose={()=>close()}
            title={'Варианты проекта'}
            isBlock={false}
        >
        <div className="modal-body">
            {/*<div className="options-title">Версии:</div>*/}
            <div className={'all-modules-body'}>
                {
                    variants.map((variant, index)=>{
                        return (
                            <div style={{ borderLeft: variant.CUR_VARIANT ? '3px solid #333' : 'none' }} key={index} className={'versions-el'} onClick={()=>openVariant(variant)}>
                                <div>{variant.NAME}</div>
                                {(variant.CUR_VARIANT && !variant?.PUBLISHED) &&
                                    <div className={'variant-pub'}>
                                        <b>Текущий</b>
                                    </div>
                                }
                                {variant?.PUBLISHED &&
                                    <div className={'variant-pub'}>
                                        {variant.CUR_VARIANT && 
                                            <b>Текущий, </b>
                                        }
                                        Опубликован {variant.PUBLISHED_DATE}
                                    </div>}
                                <div>{variant.CRM_USER} <span>({variant.CRM_RIGHTS})</span></div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        </ModalWrapper>
}

export default VariantsInfo
