import React, { useState, useEffect } from 'react';
import {
    actionsState as projectState
} from "../Redux/project";
import { actionsState as modulesState } from "../Redux/modules";
import { useDispatch, useSelector } from "react-redux";
import { sendRedrawEvent, sendUnselectEvent } from "../Helpers/Events";
import ModalWrapper from "./ModalWrapper";
import ObjectInfoBlock from "./widgets/ObjectInfoBlock";

import SizeBlock from './widgets/SizeBlock';
import { EstimateInfo } from './Features/SideMenu';
import { convertQuotes, keys } from './Utils';
import { useShortcuts } from './Features/Shortcuts';

const ModuleMenu = ({ module }) => {
    const dispatch = useDispatch();
    const allModules = useSelector(store => store.modules.allModules);
    const planeEdit = useSelector(state => state.project.planeEdit);
    const canvasSelector = (planeEdit) ? '#plan' : '#scene';

    const showModuleInfo = () => {
        dispatch(projectState.setModal('moduleInfo'))
    }

    const remove = () => {
        dispatch(modulesState.remove(module));
        dispatch(modulesState.getPrice());
        sendUnselectEvent(document.querySelector(canvasSelector));
        sendRedrawEvent(document.querySelector(canvasSelector));
    }

    const unselect = () => {
        sendUnselectEvent(document.querySelector(canvasSelector));
        sendRedrawEvent(document.querySelector(canvasSelector));
    }

    const copy = () => {
        const newModule = JSON.parse(JSON.stringify(allModules.filter(m => m.ID === module.id)[0]))

        dispatch(modulesState.addModule(newModule, module.position, module));
    }

    const close = () => {
        module.updateShema();
        dispatch(projectState.setModal(''));
        sendUnselectEvent(document.querySelector(canvasSelector));
    }

    // const locationInRooms = useSelector(state => state.project.locationInRooms);

    const [location, setLocation] = useState(module.locationInRooms);

    const handlerUp = (value) => {
        if (module.locationInRooms.up === undefined) {
            setLocation({ ...location, up: module.locationInRooms.up });
            return
        }
        if ((module.locationInRooms.bottom !== null) && (module.locationInRooms.up + module.locationInRooms.bottom < value)) {
            handlerBottom(0);
            return
        }
        if (value === 0) {
            module.position.x -= module.locationInRooms.up * Math.sin(module._angle);
            module.position.y -= module.locationInRooms.up * Math.cos(module._angle);
        } else {
            module.position.x -= (module.locationInRooms.up - value) * Math.sin(module._angle);
            module.position.y -= (module.locationInRooms.up - value) * Math.cos(module._angle);
        }
        setLocation({ ...location, up: value });
        sendRedrawEvent(document.querySelector(canvasSelector));
    }
    const handlerBottom = (value) => {
        if (module.locationInRooms.bottom === undefined) {
            setLocation({ ...location, bottom: module.locationInRooms.bottom });
            return
        }
        if ((module.locationInRooms.up !== null) && (module.locationInRooms.up + module.locationInRooms.bottom < value)) {
            handlerUp(0);
            return
        }
        if (value === 0) {
            module.position.x += module.locationInRooms.bottom * Math.sin(module._angle);
            module.position.y += module.locationInRooms.bottom * Math.cos(module._angle);
        } else {
            module.position.x += (module.locationInRooms.bottom - value) * Math.sin(module._angle);
            module.position.y += (module.locationInRooms.bottom - value) * Math.cos(module._angle);
        }
        setLocation({ ...location, bottom: value });
        sendRedrawEvent(document.querySelector(canvasSelector));
    }
    const handlerRight = (value) => {
        if (module.locationInRooms.right === undefined) {
            setLocation({ ...location, right: module.locationInRooms.right });
            return
        }
        if ((module.locationInRooms.left !== null) && (module.locationInRooms.left + module.locationInRooms.right < value)) {
            handlerLeft(0);
            return
        }
        if (value === 0) {
            module.position.x += module.locationInRooms.right * Math.cos(module._angle);
            module.position.y -= module.locationInRooms.right * Math.sin(module._angle);
        } else {
            module.position.x += (module.locationInRooms.right - value) * Math.cos(module._angle);
            module.position.y -= (module.locationInRooms.right - value) * Math.sin(module._angle);
        }
        setLocation({ ...location, right: value });
        sendRedrawEvent(document.querySelector(canvasSelector));
    }
    const handlerLeft = (value) => {
        if (module.locationInRooms.left === undefined) {
            setLocation({ ...location, left: module.locationInRooms.left });
            return
        }
        if ((module.locationInRooms.right !== null) && (module.locationInRooms.left + module.locationInRooms.right < value)) {
            handlerRight(0);
            return
        }
        if (value === 0) {
            module.position.x -= module.locationInRooms.left * Math.cos(module._angle);
            module.position.y += module.locationInRooms.left * Math.sin(module._angle);
        } else {
            module.position.x -= (module.locationInRooms.left - value) * Math.cos(module._angle);
            module.position.y += (module.locationInRooms.left - value) * Math.sin(module._angle);
        }
        setLocation({ ...location, left: value });
        sendRedrawEvent(document.querySelector(canvasSelector));
    }

    useEffect(() => {
        setLocation(module.locationInRooms);
    }, [])

    useShortcuts({
        onDelete: remove,
        onCopy: () => {
            const newModule = module.clone();
            newModule.position.x += 1000;
            newModule.position.y += 1000;
            dispatch(modulesState.addInstanceModule(newModule));
            sendRedrawEvent(document.querySelector(canvasSelector));
        }
    });

    return <ModalWrapper
        isSideMenu={true}
        title={convertQuotes(module.name)}
        deleteActive={true}
        onDelete={() => remove()}
        onClose={() => close()}
    >
        <div className="modal-body">
            <div className="block module-block">
                <div className="options-title">Действия</div>
                <div className="btn btn-icon" style={{ zIndex: 1000 }} onClick={() => { showModuleInfo() }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51">
                        <g fill="currentColor">
                            <path d="M26,41.22H24a3,3,0,0,1-3-3v-.69a12.88,12.88,0,0,1-2-.84l-.48.49a3,3,0,0,1-4.23,0l-1.44-1.44a3,3,0,0,1,0-4.23L13.3,31a12.88,12.88,0,0,1-.84-2h-.69a3,3,0,0,1-3-3V24a3,3,0,0,1,3-3h.69a12.88,12.88,0,0,1,.84-2l-.49-.48a3,3,0,0,1,0-4.23l1.44-1.44a3,3,0,0,1,4.23,0l.48.49a12.88,12.88,0,0,1,2-.84v-.69a3,3,0,0,1,3-3h2a3,3,0,0,1,3,3v.69a12.88,12.88,0,0,1,2,.84l.48-.49a3,3,0,0,1,4.23,0l1.44,1.44a3,3,0,0,1,0,4.23L36.7,19a12.88,12.88,0,0,1,.84,2h.69a3,3,0,0,1,3,3v2a3,3,0,0,1-3,3h-.69a12.88,12.88,0,0,1-.84,2l.49.48a3,3,0,0,1,0,4.23l-1.44,1.44a3,3,0,0,1-4.23,0L31,36.7a12.88,12.88,0,0,1-2,.84v.69A3,3,0,0,1,26,41.22ZM19.28,34.7a11.49,11.49,0,0,0,2.9,1.21,1,1,0,0,1,.72.92v1.4A1.08,1.08,0,0,0,24,39.32h2a1.08,1.08,0,0,0,1.08-1.09v-1.4a1,1,0,0,1,.72-.92,11.49,11.49,0,0,0,2.9-1.21.94.94,0,0,1,1.15.15l1,1a1.08,1.08,0,0,0,1.53,0l1.44-1.44a1.08,1.08,0,0,0,0-1.53l-1-1a.94.94,0,0,1-.15-1.15,11.49,11.49,0,0,0,1.21-2.9,1,1,0,0,1,.92-.72h1.4A1.08,1.08,0,0,0,39.32,26V24a1.08,1.08,0,0,0-1.09-1.08h-1.4a1,1,0,0,1-.92-.72,11.49,11.49,0,0,0-1.21-2.9.94.94,0,0,1,.15-1.15l1-1a1.08,1.08,0,0,0,0-1.53L34.4,14.16a1.08,1.08,0,0,0-1.53,0l-1,1a.94.94,0,0,1-1.15.15,11.49,11.49,0,0,0-2.9-1.21,1,1,0,0,1-.72-.92v-1.4A1.08,1.08,0,0,0,26,10.68H24a1.08,1.08,0,0,0-1.08,1.09v1.4a1,1,0,0,1-.72.92,11.49,11.49,0,0,0-2.9,1.21.94.94,0,0,1-1.15-.15l-1-1a1.08,1.08,0,0,0-1.53,0L14.16,15.6a1.08,1.08,0,0,0,0,1.53l1,1a.94.94,0,0,1,.15,1.15,11.49,11.49,0,0,0-1.21,2.9,1,1,0,0,1-.92.72h-1.4A1.08,1.08,0,0,0,10.68,24v2a1.08,1.08,0,0,0,1.09,1.08h1.4a1,1,0,0,1,.92.72,11.49,11.49,0,0,0,1.21,2.9.94.94,0,0,1-.15,1.15l-1,1a1.08,1.08,0,0,0,0,1.53l1.44,1.44a1.08,1.08,0,0,0,1.53,0l1-1a.94.94,0,0,1,1.15-.15Z" />
                            <path d="M25,32.06A7.06,7.06,0,1,1,32.06,25,7.07,7.07,0,0,1,25,32.06Zm0-12.22A5.16,5.16,0,1,0,30.16,25,5.17,5.17,0,0,0,25,19.84Z" />
                        </g>
                    </svg>
                    Настройка
                </div>
                <div>
                    {module.locationInRooms && <>
                        <div className="options-title">Расположение</div>
                        {module.locationInRooms.left !== null && <div className="size-block">
                            <h2 className='title-W_direction'>Слева</h2>
                            <SizeBlock
                                value={module.locationInRooms.left}
                                onChange={handlerLeft}
                                min={0}
                                max={false}
                                step={5}
                                isLocked={false}
                            />
                        </div>}
                        {module.locationInRooms.right !== null && <div className="size-block">
                            <h2 className='title-E_direction'>Справа</h2>
                            <SizeBlock
                                value={module.locationInRooms.right}
                                onChange={handlerRight}
                                min={0}
                                max={false}
                                step={5}
                                isLocked={false}
                            />
                        </div>}
                        {module.locationInRooms.up !== null && <div className="size-block">
                            <h2 className='title-N_direction'>Сверху</h2>
                            <SizeBlock
                                value={module.locationInRooms.up}
                                onChange={handlerUp}
                                min={0}
                                max={false}
                                step={5}
                                isLocked={false}
                            />
                        </div>}
                        {module.locationInRooms.bottom !== null && <div className="size-block">
                            <h2 className='title-S_direction'>Снизу</h2>
                            <SizeBlock
                                value={module.locationInRooms.bottom}
                                onChange={handlerBottom}
                                min={0}
                                max={false}
                                step={5}
                                isLocked={false}
                            />
                        </div>}
                    </>}
                </div>
                {/*<div className="btn btn-icon btn-icon-fix1" onClick={()=>{copy()}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 19.5" style={{width:'20px',height:'20px'}}>
                        <rect fill="none" stroke="currentColor" x="2" y="0.5" width="17"
                              height="17"/>
                        <path fill="currentColor" d="M0,2.5v17H17V2.5ZM12.75,12H9.5v3.25a1,1,0,0,1-2,0V12H4.25a1,1,0,0,1,0-2H7.5V6.75a1,1,0,0,1,2,0V10h3.25a1,1,0,0,1,0,2Z"/>
                    </svg>
                    Клонировать
                </div>*/}

                <ObjectInfoBlock obj={module} />
                <EstimateInfo object={module} />
            </div>
        </div>


    </ModalWrapper>
}
export default ModuleMenu;
