import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { sendRedrawSimpleEvent, sendSelectObjectEvent, sendUnselectEvent } from '../../../../Helpers/Events';
import { Button } from '../../../UI';
import { actionsState as projectState } from '../../../../Redux/project';
import styles from './Objects.module.scss';

const getObjectType = (element) => {
    let type = 'не известно ';

    if (element.isHole === true) {
        type = 'Проем ';
    }
    if (element.isDoor === true) {
        type = 'Дверь ';
    }
    if (element.isWindow === true) {
        type = 'Окна ';
    }
    if (element.isElectricSocket === true) {
        if (element.count > 1) {
            if (element.type === 'horizontally') type = 'Горизонтальные розетки x ';
            else if (element.type === 'vertically')
                type = 'Вертикальные розетки x ';
        } else type = 'Розетка ';
    }
    if (element.isSwitch === true) {
        if (element.count > 1) {
            if (element.type === 'horizontally')
                type = 'Горизонтальные выключатели x ';
            else if (element.type === 'vertically')
                type = 'Горизонтальные выключатели x ';
        } else type = 'Выключатель ';
    }
    if (element.outletElectricalWire === true) {
        if (element.count > 1) {
            if (element.type === 'horizontally')
                type = 'Горизонтальные выводы электропровода x ';
            else if (element.type === 'vertically')
                type = 'Вертикальные выводы электропровода x ';
        } else type = 'Вывод электропровода ';
    }
    if (element.isHeatingBattery === true) {
        type = 'Батарея отопления ';
    }
    if (element.isElectricPanel === true) {
        type = 'Электрощит ';
    }
    if (element.isRedCube === true) {
        type = 'Куб ';
    }
    if (element.isCylinder === true) {
        type = 'Цилиндр ';
    }

    return type;
}

export const ObjectList = ({ plan, parent, objects, canvasSelector }) => {
    const [isExpandObjects, setIsExpandObjects] = useState(false);
    const dispatch = useDispatch();

    const setSelect = (object) => {
        sendSelectObjectEvent(
            document.querySelector(canvasSelector),
            object,
            parent
        );
        plan.setActionUndo({type: 'plan'});
    };

    const close = () => {
        dispatch(projectState.setModal(""));
        sendUnselectEvent(document.querySelector(canvasSelector));
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    };

    return (
        <div className={styles?.["object-list"]}>
            <div className={clsx("block-list", styles?.['block-list'])}>
                {objects.map((element, index) => (index < 3 || isExpandObjects)
                    ? (
                        <div key={index} className={'wall-objects-list'}>
                            <div className={'wall-objects-list-name'}>
                                {getObjectType(element)} {element.count > 1 ? element.count : element.ordinalNumber}
                            </div>
                            <div className="btn__block">
                            <span
                                className="btn btn-edit"
                                style={{zIndex: 1000}}
                                onClick={() => setSelect(element)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                  <g fill="currentColor">
                                    <path
                                        d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"/>
                                  </g>
                                </svg>
                            </span>
                                <span
                                    className="btn btn-delete"
                                    onClick={() => {
                                        parent.removeObject(parent?.isColumn ? element : index);
                                        close();
                                    }}
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 25">
                                  <g fill="currentColor">
                                    <path
                                        d="M18.08,3.08H13.85V2.31A2.32,2.32,0,0,0,11.54,0H8.46A2.32,2.32,0,0,0,6.15,2.31v.77H1.92A1.92,1.92,0,0,0,0,5V7.69a.76.76,0,0,0,.77.77h.42l.66,14a2.32,2.32,0,0,0,2.31,2.2H15.84a2.32,2.32,0,0,0,2.31-2.2l.66-14h.42A.76.76,0,0,0,20,7.69V5a1.92,1.92,0,0,0-1.92-1.92ZM7.69,2.31a.77.77,0,0,1,.77-.77h3.08a.77.77,0,0,1,.77.77v.77H7.69ZM1.54,5a.38.38,0,0,1,.38-.38H18.08a.38.38,0,0,1,.38.38V6.92H1.54ZM16.61,22.34a.77.77,0,0,1-.77.74H4.16a.77.77,0,0,1-.77-.74L2.73,8.46H17.27Z"></path>
                                    <path
                                        d="M10,21.54a.78.78,0,0,0,.77-.77v-10a.77.77,0,0,0-1.54,0v10A.78.78,0,0,0,10,21.54Z"></path>
                                    <path
                                        d="M13.85,21.54a.78.78,0,0,0,.77-.77v-10a.77.77,0,0,0-1.54,0v10A.77.77,0,0,0,13.85,21.54Z"></path>
                                    <path
                                        d="M6.15,21.54a.77.77,0,0,0,.77-.77v-10a.77.77,0,0,0-1.54,0v10A.78.78,0,0,0,6.15,21.54Z"></path>
                                  </g>
                                </svg>
                            </span>
                            </div>
                        </div>)
                    : null
                )}
            </div>
            {objects?.length > 3 && (
                <Button
                    variant={"text"}
                    onClick={() => setIsExpandObjects(!isExpandObjects)}
                >
                    {isExpandObjects ? "Свернуть" : "Показать все"}
                </Button>
            )}
        </div>
    );
};
