import React from 'react';
import style from './Checkbox.module.scss';

export const Checkbox = ({ value, label = "", onChange }) => {
    return (
        <div
            className={style.container}
            onClick={onChange}
        >
            <input
                type="checkbox"
                className={style.input}
                checked={value}
                onChange={onChange}
            />
            <label
                className={style.label}
            >
                {value &&
                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.2337 2.26132L5.99909 9.27524C5.84556 9.44787 5.63164 9.53671 5.41616 9.53671C5.24509
                                9.53671 5.07325 9.4806 4.92947 9.36604L1.03284 6.24873C0.696953 5.98026 0.6424 5.48967 0.911268
                                5.15339C1.17975 4.81711 1.67072 4.76256 2.00661 5.03143L5.32537 7.6862L11.0686 1.2252C11.3538
                                0.903343 11.8471 0.874508 12.1686 1.16052C12.4905 1.44692 12.5197 1.93946 12.2337 2.26132Z"
                              fill="white"
                        />
                    </svg>
                }
                {label}
            </label>
        </div>
    )
}