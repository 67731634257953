import React,{ Component } from "react";
import {useSelector} from "react-redux";

const Preloader = ()=>{

    const preloaderCount = useSelector(store=>store.project.preloaderCount);
    if(preloaderCount<1) return null;
    return <div className="preloader">
        <img src={window.confComponentUrl+"assets/preloader.svg"} alt=""/>
    </div>
}

export default Preloader;

