import React, { useEffect, useRef } from 'react';
import style from './Input.module.scss';
import clsx from 'clsx';

export const TextField = ({ placeholder = "", adornment = "", type = "text", value, onChange, onBlur }) => {
    const isFirstRender = useRef(true);
    const ref = useRef(null);

    useEffect(() => {
        if (value === 0 && !isFirstRender) {
            ref.current.type = 'text';
            ref.current?.setSelectionRange?.(1, 1);
            ref.current.type = 'number';
        }
        isFirstRender.current = false;
    }, [value]);

    return (
        <div className={style.container}>
            <input
                className={clsx(style?.input, style?.["text-field"])}
                type={type}
                pattern={type === "number" ? "\d*" : ""}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
            {adornment && <span className={style?.["right-icon"]}>{adornment}</span>}
        </div>
    )
}
