import * as THREE from 'three';
import { getMesh } from "./functions";
import { Bezier } from "./Bezier";

const d = (p1,p2)=>{
    return Math.sqrt((p1[0]-p2[0])*(p1[0]-p2[0])+(p1[1]-p2[1])*(p1[1]-p2[1]));
};

export const getObjectDefaultCoordinate = (perimetr,projection)=>{

    const gabarits = [projection[0][0]-projection[1][0],projection[0][1]-projection[1][1]];

    for(let i=0;i<perimetr.length;i++){
        const point1 = perimetr[i];
        const point2 = i<(perimetr.length-1)?perimetr[i+1]:perimetr[0];
        const dist = d(point1,point2);
        if(dist>gabarits[0]){
            const vec = new THREE.Vector2(point2[0]-point1[0],point2[1]-point1[1]).normalize();
        }
    }

};

const dot2D = (v1,v2)=>{
    return v1[0] * v2[0] + v1[1] * v2[1];
};

export const distanceSegmentToPoint2D = ([sp1x,sp1y,sp2x,sp2y],[px,py])=>{
    const v = [sp2x-sp1x,sp2y-sp1y];
    const w = [px-sp1x,py-sp1y];
    const c1 = dot2D(v,w);
    const c2 = dot2D(v,v);
    const b = c1/c2;
    if ( c1 <= 0 ) return d([px,py],[sp1x,sp1y]);
    else if ( c2 <= c1 ) return d([px,py],[sp2x,sp2y]);
    else return d([px,py],[sp1x+b*v[0],sp1y+b*v[1]]);
};

export const distanceRayToSegment = (origin,direction,p1,p2)=>{
    const a1 = -direction.y;
    const b1 = direction.x;
    const c1 = origin.x*(origin.y+direction.y) - (origin.x+direction.x)*origin.y;

    const a2 = p1.y - p2.y;
    const b2 = p2.x - p1.x;
    const c2 = p1.x*p2.y - p2.x*p1.y;

    const det = (a,b,c,d)=>a * d - b * c;

    const zn = det(a1,b1,a2,b2)

    if (Math.abs(zn) < 0.0000001) return Infinity;
    else{
        const v = new THREE.Vector2(
            - det (c1, b1, c2, b2) / zn,
            - det (a1, c1, a2, c2) / zn
        );
        //проверяем сторону луча
        if(v.clone().sub(origin).dot(direction)<0) return Infinity;
        //проверяем что точка на отрезке
        if(p1.x<p2.x && (v.x<p1.x || v.x>p2.x)) return Infinity;
        if(p1.x>p2.x && (v.x>p1.x || v.x<p2.x)) return Infinity;
        if(p1.x===p2.x && p1.y<p2.y && (v.y<p1.y || v.y>p2.y)) return Infinity;
        if(p1.x===p2.x && p1.y>p2.y && (v.y>p1.y || v.y<p2.y)) return Infinity;
        return v.sub(origin).length();
    }
};

export const distanceSegmentToSegment = (a,b)=>{

};

export const getScene = (obj)=>{
    if(obj.isScene) return obj;
    else{
        if(obj.parent) return getScene(obj.parent);
        else return null;
    }
};

export const hasCollision = (obj)=>{
    let result = false;
    let scene = getScene(obj);
};
export const distanceSegmentToObject = ([sp1x,sp1y,sp2x,sp2y],obj)=>{
    const px = obj.position.x;
    const py = -obj.position.z;
    let bb = null;
    let matrix = null;
    obj.traverse(_obj=>{
        if(_obj.isMesh && bb === null){
            bb = _obj.geometry.boundingBox;
            matrix = _obj.matrix;
        }
    });

    const objPerimetrVector = [
        new THREE.Vector3(bb.min.x,0,bb.min.z).applyMatrix4(matrix),
        new THREE.Vector3(bb.max.x,0,bb.min.z).applyMatrix4(matrix),
        new THREE.Vector3(bb.max.x,0,bb.max.z).applyMatrix4(matrix),
        new THREE.Vector3(bb.min.x,0,bb.max.z).applyMatrix4(matrix)
    ];

    const perimetr = [
        [obj.position.x+objPerimetrVector[0].x,obj.position.y+objPerimetrVector[0].z],
        [obj.position.x+objPerimetrVector[1].x,obj.position.y+objPerimetrVector[1].z],
        [obj.position.x+objPerimetrVector[2].x,obj.position.y+objPerimetrVector[2].z],
        [obj.position.x+objPerimetrVector[3].x,obj.position.y+objPerimetrVector[3].z]
    ];

    let minDistanse = 999999999;
    perimetr.map(p=>{
        const l = distanceSegmentToPoint2D([sp1x,sp1y,sp2x,sp2y],p);
        minDistanse = l<minDistanse?l:minDistanse;
    });
    return distanceSegmentToPoint2D([sp1x,sp1y,sp2x,sp2y],[px,py]);
};

export const isCrossWithPerimeter = (obj)=>{
    let result = false;
    const getScene = (_obj)=>{
        if(!_obj.parent) return null;
        if(_obj.parent.isScene) return _obj.parent;
        else return getScene(_obj.parent);
    };
    const scene = getScene(obj);
    if(scene){
        let walls = null;
        scene.children.map(g=>{
            if(g.userData.type === 'wall') walls = g;
        });
        const originPoint = obj.position.clone();
        if(walls){
            const collidableMeshList = walls.children;
            const originPoint = obj.position.clone();
            const bbPoints = [];
            const bb = obj.children[0].geometry.boundingBox;
            bbPoints.push(bb.max.clone());
            bbPoints.push(new THREE.Vector3(bb.max.x,bb.max.y,bb.min.z));
            bbPoints.push(new THREE.Vector3(bb.min.x,bb.max.y,bb.min.z));
            bbPoints.push(new THREE.Vector3(bb.min.x,bb.max.y,bb.max.z));
            bbPoints.push(bb.min.clone());
            bbPoints.push(new THREE.Vector3(bb.max.x,bb.min.y,bb.min.z));
            bbPoints.push(new THREE.Vector3(bb.min.x,bb.min.y,bb.min.z));
            bbPoints.push(new THREE.Vector3(bb.min.x,bb.min.y,bb.max.z));

            bbPoints.map(p=>{
                const localVertex = p.clone();
                const globalVertex = localVertex.applyMatrix4(obj.children[0].matrix).applyMatrix4(obj.matrix);
                //const globalVertex = localVertex.applyMatrix4(obj.matrix);
                const directionVector = globalVertex.sub(originPoint);
                const ray = new THREE.Raycaster(originPoint,directionVector.clone().normalize());
                const collisionResult = ray.intersectObjects(collidableMeshList);
                if(collisionResult.length>0 && collisionResult[0].distance < directionVector.length()) result=true;
            });
        }
    }
    return result;
};

export const resizeWindow = (obj,width,height)=>{
        const dx = (width-1)/2;
        const dy = (height-1)/2;
        const mesh = getMesh(obj);
        const pos = mesh.geometry.attributes.position;
        pos.array = pos.array.map((p,i)=>{
            const k = i%3;
            if(k === 0){
                if(p>0) return p+dx;
                else if(p<0) return p-dx;
            }
            else if(k === 1){
                if(p>0) return p+dy;
                else if(p<0) return p-dy;
            }
            else return p;
        });
        mesh.geometry.computeBoundingBox();
};

export const resizeDoor = (obj,width,height)=>{

    const dx = (width-1)/2;
    const dy = (height-2)/2;
    const mesh = getMesh(obj);
    const pos = mesh.geometry.attributes.position;
    pos.array = pos.array.map((p,i)=>{
        const k = i%3;
        if(k === 0){
            if(p>0) return p+dx;
            else if(p<0) return p-dx;
            else return p
        }
        else if(k === 1){
            if(p>0.5) return p+dy;
            else if(p<-0.5) return p-dy;
            else return p;
        }
        else return p;
    });
    mesh.geometry.computeBoundingBox();
};
/*
* p point (THREE.Vector2)
* aTp array points triangle 3x THREE.Vector2
*/
export const pointInTriangle = (p,aTp)=>{
    const cross_product = (v1,v2,v)=>{
        return (v.x - v2.x) * (v2.y - v1.y) - (v.y - v2.y) * (v2.x - v1.x);
    };
    const cp1 = cross_product(aTp[0], aTp[1], p) < 0.0;
    const cp2 = cross_product(aTp[1], aTp[2], p) < 0.0;
    const cp3 = cross_product(aTp[2], aTp[0], p) < 0.0
    return cp1 === cp2 && cp2 === cp3 && cp3 === cp1;
};

export const generateSizeTexture = (size)=>{
    const element = document.createElement('canvas');
    const ctx = element.getContext('2d');
    ctx.width = element.width = 64;
    ctx.height = element.height = 32;
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, 64, 32);
    ctx.font = "16px Arial";
    ctx.fillStyle = "#000000";
    ctx.textAlign = "center";
    ctx.fillText(size+" см", 32, 23);
    const texture = new THREE.CanvasTexture(element);
    return texture;
};

export const generateSizeTexture2 = (size)=>{
    const element = document.createElement('canvas');
    const ctx = element.getContext('2d');
    ctx.width = element.width = 64;
    ctx.height = element.height = 32;
    ctx.font = "16px Arial";
    ctx.fillStyle = "#000000";
    ctx.textAlign = "center";
    ctx.fillText(size+" см", 32, 23);
    const texture = new THREE.CanvasTexture(element);
    return texture;
};

export const lengthBezierCurve = (Ax, Ay, Bx, By, Cx, Cy, Dx, Dy) => {
    const bezier = new Bezier(Ax, Ay, Bx, By, Cx, Cy, Dx, Dy);
    return parseInt(bezier.length());
}

export const lengthBezierCurves = (bezier) => {
    return bezier.reduce((acc, cur) => {
        acc += lengthBezierCurve(
            cur.points[0].x, cur.points[0].y,
            cur.points[1].x, cur.points[1].y,
            cur.points[2].x, cur.points[2].y,
            cur.points[3].x, cur.points[3].y,
        );
        return acc;
    }, 0)
}

export const findPointOnCurve = (bezier, searchLength) => {
    let currentLength, point;
    let t = 0.5;
    do {
        const findBezier = bezier.split(t).left;
        currentLength = findBezier.length();
        const condition = currentLength + 2 > searchLength && currentLength - 2 < searchLength;
        if (condition) point = t;
        if (currentLength > searchLength) t = t / 2;
        if (currentLength < searchLength) t = t + t / 2;
    } while (currentLength + 2 < searchLength || currentLength - 2 > searchLength)
    return bezier.get(point);
}
