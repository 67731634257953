import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendRedrawSimpleEvent,
  sendSelectObjectEvent,
  sendUnselectEvent,
} from "../Helpers/Events";
import { actionsState as projectState } from "../Redux/project";
import ModalWrapper from "./ModalWrapper";
import SizeBlock from "./widgets/SizeBlock";

import { DistanceButton } from "./BluetoothRoulette/DistanceButton";
import { distance } from "./BluetoothRoulette/utils/distance";
import { useShortcuts } from "./Features/Shortcuts";
import { EstimateInfo, ObjectList } from './Features/SideMenu';
import MaterialSelect from "./MaterialSelect";
import { Button, Select } from "./UI";
import { checkRightDirectionWall, getWallCycle } from "./Utils";
import ObjectInfoBlock from "./widgets/ObjectInfoBlock";

const optionsFillWall = [
  { label: "Бетон", value: "blackFill" },
  { label: "Кирпич", value: "hatchDraw" },
  { label: "Гипсокартон", value: "beigeFill" },
  { label: "Пазоблок", value: "greyFill" },
  { label: "Пеноблок", value: "pointsDraw" },
];

const BWallInfo = ({ plan, wall }) => {
  const dispatch = useDispatch();

  const planeEdit = useSelector((state) => state.project.planeEdit);
  const bluetoothStatus = useSelector(
    (store) => store.project.devices.bluetooth.status
  );
  const canvasSelector = planeEdit ? "#plan" : "#scene";

  const [materialWnd, setMaterialWnd] = useState(false);
  const [material, setMaterial] = useState(wall.material);
  const [materialRGBrgb, setMaterialRGBrgb] = useState(wall.materialRGB.rgb);
  const [materialRGBrgbColor, setMaterialRGBrgbColor] = useState(
    wall.materialRGB.rgbColor
  );

  const [_activeObject, setActiveObject] = useState(null);

  const [distanceButton, setDistanceButton] = useState({
    property: { depth: false, length: true, height: false, innerDepth: false },
    action: handlerLength,
  });

  const _setMaterial = (m) => {
    wall.materialRGB.rgbColor = false;
    setMaterialRGBrgbColor(false);

    wall.material = m;
    setMaterial(m);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));

    plan.setActionUndo({ type: "plan" });
  };
  const _setMaterialRgbColor = (rgb) => {
    wall.materialRGB.rgbColor = true;
    setMaterialRGBrgbColor(true);
    wall.materialRGB.rgb = rgb;
    setMaterialRGBrgb(rgb);

    sendRedrawSimpleEvent(document.querySelector(canvasSelector));

    plan.setActionUndo({ type: "plan" });
  };

  const [lrBuild, setLRBuild] = useState(wall.mainLink.lrBuild);
  const toggleLRBuild = () => {
    let value = wall.mainLink.lrBuild === "left" ? "right" : "left";
    setLRBuild(value);
    wall.mainLink.lrBuild = value;

    plan.changeWallLRBuild(wall.mainLink, true);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));

    plan.setActionUndo({ type: "plan" });
  };

  const [visibility, setVisibility] = useState(wall.showModule);
  const toggleVisibility = () => {
    setVisibility(!visibility);
    wall.showModule = !visibility;

    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  };

  const [depth, setDepth] = useState(wall.mainLink.depth);
  function handlerDepth(value) {
    setDepth(value);
    wall.mainLink.depth = value;

    plan.changeWallLRBuild(wall.mainLink);
    plan.moveWallColumns(wall);

    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  }

  const [innerDepth, setInnerDepth] = useState(wall.mainLink.innerDepth);
  const handlerInnerDepth = (value) => {
    setInnerDepth(value);
    wall.mainLink.innerDepth = value;

    plan.changeWallLRBuild(wall.mainLink);
    plan.moveWallColumns(wall);

    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  };

  const [length, setLength] = useState(wall.mainLink.length);
  function handlerLength(value) {
    setLength(value);
    if (value > 5) {
      if (!checkRightDirectionWall(plan.cycles, wall.mainLink)) {
        for (let i = 0; i < plan.links.length; i++) {
          if (wall.mainLink === plan.links[i]) {
            const oldA = {
              x: plan.links[i].a.x,
              y: plan.links[i].a.y,
            };
            const oldB = {
              x: plan.links[i].b.x,
              y: plan.links[i].b.y,
            };
            plan.links[i].a.set(oldB.x, oldB.y);
            plan.links[i].b.set(oldA.x, oldA.y);
            if (plan.links[i].lrBuild === "right") {
              plan.links[i].lrBuild = "left";
            } else {
              plan.links[i].lrBuild = "right";
            }
            break;
          }
        }
      }

      wall.mainLink.length = value;
      plan.changeWallLRBuild(wall.mainLink);

      sendRedrawSimpleEvent(document.querySelector(canvasSelector));
      plan.setActionUndo({ type: "plan" });
    }
  }

  const [height, setHeight] = useState(wall.mainLink.height);
  const handlerHeight = (value) => {
    setHeight(value);
    wall.mainLink.height = value;
  };

  const [isArc, setIsArc] = useState(wall.isArc);
  const [isBezier, setIsBezier] = useState(wall.isBezier);

  const toggleWallType = (type) => {
    switch (type) {
      case "Arc":
        wall.isArc = !wall.isArc;
        wall.isBezier = false;
        setIsBezier(wall.isBezier);
        setIsArc(wall.isArc);
        break;
      case "Bezier":
        wall.isBezier = !wall.isBezier;
        wall.isArc = false;
        setIsArc(wall.isArc);
        setIsBezier(wall.isBezier);
        break;
      default:
        wall.isArc = false;
        wall.isBezier = false;
        setIsBezier(false);
        setIsArc(false);
        break;
    }

    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));

    plan.setActionUndo({ type: "plan" });
  };

  const [arcRadiusLine, setArcRadiusLine] = useState(wall.arcRadiusLine);
  const [arcRadiusLineMin, setArcRadiusLineMin] = useState(false);
  const [arcRadiusLineMax, setArcRadiusLineMax] = useState(false);
  const handleArcRadiusLine = (val) => {
    setArcRadiusLine(val);
    wall.arcRadiusLine = val;

    setArcRadiusLineMin(val * 0.9);
    setArcRadiusLineMax(val * 1.1);

    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
  };
  const handleArcRadiusLineTune = (val) => {
    setArcRadiusLine(val);
    wall.arcRadiusLine = val;

    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
  };

  const [arcRadius, setArcRadius] = useState(wall.arcRadius);
  const handleArcRadius = (val) => {
    setArcRadius(val);
    wall.arcRadius = val;

    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
  };

  const [arcLenght, setArcLenght] = useState(wall.arcLenght);
  const handleArcLenght = () => {
    setArcLenght(!wall.arcLenght);
    wall.arcLenght = !wall.arcLenght;

    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
  };

  const [planMaterial, setPlanMaterial] = useState(wall.planMaterial);
  const handlerDrawMaterial = (value) => {
    setPlanMaterial(value);
    wall.planMaterial = value;

    plan.changeWallLRBuild(wall.mainLink);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
  };

  const _addHole = () => {
    const wallObjects = wall.addObject();
    sendSelectObjectEvent(
      document.querySelector(canvasSelector),
      wallObjects[wallObjects.length - 1],
      wall
    );
    plan.setActionUndo({ type: "plan" });
  };

  const addObjectOnWall = () => {
    const wallObjects = wall.addObjectOnWall();
    const onWallObjects = wallObjects.filter(
      (object) => object.isHole === undefined
    );
    sendSelectObjectEvent(
      document.querySelector(canvasSelector),
      onWallObjects[onWallObjects.length - 1],
      wall
    );

    plan.setActionUndo({ type: "plan" });
  };

  const close = () => {
    dispatch(projectState.setModal(""));
    sendUnselectEvent(document.querySelector(canvasSelector));
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
  };

  const remove = () => {
    plan.removeWall(wall);
    dispatch(projectState.setModal(""));
    sendUnselectEvent(document.querySelector(canvasSelector));
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: 'plan' });
  };

  useEffect(() => {
    setDepth(wall.mainLink.depth);
    setInnerDepth(wall.mainLink.innerDepth);
    setLength(wall.mainLink.length);
    setArcLenght(wall.arcLenght);
    setArcRadius(wall.arcRadius);
    setArcRadiusLine(wall.arcRadiusLine);
    setIsArc(wall.isArc);
    setVisibility(wall.showModule);
    setPlanMaterial(wall.planMaterial);

    setArcRadiusLineMin(wall.arcRadiusLine * 0.9);
    setArcRadiusLineMax(wall.arcRadiusLine * 1.1);

    plan.changeWallLRBuild(wall.mainLink);

    setHeight(wall.mainLink.height);
  }, [
    wall,
    wall.arcLenght,
    wall.mainLink.depth,
    wall.mainLink.innerDepth,
    wall.mainLink.length,
    wall.height,
    wall.showModule,
    wall.planMaterial,
  ]);

  const setWallHeightForCycle = () => {
    const cycle = getWallCycle(wall, plan.cycles);
    cycle._links.forEach((link) => {
      link.height = height;
    });
  };

  const setStatusDistanceButton = ({ property, action }) => {
    const newDistanceButton = {
      property: {},
      action: {},
    };
    for (let key in distanceButton.property) {
      newDistanceButton.property[key] = property === key ? true : false;
    }
    newDistanceButton.action = action;
    setDistanceButton(newDistanceButton);
  };

  const wallCycles = getWallCycle(wall, plan.cycles);

  useShortcuts({ onDelete: remove });

  useEffect(() => {
    if (bluetoothStatus !== "connected") {
      return;
    }
    if (Object.keys(distance.setCallBack)) {
      distance.setCallBack(distanceButton.action);
    } else {
      distance.start(distanceButton.action);
    }
    return () => distance.setCallBack({});
  }, [bluetoothStatus, distanceButton]);

  return (
    <ModalWrapper
      isSideMenu={true}
      title={"Настройки стены"}
      onDelete={() => remove()}
      onClose={() => close()}
    >
      <div className="modal-body">
        <div className={"size-block"}>
          <div className="btn btn-icon btn-onestyle" onClick={toggleLRBuild}>
            {lrBuild === "left" && (
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
              >
                <path d="M256,0c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15V15C271,6.716,264.284,0,256,0z" />
                <path d="M256,137c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,143.716,264.284,137,256,137z" />
                <path d="M256,275c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,281.716,264.284,275,256,275z" />
                <path d="M256,412c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,418.716,264.284,412,256,412z" />
                <path
                  d="M179.402,103.503l-120-36.842c-4.549-1.398-9.49-0.553-13.317,2.276C42.258,71.765,40,76.241,40,81v350
                c0,4.759,2.258,9.235,6.085,12.063c3.841,2.839,8.787,3.668,13.317,2.275l120-36.842c6.299-1.933,10.598-7.751,10.598-14.339
                V117.842C190,111.254,185.7,105.437,179.402,103.503z"
                />
                <path
                  d="M465.915,68.937c-3.827-2.828-8.769-3.674-13.317-2.276l-120,36.842c-6.299,1.933-10.598,7.751-10.598,14.339v276.316
                        c0,6.588,4.3,12.405,10.598,14.339l120,36.842c4.566,1.402,9.505,0.541,13.317-2.276C469.742,440.235,472,435.759,472,431V81
                        C472,76.241,469.742,71.765,465.915,68.937z M442,410.704l-90-27.632V128.928l90-27.632V410.704z"
                />
              </svg>
            )}
            {lrBuild === "right" && (
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
              >
                <path d="M256,0c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15V15C271,6.716,264.284,0,256,0z" />
                <path d="M256,137c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,143.716,264.284,137,256,137z" />
                <path d="M256,275c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,281.716,264.284,275,256,275z" />
                <path d="M256,412c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,418.716,264.284,412,256,412z" />
                <path
                  d="M179.402,103.503l-120-36.842c-4.549-1.398-9.491-0.552-13.317,2.276C42.258,71.765,40,76.241,40,81v350
                            c0,4.759,2.258,9.235,6.085,12.063c3.841,2.839,8.787,3.668,13.317,2.275l120-36.842c6.299-1.933,10.598-7.751,10.598-14.339
                            V117.842C190,111.254,185.7,105.437,179.402,103.503z M160,383.071l-90,27.632V101.296l90,27.632V383.071z"
                />
                <path
                  d="M465.915,68.937c-3.826-2.828-8.77-3.673-13.317-2.276l-120,36.842c-6.299,1.933-10.598,7.751-10.598,14.339v276.316
                    c0,6.588,4.3,12.405,10.598,14.339l120,36.842c4.566,1.402,9.505,0.541,13.317-2.276C469.742,440.235,472,435.759,472,431V81
                    C472,76.241,469.742,71.765,465.915,68.937z"
                />
              </svg>
            )}
            Построение
          </div>
          <div
            className="btn btn-icon btn-onestyle"
            style={{ marginLeft: "5px" }}
            onClick={toggleVisibility}
          >
            {visibility ? "Скрыть" : "Показать"}
          </div>
        </div>
        <div className="size-block">
          <h2 className={clsx(wallCycles && "title_cycles")}>
            Внешняя толщина
          </h2>
          {wallCycles && <div style={{ width: "34px" }}></div>}
          <SizeBlock
            value={depth}
            onChange={handlerDepth}
            min={1}
            max={1000}
            step={5}
            isLocked={false}
          />
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"depth"}
              action={handlerDepth}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
        </div>
        <div className="size-block">
          <h2 className={clsx(wallCycles && "title_cycles")}>
            Внутренняя толщина
          </h2>
          {wallCycles && <div style={{ width: "34px" }}></div>}
          <SizeBlock
            value={innerDepth}
            onChange={handlerInnerDepth}
            min={0}
            max={1000}
            step={5}
            isLocked={false}
          />
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"innerDepth"}
              action={handlerInnerDepth}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
        </div>
        <div className="size-block">
          <h2 className={clsx(wallCycles && "title_cycles")}>Длина</h2>
          {wallCycles && <div style={{ width: "34px" }}></div>}
          <SizeBlock
            value={length}
            onChange={handlerLength}
            min={5}
            step={5}
            isLocked={false}
          />
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"length"}
              action={handlerLength}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
        </div>
        <div className="size-block">
          <h2 className={clsx(wallCycles && "title_cycles")}>Высота</h2>
          {wallCycles && (
            <svg
              className="size-block__btn"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              onClick={setWallHeightForCycle}
            >
              <path d="M9,5C10.04,5 11.06,5.24 12,5.68C12.94,5.24 13.96,5 15,5C18.87,5 22,8.13 22,12C22,15.87 18.87,19 15,19C13.96,19 12.94,18.76 12,18.32C11.06,18.76 10.04,19 9,19C5.13,19 2,15.87 2,12C2,8.13 5.13,5 9,5M8.5,12C8.5,13.87 9.29,15.56 10.56,16.75L11.56,16.29C10.31,15.29 9.5,13.74 9.5,12C9.5,10.26 10.31,8.71 11.56,7.71L10.56,7.25C9.29,8.44 8.5,10.13 8.5,12M15.5,12C15.5,10.13 14.71,8.44 13.44,7.25L12.44,7.71C13.69,8.71 14.5,10.26 14.5,12C14.5,13.74 13.69,15.29 12.44,16.29L13.44,16.75C14.71,15.56 15.5,13.87 15.5,12Z" />
            </svg>
          )}
          <SizeBlock
            value={height}
            onChange={handlerHeight}
            min={5}
            step={5}
            isLocked={false}
          />
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"height"}
              action={handlerHeight}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
        </div>

        <br />

        <Select
          options={optionsFillWall}
          value={optionsFillWall.find(
            (option) => option.value === wall.planMaterial
          )}
          onChange={(option) => handlerDrawMaterial(option.value)}
        />

        <div className={"options-title-wrap"}>
          <div className={"options-title"}>Кривые Безье</div>
          <div className={"options-title__option"}>
            <div
              className={
                wall.isBezier
                  ? "oval-checkbox oval-checkbox_active"
                  : "oval-checkbox"
              }
              onClick={() => toggleWallType("Bezier")}
            >
              &nbsp;
            </div>
          </div>
        </div>

        <div className="materials-block">
          <div className="options-title">Материал стены</div>
          <div className="material-select">
            {!wall.materialRGB.rgbColor && (
              <div
                className="material-select__select"
                onClick={() => setMaterialWnd(true)}
                style={{
                  backgroundImage: "url(" + wall.material.userData.PICT + ")",
                  cursor: "pointer",
                }}
              >
                <span>{wall.material.userData.NAME}</span>
              </div>
            )}
            {wall.materialRGB.rgbColor && (
              <div
                className="material-select__select"
                onClick={() => setMaterialWnd(true)}
                style={{
                  background:
                    "rgba(" +
                    wall.materialRGB.rgb.r +
                    "," +
                    wall.materialRGB.rgb.g +
                    "," +
                    wall.materialRGB.rgb.b +
                    "," +
                    wall.materialRGB.rgb.a +
                    ")",
                  cursor: "pointer",
                }}
              >
                <span>RGB цвет</span>
              </div>
            )}
          </div>
          {materialWnd === true && (
            <MaterialSelect
              onClose={() => setMaterialWnd(false)}
              active={wall.material}
              list={window.materials.wall}
              setMaterial={_setMaterial}
              title="Материал стены"
              rgbColor={true}
              _setRgbColor={_setMaterialRgbColor}
              activeRGB={wall.materialRGB.rgbColor}
              colorDef={wall.materialRGB.rgb}
            />
          )}
        </div>

        {planeEdit && (
          <div className="block">
            <div className="options-title">Проемы и объекты</div>
            <div className="wall-objects">
              <div className="wall-objects-btn">
                <div
                  className="btn btn-icon"
                  onClick={() => {
                    _addHole();
                    // close()
                  }}
                >
                  Добавить проем
                </div>
                <div
                  className="btn btn-icon"
                  onClick={() => {
                    addObjectOnWall();
                    // close()
                  }}
                >
                  Добавить объект
                </div>
              </div>
              <ObjectList plan={plan} canvasSelector={canvasSelector} parent={wall} objects={wall?.objects} />
            </div>
          </div>
        )}

        <ObjectInfoBlock obj={wall} />
        <EstimateInfo object={wall} />
      </div>
    </ModalWrapper>
  );
};

export default BWallInfo;
