export const uploadImage = async (image, tokenRID, imageType = 'objImage') => {
    const data = new FormData();

    data.append('method', 'uploadImage');
    data.append('token', window.rr_token);
    data.append('tokenRID', tokenRID);
    data.append(imageType, image);

    try {
        let resp = await fetch(window.confAjaxUrl, {
            method: 'POST',
            body: data
        });
        return await resp.json();
    } catch (error) {
        return false;
    }
};


export const removeImage = async (fileID) => {
    try {
        let resp = await fetch(window.confAjaxUrl, {
            method: 'POST',
            body: JSON.stringify({ method: "removeImage", token: window.rr_token, fileID: fileID }),
            headers: {
                'Content-Type': 'application/json',
                'application': 'x-www-form-urlencoded'
            }
        });
        return await resp.json();
    } catch (error) {
        return false;
    }
};
