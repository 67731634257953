import React, { useState } from 'react';
import { Button, Checkbox, Modal, Select } from '../../../UI';
import { useOutside } from '../../../Hooks';
import { algorithmOptions, settingsDxf } from './utils';
import styles from './DXF.module.scss';

export const ExportSettings = ({ open, onSubmit, onClose }) => {
    const { ref } = useOutside(onClose);
    const [settings, setSettings] = useState(settingsDxf);

    return (
        <Modal modalRef={ref} open={open} title="Настройки экспорта DXF">
            <div className={styles.settings}>
                <div className={styles?.algorithm}>
                    <div className={styles?.title}>Алгоритм экспорта</div>
                    <Select
                        options={algorithmOptions}
                        value={algorithmOptions.find((option) => option.value === settings.algorithm)}
                        onChange={(option) => {
                            settingsDxf.algorithm = option.value;
                            setSettings({ ...settings, algorithm: option.value });
                        }}
                    />
                </div>
                <Checkbox
                    value={settings.showElectricity}
                    onChange={() => {
                        const value = !settings.showElectricity;
                        settingsDxf.showElectricity = value;
                        setSettings({ ...settings, showElectricity: value });
                    }}
                    label="Электрика"
                />
                <Checkbox
                    value={settings.showModules}
                    onChange={() => {
                        const value = !settings.showModules;
                        settingsDxf.showModules = value;
                        setSettings({ ...settings, showModules: value });
                    }}
                    label="Мебель"
                />
                <Checkbox
                    value={settings.showAreaFigures}
                    onChange={() => {
                        const value = !settings.showAreaFigures;
                        settingsDxf.showAreaFigures = value;
                        setSettings({ ...settings, showAreaFigures: value });
                    }}
                    label="Площадь фигур"
                />
                <div className={styles.buttons}>
                    <Button
                        onClick={() => {
                            onSubmit();
                            onClose();
                        }}
                    >
                        Экспортировать
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Отмена
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
