import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendRedrawSimpleEvent, sendUnselectEvent } from "../Helpers/Events";
import { actionsState as projectState } from "../Redux/project";
import { DistanceButton } from "./BluetoothRoulette/DistanceButton";
import { distance } from "./BluetoothRoulette/utils/distance";
import { useShortcuts } from "./Features/Shortcuts";
import ModalWrapper from "./ModalWrapper";
import SizeBlock from "./widgets/SizeBlock";

const LinkInfo = ({ plan, link }) => {
  const dispatch = useDispatch();
  const bluetoothStatus = useSelector(
    (store) => store.project.devices.bluetooth.status
  );
  const [distanceButton, setDistanceButton] = useState({
    property: {
      length: true,
    },
    action: handlerLength,
  });
  const [length, setLength] = useState(link.length);
  const [showCircle, setShowCircle] = useState(link.showCircle);
  const [leaderText, setLeaderText] = useState(link.leaderText);

  const setStatusDistanceButton = ({ property, action }) => {
    const newDistanceButton = {
      property: {},
      action: {},
    };
    for (let key in distanceButton.property) {
      newDistanceButton.property[key] = property === key ? true : false;
    }
    newDistanceButton.action = action;
    setDistanceButton(newDistanceButton);
  };

  function handlerLength(value) {
    setLength(value);
    if (value > 5) {
      link.length = value;
      sendRedrawSimpleEvent(document.querySelector("#plan"));
      plan.setActionUndo({ type: "plan" });
    }
  }

  function handlerShowCircle(value) {
    setShowCircle(value);
    link.showCircle = value;
    sendRedrawSimpleEvent(document.querySelector("#plan"));
    plan.setActionUndo({ type: "plan" });
  }

  /*
        const [isArc,setIsArc] = useState(wall.isArc);
        const toggleIsArc = ()=>{
            setIsArc(!wall.isArc);
            wall.isArc = !wall.isArc;

            plan.changeWallLRBuild(link);
            sendRedrawSimpleEvent(document.querySelector('#plan'));
        }

        const [arcRadiusLine,setArcRadiusLine] = useState(wall.arcRadiusLine);
        const [arcRadiusLineMin,setArcRadiusLineMin] = useState(false);
        const [arcRadiusLineMax,setArcRadiusLineMax] = useState(false);
        const handleArcRadiusLine = (val)=>{
            setArcRadiusLine(val);
            wall.arcRadiusLine = val;

            setArcRadiusLineMin(val*.9);
            setArcRadiusLineMax(val*1.1);

            plan.changeWallLRBuild(link);
            sendRedrawSimpleEvent(document.querySelector('#plan'));
        }
        const handleArcRadiusLineTune = (val)=>{
            setArcRadiusLine(val);
            wall.arcRadiusLine = val;

            plan.changeWallLRBuild(link);
            sendRedrawSimpleEvent(document.querySelector('#plan'));
        }

        const [arcRadius,setArcRadius] = useState(wall.arcRadius);
        const handleArcRadius = (val)=>{
            setArcRadius(val);
            wall.arcRadius = val;

            plan.changeWallLRBuild(link);
            sendRedrawSimpleEvent(document.querySelector('#plan'));
        }

        const [arcLenght,setArcLenght] = useState(wall.arcLenght);
        const handleArcLenght = ()=>{
            setArcLenght(!wall.arcLenght);
            wall.arcLenght = !wall.arcLenght;

            plan.changeWallLRBuild(link);
            sendRedrawSimpleEvent(document.querySelector('#plan'));
        }*/

  const close = () => {
    dispatch(projectState.setModal(""));
    sendUnselectEvent(document.querySelector("#plan"));
    sendRedrawSimpleEvent(document.querySelector("#plan"));
  };

  const remove = () => {
    plan.removeLink(link);
    dispatch(projectState.setModal(""));
    sendUnselectEvent(document.querySelector("#plan"));
    sendRedrawSimpleEvent(document.querySelector("#plan"));
    plan.setActionUndo({ type: "plan" });
  };

  const handlerLeaderText = (e) => {
    const value = e.target.value;

    setLeaderText(value);
    link.leaderText = value;

    sendRedrawSimpleEvent(document.querySelector("#plan"));
    plan.setActionUndo({ type: "plan" });
  };

  useShortcuts({ onDelete: remove });

  useEffect(() => {
    setLength(link.length);
    setShowCircle(link.showCircle);
    setLeaderText(link.leaderText);
    /*
        setArcLenght(wall.arcLenght);
        setArcRadius(wall.arcRadius);
        setArcRadiusLine(wall.arcRadiusLine);
        setIsArc(wall.isArc);

        setArcRadiusLineMin(wall.arcRadiusLine*.9);
        setArcRadiusLineMax(wall.arcRadiusLine*1.1);

        plan.changeWallLRBuild(wall);*/
  }, [link, link.length, link.leaderText, link.showCircle]);

  useEffect(() => {
    if (bluetoothStatus !== "connected") {
      return;
    }
    if (Object.keys(distance.setCallBack)) {
      distance.setCallBack(distanceButton.action);
    } else {
      distance.start(distanceButton.action);
    }
    return () => distance.setCallBack({});
  }, [bluetoothStatus, distanceButton]);

  const name = () => {
    if (link.isRuler) return "линейки";
    if (link.isFigure) return "линии";
    if (link?.isLeader) return "сноски";
    return "линейки";
  };

  return (
    <ModalWrapper
      isSideMenu={true}
      title={"Настройки " + name()}
      onDelete={() => remove()}
      onClose={() => close()}
    >
      <div className="modal-body">
        <div className="size-block">
          <h2>Длина</h2>
          <SizeBlock
            value={length}
            onChange={handlerLength}
            min={1}
            step={5}
            isLocked={false}
          />
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"length"}
              action={handlerLength}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
        </div>
        {link.isRuler && (
          <div className={"options-title-wrap"}>
            <div className={"options-title"}>Показать окружность</div>
            <div className={"options-title__option"}>
              <div
                className={
                  showCircle
                    ? "oval-checkbox oval-checkbox_active"
                    : "oval-checkbox"
                }
                onClick={() => handlerShowCircle(!showCircle)}
              >
                &nbsp;
              </div>
            </div>
          </div>
        )}
        {link?.isLeader && (
          <div className={"options-title-wrap"}>
            <div className={"options-title"}>Комментарий</div>
            <input
              value={leaderText}
              onChange={handlerLeaderText}
              type="text"
              className={"form-control"}
            />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

export default LinkInfo;
