import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";

import style from './bluetoothRoulette.module.scss';
import { setConnection } from './utils/setConnection';

const getString = (state) => {
    let returnStyle = style.connectionMenu_string;
    if (state === 'disable') {
        return { text: 'Подключить рулетку с Bluetooth', textStyle: returnStyle }
    }
    if (state === 'connection') {
        return { text: 'Подключаем рулетку...', textStyle: clsx(returnStyle, style.connectionMenu_string__connection) }
    }
    if (state === 'error') {
        return { text: 'Ошибка подключения', textStyle: clsx(returnStyle, style.connectionMenu_string__error) }
    }
    if (state === 'connected') {
        return { text: 'Рулетка подключена', textStyle: clsx(returnStyle, style.connectionMenu_string__connected) }
    }
    if (state === 'reConnection') {
        return { text: 'Переподключение...', textStyle: clsx(returnStyle, style.connectionMenu_string__connection) }
    }
}


export const ConnectionMenu = () => {
    const dispatch = useDispatch();
    const bluetoothStatus = useSelector(store => store.project.devices.bluetooth.status);
    const handlerClick = async () => {
        await setConnection(dispatch);
    }
    const { text, textStyle } = getString(bluetoothStatus);

    return (
        <div>
            <div className={textStyle} onClick={handlerClick}>{text}</div>
        </div>)
}