import { actionsState } from "../../../Redux/project";
import { bluetoothRouletteObj } from "./bluetoothRouletteObj";
import { distance } from "./distance";
import { setReConnection } from "./setReConnection";

export const setConnection = async (dispatch) => {
  const onDisconnected = () => {
    console.log("> Bluetooth Device disconnected");
    setReConnection(dispatch);
  };
  dispatch(actionsState.setBluetoothDevices({ status: "connection" }));
  try {
    console.log("Requesting Bluetooth Device...");
    const device = await navigator.bluetooth.requestDevice({
      acceptAllDevices: true,
      // filters: [
      //     { services: [bluetoothRouletteObj.serviceID] },
      //     // { name: [' XP3 PRO'] }
      // ],
      optionalServices: [bluetoothRouletteObj.serviceID],
    });
    console.log("> Name:             " + device.name);
    console.log("> Id:               " + device.id);
    console.log("> Connected:        " + device.gatt.connected);
    console.log("> Try to Connect...");
    bluetoothRouletteObj.device = device;

    const server = await device.gatt.connect();
    console.log("> Connected:        " + device.gatt.connected);
    bluetoothRouletteObj.server = server;

    const service = await server.getPrimaryService(
      bluetoothRouletteObj.serviceID
    );
    console.log("> Service: " + service.uuid);
    bluetoothRouletteObj.service = service;

    const characteristic = await service.getCharacteristic(
      bluetoothRouletteObj.characteristicID
    );
    console.log("> Characteristic: " + characteristic.uuid);
    bluetoothRouletteObj.characteristic = characteristic;

    const notify = await characteristic.startNotifications();
    bluetoothRouletteObj.notify = notify;

    device.addEventListener("gattserverdisconnected", onDisconnected);
    dispatch(actionsState.setBluetoothDevices({ status: "connected" }));
    distance.start();
  } catch (error) {
    console.log(error.message);
    if (error.message === "User cancelled the requestDevice() chooser.") {
      dispatch(actionsState.setBluetoothDevices({ status: "connection" }));
    } else {
      dispatch(actionsState.setBluetoothDevices({ status: "error" }));
      if (bluetoothRouletteObj.device.gatt) {
        await bluetoothRouletteObj.device.gatt.disconnect();
      }
    }
  }
};
