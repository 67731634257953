/**
 * Функция проверяет лежит ли точка на отрезке
 * @param {{x:number, y:number}} startPointLine 
 * @param {{x:number, y:number}} endPointLine 
 * @param {{x:number, y:number}} point 
 * @returns true если точка лежит на отрезке
 */
export const checkPointLiesOnSegment = (startPointLine, endPointLine, point) => {

    if (0 !== Math.round(((endPointLine.x - startPointLine.x) * (point.y - startPointLine.y)
        - (point.x - startPointLine.x) * (endPointLine.y - startPointLine.y)) * 1000) / 1000) {
        return false;
    }

    startPointLine.x = Math.round(startPointLine.x * 10000) / 10000;
    startPointLine.y = Math.round(startPointLine.y * 10000) / 10000;
    endPointLine.x = Math.round(endPointLine.x * 10000) / 10000;
    endPointLine.y = Math.round(endPointLine.y * 10000) / 10000;
    point.x = Math.round(point.x * 10000) / 10000;
    point.y = Math.round(point.y * 10000) / 10000;

    if (!((point.x >= startPointLine.x && point.x <= endPointLine.x)
        || (point.x <= startPointLine.x && point.x >= endPointLine.x))) {
        return false;
    }

    if (!((point.y >= startPointLine.y && point.y <= endPointLine.y)
        || (point.y <= startPointLine.y && point.y >= endPointLine.y))) {
        return false;
    }
    return true;
}