import React from 'react';
import style from './ImageViewer.module.scss';
import { useOutside } from '../../Hooks';

export const ImageViewer = ({ img, show, onClose }) => {
    const { ref } = useOutside(onClose);

    if (!show) return null;

    return (
        <div className={style.container}>
            <img ref={ref} className={style.img} src={img} alt={'image'} />
            <img
                className={style.close}
                src={window.confComponentUrl + "assets/icons/icon-close.svg"}
                alt=""
            />
        </div>
    )
}
