import React, {Suspense, useEffect, useRef, useState} from 'react';
import ModalWrapper from "./ModalWrapper";
import {useDispatch, useSelector} from "react-redux";
import {actionsState as modulesState} from "../Redux/modules";
import {actionsState as projectState} from "../Redux/project";
import * as THREE from "three";
import {
    sendCenterModuleEvent,
    sendRedrawEvent,
    sendUnselectEvent
} from "../Helpers/Events";
import SizeBlock from "./widgets/SizeBlock";
import MaterialSelect from "./MaterialSelect";
import { convertQuotes } from './Utils';

let camera,scene,mouse = new THREE.Vector2(), controls,
    ambientLight, spotLight, spotLight2,
    renderer,raycaster,composer,rp,effectFXAA,saoPass,clock;

const ModuleInfo3D = ({module})=>{

    const dispatch = useDispatch();

    // console.log('module.model',module.model)

    const planeEdit = useSelector(state=>state.project.planeEdit);
    const canvasSelector = (planeEdit) ? '#plan' : '#scene';

    const [materialCorp,setMaterialCorp] = useState(module.corp);
    const [materialFace,setMaterialFace] = useState(module.face);
    const [materialCloth,setMaterialCloth] = useState(module.cloth);
    const [option,setOption] = useState(module.optionStatus);
    const [showModule,setShowModule] = useState(module.showModule);

    const [materialCorpWnd,setMaterialCorpWnd] = useState(false);
    const [materialFaceWnd,setMaterialFaceWnd] = useState(false);
    const [materialClothWnd,setMaterialClothWnd] = useState(false);

    const [heightFromFloor,setHeightFromFloor] = useState(module.heightFromFloor)
    const [scaleX,setScaleX] = useState(module.size.sizeX)
    const [scaleY,setScaleY] = useState(module.size.sizeY)
    const [scaleZ,setScaleZ] = useState(module.size.sizeZ)
    // console.log('ModuleInfo module',module)
    // console.log('ModuleInfo module.size',module.size)

    const handlerShowModule = ()=>{
        const value = !showModule;
        setShowModule(value);
        module.showModule = value;
        sendRedrawEvent(document.querySelector(canvasSelector));
    }

    const handlerHeightFromFloor = (value)=>{
        setHeightFromFloor(value);
        module.heightFromFloor = value;
        sendRedrawEvent(document.querySelector(canvasSelector));
    }

    const handlerScaleX = (value)=>{
        setScaleX(value);
        module.size.sizeX = value;
        sendRedrawEvent(document.querySelector(canvasSelector));
    }
    const handlerScaleY = (value)=>{
        setScaleY(value);
        module.size.sizeY = value;
        sendRedrawEvent(document.querySelector(canvasSelector));
    }
    const handlerScaleZ = (value)=>{
        setScaleZ(value);
        module.size.sizeZ = value;
        sendRedrawEvent(document.querySelector(canvasSelector));
    }

    useEffect(()=>{
        dispatch(modulesState.getPrice());
        setShowModule(module.showModule);
    },[option,materialCorp,materialFace,materialCloth,module.showModule]);
    const remove = ()=>{
        dispatch(modulesState.remove(module));
        dispatch(modulesState.getPrice());
        dispatch(projectState.setModal(''));
        sendUnselectEvent(document.querySelector(canvasSelector));
    }

    const centerModule = ()=>{
        sendCenterModuleEvent(document.querySelector(canvasSelector), module, 'module');
    }

    const close = ()=>{
        module.updateShema();
        dispatch(projectState.setModal(''));
        if ('#scene' === canvasSelector)
            sendUnselectEvent(document.querySelector(canvasSelector));
    }


    const corpKeys = module.materials.corp?Object.keys(module.materials.corp):null;
    const corpMaterials = corpKeys?window.materials.corp.filter(m=>corpKeys.find(id=>id===m.userData.ID.toString())):window.materials.corp;

    const faceKeys = module.materials.face?Object.keys(module.materials.face):null;
    const faceMaterials = faceKeys?window.materials.face.filter(m=>faceKeys.find(id=>id===m.userData.ID.toString())):window.materials.face;

    const clothKeys = module.materials.cloth?Object.keys(module.materials.cloth):null;
    const clothMaterials = clothKeys?window.materials.cloth.filter(m=>clothKeys.find(id=>id===m.userData.ID.toString())):window.materials.cloth;

    const setMaterial = (material)=>{
        if(material.name === 'corp'){
            module.corp = material;
            setMaterialCorp(module.corp);
        }
        else if(material.name === 'face'){
            module.face = material;
            setMaterialFace(module.face);
        }
        else if(material.name === 'cloth'){
            module.cloth = material;
            setMaterialCloth(module.cloth);
        }
    }

    const toggleOption = ()=>{
        const newStatus = !module.optionStatus;
        if(newStatus && module.option){
            module.optionStatus = newStatus;
            setOption(module.optionStatus);
        }
        else{
            module.optionStatus = false;
            setOption(module.optionStatus);
        }
        dispatch(modulesState.getPrice());
    }
    const modules = useSelector(store=>store.modules.allModules);
    const opt = module.option?modules.find(m=>m.ID == module.option.ID):null;
    const mod = modules.find(m=>m.ID == module.id);
    const variants = mod.VARIANTS.reduce((acc,v)=>{
        const item = modules.find(m=>m.ID == v.ID);
        if(item) acc.push(item);
        return acc;
    },[]);

    // console.log('ModuleInfo mod',mod)

    useEffect(()=>{

    },[module,showModule]);

    return <ModalWrapper
        isSideMenu={true}
        title={convertQuotes(module.name)}
        onDelete={()=>remove()}
        onClose={()=>close()}
        planeEdit={planeEdit}
        >
        <div className="modal-body">
            <div className="module-btns">
                <div className="btn btn-icon" onClick = {handlerShowModule}>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g fill="currentColor">
                            <path d="M21.8602 10.5721C21.6636 10.3032 16.9808 3.98898 10.9999 3.98898C5.01902 3.98898 0.33593 10.3032 0.139606 10.5718C-0.0465352 10.8269 -0.0465352 11.1728 0.139606 11.4279C0.33593 11.6967 5.01902 18.011 10.9999 18.011C16.9808 18.011 21.6636 11.6967 21.8602 11.4281C22.0466 11.1731 22.0466 10.8269 21.8602 10.5721ZM10.9999 16.5604C6.59434 16.5604 2.77867 12.3695 1.64914 10.9995C2.7772 9.62821 6.58489 5.43953 10.9999 5.43953C15.4052 5.43953 19.2206 9.62967 20.3506 11.0005C19.2226 12.3717 15.4149 16.5604 10.9999 16.5604Z"/>
                            <path d="M11.0001 6.64835C8.60064 6.64835 6.64844 8.60055 6.64844 11C6.64844 13.3995 8.60064 15.3517 11.0001 15.3517C13.3996 15.3517 15.3518 13.3995 15.3518 11C15.3518 8.60055 13.3996 6.64835 11.0001 6.64835ZM11.0001 13.9011C9.40038 13.9011 8.09902 12.5997 8.09902 11C8.09902 9.40033 9.40042 8.09893 11.0001 8.09893C12.5998 8.09893 13.9012 9.40033 13.9012 11C13.9012 12.5997 12.5998 13.9011 11.0001 13.9011Z"/>
                        </g>
                    </svg>
                    {showModule && <span>Спрятать</span>}
                    {!showModule && <span>Показать</span>}
                </div>
                {showModule && <div className="btn btn-icon" onClick = {centerModule}>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 492.001 492.001">
                        <g fill="currentColor">
                            <path d="M487.97,237.06l-58.82-58.82c-5.224-5.228-14.376-5.228-19.592,0l-7.436,7.432c-5.4,5.4-5.4,14.064,0,19.46l21.872,21.74
                            H265.206V68.396l21.808,22.132c5.224,5.22,14.216,5.22,19.428,0l7.36-7.432c5.404-5.404,5.356-14.196-0.044-19.596L254.846,4.444
                            c-2.6-2.592-6.088-4.184-9.804-4.184h-0.404c-3.712,0-7.188,1.588-9.784,4.184l-57.688,57.772
                            c-2.612,2.608-4.052,6.124-4.052,9.836c0,3.704,1.44,7.208,4.052,9.816l7.432,7.444c5.224,5.22,14.612,5.228,19.828,0.004
                            l22.368-22.132v159.688H67.814l22.14-22.008c2.608-2.608,4.048-6.028,4.048-9.732s-1.44-7.16-4.052-9.76l-7.436-7.42
                            c-5.22-5.216-14.372-5.2-19.584,0.008L4.034,236.856c-2.672,2.672-4.1,6.244-4.032,9.92c-0.068,3.816,1.356,7.388,4.028,10.056
                            l57.68,57.692c5.224,5.22,14.38,5.22,19.596,0l7.44-7.44c2.604-2.6,4.044-6.084,4.044-9.788c0-3.716-1.44-7.232-4.044-9.836
                            l-22.14-22.172H226.79V425.32l-23.336-23.088c-5.212-5.22-14.488-5.22-19.7,0l-7.5,7.44c-2.604,2.6-4.072,6.084-4.072,9.792
                            c0,3.704,1.424,7.184,4.028,9.792l58.448,58.456c2.596,2.592,6.068,4.028,9.9,4.028c0.024-0.016,0.24,0,0.272,0
                            c3.712,0,7.192-1.432,9.792-4.028l58.828-58.832c2.6-2.604,4.044-6.088,4.044-9.792c0-3.712-1.44-7.192-4.044-9.796l-7.44-7.44
                            c-5.216-5.22-14.044-5.22-19.264,0l-21.54,21.868V265.284H425.59l-23.096,23.132c-2.612,2.608-4.048,6.112-4.048,9.82
                            s1.432,7.192,4.048,9.8l7.44,7.444c5.212,5.224,14.372,5.224,19.584,0l58.452-58.452c2.672-2.664,4.096-6.244,4.028-9.916
                            C492.07,243.296,490.642,239.728,487.97,237.06z"/>
                        </g>
                    </svg>
                    Центрировать
                </div>}
            </div>
{/*
            {mod.ICO && (
                <div className={"module-image"}>
                    <img src={mod.ICO} alt={module.name} />
                </div>
            )}
            {!mod.ICO && (
                <br />
            )}
            {module.heightFromFloor > -1 && (
                <div className="size-block">
                    <h2>Отступ от пола</h2>
                    <SizeBlock
                        value={heightFromFloor}
                        onChange={handlerHeightFromFloor}
                        min={0}
                        max={500}
                        isLocked={false}
                    />
                </div>
            )}

            <div className={"options-title"}>РАЗМЕРЫ ОБЪЕКТА</div>
            <div className="size-block">
                <h2>Ширина</h2>
                <SizeBlock
                    value={scaleX}
                    min={0}
                    step={5}
                    onChange={handlerScaleX}
                />
            </div>
            <div className="size-block">
                <h2>Глубина</h2>
                <SizeBlock
                    value={scaleZ}
                    min={0}
                    step={5}
                    onChange={handlerScaleZ}
                />
            </div>
            <div className="size-block">
                <h2>Высота</h2>
                <SizeBlock
                    value={scaleY}
                    min={0}
                    step={5}
                    onChange={handlerScaleY}
                />
            </div>

            {(module.corp!==null || module.face!==null) && <div className={"options-title"}>Материалы</div>}
            {(module.corp!==null || module.face!==null) &&
            <div className="modal-section">
                {module.corp!==null &&
                <div className="material-select">
                    <div className="material-select__name">Корпус</div>
                    <div className="material-select__select" onClick={()=>setMaterialCorpWnd(true)} style={{backgroundImage: 'url(' + materialCorp.userData.PICT + ')',cursor:'pointer'}}>
                        <span>{materialCorp.userData.NAME}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.78 6">
                            <polygon fill="currentColor" points="8.78 0 4.39 6 0 0 8.78 0"></polygon>
                        </svg>
                    </div>
                </div>
                }
                {materialCorpWnd === true && <MaterialSelect
                    onClose={()=>setMaterialCorpWnd(false)}
                    active={materialCorp}
                    list={corpMaterials}
                    setMaterial={setMaterial}
                    title="Корпус"
                />}


                {module.face!==null &&
                <div className="material-select">
                    <div className="material-select__name">Фасад</div>
                    <div className="material-select__select" onClick={()=>setMaterialFaceWnd(true)} style={{backgroundImage: 'url(' + materialFace.userData.PICT + ')',cursor:'pointer'}}>
                        <span>{materialFace.userData.NAME}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.78 6">
                            <polygon fill="currentColor" points="8.78 0 4.39 6 0 0 8.78 0"></polygon>
                        </svg>
                    </div>
                </div>
                }
                {materialFaceWnd === true && <MaterialSelect
                    onClose={()=>setMaterialFaceWnd(false)}
                    active={materialFace}
                    list={faceMaterials}
                    setMaterial={setMaterial}
                    title="Фасад"
                />}
            </div>
            }


            {materialCloth &&
            <div className="material-select">
                <div className="material-select__name">Ткань</div>
                <div className="material-select__select" onClick={()=>setMaterialClothWnd(true)} style={{backgroundImage: 'url(' + materialCloth.userData.PICT + ')',cursor:'pointer'}}>
                    <span>{materialCloth.userData.NAME}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.78 6">
                        <polygon fill="currentColor" points="8.78 0 4.39 6 0 0 8.78 0"></polygon>
                    </svg>
                </div>
            </div>
            }
            {materialClothWnd === true && materialCloth && <MaterialSelect
                onClose={()=>setMaterialClothWnd(false)}
                active={materialCloth}
                list={clothMaterials}
                setMaterial={setMaterial}
                title="Диван"
            />}

            {mod.SIZE.HEIGHT!==null && (
                <div className="size-block">
                    <span className="size-block__name">Высота</span>
                    <span className="size-block__value">{mod.SIZE.HEIGHT} см</span>
                </div>
            )}
            {mod.SIZE.WIDTH!==null && (
                <div className="size-block">
                    <span className="size-block__name">Ширина</span>
                    <span className="size-block__value">{mod.SIZE.WIDTH} см</span>
                </div>
            )}
            {mod.SIZE.DEPTH!==null && (
                <div className="size-block">
                    <span className="size-block__name">Глубина</span>
                    <span className="size-block__value">{mod.SIZE.DEPTH} см</span>
                </div>
            )}
            {mod.SIZE.DEPTH2!==null && (
                <div className="size-block">
                    <span className="size-block__name">Глубина в открытом виде</span>
                    <span className="size-block__value">{mod.SIZE.DEPTH2} см</span>
                </div>
            )}


            {module.option && materialCloth && (
                <div className={"options-title-wrap"}>
                    <div className={"options-title"}>Размеры дивана</div>
                    <div className={"options-title__option"}>
                        <span>Диван</span>
                        <div className={module.optionStatus?"oval-checkbox oval-checkbox_active":"oval-checkbox"}
                        onClick = {toggleOption}
                        >&nbsp;</div>
                    </div>
                </div>
            )}
            {module.option && !materialCloth && (
                <div className={"options-title-wrap"}>
                    <div className={"options-title"}>Техника для кухни</div>
                    <div className={"options-title__option"}>
                        <span>Техника</span>
                        <div className={module.optionStatus?"oval-checkbox oval-checkbox_active":"oval-checkbox"}
                        onClick = {toggleOption}
                        >&nbsp;</div>
                    </div>
                </div>
            )}
*/}
        </div>
    </ModalWrapper>
}

export default ModuleInfo3D
