import React from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    flexRender
} from '@tanstack/react-table';
import clsx from 'clsx';
import style from './Table.module.scss';

export const Table = ({ pageSize, data, columns, selectedRow, onSelectRow, onChangeData, isPaddings = true, amount = null }) => {
    const getRowId = React.useCallback(row => {
        return row.id
    }, [])

    const {
        getHeaderGroups,
        getRowModel,
        getCanPreviousPage,
        getCanNextPage,
        nextPage,
        previousPage
    } = useReactTable({
        columns,
        data,
        ...(selectedRow ?
            {
                state: {
                    rowSelection: selectedRow,
                },
            } : {}),
        ...(pageSize ?
            {
                initialState: {
                    pagination: {
                        pageSize: 5,
                    },
                },
            } : {}),
        ...(onChangeData ?
            {
                meta: {
                    updateData: (rowIndex, newValues) => {
                        onChangeData((old) =>
                            old.map((row, index) => {
                                if (index === rowIndex) {
                                    return {
                                        ...old[rowIndex],
                                        ...newValues,
                                    }
                                }
                                return row
                            })
                        )
                    },
                }
            } : {}),
        getRowId,
        enableRowSelection: true,
        getCoreRowModel: getCoreRowModel(),
        ...(onSelectRow ? { onRowSelectionChange: onSelectRow } : {}),
        ...(pageSize ? { getPaginationRowModel: getPaginationRowModel() } : {}),
    });

    return (
        <>
            <table className={style.table}>
                <thead>
                    {getHeaderGroups().map((headerGroup, index) => (
                        <tr key={headerGroup.id + index} className={style.header}>
                            {headerGroup.headers.map((header, index) => (
                                <th
                                    key={header.id + index}
                                    className={style?.["header-cell"]}
                                    colSpan={header.colSpan}
                                    style={{
                                        ...(header.getSize() !== 150 ? { width: header.getSize() } : {}),
                                    }}
                                >{
                                    flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )
                                }</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {getRowModel().rows.map((row, index) => {
                        return (
                            <tr key={row.id + index} className={style.row}>
                                {row.getVisibleCells().map((cell, index) => (
                                    <td key={cell.id + index} className={clsx(style.cell, index === 0 && isPaddings ? style?.["first-cell"] : {})}>{
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }</td>
                                ))}
                            </tr>
                        );
                    })}
                    {amount &&
                        <tr className={style.row}>
                            {getRowModel().rows?.[0].getVisibleCells().map((cell, index, cells) => (
                                <td
                                    key={index}
                                    className={style.cell}
                                    style={{
                                            fontSize: index === cells.length - 1 ? '22px' : '14px',
                                        }}
                                    >
                                    {index === cells.length - 2 && "Итого"}
                                    {index === cells.length - 1 && amount}
                                </td>
                            ))}
                        </tr>
                    }
                </tbody>
            </table>
            {pageSize &&
                (
                    <div className={style.footer}>
                        {getCanPreviousPage() &&
                            (
                                <div
                                    className={clsx("btn", style.button)}
                                    onClick={() => previousPage()}
                                >
                                    Назад
                                </div>
                            )
                        }
                        {getCanNextPage() &&
                            (
                                <div
                                    className={clsx("btn", style.button)}
                                    style={{ marginLeft: '12px' }}
                                    onClick={() => nextPage()}
                                >
                                    Далее
                                </div>
                            )
                        }
                    </div>
                )
            }
        </>
    );
};
