import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { actionsState as projectState } from '../../Redux/project';
import { ImageViewer } from '../UI';
import style from './Widgets.module.scss';
import { checkColumn } from '../Features/SideMenu/Estimate/utils/checkRoom';

const ObjectInfoBlock = ({ obj }) =>{
    const dispatch = useDispatch();
    const [showImage, setShowImage] = useState(false);
    const plan = useSelector(store => store.project.plan);
    const electricalPointsInfo = useMemo(() => {
        const electricalPoints = {
            electricSocket: 0,
            switch: 0,
            outletElectricalWire: 0,
            allCount: 0,
        };

        if (!obj.isFloor) {
            return electricalPoints;
        }

        const walls = [];
        for (const link of obj.links) {
            for (const wall of plan.bWalls) {
                if (link !== wall.mainLink) {
                    continue;
                }
                walls.push(wall);
            }
        }

        const columns = [];
        for (const column of plan.columns) {
            if (!checkColumn(column, obj)) {
                continue;
            }
            columns.push(column);
        }

        const fillObjectsList = (parent) => {
            for (const object of parent.objects) {
                if (!!object.isElectricSocket) {
                    electricalPoints.electricSocket += object.count || 0;
                    electricalPoints.allCount += object.count || 0;
                }
                if (!!object.isSwitch) {
                    electricalPoints.switch += object.count || 0;
                    electricalPoints.allCount += object.count || 0;
                }
                if (!!object.outletElectricalWire) {
                    electricalPoints.outletElectricalWire += object.count || 0;
                    electricalPoints.allCount += object.count || 0;
                }
            }
        }

        walls.forEach(fillObjectsList);
        columns.forEach(fillObjectsList);

        return electricalPoints;
    }, [obj]);

    return  (
        <div className="block">
            <div className="options-title">Информация</div>
            <div className="size-block">
                <div
                    className="btn btn-icon"
                    onClick={() => dispatch(projectState.setModal('info'))}
                >
                    Редактировать
                </div>
            </div>
            {obj?.objTitle &&
                <div className={style?.["info-preview"]}>
                    {obj?.objImages?.[0] ?
                        <>
                            <img
                                className={style?.["info-preview__img"]}
                                src={window.confSiteUrl + obj?.objImages?.[0]?.imageUrl}
                                alt={'prev-img'}
                                onClick={() => setShowImage(true)}
                            />
                            <ImageViewer
                                show={showImage}
                                onClose={() => setShowImage(false)}
                                img={window.confSiteUrl + obj?.objImages?.[0]?.imageUrl}
                            />
                        </> :
                        <div className={style?.["info-preview__blank"]}></div>
                    }
                    <div className={style?.["info-preview__text"]}>
                        <p className={style?.["info-preview__text-title"]}>{obj.objTitle}</p>
                        <p className={style?.["info-preview__text-comment"]}>{obj?.objComment}</p>
                    </div>
                </div>
            }
            {!!electricalPointsInfo.allCount &&
                <div className={style?.["info-preview__text-header"]}>
                    Количество электроточек: {electricalPointsInfo.allCount}
                    <ul className={style?.["info-preview__text-list"]}>
                        <li>Розеток: {electricalPointsInfo.electricSocket}</li>
                        <li>Выключателей: {electricalPointsInfo.switch}</li>
                        <li>Выводов электропровода: {electricalPointsInfo.outletElectricalWire}</li>
                    </ul>
                </div>
            }
        </div>
    )
}
export default ObjectInfoBlock;
