export const putMaterials = 'MATERIAL_PUT_MATERIALS';

const InitialState = {
    isLoading:false,
    corpMaterials:[],
    faceMaterials:[],
    clothMaterials: [],
    floorMaterial:[],
    wallMaterial:[]
};

export const actionsState = {
    putMaterials:(value)=>{
        return {type:putMaterials,value}
    }
};

const reducer = (state = InitialState,action)=>{
    switch(action.type){
        case putMaterials:{
            return {
                ...state,
                ...action.value
            }
        }
        default:{
            return state;
        }
    }
};

export default reducer;
