import { all, put } from 'redux-saga/effects'
import { actionsState as projectState } from "../Redux/project";
import dataSagaAsync from "./dataSagaAsync";

const rootSaga = function* rootSaga() {
    try {
        yield all([dataSagaAsync()])
    } catch (error) {
        yield put(projectState.setApplicationCrashError(error));
    }
};

export default rootSaga;
