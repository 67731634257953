import React, {Suspense, useEffect, useRef, useState} from 'react';
import ModalWrapper from "./ModalWrapper";
import {useDispatch, useSelector} from "react-redux";
import {actionsState as modulesState} from "../Redux/modules";
import {actionsState as projectState} from "../Redux/project";
import * as THREE from 'three/build/three.min';
import {
    sendRedrawEvent, sendRedrawSimpleEvent,
    sendReselectEvent,
    sendUnselectEvent
} from "../Helpers/Events";
import MaterialSelect from "./MaterialSelect";

const Materials = ()=>{

    const dispatch = useDispatch();
    const storeMaterial = useSelector(store=>store.material);
    const storeModules = useSelector(store=>store.modules.modules);
    // const storeAll = useSelector(store=>store);
    const storeFloors = useSelector(store=>store.project.plan.cycles);
    const storeWalls = useSelector(store=>store.project.plan.links);
    const planeEdit = useSelector(state=>state.project.planeEdit);
    const canvasSelector = (planeEdit) ? '#plan' : '#scene';

    const plan = useSelector(store=>store.project.plan);
    // console.log('storeAll',storeAll)
    // console.log('storeFloors',storeFloors)
    // console.log('storeWalls',storeWalls)
    // console.log('storeMaterial',storeMaterial)
    // console.log('storeModules',storeModules)

    const [materialCorpWnd,setMaterialCorpWnd] = useState(false);
    const [materialFaceWnd,setMaterialFaceWnd] = useState(false);
    const [materialClothWnd,setMaterialClothWnd] = useState(false);

    const [rMaterialWnd,setRMaterialWnd] = useState(false);
    const [lMaterialWnd,setLMaterialWnd] = useState(false);

    const [materialFloorWnd,setMaterialFloorWnd] = useState(false);

    const _setLMaterial = (material)=>{
        storeMaterial.activeWallLMaterial = material;
        storeWalls.map(m => {
            m.leftSide = material.clone();
        });

        sendUnselectEvent(document.querySelector(canvasSelector));
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    }

    const _setRMaterial = (material)=>{
        storeMaterial.activeWallRMaterial = material;
        storeWalls.map(m => {
            m.rightSide = material.clone();
        });

        sendUnselectEvent(document.querySelector(canvasSelector));
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    }

    const _setFloorMaterial = (material)=>{
        storeMaterial.activeFloorMaterial = material;
        storeFloors.map(m => {
            m.material = material.clone();
        });

        sendUnselectEvent(document.querySelector(canvasSelector));
        // sendRedrawEvent(document.querySelector(canvasSelector));
        plan.setFloors();
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    }

    const setMaterial = (material)=>{
        if(material.name === 'corp'){
            storeMaterial.activeCorpMaterial = material;
            storeModules.map(m => {
                m.corp = material.clone();
            });
        }
        else if(material.name === 'face'){
            storeMaterial.activeFaceMaterial = material;
            storeModules.map(m => {
                m.face = material.clone();
            });
        }
        else if(material.name === 'cloth'){
            storeMaterial.activeClothMaterial = material;
            storeModules.map(m => {
                m.cloth = material.clone();
            });
        }
        dispatch(projectState.decPreloader());

        sendUnselectEvent(document.querySelector(canvasSelector));
        sendRedrawEvent(document.querySelector(canvasSelector));
    }


    const close = ()=>{
        dispatch(projectState.setModal(''));
    }

    useEffect(()=>{

    },[])

    return  <ModalWrapper
            title="Отделка/материалы"
            onClose={()=>close()}
        >
            <div className="modal-body">

                <div className="materials-block">
                    <div className="options-title">Общие материалы мебели</div>
                    {storeMaterial.faceMaterials && storeMaterial.activeFaceMaterial &&
                    <div className="material-select">
                        <div className="material-select__name">Фасад</div>
                        <div className="material-select__select" onClick={()=>setMaterialFaceWnd(true)} style={{backgroundImage: 'url(' + storeMaterial.activeFaceMaterial.userData.PICT + ')',cursor:'pointer'}}>
                            <span>{storeMaterial.activeFaceMaterial.userData.NAME}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.78 6">
                                <polygon fill="currentColor" points="8.78 0 4.39 6 0 0 8.78 0"></polygon>
                            </svg>
                        </div>
                    </div>
                    }
                    {materialFaceWnd === true && storeMaterial.faceMaterials && <MaterialSelect
                        onClose={()=>setMaterialFaceWnd(false)}
                        active={storeMaterial.activeFaceMaterial}
                        list={storeMaterial.faceMaterials}
                        setMaterial={setMaterial}
                        title="Фасад"
                    />}

                    {storeMaterial.corpMaterials && storeMaterial.activeCorpMaterial &&
                    <div className="material-select">
                        <div className="material-select__name">Корпус</div>
                        <div className="material-select__select" onClick={()=>setMaterialCorpWnd(true)} style={{backgroundImage: 'url(' + storeMaterial.activeCorpMaterial.userData.PICT + ')',cursor:'pointer'}}>
                            <span>{storeMaterial.activeCorpMaterial.userData.NAME}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.78 6">
                                <polygon fill="currentColor" points="8.78 0 4.39 6 0 0 8.78 0"></polygon>
                            </svg>
                        </div>
                    </div>
                    }
                    {materialCorpWnd === true && storeMaterial.corpMaterials && <MaterialSelect
                        onClose={()=>setMaterialCorpWnd(false)}
                        active={storeMaterial.activeCorpMaterial}
                        list={storeMaterial.corpMaterials}
                        setMaterial={setMaterial}
                        title="Корпус"
                    />}

                    {storeMaterial.clothMaterials && storeMaterial.activeClothMaterial &&
                    <div className="material-select">
                        <div className="material-select__name">Ткань</div>
                        <div className="material-select__select" onClick={()=>setMaterialClothWnd(true)} style={{backgroundImage: 'url(' + storeMaterial.activeClothMaterial.userData.PICT + ')',cursor:'pointer'}}>
                            <span>{storeMaterial.activeClothMaterial.userData.NAME}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.78 6">
                                <polygon fill="currentColor" points="8.78 0 4.39 6 0 0 8.78 0"></polygon>
                            </svg>
                        </div>
                    </div>
                    }
                    {materialClothWnd === true && storeMaterial.clothMaterials && <MaterialSelect
                        onClose={()=>setMaterialClothWnd(false)}
                        active={storeMaterial.activeClothMaterial}
                        list={storeMaterial.clothMaterials}
                        setMaterial={setMaterial}
                        title="Ткань"
                    />}
                </div>


                <div className="materials-block">
                    <div className="options-title">Материалы стен</div>
                    <div className="material-select">
                        <div className="material-select__name">Левая сторна</div>
                        <div className="material-select__select" onClick={()=>setLMaterialWnd(true)} style={{backgroundImage: 'url(' + storeMaterial.activeWallLMaterial.userData.PICT + ')',cursor:'pointer'}}>
                            <span>{storeMaterial.activeWallLMaterial.userData.NAME}</span>
                        </div>
                    </div>
                    {lMaterialWnd === true && <MaterialSelect
                        onClose={()=>setLMaterialWnd(false)}
                        active={storeMaterial.activeWallLMaterial}
                        list={storeMaterial.wallMaterial}
                        setMaterial={_setLMaterial}
                        title="Левая сторна"
                    />}
                    <div className="material-select">
                        <div className="material-select__name">Правая сторна</div>
                        <div className="material-select__select" onClick={()=>setRMaterialWnd(true)} style={{backgroundImage: 'url(' + storeMaterial.activeWallRMaterial.userData.PICT + ')',cursor:'pointer'}}>
                            <span>{storeMaterial.activeWallRMaterial.userData.NAME}</span>
                        </div>
                    </div>
                    {rMaterialWnd === true && <MaterialSelect
                        onClose={()=>setRMaterialWnd(false)}
                        active={storeMaterial.activeWallRMaterial}
                        list={storeMaterial.wallMaterial}
                        setMaterial={_setRMaterial}
                        title="Правая сторна"
                    />}
                </div>


                <div className="materials-block">
                    <div className="options-title">Материал пола</div>
                    <div className="material-select">
                        <div className="material-select__name">Материал пола</div>
                        <div className="material-select__select" onClick={()=>setMaterialFloorWnd(true)} style={{backgroundImage: 'url(' + storeMaterial.activeFloorMaterial.userData.PICT + ')',cursor:'pointer'}}>
                            <span>{storeMaterial.activeFloorMaterial.userData.NAME}</span>
                        </div>
                    </div>
                    {materialFloorWnd === true && <MaterialSelect
                        onClose={()=>setMaterialFloorWnd(false)}
                        active={storeMaterial.activeFloorMaterial}
                        list={storeMaterial.floorMaterial}
                        setMaterial={_setFloorMaterial}
                        title="Материал пола"
                    />}
                </div>


            </div>
        </ModalWrapper>
}

export default Materials
