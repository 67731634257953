/**
 * Рассчитывает угол между линиями
 * @param {{a:{x,y},b:{x,y}}} line1 
 * @param {{a:{x,y},b:{x,y}}} line2 
 * @returns Возвращает угол в градусах
 */
export const calcAngleDeg = (line1, line2) => {
    const virtualVector = line1.a.clone().sub(line1.b).normalize();
    if (virtualVector.x < 0) {
        virtualVector.negate();
    }
    const wallVector = line2.a.clone().sub(line2.b).normalize();
    if (wallVector.x < 0) {
        wallVector.negate();
    }

    const angle = Math.abs(180 * (virtualVector.angle() - wallVector.angle()) / Math.PI);

    return (angle > 180 ? 360 - angle : angle)
}