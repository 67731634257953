import {
    LWPolylineFlags,
    TrueColor
} from '@tarikjabiri/dxf';
import { drawArc, drawCircle, drawHatches, drawPolylines } from './primitives';
import {
    calculateAngle,
    extrudeLine,
    getCirclePoints,
    movePoint,
    rotatePoint,
    segmentLine,
    squaredDistance,
    thirdRectPoint
} from './geometry';
import { info } from './drawInfo';
import { COLOR } from './index';

const lineOptions = { flags: LWPolylineFlags.Closed };

const drawDoor = (dxf, start, end, width, object) => {
    const isInner = object.inside;
    const isLeft = object.left;

    const door = extrudeLine(start, end, width).map((point) => {
        if (isLeft) {
            return rotatePoint(
                point,
                isInner ? end : start,
                isInner ? -90 : 90
            );
        } else {
            return rotatePoint(
                point,
                isInner ? start : end,
                isInner ? 90 : -90
            );
        }
    })

    drawPolylines(dxf, lineOptions, door);

    if (isLeft) {
        const { angle } = calculateAngle(start, end);
        drawArc(
            dxf,
            isInner ? end : start,
            isInner ? start : end,
            !isInner ? 90 - angle : -angle,
            !isInner ? 180 - angle : 90 - angle
        );
    } else {
        const { angle } = calculateAngle(end, start);
        drawArc(
            dxf,
            isInner ? start : end,
            isInner ? end : start,
            isInner ?  180 + 90 - angle : 180 - angle,
            isInner ? -angle : -90 - angle
        );
    }
}

export const drawObject = (dxf, object, parent, isReverse) => {
    const isObjectReverse = parent?.isWall ? (parent.mainLink.lrBuild === 'right' && !isReverse) : false;
    const parentDepth = parent?.isWall ? (parent.mainLink.depth + parent.mainLink.innerDepth) : object.depth;

    const a = isObjectReverse ? object.rB1Inner : object.rA1Inner;
    const b = isObjectReverse ? object.rA1Inner : object.rB1Inner;
    const d = isObjectReverse ? object.rB2Inner : object.rA2Inner;
    const c = isObjectReverse ? object.rA2Inner : object.rB2Inner;
    const e = isObjectReverse ? object.rB13d : object.rA13d;
    const f = isObjectReverse ? object.rA13d : object.rB13d;
    const h = isObjectReverse ? object.rB23d : object.rA23d;
    const g = isObjectReverse ? object.rA23d : object.rB23d;

    if (object.isWindow) {
        drawPolylines(dxf, lineOptions, [a, b, c, d]);
        drawPolylines(dxf, lineOptions, [segmentLine(d, a, 1 / 3), segmentLine(c, b, 1 / 3)]);
        drawPolylines(dxf, lineOptions, [segmentLine(d, a, 2 / 3), segmentLine(c, b, 2 / 3)]);
    }

    if (object.isHole) {
        const outerPartHole = object.depthIndent + object.depth < (parentDepth) ? [c, d, h, g] : null;
        const innerPartHole = object.depthIndent ? [a, b, f, e] : null;

        if (outerPartHole) {
            drawPolylines(dxf, lineOptions, outerPartHole);
            drawHatches(dxf, { trueColor: COLOR.GRAY }, outerPartHole);
        }

        if (innerPartHole) {
            drawPolylines(dxf, lineOptions, innerPartHole);
            drawHatches(dxf, { trueColor: COLOR.GRAY }, innerPartHole);
        }
    }

    if (object.isDoor) {
        const insidePoints = [c, d];
        const outerPoints = [a, b];

        if (object?.inside) {
            drawPolylines(dxf, lineOptions, [
                insidePoints[0],
                segmentLine(insidePoints[0], outerPoints[1], 1),
                thirdRectPoint(
                    insidePoints[0],
                    segmentLine(insidePoints[0], outerPoints[1], 1),
                    segmentLine(insidePoints[0], insidePoints[1], 0.1)
                ),
                segmentLine(insidePoints[0], insidePoints[1], 0.1)
            ]);
            drawPolylines(dxf, lineOptions, [
                insidePoints[1],
                segmentLine(insidePoints[1], outerPoints[0], 1),
                thirdRectPoint(
                    insidePoints[1],
                    segmentLine(insidePoints[1], outerPoints[0], 1),
                    segmentLine(insidePoints[1], insidePoints[0], 0.1)
                ),
                segmentLine(insidePoints[1], insidePoints[0], 0.1)
            ]);

            drawDoor(
                dxf,
                segmentLine(insidePoints[0], insidePoints[1], 0.1),
                segmentLine(insidePoints[1], insidePoints[0], 0.1),
                20,
                object
            );
        } else {
            drawPolylines(dxf, lineOptions, [
                outerPoints[0],
                segmentLine(outerPoints[0], insidePoints[1], 1),
                thirdRectPoint(
                    outerPoints[0],
                    segmentLine(outerPoints[0], insidePoints[1], 1),
                    segmentLine(outerPoints[0], outerPoints[1], 0.1)
                ),
                segmentLine(outerPoints[0], outerPoints[1], 0.1)
            ]);
            drawPolylines(dxf, lineOptions, [
                outerPoints[1],
                segmentLine(outerPoints[1], insidePoints[0], 1),
                thirdRectPoint(
                    outerPoints[1],
                    segmentLine(outerPoints[1], insidePoints[0], 1),
                    segmentLine(outerPoints[1], outerPoints[0], 0.1)
                ),
                segmentLine(outerPoints[1], outerPoints[0], 0.1)
            ]);

            drawDoor(
                dxf,
                segmentLine(outerPoints[0], outerPoints[1], 0.1),
                segmentLine(outerPoints[1], outerPoints[0], 0.1),
                20,
                object
            );
        }
    }

    {
        const isHorizontally = object.type === 'horizontally';
        const isLeftWall = parent?.isWall ? (parent.mainLink.lrBuild === 'left') : true;
        const isLeftObject = object.lrBuild === 'left';

        const start = isLeftWall === isLeftObject ? e : h;
        const end = isLeftWall === isLeftObject ? f : g;

        const { angle } = calculateAngle(isLeftObject ? start : end, isLeftObject ? end : start);
        const moveDistance = (Math.sqrt(squaredDistance(start, end)) - Math.sqrt(squaredDistance(start, end)) * object.scale) / 2;

        if (object?.isElectricSocket) {
            for (let i = 0; i < object.count; i++) {
                const center = isHorizontally
                    ? movePoint(segmentLine(start, end, (i + 0.5) / (object.count) * object.scale), angle, isLeftObject ? -moveDistance : moveDistance)
                    : segmentLine(start, end, 0.5);

                const radiusPoint = movePoint((isHorizontally
                    ? segmentLine(start, end, (i + 1) / (object.count) * object.scale)
                    : segmentLine(start, end, object.scale)), angle, isLeftObject ? -moveDistance : moveDistance);

                if (!isHorizontally) i = object.count;

                drawArc(
                    dxf,
                    center,
                    radiusPoint,
                    -angle,
                    180 - angle,
                    { trueColor: COLOR.GREEN }
                );

                const centerSocket = rotatePoint(radiusPoint, center, isLeftObject ? 90 : -90);
                const endSocket = segmentLine(center, centerSocket, 3);
                const leftSocket = rotatePoint(center, centerSocket, -90);
                const rightSocket = rotatePoint(center, centerSocket, 90);

                drawPolylines(dxf, { ...lineOptions, trueColor: COLOR.GREEN }, [center, centerSocket]);
                drawPolylines(dxf, { ...lineOptions, trueColor: COLOR.GREEN }, [centerSocket, endSocket]);
                drawPolylines(dxf, { ...lineOptions, trueColor: COLOR.GREEN }, [centerSocket, leftSocket]);
                drawPolylines(dxf, { ...lineOptions, trueColor: COLOR.GREEN }, [centerSocket, rightSocket]);
            }
        }

        if (object?.isSwitch) {
            for (let i = 0; i < object.count; i++) {
                const centerProjection = isHorizontally
                    ? movePoint(segmentLine(end, start, (i - 1 / 4) / object.count * object.scale), angle, isLeftObject ? moveDistance : -moveDistance)
                    : segmentLine(end, start, 1 / 4 * object.scale);

                const endProjection = isHorizontally
                    ? movePoint(segmentLine(end, start, (i - 1 / 2) / object.count * object.scale), angle, isLeftObject ? moveDistance : -moveDistance)
                    : end;

                if (!isHorizontally) i = object.count;

                const radiusCircle = Math.sqrt(squaredDistance(centerProjection, endProjection));
                const points = extrudeLine(
                    centerProjection,
                    endProjection,
                    isLeftObject ? -radiusCircle : radiusCircle
                );
                const center = points[points.length - 1];

                drawArc(dxf, center, centerProjection, 0, 360, { trueColor: COLOR.BLUE })

                const arrowStart = rotatePoint(centerProjection, center, isLeftObject ? -120 : 120);
                const arrowEnd = segmentLine(endProjection, arrowStart, 2);

                drawPolylines(dxf, { ...lineOptions, trueColor: COLOR.BLUE }, [arrowStart, arrowEnd]);

                const leftSideArrow = rotatePoint(segmentLine(arrowEnd, arrowStart, 1 / 2), arrowEnd, -90);
                const rightSideArrow = rotatePoint(segmentLine(arrowEnd, arrowStart, 1 / 2), arrowEnd, 90);

                drawPolylines(dxf, { ...lineOptions, trueColor: COLOR.BLUE }, [arrowEnd, leftSideArrow]);
                drawPolylines(dxf, { ...lineOptions, trueColor: COLOR.BLUE }, [arrowEnd, rightSideArrow]);
            }
        }

        if (object?.outletElectricalWire) {
            for (let i = 0; i < object.count; i++) {
                const centerProjection = movePoint((isHorizontally
                    ? segmentLine(start, end, (i + 0.5) / (object.count) * object.scale)
                    : segmentLine(start, end, 0.5 * object.scale)), angle, isLeftObject ? -moveDistance : moveDistance);
                const endProjection = movePoint((isHorizontally
                    ? segmentLine(start, end, (i + 1) / (object.count) * object.scale)
                    : segmentLine(start, end, object.scale)), angle, isLeftObject ? -moveDistance : moveDistance);

                if (!isHorizontally) i = object.count;

                const radiusCircle = Math.sqrt(squaredDistance(centerProjection, endProjection));
                const points = extrudeLine(
                    centerProjection,
                    endProjection,
                    isLeftObject ? -radiusCircle : radiusCircle
                );
                const center = points[points.length - 1];

                const arrowEnd = rotatePoint(centerProjection, center, isLeftObject ? -120 : 120);
                drawPolylines(dxf, { ...lineOptions, trueColor: COLOR.RED }, [arrowEnd, endProjection]);

                const leftSideArrow = rotatePoint(segmentLine(arrowEnd, endProjection, 1 / 6), arrowEnd, -25);
                const rightSideArrow = rotatePoint(segmentLine(arrowEnd, endProjection, 1 / 6), arrowEnd, 25);

                drawPolylines(dxf, { ...lineOptions, trueColor: COLOR.RED }, [arrowEnd, leftSideArrow, rightSideArrow]);
                drawHatches(dxf, { trueColor: COLOR.RED }, [arrowEnd, leftSideArrow, rightSideArrow]);
            }
        }

        if (object?.isHeatingBattery) {
            const initPoints = extrudeLine(start, end, isLeftObject ? -object.depthIndentFor3D : object.depthIndentFor3D);
            const depthStart = initPoints[3];
            const depthEnd = initPoints[2];

            const points = extrudeLine(
                movePoint(depthStart, angle, isLeftObject ? -moveDistance : moveDistance),
                movePoint(segmentLine(depthStart, depthEnd, object.scale), angle, isLeftObject ? -moveDistance : moveDistance),
                (isLeftObject ? -object.depthFor3D : object.depthFor3D) * object.scale
            );

            drawPolylines(dxf, lineOptions, points);

            const centerPoints = extrudeLine(
                movePoint(depthStart, angle, isLeftObject ? -moveDistance : moveDistance),
                movePoint(segmentLine(depthStart, depthEnd, object.scale), angle, isLeftObject ? -moveDistance : moveDistance),
                (isLeftObject ? -object.depthFor3D : object.depthFor3D) * object.scale / 2
            );
            const leftCenterPoint = centerPoints[centerPoints.length - 1];
            const rightCenterPoint = centerPoints[centerPoints.length - 2];

            const leftInsidePoint = segmentLine(leftCenterPoint, rightCenterPoint, 1 / 6);
            const rightInsidePoint = segmentLine(rightCenterPoint, leftCenterPoint, 1 / 6);

            drawPolylines(dxf, lineOptions, [leftInsidePoint, rightInsidePoint]);
        }

        if (object?.isElectricPanel) {
            const initPoints = extrudeLine(start, end, isLeftObject ? -object.depthIndentFor3D : object.depthIndentFor3D);
            const depthStart = initPoints[3];
            const depthEnd = initPoints[2];

            const points = extrudeLine(
                movePoint(depthStart, angle, isLeftObject ? -moveDistance : moveDistance),
                movePoint(segmentLine(depthStart, depthEnd, object.scale), angle, isLeftObject ? -moveDistance : moveDistance),
                (isLeftObject ? -object.depthFor3D : object.depthFor3D) * object.scale
            );

            drawPolylines(dxf, { ...lineOptions, trueColor: COLOR.RED, constantWidth: 0.2 }, points);

            const centerPoints = extrudeLine(
                movePoint(depthStart, angle, isLeftObject ? -moveDistance : moveDistance),
                movePoint(segmentLine(depthStart, depthEnd, object.scale), angle, isLeftObject ? -moveDistance : moveDistance),
                (isLeftObject ? -object.depthFor3D : object.depthFor3D) * object.scale / 2
            );
            const leftCenterPoint = centerPoints[centerPoints.length - 1];
            const rightCenterPoint = centerPoints[centerPoints.length - 2];

            drawHatches(dxf, { ...lineOptions, trueColor: COLOR.RED }, [points[0], leftCenterPoint, rightCenterPoint, points[1]]);
        }

        if (object?.isRedCube) {
            const initPoints = extrudeLine(
                movePoint(start, angle, isLeftObject ? -moveDistance : moveDistance),
                movePoint(segmentLine(start, end, object.scale), angle, isLeftObject ? -moveDistance : moveDistance),
                isLeftObject ? -object.depthIndentFor3D : object.depthIndentFor3D
            );
            const points = extrudeLine(initPoints[3], initPoints[2], (isLeftObject ? -object.depthFor3D : object.depthFor3D) * object.scale);
            drawPolylines(dxf, lineOptions, points);
            const color = object.rgb ? object.rgb : { r: 255, g: 0, b: 0 };
            drawHatches(dxf, { trueColor: TrueColor.fromRGB(color.r, color.g, color.b) }, points);
        }

        if (object?.isCylinder) {
            const initPoints = extrudeLine(start, end, isLeftObject ? -object.depthIndentFor3D : object.depthIndentFor3D);
            const points = extrudeLine(initPoints[3], initPoints[2], (isLeftObject ? -object.width / 2 : object.width / 2) * object.scale);
            const center = segmentLine(points[2], points[3], 1 / 2);
            const centerProjection = segmentLine(points[0], points[1], 1 / 2);
            drawCircle(dxf, center, centerProjection);
            const color = object.rgb ? object.rgb : { r: 255, g: 0, b: 0 };
            drawHatches(dxf, { trueColor: TrueColor.fromRGB(color.r, color.g, color.b) }, getCirclePoints(center.x, center.y, Math.sqrt(squaredDistance(centerProjection, center)), 55));
        }
    }

    info.addObject({ ...object, isReverse: isObjectReverse });
};
