export const getImage = async (module, filename) => {
    const image = module.shema.normal;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const width = (image.width / 4) * 10;
    const height = (image.height / 4) * 10;
    const x = (module.size.width / 4) - width / 2;
    const y = (module.size.height / 4) - height / 2;

    canvas.width = module.size.width / 2;
    canvas.height = module.size.height / 2;

    ctx.drawImage(image, x, y, width, height);

    return await new Promise((resolve) => {
        canvas.toBlob(resolve);
    });
};

export const saveFile = async (data, filename) => {
    const blob = new Blob([data], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 100);
}

export const getImageInfo = (src) => {
    return fetch(src)
        .then((response) => {
            if (response.ok) {
                return response.blob();
            }
            throw new Error('Ошибка запроса');
        })
        .then((blob) => {
            return new Promise((resolve, reject) => {
                const img = new Image();

                img.onload = () => {
                    URL.revokeObjectURL(img.src);
                    resolve({
                        blob,
                        width: img.width,
                        height: img.height
                    });
                };

                img.onerror = (e) => {
                    reject(new Error('Ошибка при загрузке изображения'));
                };

                img.src = URL.createObjectURL(blob);
            });
        })
        .catch((error) => {
            console.error('Произошла ошибка при загрузке изображения:', error);
        });
}
