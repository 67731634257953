import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Button, Table } from '../../../UI';
import { useOutside } from '../../../Hooks';
import { useCollectEstimate } from './hooks';
import { actionsState as projectState } from '../../../../Redux/project';
import style from './Estimate.module.scss';

const EstimateMode = ({ tab, onChangeTab, value, onChange }) => {
    const getStyle = (type) => clsx(style?.["estimate-filter__tab"], tab === type ? style?.['tab-active'] : {});
    const onChangeType = (type) => onChangeTab(type);

    return (
        <div>
            <div className={style?.["estimate-filter"]}>
                <div
                    className={getStyle('jobs')}
                    onClick={() => onChangeType('jobs')}
                >
                    Работы
                </div>
                <div
                    className={getStyle('draft')}
                    onClick={() => onChangeType('draft')}
                >
                    Черновые материалы
                </div>
                <div
                    className={getStyle('final')}
                    onClick={() => onChangeType('final')}
                >
                    Чистовые материалы
                </div>
                <div
                    className={clsx(
                    value
                            ? 'control__item_sel'
                            : 'control__item_sel control__item_sel_active',
                        style.switch
                    )}
                    onClick={onChange}
                >
                    <span>По комнатам</span>
                    <span></span>
                    <span>Общая</span>
                </div>
            </div>
            <div className={style?.divider}></div>
        </div>
    )
}

const EstimateRoom = ({ room, index, columns }) => {
    return (
        <div>
            <div className={style?.["estimate-result__room"]} style={{ ...(index > 0 ? { marginTop: '36px'} : {} )}}>
                <div className={style?.["estimate-result__room-name"]}>{room.title}</div>
                <div className={style?.["estimate-result__room-amount"]}>
                    {Math.round(room.amount).toLocaleString('fr-FR')} руб.
                </div>
            </div>
            {Object.entries(room).map(([object, value]) => value.estimate?.length ? (
                <>
                    <div className={style?.["estimate-result__table-title"]}>{object}</div>
                    <Table
                        data={value.estimate}
                        columns={columns}
                        isPaddings={false}
                        amount={Math.round(value.amount)?.toLocaleString('fr-FR')}
                    />
                </>) : null
            )}
        </div>
    )
}

export const EstimateResult = () => {
    const dispatch = useDispatch();
    const { ref } = useOutside(() => { dispatch(projectState.setModal('')) });

    const [mode, setMode] = useState('room');
    const [type, setType] = useState('jobs');

    const columns = useMemo(() => ([
        {
            size: 400,
            header: 'наименование',
            accessorKey: 'name',
        },
        {
            size: 160,
            header: 'код',
            accessorKey: 'code',
        },
        {
            size: 160,
            header: 'цена за ед.',
            accessorKey: 'cost',
        },
        {
            size: 160,
            header: 'ед.изм.',
            accessorKey: 'unit',
        },
        {
            size: 160,
            header: 'объем',
            accessorKey: type === 'jobs' ? 'volume' : 'quantity',
        },
        {
            size: 160,
            header: 'стоимость, руб.',
            accessorKey: 'amount',
            cell: ({ getValue }) => {
                const value = Math.round(getValue());
                return (
                    <p className={style?.["table-summary"]}>
                        {value?.toLocaleString('fr-FR')}
                    </p>
                );
            },
        },
    ]), [type]);

    const tokenEID = useSelector(state => state.project.saveResult.tokenEID);
    const enableViewMenu = useSelector(state => state.project.userSetup.enableViewMenu);
    const isEstimate = tokenEID ? true : false;

    const { estimateByRooms, estimateByItems, estimateJson, resultAmount } = useCollectEstimate(type);

    return (
        <div className={style?.["estimate-container"]}>
            <div ref={ref} className={style?.["estimate-result"]}>
                <div className={style?.["estimate-result__title"]}>
                    <div>Смета</div>
                    <div
                        className={style?.["estimate-result__title-close"]}
                        onClick={() => { dispatch(projectState.setModal('')) }}
                    >
                        <img style={{ height: '24px' }} src={window.confComponentUrl + "assets/icons/icon-close.svg"} alt="" />
                    </div>
                </div>
                <EstimateMode
                    tab={type}
                    onChangeTab={(tab) => setType(tab)}
                    value={mode === 'room'}
                    onChange={() => setMode(mode === 'room' ? 'common' : 'room')}
                />
                {mode === "room" ?
                    <div className={style?.["estimate-result__body"]}>
                        {estimateByRooms.rooms.map((room, index) => (
                            <EstimateRoom
                                key={index}
                                index={index}
                                room={room}
                                columns={columns}
                            />
                        ))}
                        {estimateByRooms.otherAmount ?
                            <div>
                                <div className={style?.["estimate-result__room"]} style={{marginTop: '36px'}}>
                                    <div className={style?.["estimate-result__room-name"]}>Остальное</div>
                                    <div className={style?.["estimate-result__room-amount"]}>
                                        {Math.round(estimateByRooms.otherAmount).toLocaleString('fr-FR')} руб.
                                    </div>
                                </div>
                                {Object.entries(estimateByRooms).map(([object, value]) => value?.estimate?.length ? (
                                    <>
                                        <div className={style?.["estimate-result__table-title"]}>{object}</div>
                                        <Table
                                            data={value.estimate}
                                            columns={columns}
                                            isPaddings={false}
                                            amount={Math.round(value.amount)?.toLocaleString('fr-FR')}
                                        />
                                    </>) : null
                                )}
                            </div> : null
                        }
                    </div> :
                    <div className={style?.["estimate-result__body"]}>
                        {Object.entries(estimateByItems).map(([object, value]) => value?.estimate?.length ? (
                            <div>
                                <div className={style?.["estimate-result__room"]} style={{marginTop: '36px'}}>
                                    <div className={style?.["estimate-result__room-name"]}>{object}</div>
                                    <div className={style?.["estimate-result__room-amount"]}>
                                        {Math.round(value.amount).toLocaleString('fr-FR')} руб.
                                    </div>
                                </div>
                                <>
                                    <Table
                                        data={value.estimate}
                                        columns={columns}
                                        isPaddings={false}
                                        amount={Math.round(value.amount)?.toLocaleString('fr-FR')}
                                    />
                                </>
                            </div>) : null
                        )}
                    </div>
                }
                <div className={style?.["estimate-result__summary"]}>
                    <div>
                        <p className={style?.["summary-title"]}>Итоговая стоимость</p>
                        <p className={style?.["summary-cost"]}>{Math.round(resultAmount).toLocaleString('fr-FR')} руб.</p>
                    </div>
                    <div>
                        {isEstimate && <Button variant="contain" onClick={() => {
                            console.log(estimateJson);
                            console.log(JSON.stringify(estimateJson));
                            dispatch({ type: 'SEND_ESTIMATE', estimate: estimateJson });
                            dispatch(projectState.setModal(''));
                        }}>
                            Отправить смету {tokenEID}
                        </Button>}
                        {!isEstimate && enableViewMenu && <p>Для отправки сметы назначьте сметчика на проект</p> }
                    </div>
                </div>
            </div>
        </div>
    )
}
