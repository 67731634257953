import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Select, TextField } from '../../../UI';
import { estimateActions } from '../../../../Redux/estimate';
import style from './TariffSelect.module.scss';

export const TariffSelect = () => {
    const dispatch = useDispatch();

    const currentTariff = useSelector(state => state.estimate.currentTariff);
    const coefficient = useSelector(state => state.estimate.coefficient);
    const tariffs = useSelector(state => state.estimate.tariffs);
    const allEstimate = useSelector(state => state.estimate.allEstimate);

    const onChangeCoefficient = (e) => {
        const { value } = e.target;

        if (value >= 10) {
            dispatch(estimateActions.setCoefficient(10));
            return;
        }
        if (value < 0) {
            dispatch(estimateActions.setCoefficient(0));
            return;
        }

        dispatch(estimateActions.setCoefficient(value));
    }

    const onBlurCoefficient = (e) => {
        const { value } = e.target;

        if (Number(value) === 0 || value === "") {
            dispatch(estimateActions.setCoefficient(1));
        }

        dispatch({ type: "UPDATE_CURRENT_ESTIMATE", allEstimate });
    }

    return (
        <div className="MainMenu-row">
            <div className={clsx("MainMenu-bl", style.container)}>
                <div className={clsx("MainMenu-bl-title", style.title)}>Тарифы</div>
                <Select
                    isDisabled={!tariffs || !tariffs?.length}
                    type="text"
                    value={currentTariff}
                    options={tariffs}
                    onChange={(tariff) => dispatch(estimateActions.setCurrentTariff(tariff))}
                />
                <div className={style.label}>Коэффициент:</div>
                <TextField type="number" value={coefficient} onChange={onChangeCoefficient} onBlur={onBlurCoefficient}/>
            </div>
        </div>
    )
}
