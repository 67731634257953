import React, {useState} from 'react';
const VariantDropBoxSimple = ({list,active,title,setActive,classes})=>{

    const [open,setOpen] = useState(false);
    const openHandler = ()=>{
        setOpen(!open);
    }
    return  (
        <div className={"variant-select variant-select-simple "+classes}>
            {title && <div className="variant-select__title">{title}</div>}
            <div
                className={open?"variant-select__select variant-select__select_open":"variant-select__select"}
                onClick={openHandler}
            >
                <span className={"variant-select__name"}>{active.NAME}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.78 6">
                    <polygon fill="currentColor" points="8.78 0 4.39 6 0 0 8.78 0"></polygon>
                </svg>
                <ul>
                    {list.map(v=>(
                        <li key={v.ID}
                            onClick = {()=>setActive(v)}
                        >
                            <span className={"variant-select__name"}>{v.NAME}</span>
                        </li>
                    ))
                    }
                </ul>
            </div>
        </div>
    )
}
export default VariantDropBoxSimple;
