import React, {useEffect, useState} from 'react';
import ModalWrapper from "./ModalWrapper";
import {useDispatch, useSelector} from "react-redux";
import {actionsState as projectState} from "../Redux/project";
import {sendGetHugeImage, sendRedrawEvent} from "../Helpers/Events";

const HugeImageInfo = ()=>{

    const dispatch = useDispatch();
    const hugeImage = useSelector(state=>state.project.hugeImage);
    const [planeEdit] = useSelector(state=>([state.project.planeEdit]));

    // console.log('hugeImage',hugeImage)

    // dispatch(projectState.addPreloader())
    // sendGetHugeImage(document.querySelector('#scene'));
    const _setWidth = event=>{
        let val = event.target.value*1
        if (val > 5000) val = 5000
        if (val < 500) val = 500
        // console.log('_setWidth',val)
        dispatch(projectState.setHugeImage({dataUrl:'',width:val,height:hugeImage.height}))
        // dispatch(projectState.addPreloader())
        // sendGetHugeImage(document.querySelector('#scene'))
    }

    const _setHeight = event=>{
        let val = event.target.value*1
        if (val > 5000) val = 5000
        if (val < 500) val = 500
        // console.log('_setHeight',val)
        dispatch(projectState.setHugeImage({dataUrl:'',width:hugeImage.width,height:val}))
        // dispatch(projectState.addPreloader())
        // sendGetHugeImage(document.querySelector('#scene'))
    }

    const getHugeImage = ()=>{
        // if(!planeEdit) dispatch(projectState.setEditMode(false));
        if(planeEdit) return false

        dispatch(projectState.addPreloader())
        sendGetHugeImage(document.querySelector('#scene'));
    }

    const close = ()=>{
        dispatch(projectState.setModal(''));
    }

    useEffect(()=>{
    })

    return  <ModalWrapper
            onClose={()=>close()}
            title="Рендер крупный"
        >
            <div className="modal-body">
                <a href={hugeImage.dataUrl} download={"conf3d_"+hugeImage.width+"x"+hugeImage.height+".png"} className="hugeImage"><img src={hugeImage.dataUrl} /></a>
                <div className="options-title">Визуализация</div>
                <div className="size-block">
                    <h2>Ширина (px)</h2>
                    <div className="size-block-input">
                        <div className="size-minus">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34"><g strokeWidth="2px" stroke="currentColor"><line x1="8.5" y1="17.5" x2="24.5" y2="17.5"/></g></svg>
                        </div>
                        <input value={hugeImage.width} onChange={_setWidth} type="text" />
                        <div className="size-plus">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34"><g strokeWidth="2px" stroke="currentColor"><line x1="8.5" y1="17.5" x2="24.5" y2="17.5"/><line x1="16.5" y1="9.5" x2="16.5" y2="25.5"/></g></svg>
                        </div>
                    </div>
                </div>
                <div className="size-block">
                    <h2>Высота (px)</h2>
                    <div className="size-block-input">
                        <div className="size-minus">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34"><g strokeWidth="2px" stroke="currentColor"><line x1="8.5" y1="17.5" x2="24.5" y2="17.5"/></g></svg>
                        </div>
                        <input value={hugeImage.height} onChange={_setHeight} type="text" />
                        <div className="size-plus">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34"><g strokeWidth="2px" stroke="currentColor"><line x1="8.5" y1="17.5" x2="24.5" y2="17.5"/><line x1="16.5" y1="9.5" x2="16.5" y2="25.5"/></g></svg>
                        </div>
                    </div>
                </div>
                <div onClick={()=>{getHugeImage()}} className="btn btn-default btn-hugeImage">Сгенерировать изображение</div>
                <a href={hugeImage.dataUrl} download={"conf3d_"+hugeImage.width+"x"+hugeImage.height+".png"} className="btn btn-default btn-hugeImage">Скачать изображение</a>
            </div>
        </ModalWrapper>
}

export default HugeImageInfo
