import { useState } from 'react';

export const usePlanField = () => {
    const [object, setObject] = useState(null);
    const [value, setValue] = useState('');
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const onChange = ({ object, value, position, callback }) => {
        if (object) setObject(object);
        if (value) {
            callback?.();
            setValue(value);
        }
        if (position) setPosition({ x: position.x - 50, y: position.y - 20 });
    }

    const clearField = () => {
        setObject(null);
        setValue('');
        setPosition({ x: 0, y: 0 });
    }

    return { object, value, position, onChange, clearField };
}
