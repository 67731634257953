export const fixDXF = (dxf, modules) => {
    const dxfByLines = dxf.split('\n');
    let dictLineIndex = 0;
    let deleteLine = false;

    const result = dxfByLines.filter((line, index) => {
        if (line.includes("ACAD_IMAGE_DICT")) {
            if (dictLineIndex === 0) dictLineIndex = index;
            deleteLine = true;
            return false;
        }
        if (deleteLine && line.trim() === "0") {
            deleteLine = false;
            return false;
        }
        return !deleteLine;
    })

    if (dictLineIndex !== 0) {
        const imagesDictionary =
            `DICTIONARY
             5
            112233
            102
            {ACAD_REACTORS
            330
            C
            102
            }
            330
            C
            100
            AcDbDictionary
            281
            1
            3`
        .split('\n').map((line) => line.trimStart());

        const modulesDictionary = modules.map((module, index) => [
            module.name,
            350,
            module.imageDefHandle,
            index === modules.length - 1 ? 0 : 3
        ]).flat();

        const end =
            `RASTERVARIABLES
            5
            223344
            102
            {ACAD_REACTORS
            330
            C
            102
            }
            330
            C
            100
            AcDbRasterVariables
            90
             0
            70
             1
            71
             1
            72
             5
            0`
        .split('\n').map((line) => line.trimStart());

        result.splice(dictLineIndex, 0, 'ACAD_IMAGE_DICT', 350, 112233, 3, 'ACAD_IMAGE_VARS', 350, 223344, 0);
        result.splice(dictLineIndex + 8, 0, ...imagesDictionary, ...modulesDictionary, ...end);

        return result.join('\n');
    }

    return dxf;
}
