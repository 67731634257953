import { bluetoothRouletteObj } from "./bluetoothRouletteObj";

export const distance = {
  callBack: {},

  setCallBack: (callBack) => {
    distance.callBack = callBack;
  },

  start: (callBack) => {
    console.info("distance.start");
    const characteristic = bluetoothRouletteObj.characteristic;
    if ("value" in characteristic) {
      characteristic.addEventListener(
        "characteristicvaluechanged",
        distance.handleDistanceChanged
      );
      if (callBack) {
        distance.callBack = callBack;
      }
    } else {
      console.error(
        "нет подключенного устройства или какие другие проблемы с bluetoothRouletteObj.characteristic."
      );
    }
  },

  handleDistanceChanged: (event) => {
    const value = event.target.value.getUint32(3) / 10;
    console.log({ value });
    if (typeof distance.callBack === "function" && value !== 80846493.1) {
      distance.callBack(Math.round(value / 5) * 5);
    } else {
      console.error(
        "в distance не задана функция callBack, используйте distance.start(callBack)."
      );
    }
  },

  stop: () => {
    console.warn("distance.stop");
    const characteristic = bluetoothRouletteObj.characteristic;
    if ("value" in characteristic) {
      characteristic.removeEventListener(
        "characteristicvaluechanged",
        distance.handleDistanceChanged
      );
      distance.callBack = {};
    }
  },
};
