import React, { useState } from 'react'

import Icon from '@mdi/react'
import {
    mdiArrowLeftBold, mdiArrowRightBold, mdiArrowUpBold, mdiArrowDownBold,
    mdiNumeric1BoxMultipleOutline, mdiNumeric5BoxMultipleOutline, mdiNumeric10BoxMultipleOutline, mdiCompassOutline
} from '@mdi/js';

import clsx from 'clsx';

import { actionsState as projectState } from "../../Redux/project";
import styles from './MovingPanel.module.css';
import { is_touch_device } from '../../Helpers/functions';
import { useDispatch } from "react-redux";

const collectionStep = [
    { multiplier: 1, icon: mdiNumeric1BoxMultipleOutline },
    { multiplier: 5, icon: mdiNumeric5BoxMultipleOutline },
    { multiplier: 10, icon: mdiNumeric10BoxMultipleOutline },
]

export const MovingPanel = ({ motionFunction }) => {
    const dispatch = useDispatch();
    const [step, setStep] = useState({ nowStep: 0, info: collectionStep[0] });

    const move = (dx, dy) => {
        motionFunction(dx * step.info.multiplier, dy * step.info.multiplier);
    }

    const bruteForceOfStep = () => {
        step.nowStep++;
        if (step.nowStep > collectionStep.length - 1) {
            step.nowStep = 0;
        }
        setStep({ nowStep: step.nowStep, info: collectionStep[step.nowStep] });
        dispatch(projectState.setSteps(collectionStep[step.nowStep].multiplier));
    }

    return <div className={clsx(styles.main, !is_touch_device() && styles.hover)} >
        <Icon path={mdiArrowUpBold}
            size={1.4}
            title="Вверх"
            onClick={() => move(0, -1)}
        />

        <div className={styles.centralRow}>
            <Icon path={mdiArrowLeftBold}
                size={1.4}
                title="Влево"
                onClick={() => move(-1, 0)}
            />
            <Icon path={step.info.icon}
                size={1}
                title="шаг"
                onClick={bruteForceOfStep}

            />
            <Icon path={mdiArrowRightBold}
                size={1.4}
                title="Вправо"
                onClick={() => move(1, 0)}

            />
        </div>
        <Icon path={mdiArrowDownBold}
            size={1.4}
            title="Вниз"
            onClick={() => move(0, 1)}
        />

    </div>
}