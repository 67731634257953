import React, {Suspense, useEffect, useRef, useState} from 'react';
import ModalWrapper from "./ModalWrapper";
import {useDispatch, useSelector} from "react-redux";
import {actionsState as projectState} from "../Redux/project";

const ShowHelp = ({floor})=>{


    const dispatch = useDispatch();

    const close = ()=>{
        dispatch(projectState.setModal(''));
    }

    useEffect(()=>{
    },[floor])

    return  <ModalWrapper
            title={'ОБРАТНАЯ СВЯЗЬ'}
            onClose={()=>close()}
        >
            <div className="modal-body">
                <p>Пожалуйста, примите участие в нашем опросе о качестве приложения Конфигуратор. Это не займет более 5 минут, но сильно поможет нам улучшить качество обслуживания. Мы приготовили для вас приятный подарок за участие - дисконт 10% на один заказ, оформленный через сайт или конфигуратор.</p>
                <p>Вашим подтверждением на скидку станет Код сохраненного проекта в конфигураторе. Пожалуйста не забудьте его указать в комментариях по прохождении опроса.</p>
                <a target={'_blank'} href={'/configurator/order/opros.php'} className="btn btn-default">Пройти опрос</a>
            </div>
        </ModalWrapper>
}

export default ShowHelp
