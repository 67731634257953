import { getCrossPointsWall } from './columnsCalculation';

export const stickPointToWall = (point, walls, zoom, distForMergeCol = 30) => {
    let x = point.x;
    let y = point.y;

    for (const [index, wall] of Object.entries(walls)) {
        if (!wall) {
            continue;
        }

        const { cross1, cross2 } = getCrossPointsWall(wall, point, zoom);

        if (cross1 && cross1.length < distForMergeCol) {
            x = cross1.crossPoint.x;
            y = cross1.crossPoint.y;
        }

        if (cross2 && cross2.length < distForMergeCol && (cross1 && cross1.length > cross2.length)) {
            x = cross2.crossPoint.x;
            y = cross2.crossPoint.y;
        }
    }

    if (point.x !== x || point.y !== y) {
        return { x: x, y: y };
    }

    return null;
};
