import * as THREE from 'three/build/three.min';
//import { fileExists } from "../Helpers/functions";
import { sendRedrawSimpleEvent } from "../Helpers/Events";
import { calculateMaterialAmount, calculateMaterialQuantity } from '../Helpers/estimate';

class Module {

    constructor(model, _obj = {}) {
        // console.log("class Module => model ",model);
        // console.log("class Module => _obj ",_obj);
        this.heightFromFloor = model.userData.heightFromFloor ? model.userData.heightFromFloor : 0;
        this.mixer = new THREE.AnimationMixer(model);
        this.model = model;
        this.roomIDs = -1;
        this.showModule = true;
        // this.size = (_obj && _obj.size)?_obj.size:{};
        // this.sizeDef = (_obj && _obj.sizeDef)?_obj.sizeDef:{};
        //
        // this.model.scale.x = (this.size.sizeX/(this.sizeDef.sizeX/100))/100;
        // this.model.scale.y = (this.size.sizeY/(this.sizeDef.sizeY/100))/100;
        // this.model.scale.z = (this.size.sizeZ/(this.sizeDef.sizeZ/100))/100;
        // this.updateSize();

        this._position = (_obj && _obj.canvasCenter) ? _obj.canvasCenter : new THREE.Vector2(0, 0);

        this._status = 'close';
        this._angle = (_obj && _obj.angle) ? _obj.angle : 0;
        this.option = _obj.LINKED?.[0] ? _obj.LINKED?.[0] : null;
        /* Вкл. диван */
        if (this.option)
            this._optionStatus = true;
        else
            this._optionStatus = false;
        this.model.traverse(obj => {
            if (obj.isGroup && obj.userData.type === 'OPTION') {
                obj.visible = true;
            }
        });
        /* / Вкл. диван */
        this.shema = {
            normal: new Image(),
            hover: new Image()
        }


        // this.updateSize();
        this.id = _obj.ID ? _obj.ID : null;
        this.name = _obj.NAME ? _obj.NAME : null;
        this.materials = _obj.MATERIALS ? _obj.MATERIALS : { corp: {}, face: {}, cloth: {}, metal: {} }
        this.isModule = true;
        this._corp = null;
        this._face = null;
        this._cloth = null;

        if (this.option) this.materials.cloth = this.option.MATERIALS.cloth;
        // console.log("class Module => this ",this);

        this.objTitle = '';
        this.objComment = '';
        this.objImages = [];
        this.estimate = [];

        this.rgbColor = false;
        this.rgb = {
            r: false,
            g: false,
            b: false,
            a: '1',
        };
        this.updateShema();
    }

    get optionStatus() {
        return this._optionStatus;
    }
    set optionStatus(val) {
        if (this._optionStatus !== val) {
            this.model.traverse(obj => {
                if (obj.isGroup && obj.userData.type === 'OPTION') {
                    obj.visible = val;
                }
            });
            this._optionStatus = val;
            this.updateShema();
            // this.updateSize();
        }
    }
    set position(p) {
        this._position.set(p.x, p.y);
        // this._position.x = p.x
        // this._position.y = p.y
        //this.model.position.set(p.x/100,0.0,p.y/100);
        //this.model.translateX(p.x/100);
        //this.model.translateZ(p.y/100);
        //this.model.matrixWorldNeedsUpdate = true;
    }

    get position() {
        return this._position;
    }

    set angle(a) {
        this._angle = a;
        //this.model.children[0].rotation.y = a;
    }

    get angle() {
        return this._angle;
    }

    set corp(m) {
        this._corp = m;
        this.model.traverse(e => {
            if (e.isMesh) {
                if (e.material.length > 0) {
                    e.material.map((_m, i) => {
                        if (_m.name === 'corp') {
                            e.material[i] = m;
                            this.changeMaterial(e.material[i], m);
                        }
                    })
                }
                else if (e.material.name === 'corp') {
                    this.changeMaterial(e.material, m);
                }
            }
        })

        this.updateShema();
    }
    get corp() {
        let find = false;
        this.model.traverse(e => {
            if (e.isMesh) {
                if (e.material.length > 0) {
                    e.material.map(_m => {
                        if (_m.name === 'corp') find = true;
                    })
                }
                else if (e.material.name === 'corp') find = true;
            }
        })
        return find ? this._corp : null;
    }
    set face(m) {
        this._face = m;
        this.model.traverse(e => {
            if (e.isMesh) {
                if (e.material.length > 0) {
                    e.material.map((_m, i) => {
                        if (_m.name === 'face') {
                            this.changeMaterial(e.material[i], m);
                        }
                    })
                }
                else if (e.material.name === 'face') {
                    this.changeMaterial(e.material, m);
                }
            }
        })
        this.updateShema();
    }
    get face() {
        let find = false;
        this.model.traverse(e => {
            if (e.isMesh) {
                if (e.material.length > 0) {
                    e.material.map((_m, i) => {
                        if (_m.name === 'face') find = true;
                    })
                }
                else if (e.material.name === 'face') find = true;
            }
        })
        return find ? this._face : null;
    }
    set cloth(m) {
        this._cloth = m;
        this.model.traverse(e => {
            if (e.isMesh) {
                if (e.material.length > 0) {
                    e.material.map((_m, i) => {
                        if (_m.name === 'cloth') {
                            this.changeMaterial(e.material[i], m);
                        }
                    })
                }
                else if (e.material.name === 'cloth') {
                    this.changeMaterial(e.material, m);
                }
            }
        })
        this.updateShema();
    }
    get cloth() {
        let find = false;
        this.model.traverse(e => {
            if (e.isMesh) {
                if (e.material.length > 0) {
                    e.material.map((_m, i) => {
                        if (_m.name === 'cloth') find = true;
                    })
                }
                else if (e.material.name === 'cloth') find = true;
            }
        })
        return find ? this._cloth : null;
    }

    set status(status) {
        // console.log('status(status)',status)
        // console.log('status(status) this',this)
        // console.log('status(status) this.animations',this.animations)
        if (this.model.userData.animations) {

            // const root = getRoot(this);
            // console.log('root',root)
            // console.log('this',this)

            this.mixer = new THREE.AnimationMixer(this.model);

            if (status === 'open' && this._status === 'close') {
                const clip = this.model.userData.animations.find(a => /open$/i.test(a.name));
                if (clip) {
                    const action = this.mixer.clipAction(clip);
                    action.loop = THREE.LoopOnce;
                    action.clampWhenFinished = true;
                    action.play();
                    this._status = 'open';

                    this.model.traverse(o => {
                        if (o.name === 'podushki') o.visible = false;
                    });
                }
            } else if (status === 'close' && this._status === 'open') {
                const clip = this.model.userData.animations.find(a => /close$/i.test(a.name));
                if (clip) {
                    const action = this.mixer.clipAction(clip);
                    action.loop = THREE.LoopOnce;
                    action.clampWhenFinished = true;
                    action.play();
                    this._status = 'close';

                    setTimeout(() => {
                        this.model.traverse(o => {
                            if (o.name === 'podushki') o.visible = true;
                        });
                    }, clip.duration * 1000);
                }
            }
        }

        this._status = status;
    }

    get status() {
        return this._status;
    }

    changeMaterial(material, donor) {
        //material = donor;
        material.map = donor.map;
        material.reflectivity = donor.reflectivity;
        material.combine = donor.combine;
        material.envMap = donor.envMap;
        material.color = donor.color.clone();
        if (donor.specular) material.specular = donor.specular.clone();
        material.shininess = donor.shininess;
        material.userData.ID = donor.userData.ID;
        material.map.needsUpdate = true;
        material.needsUpdate = true;
    };

    storeScheme = async (fileName, imgRegularData, imgHoverData, modelSize) => {
        const url = window.confAjaxUrl2dSchemes + 'ajax_modules_from_top.php'
        const data = {
            fileName: fileName,
            imgRegularData: imgRegularData,
            imgHoverData: imgHoverData,
            modelSize: modelSize
        }

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            // const json = await response.json()
            // console.log('Success:', JSON.stringify(json))
        } catch (error) {
            console.error('Error:', error)
        }
    }

    renderShema(imgFileName = false) {
        const { devicePixelRatio: ratio = 1 } = window

        const whSizes = 500 * 2 / ratio

        let container = document.createElement('canvas');
        container.width = container.height = whSizes;
        container.style.border = 'none';
        const scene = new THREE.Scene();
        const halfSize = 2.5;
        const camera = new THREE.OrthographicCamera(-halfSize, halfSize, halfSize, -halfSize, 1, 10);
        camera.position.set(0, 10, 0);
        camera.lookAt(new THREE.Vector3(0, 0, 0));
        const ambientLight = new THREE.AmbientLight(0xFFFFFF);
        scene.add(ambientLight);

        const spotLight = new THREE.SpotLight(0xFFFFFF);
        spotLight.power = 0.6;
        spotLight.position.set(5, 15, 5);
        spotLight.lookAt(new THREE.Vector3(0, 0, 0));
        spotLight.castShadow = false;
        // console.log('SPOT',spotLight);

        scene.add(spotLight);
        const oldMatrix = this.model.matrix.clone();
        const oldParent = this.model.parent;


        // const modelGroup = new THREE.Group();
        // modelGroup.add(this.model);
        // modelGroup.computeBoundingBox();
        // const offset = this.model.boundingBox.getCenter().negate();
        // this.model.translate( offset.x, offset.y, offset.z );

        const modelClone = this.model.clone(true)
        // console.log('this.model',this.model)
        if (!this._optionStatus) {
            modelClone.traverse(obj => {
                if (obj.isGroup && obj.userData.type === 'OPTION') {
                    // console.log('obj',obj)
                    for (let i = obj.children.length - 1; i >= 0; i--) {
                        obj.remove(obj.children[i])
                    }

                }
            })
        }
        // console.log('modelClone',modelClone)


        const bb = new THREE.Box3()
        const center = new THREE.Vector3();
        bb.setFromObject(modelClone)
        const modelCenter = bb.getCenter(center)

        // console.log('this.model',this.model)
        // console.log('modelClone',modelClone)
        camera.position.set(modelCenter.x, 10, modelCenter.z)

        scene.add(this.model);
        let renderer = new THREE.WebGLRenderer({ canvas: container, antialias: true, alpha: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(whSizes, whSizes);
        renderer.render(scene, camera);


        const shema = new Image();
        shema.src = renderer.domElement.toDataURL();
        ambientLight.color = new THREE.Color(0x59DA28);

        //pointLight.color = 0x5555ff;
        renderer.render(scene, camera);
        const shemaHover = new Image();
        shemaHover.src = renderer.domElement.toDataURL();
        this.shema = {
            normal: shema,
            hover: shemaHover
        }

        scene.traverse(obj => {
            if (obj.isMesh) {
                obj.geometry.dispose();
                if (obj.material.length > 0) {

                    obj.material.map(m => { m.dispose() });
                }
                else {
                    obj.material.dispose();
                }
            }
        });

        scene.remove(this.model);
        if (oldParent && oldParent.isObject3D) {
            oldParent.add(this.model);
        }
        renderer = null;

        this.updateSize();
        if (imgFileName)
            this.storeScheme(imgFileName, shema.src, shemaHover.src, this.size);
        // console.log('this.size',this.size)

        if (document.querySelector('#plan'))
            sendRedrawSimpleEvent(document.querySelector('#plan'));
        else
            sendRedrawSimpleEvent(document.querySelector('#scene'));
    }

    async updateShema() {
        if (!this._corp || !this._face || !this._cloth) {
            return false;
        }
        this.renderShema();
        // if (this.size &&
        //     this.size.sizeX == this.sizeDef.sizeX &&
        //     this.size.sizeY == this.sizeDef.sizeY &&
        //     this.size.sizeZ == this.sizeDef.sizeZ) {
        //
        //     let imgFileName = this.id;
        //     if (this.corp !== null && this.corp !== undefined)
        //         imgFileName += '_' + this.corp.userData.ID;
        //     if (this.face !== null && this.face !== undefined)
        //         imgFileName += '_' + this.face.userData.ID;
        //     if (this.cloth !== null && this.cloth !== undefined)
        //         imgFileName += '_' + this.cloth.userData.ID;
        //
        //     if (this.option)
        //         imgFileName += (this._optionStatus) ? '_optionON' : '_optionOFF';
        //     if (this.rbg !== null && this.rgb !== undefined)
        //         imgFileName += '_r' + this.rgb.r + 'g' + this.rgb.g + 'b' + this.rgb.b;
        //     /*if (this.size !== null && this.size !== undefined)
        //         imgFileName += '_x' + this.size.sizeX + 'y' + this.size.sizeY + 'x' + this.size.sizeZ;*/
        //
        //     if (imgFileName === undefined)
        //         return false;
        //
        //     const imgSrcJSON = window.confAjaxUrl2dSchemes + 'tmp_images/modules_from_top/' + imgFileName + '.json';
        //
        //     fetch(imgSrcJSON)
        //         .then((response) => {
        //             if (response.ok) {
        //                 return response.json()
        //             } else if (response.status === 404) {
        //                 this.renderShema(imgFileName);
        //                 return Promise.reject('error 404');
        //             } else {
        //                 return Promise.reject('some other error: ' + response.status)
        //             }
        //         })
        //         .then((data) => {
        //             //console.log('fetch(imgSrcJSON)',data);
        //
        //             const shema = new Image();
        //             shema.src = data.imgRegularData;
        //
        //             const shemaHover = new Image();
        //             shemaHover.src = data.imgHoverData;
        //             this.shema = {
        //                 normal: shema,
        //                 hover: shemaHover
        //             }
        //             this.size = data.modelSize;
        //             if (document.querySelector('#plan'))
        //                 sendRedrawSimpleEvent(document.querySelector('#plan'));
        //             else
        //                 sendRedrawSimpleEvent(document.querySelector('#scene'));
        //         });
        // } else {
        //     this.renderShema();
        // }
    }

    isVisible(mesh) {
        let result = true;
        const recursionSearch = (obj) => {
            if (!obj.visible) { result = false; return; }
            else if (obj.parent) recursionSearch(obj.parent);
            return;
        };
        recursionSearch(mesh);
        return result;
    }

    updateSize() {
        let maxX = 0;
        let minX = 0;
        let maxY = 0;
        let minY = 0;
        let maxZ = 0;
        let minZ = 0;
        const getMatrix = (obj) => {
            let matrix = new THREE.Matrix4();
            const recursionMatrix = (obj) => {
                if (obj.parent) {
                    if (obj.parent.parent && obj.parent.parent.userData.type !== 'MODULE') {
                        // console.log('MULTIPLY ',obj.parent.matrix.clone());
                        matrix.multiplyMatrices(obj.parent.matrix, matrix);
                        recursionMatrix(obj.parent);
                    }
                    return;
                }
                return;
            }
            recursionMatrix(obj);
            return matrix;
        }
        this.model.traverse(obj => {
            if (obj.isMesh) {
                if (this.isVisible(obj)) {
                    obj.geometry.computeBoundingBox();
                    const matrix = getMatrix(obj);
                    const max = obj.geometry.boundingBox.max.clone().applyMatrix4(matrix);
                    const min = obj.geometry.boundingBox.min.clone().applyMatrix4(matrix);
                    //console.log('VISIBLE ',maxX,minX,maxY,minY);
                    maxX = Math.max(maxX, max.x);
                    maxY = Math.max(maxY, max.z);
                    maxZ = Math.max(maxZ, max.y);
                    minX = Math.min(minX, min.x);
                    minY = Math.min(minY, min.z);
                    minZ = Math.min(minZ, min.y);
                }
            }
        });
        const result = {
            pivot: { x: Math.abs(-minX * 1000), y: -minY * 1000 },
            width: Math.abs(maxX - minX) * 1000,
            height: Math.abs(maxY - minY) * 1000,
            sizeX: Math.round(Math.abs(maxX - minX) * 1000),
            sizeZ: Math.round(Math.abs(maxY - minY) * 1000),
            sizeY: Math.round(Math.abs(maxZ - minZ) * 1000),
        }
        // if (!this.sizeDef) {
        //     result.sizeX = Math.ceil(Math.abs(maxX-minX)*1000);
        //     result.sizeZ = Math.ceil(Math.abs(maxY-minY)*1000);
        //     result.sizeY = Math.ceil(Math.abs(maxZ-minZ)*1000);
        // }

        this.size = result;

        if (!this.sizeDef) {
            this.sizeDef = {
                sizeX: result.sizeX,
                sizeZ: result.sizeZ,
                sizeY: result.sizeY,
            };
        }

        // console.log('module.sizeDef',this.sizeDef)
        // console.log('module.size',this.size)
    }

    isHover(point) {
        const rotatePoint = new THREE.Vector2(point.x, point.y).rotateAround(this.position, this.angle);
        if (
            rotatePoint.x >= this.position.x - this.size.pivot.x &&
            rotatePoint.x <= this.position.x + (this.size.width - this.size.pivot.x) &&
            rotatePoint.y >= this.position.y - this.size.pivot.y &&
            rotatePoint.y <= this.position.y + (this.size.height - this.size.pivot.y)
        ) return true;
        else return false;
    }

    isHoverRotateControl(point, zoom) {
        const rs = 12 / zoom;
        const vp = new THREE.Vector2(point.x, point.y).rotateAround(this.position, this.angle);
        const vc = new THREE.Vector2(
            this.position.x - this.size.width + this.size.pivot.x - 10 - rs,
            this.position.y - this.size.height + this.size.pivot.y - 10 - rs
        );
        return vc.sub(vp).length() < rs * 1.5;
    }
    setEstimate(estimate) {
        this.estimate = estimate.filter((est, index, self) => (
            index === self.findIndex((selfJob) => selfJob.id === est.id)
        )).map((estimate) => {
            return {
                id: estimate.id,
                externalId: estimate.externalId,
                group: estimate.group,
                stage: estimate.stage,
                price: estimate.price,
                code: estimate.code,
                name: estimate.name,
                cost: estimate.cost,
                unit: estimate.unit,
                object: estimate.object,
                volume: estimate.volume,
                amount: estimate.amount,
                materials: estimate.materials.map((material) => {
                    material.volume = estimate.volume;
                    material.quantity = calculateMaterialQuantity(estimate, material);
                    material.amount = calculateMaterialAmount(material);
                    return { ...material }
                })
            }
        });
    }
    lookTo(point, clickMousePoint, startAngle) {
        let angle1 = point.clone().sub(this.position).angle();
        let angle2 = clickMousePoint.clone().sub(this.position).angle();

        let diff = angle1 - angle2;
        let angle = startAngle - diff;
        let angleD = angle * (360 / (Math.PI * 2));
        angleD = Math.round(angleD / 5) * 5;
        angle = angleD / (180 / Math.PI);
        this.angle = angle;

        // let angle = point.clone().sub(this.position).angle()-Math.PI;
        //
        // if(angle<0) angle+=Math.PI*2;
        // else if(angle>Math.PI*2) angle-=Math.PI*2;
        //
        // let angleD = angle * (360 / Math.PI*2);
        // angleD = Math.round(angleD / 5)*5;
        // angle = angleD / (180 / Math.PI);
        //
        // this.angle = Math.PI*2 - angle;
    }
    clone() {
        const newModel = this.model.clone(true);
        const newModule = new Module(newModel);

        newModule.id = this.id;
        newModule.name = this.name;

        newModule.materials = this.materials;
        newModule._status = this._status;
        newModule.animations = this.animations;
        newModule.showModule = this.showModule;
        newModule.roomIDs = this.roomIDs;

        const corp = window.materials.corp.find(m => m.userData.ID === this.corp);
        const face = window.materials.face.find(m => m.userData.ID === this.face);
        const cloth = window.materials.cloth.find(m => m.userData.ID === this.cloth);

        newModule.corp = corp ? corp.clone() : window.materials.corp[0].clone();
        newModule.face = face ? face.clone() : window.materials.face[0].clone();
        newModule.cloth = cloth ? cloth.clone() : window.materials.cloth[0].clone();

        newModule.position = this.position.clone();
        newModule.angle = this.angle;

        newModule.rgb = this.rgb;
        newModule.rgbColor = this.rgbColor;

        newModule.model.scale.x = (this.size.sizeX / (this.sizeDef.sizeX / 1000)) / 1000;
        newModule.model.scale.y = (this.size.sizeY / (this.sizeDef.sizeY / 1000)) / 1000;
        newModule.model.scale.z = (this.size.sizeZ / (this.sizeDef.sizeZ / 1000)) / 1000;

        newModule.updateShema();

        newModule.size = this.size;
        newModule.sizeDef = this.sizeDef;

        return newModule;
    }
}

export default Module;
