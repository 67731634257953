import { Vector2 } from 'three';

const checkDotEntersRoom = (point, cycle) => {
    const x = point.x;
    const y = point.y;

    let inside = false;
    for (let i = 0, j = cycle._points.length - 1; i < cycle._points.length; j = i++) {
        const xi = cycle._points[i].x, yi = cycle._points[i].y;
        const xj = cycle._points[j].x, yj = cycle._points[j].y;
        const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
}

const findMostFrequentObject = (arr) => {
    if (arr.length < 4) {
        return null;
    }

    let counts = {};
    let mostFrequent = null;
    let maxCount = 0;

    for (let i = 0; i < arr.length; i++) {
        let obj = arr[i];
        counts[obj] = counts[obj] ? counts[obj] + 1 : 1;

        if (counts[obj] > maxCount) {
            mostFrequent = obj;
            maxCount = counts[obj];
        }
    }

    return mostFrequent;
}

export const checkRoom = (module, cycle) => {
    const zeroVector = new Vector2(0, 0);
    const rooms = [];

    const width = module.size.width;
    const height = module.size.height;
    const pivot = module.size.pivot;
    const angle = module.angle;

    const a = new Vector2(0, -pivot.y);
    const b = new Vector2(0, height - pivot.y);
    const c = new Vector2(-pivot.x, 0);
    const d = new Vector2(width - pivot.x, 0);

    [a, b, c, d].forEach((origin) => {
        origin.rotateAround(zeroVector, -angle);
        origin.add(new Vector2(module.position.x, module.position.y));

        if (checkDotEntersRoom(origin, cycle)) {
            rooms.push(cycle);
        }
    })

    return findMostFrequentObject(rooms);
}

export const checkColumn = (column, cycle) => {
    const rooms = [];

    const width = column.width;
    const height = column.depth;
    const pivot = { x: column.x, y: column.y };
    const angle = column.angle;

    const a = new Vector2(pivot.x + width / 2 - 1, pivot.y + height / 2 - 1);
    const b = new Vector2(pivot.x + width / 2 - 1, pivot.y - height / 2 + 1);
    const c = new Vector2(pivot.x - width / 2 + 1, pivot.y + height / 2 - 1);
    const d = new Vector2(pivot.x - width / 2 + 1, pivot.y - height / 2 + 1);

    [a, b, c, d].forEach((origin) => {
        origin.rotateAround({ x: column.x, y: column.y }, -angle);

        if (checkDotEntersRoom(origin, cycle)) {
            rooms.push(cycle);
        }
    })

    return findMostFrequentObject(rooms);
}
