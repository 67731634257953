'use strict'

import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

class ColorRBG extends React.Component {
    state = {
        displayColorPicker: false,
        color: {
            r: this.props.colorDef.r,
            g: this.props.colorDef.g,
            b: this.props.colorDef.b,
            a: this.props.colorDef.a,
        },
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
        this.props._setRgbColor(this.state.color)
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ color: color.rgb })
        this.props._setRgbColor(color.rgb)
    };

    componentWillReceiveProps(props) {
        const { colorDef } = this.props;
        if (props.colorDef !== colorDef) {
            this.setState({ color: props.colorDef })
        }
    }

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '100%',
                    height: '100%',
                    background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
                    display: 'flex',
                    alignItems: 'flex-end',
                    padding: '10px',
                },
                swatch: {
                    display: 'block',
                    cursor: 'pointer',
                    width: '100%',
                    height: '100%',
                    background: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==) left center',
                },
                material_item_rgb: {
                    padding: '0',
                },
                title: {

                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                    right: '22%',
                    top: '32%',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div className={this.props.active?'material-item material-item_active material-item-rgb':'material-item material-item-rgb'} style={ styles.material_item_rgb }>
                <div style={ styles.swatch } onClick={ this.handleClick }>
                    <div style={ styles.color }><span style={ styles.title }>RGB цвет</span></div>
                </div>
                { this.state.displayColorPicker ? <div style={ styles.popover }>
                    <div style={ styles.cover } onClick={ this.handleClose }/>
                    <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
                </div> : null }
            </div>
        )
    }
}

export default ColorRBG
