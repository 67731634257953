import React from 'react';
import ModalWrapper from "./ModalWrapper";
import { actionsState as projectState } from "../Redux/project";
import { useDispatch, useSelector } from "react-redux";
import { actionsState as modulesState } from "../Redux/modules";
import {
    sendCenterModuleEvent,
    sendRedrawEvent,
    sendUnselectEvent
} from "../Helpers/Events";

const ModulesList = () => {
    const dispatch = useDispatch();
    const modules = useSelector(store => store.modules.modules);
    const allModules = useSelector(store => store.modules.allModules);

    return (
        <ModalWrapper
            title="Объекты в проекте"
            onClose={() => { dispatch(projectState.setModal('')) }}
        >
            <div className="modal-body">
                <div className={"options-title"}>ОБЪЕКТЫ В ПРОЕКТЕ</div>
                <div className={'all-modules-body'}>
                    {
                        modules.map((_m, index) => {
                            const cM = modules[modules.length - 1 - index];
                            const item = allModules.find(mod => cM.id == mod.ID);
                            if (item.SECTION !== 433 || window.debug) return <ModuleItem key={item.id} module={cM} mID={modules.length - 1 - index} />
                            return null
                        })
                    }
                </div>
            </div>
        </ModalWrapper>
    )
}
export default ModulesList;

const ModuleItem = ({ module, mID }) => {
    const dispatch = useDispatch();
    const planeEdit = useSelector(state => state.project.planeEdit);
    const canvasSelector = (planeEdit) ? '#plan' : '#scene';
    const modules = useSelector(store => store.modules.modules);
    const allModules = useSelector(store => store.modules.allModules);
    const item = allModules.find(m => module.id == m.ID);

    const deleteModule = (module) => {
        dispatch(modulesState.remove(module));
        dispatch(modulesState.getPrice());
        sendUnselectEvent(document.querySelector(canvasSelector));
        sendRedrawEvent(document.querySelector(canvasSelector));
    }

    const handlerShowModule = (module) => {
        module.showModule = !module.showModule;
        sendRedrawEvent(document.querySelector(canvasSelector));
    }

    const centerModule = (module) => {
        sendCenterModuleEvent(document.querySelector(canvasSelector), module);
    }

    const replaceModules = (firstIndex, secondIndex) => {
        dispatch(modulesState.replaceModule(firstIndex, secondIndex));
        sendRedrawEvent(document.querySelector(canvasSelector));
    }

    return (
        <div className="module-card module-card_one-col _active">
            <div className="card-img" onClick={() => centerModule(module)}><img src={item.IMAGE} alt="" /></div>
            <div className="card-right">
                <div className="card-section" onClick={() => centerModule(module)}>
                    <div className="card-name">{item.NAME}</div>
                </div>
                <div className="card-btns">
                    {/*<div className="card-price"></div>*/}
                    {!planeEdit && <span className="btn btn-delete" onClick={() => centerModule(module)}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 492.001 492.001"><g fill="currentColor"><path d="M487.97,237.06l-58.82-58.82c-5.224-5.228-14.376-5.228-19.592,0l-7.436,7.432c-5.4,5.4-5.4,14.064,0,19.46l21.872,21.74 H265.206V68.396l21.808,22.132c5.224,5.22,14.216,5.22,19.428,0l7.36-7.432c5.404-5.404,5.356-14.196-0.044-19.596L254.846,4.444 c-2.6-2.592-6.088-4.184-9.804-4.184h-0.404c-3.712,0-7.188,1.588-9.784,4.184l-57.688,57.772 c-2.612,2.608-4.052,6.124-4.052,9.836c0,3.704,1.44,7.208,4.052,9.816l7.432,7.444c5.224,5.22,14.612,5.228,19.828,0.004 l22.368-22.132v159.688H67.814l22.14-22.008c2.608-2.608,4.048-6.028,4.048-9.732s-1.44-7.16-4.052-9.76l-7.436-7.42 c-5.22-5.216-14.372-5.2-19.584,0.008L4.034,236.856c-2.672,2.672-4.1,6.244-4.032,9.92c-0.068,3.816,1.356,7.388,4.028,10.056 l57.68,57.692c5.224,5.22,14.38,5.22,19.596,0l7.44-7.44c2.604-2.6,4.044-6.084,4.044-9.788c0-3.716-1.44-7.232-4.044-9.836 l-22.14-22.172H226.79V425.32l-23.336-23.088c-5.212-5.22-14.488-5.22-19.7,0l-7.5,7.44c-2.604,2.6-4.072,6.084-4.072,9.792 c0,3.704,1.424,7.184,4.028,9.792l58.448,58.456c2.596,2.592,6.068,4.028,9.9,4.028c0.024-0.016,0.24,0,0.272,0 c3.712,0,7.192-1.432,9.792-4.028l58.828-58.832c2.6-2.604,4.044-6.088,4.044-9.792c0-3.712-1.44-7.192-4.044-9.796l-7.44-7.44 c-5.216-5.22-14.044-5.22-19.264,0l-21.54,21.868V265.284H425.59l-23.096,23.132c-2.612,2.608-4.048,6.112-4.048,9.82 s1.432,7.192,4.048,9.8l7.44,7.444c5.212,5.224,14.372,5.224,19.584,0l58.452-58.452c2.672-2.664,4.096-6.244,4.028-9.916 C492.07,243.296,490.642,239.728,487.97,237.06z" /></g></svg>
                    </span>}
                    {!planeEdit && <span className="btn btn-delete" onClick={() => handlerShowModule(module)}>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path d="M21.8602 10.5721C21.6636 10.3032 16.9808 3.98898 10.9999 3.98898C5.01902 3.98898 0.33593 10.3032 0.139606 10.5718C-0.0465352 10.8269 -0.0465352 11.1728 0.139606 11.4279C0.33593 11.6967 5.01902 18.011 10.9999 18.011C16.9808 18.011 21.6636 11.6967 21.8602 11.4281C22.0466 11.1731 22.0466 10.8269 21.8602 10.5721ZM10.9999 16.5604C6.59434 16.5604 2.77867 12.3695 1.64914 10.9995C2.7772 9.62821 6.58489 5.43953 10.9999 5.43953C15.4052 5.43953 19.2206 9.62967 20.3506 11.0005C19.2226 12.3717 15.4149 16.5604 10.9999 16.5604Z" /><path d="M11.0001 6.64835C8.60064 6.64835 6.64844 8.60055 6.64844 11C6.64844 13.3995 8.60064 15.3517 11.0001 15.3517C13.3996 15.3517 15.3518 13.3995 15.3518 11C15.3518 8.60055 13.3996 6.64835 11.0001 6.64835ZM11.0001 13.9011C9.40038 13.9011 8.09902 12.5997 8.09902 11C8.09902 9.40033 9.40042 8.09893 11.0001 8.09893C12.5998 8.09893 13.9012 9.40033 13.9012 11C13.9012 12.5997 12.5998 13.9011 11.0001 13.9011Z" /></g></svg>
                    </span>}
                    <div className='replace-btns'>
                        {mID !== modules.length - 1 && <button title='На слой выше' onClick={() => {
                            replaceModules(mID, mID + 1);
                        }}>
                            <img width={20} height={20} src={window.confComponentUrl + "assets/icons/arrow-up.svg"} />
                        </button>}
                        {mID !== 0 && <button onClick={() => {
                            replaceModules(mID, mID - 1);
                        }}>
                            <img title='На слой ниже' style={{ rotate: "180deg" }} width={20} height={20} src={window.confComponentUrl + "assets/icons/arrow-up.svg"} />
                        </button>}
                    </div>
                    <span className="btn btn-delete" onClick={() => deleteModule(module)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 25"><g fill="currentColor"><path d="M18.08,3.08H13.85V2.31A2.32,2.32,0,0,0,11.54,0H8.46A2.32,2.32,0,0,0,6.15,2.31v.77H1.92A1.92,1.92,0,0,0,0,5V7.69a.76.76,0,0,0,.77.77h.42l.66,14a2.32,2.32,0,0,0,2.31,2.2H15.84a2.32,2.32,0,0,0,2.31-2.2l.66-14h.42A.76.76,0,0,0,20,7.69V5a1.92,1.92,0,0,0-1.92-1.92ZM7.69,2.31a.77.77,0,0,1,.77-.77h3.08a.77.77,0,0,1,.77.77v.77H7.69ZM1.54,5a.38.38,0,0,1,.38-.38H18.08a.38.38,0,0,1,.38.38V6.92H1.54ZM16.61,22.34a.77.77,0,0,1-.77.74H4.16a.77.77,0,0,1-.77-.74L2.73,8.46H17.27Z"></path><path d="M10,21.54a.78.78,0,0,0,.77-.77v-10a.77.77,0,0,0-1.54,0v10A.78.78,0,0,0,10,21.54Z"></path><path d="M13.85,21.54a.78.78,0,0,0,.77-.77v-10a.77.77,0,0,0-1.54,0v10A.77.77,0,0,0,13.85,21.54Z"></path><path d="M6.15,21.54a.77.77,0,0,0,.77-.77v-10a.77.77,0,0,0-1.54,0v10A.78.78,0,0,0,6.15,21.54Z"></path></g></svg>
                    </span>
                </div>
            </div>
        </div>
    )
}