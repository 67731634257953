export const calculateMaterialQuantity = (estimate, material) => {
    if (estimate.volume === 0) return 0;

    let value = (material.norm * material.volume) / material.tara;

    if (estimate.type === 'type_client') {
        value = Math.ceil(value);
    } else {
        if (material.rounding_type === undefined || material.rounding_type === 'rounding_yes') {
            value = Math.ceil(value);
        } else {
            value = roundValue(value, 3);
        }
    }

    return value;
}

export const calculateMaterialAmount = (material) => {
    return roundValue(material.cost * material.quantity);
}

export const roundValue = (value, accuracy = 2) => {
    const digit = Math.pow(10, accuracy);
    return Math.round(Number(value) * digit) / digit;
}

