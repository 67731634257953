import React from 'react';

const ContextMenu = React.forwardRef(({ list, show, anchorPoint, onClick }, ref) => {
    if (!show || !list.length) return null;

    return (
        <div className="ctx-menu" style={{ top: anchorPoint.y, left: anchorPoint.x  }}>
            <ul className="ctx-list">
                {list.map((listItem) =>
                    <li ref={ref} className="ctx-item" key={listItem.value} onClick={() => onClick(listItem.value)}>{listItem.text}</li>)}
            </ul>
        </div>
    );
});

export default ContextMenu;
