import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';


export const PopUpDialog = ({title, onYes,onNo }) => {

    const yes = () => {
        onYes();
    }
    const no = () => {
        onNo();
    }
    const onKeydown = ({ key }) => {
        switch (key) {
            case 'Escape': {
                no();
                break
            }
            case 'Enter': {
                yes();
                break
            }
            default: { }
        }
    }
    const handlerClick = (event) => {
        if (!event.composedPath().includes(document.querySelector('.popUpQuestion'))) {
            no();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', onKeydown);
        document.addEventListener('click', handlerClick);
        return () => {
            document.removeEventListener('keydown', onKeydown)
            document.removeEventListener('click', handlerClick);
        }
    })
    return ReactDOM.createPortal (<div className='popUpQuestion'>
        <h3>{title}</h3>
        <div className="popUpBtn">
            <div className="modal-header_btn" onClick={() => yes()} title="да">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                    <g fill="currentColor">
                        <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                    </g>
                </svg>
            </div>
            <div className="modal-header_btn" onClick={() => no()} title="нет">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                    <g fill="currentColor">
                        <path d="M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z" />
                    </g>
                </svg>
            </div>
        </div>

    </div>
    ,
    document.getElementById('root'))
}