export const putEstimate = 'PUT_ESTIMATE';
export const putTariffs = 'PUT_TARIFFS';
export const setCurrentTariff = 'SET_CURRENT_TARIFF';
export const setCoefficient = 'SET_COEFFICIENT';

const initialState = {
    isLoading: false,
    allEstimate: [],
    tariffs: [],
    coefficient: 1,
    currentTariff: null,
    objectsByTariff: {},
};

export const estimateActions = {
    putEstimate: (value) => {
        return { type: putEstimate, value }
    },
    putTariffs: (value) => {
        return { type: putTariffs, value }
    },
    setCurrentTariff: (value) => {
        return { type: setCurrentTariff, value }
    },
    setCoefficient: (value) => {
        return { type: setCoefficient, value }
    }
};

const reducer = (state = initialState, action)=>{
    switch(action.type){
        case putEstimate: {
            return {
                ...state,
                allEstimate: action.value,
            }
        }
        case setCoefficient: {
            return {
                ...state,
                coefficient: action.value,
            }
        }
        case putTariffs: {
            return {
                ...state,
                tariffs: action.value.tariffs,
                currentTariff: action.value.currentTariff || null,
                objectsByTariff: action.value.objectsByTariff || {},
            }
        }
        case setCurrentTariff: {
            return {
                ...state,
                currentTariff: action.value,
            }
        }
        default: {
            return state;
        }
    }
};

export default reducer;
