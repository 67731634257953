import { Mesh } from "three";
import { setPolygonFace } from "./setPolygonFace";

/**
 * Функция устанавливает заглушки на торцы стен и колонн.
 * @param { wallPoints, wallSlicesPoints, material, group }
 */
export const setSideEdging = ({ wallPoints, wallSlicesPoints, material, group }) => {
    const [p1, p2, p3, p4] = wallPoints;
    for (let q = 0; q < wallSlicesPoints.length; q++) {
        const wallSlicesPoint = wallSlicesPoints[q];

        if (
            (Math.abs(wallSlicesPoint.rB1.x - p1[0] * 100) < 1
                && Math.abs(wallSlicesPoint.rB1.y - p1[2] * 100) < 1
                && Math.abs(wallSlicesPoint.rB2.x - p2[0] * 100) < 1
                && Math.abs(wallSlicesPoint.rB2.y - p2[2] * 100) < 1)
            ||
            (Math.abs(wallSlicesPoint.rA1.x - p1[0] * 100) < 1
                && Math.abs(wallSlicesPoint.rA1.y - p1[2] * 100) < 1
                && Math.abs(wallSlicesPoint.rA2.x - p2[0] * 100) < 1
                && Math.abs(wallSlicesPoint.rA2.y - p2[2] * 100) < 1)) {
            if (wallSlicesPoint.heightFromFloor !== 0) {
                const geometry = setPolygonFace(
                    [p1[0], wallSlicesPoint.heightFromFloor / 100, p1[2]],
                    [p2[0], wallSlicesPoint.heightFromFloor / 100, p2[2]],
                    p3,
                    p4);
                group.add(new Mesh(geometry.clone(), material)); //дополнительная окантовка стены от пола до проема
            }
            p3[1] = wallSlicesPoint.heightFromFloor !== 0
                ? (wallSlicesPoint.height + wallSlicesPoint.heightFromFloor) / 100
                : wallSlicesPoint.height / 100;
            p4[1] = wallSlicesPoint.heightFromFloor !== 0
                ? (wallSlicesPoint.height + wallSlicesPoint.heightFromFloor) / 100
                : wallSlicesPoint.height / 100;
            break
        }
    }
    group.add(new Mesh(setPolygonFace(p1, p2, p3, p4).clone(), material)); //стенка
}
