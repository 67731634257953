import { Line3, Vector2, Vector3 } from 'three';
import { getIntersection, polyPoint } from '../../Helpers/functions';
import Node from '../../Classes/Node';
import { checkPointLiesOnSegment } from '../PlanEditor/helpers';

const dist = (origin, direction, a, b) => {
    const a1 = -direction.y;
    const b1 = direction.x;
    const c1 = origin.x * (origin.y + direction.y) - (origin.x + direction.x) * origin.y;

    const a2 = a.y - b.y;
    const b2 = b.x - a.x;
    const c2 = a.x * b.y - b.x * a.y;

    const det = (a, b, c, d) => a * d - b * c;

    const zn = det(a1, b1, a2, b2);

    if (Math.abs(zn) < 0.0000001) return Infinity;
    else {
        const v = new Vector2(
            - det(c1, b1, c2, b2) / zn,
            - det(a1, c1, a2, c2) / zn
        );
        if (v.clone().sub(origin).dot(direction) < 0) return Infinity;
        if (a.x < b.x && (v.x < a.x || v.x > b.x)) return Infinity;
        if (a.x > b.x && (v.x > a.x || v.x < b.x)) return Infinity;
        if (a.x === b.x && a.y < b.y && (v.y < a.y || v.y > b.y)) return Infinity;
        if (a.x === b.x && a.y > b.y && (v.y > a.y || v.y < b.y)) return Infinity;
        return v.sub(origin).length();
    }
};

export const distanceRayToColumn = (origin, direction, clmn) => {
    if (undefined === clmn) return Infinity;

    const angle = -clmn.angle;

    const x1 = clmn.x - Math.ceil(clmn.width / 2);
    const y1 = clmn.y - Math.ceil(clmn.depth / 2);
    const x2 = x1 + clmn.width;
    const y2 = y1;
    const x3 = x2;
    const y3 = y2 + clmn.depth;
    const x4 = x1;
    const y4 = y3;

    const p1 = new Vector2(x1, y1);
    const p2 = new Vector2(x2, y2);
    const p3 = new Vector2(x3, y3);
    const p4 = new Vector2(x4, y4);

    p1.rotateAround(new Vector2(clmn.x, clmn.y), angle);
    p2.rotateAround(new Vector2(clmn.x, clmn.y), angle);
    p3.rotateAround(new Vector2(clmn.x, clmn.y), angle);
    p4.rotateAround(new Vector2(clmn.x, clmn.y), angle);

    let d = Infinity;

    let _d = dist(origin, direction, p1, p2);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p2, p3);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p3, p4);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p4, p1);
    if (_d < d) d = _d;

    return d;
}

export const distanceRayToLink = (origin, direction, wall) => {
    const p1 = new Vector2(wall.innerLink.a.x, wall.innerLink.a.y);
    const p2 = new Vector2(wall.innerLink.b.x, wall.innerLink.b.y);
    const p3 = new Vector2(wall.parallelLink.a.x, wall.parallelLink.a.y);
    const p4 = new Vector2(wall.parallelLink.b.x, wall.parallelLink.b.y);
    let d = Infinity;

    const dist = (origin, direction, a, b) => {
        const a1 = -direction.y;
        const b1 = direction.x;
        const c1 = origin.x * (origin.y + direction.y) - (origin.x + direction.x) * origin.y;

        const a2 = a.y - b.y;
        const b2 = b.x - a.x;
        const c2 = a.x * b.y - b.x * a.y;

        const det = (a, b, c, d) => a * d - b * c;

        const zn = det(a1, b1, a2, b2)

        if (Math.abs(zn) < 0.0000001) return Infinity;
        else {
            const v = new Vector2(
                - det(c1, b1, c2, b2) / zn,
                - det(a1, c1, a2, c2) / zn
            );
            if (v.clone().sub(origin).dot(direction) < 0) return Infinity;
            if (!checkPointLiesOnSegment(a, b, v)) return Infinity;
            return v.sub(origin).length();
        }
    }
    let _d = dist(origin, direction, p1, p2);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p2, p4);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p3, p4);
    if (_d < d) d = _d;
    _d = dist(origin, direction, p1, p3);
    if (_d < d) d = _d;

    return d;
};

export const distanceRayToWallCorners = (origin, direction, wall, side, parentWallSide) => {
    let d = Infinity;
    let p1, p2;

    const mainLink = wall.mainLink.lrBuild === 'right' ? wall.innerLink : wall.parallelLink;
    const parallelLink = wall.mainLink.lrBuild === 'right' ? wall.parallelLink : wall.innerLink;

    if (!wall)
        return Infinity;

    if (side === 'LEFT') {
        if (parentWallSide === 'left') {
            p1 = new Node(mainLink.a.x, mainLink.a.y);
            p2 = new Node(mainLink.a.x, mainLink.a.y);
        } else if (parentWallSide === 'right') {
            p1 = new Node(parallelLink.a.x, parallelLink.a.y);
            p2 = new Node(parallelLink.a.x, parallelLink.a.y);
        }
    } else if (side === 'RIGHT') {
        if (parentWallSide === 'left') {
            p1 = new Node(mainLink.b.x, mainLink.b.y);
            p2 = new Node(mainLink.b.x, mainLink.b.y);
        } else if (parentWallSide === 'right') {
            p1 = new Node(parallelLink.b.x, parallelLink.b.y);
            p2 = new Node(parallelLink.b.x, parallelLink.b.y);
        }
    } else {
        return Infinity;
    }

    if (!p1) {
        return Infinity;
    }

    const v = wall.innerLink.a.clone().sub(wall.innerLink.b);
    const vBase = v.clone().rotateAround(new Vector2(0, 0), 90 * (Math.PI / 180)).setLength(100000);
    p1.x = p1.x + vBase.x;
    p1.y = p1.y + vBase.y;
    p2.x = p2.x - vBase.x;
    p2.y = p2.y - vBase.y;

    let _d = dist(origin, direction, p1, p2);
    if (_d < d) d = _d;

    return d;
}

export const distanceRayToLine = (origin, lineA, lineB, zoom) => {
    const crossPoint = getIntersection(lineA, lineB);
    if (crossPoint) {
        const ln = Math.abs((new Node(origin.x, origin.y)).clone().sub((new Node(crossPoint.x, crossPoint.y))).length());
        return {
            crossPoint: new Node(crossPoint.x, crossPoint.y),
            length: ln * zoom,
        };
    }
    return false;
};

export const getCrossPointsWall = (wall, point, zoom) => {
    const { mainLink, innerLink, parallelLink } = wall;

    const v = innerLink.a.clone().sub(innerLink.b);
    const vBase = v.clone().rotateAround(new Vector2(0, 0), 90 * (Math.PI / 180)).setLength(100000 * zoom / 2);

    const A = new Node(0, 0);
    const B = new Node(0, 0);

    A.x = point.x - vBase.x;
    A.y = point.y - vBase.y;
    B.x = point.x + vBase.x;
    B.y = point.y + vBase.y;

    let A1 = parallelLink.a;
    let A2 = parallelLink.b;
    let B1 = innerLink.a;
    let B2 = innerLink.b;

    if (mainLink.lrBuild === 'right') {
        A1 = innerLink.a;
        A2 = innerLink.b;
        B1 = parallelLink.a;
        B2 = parallelLink.b;
    }

    const cross1 = distanceRayToLine(
        { x: point.x, y: point.y },
        { x: { x: A.x, y: A.y }, y: { x: B.x, y: B.y } },
        { x: { x: A1.x, y: A1.y }, y: { x: A2.x, y: A2.y } },
        zoom
    );
    const cross2 = distanceRayToLine(
        { x: point.x, y: point.y },
        { x: { x: A.x, y: A.y }, y: { x: B.x, y: B.y } },
        { x: { x: B1.x, y: B1.y }, y: { x: B2.x, y: B2.y } },
        zoom
    );

    return { cross1, cross2 };
};

export const stickColumnToWall = (point, walls, column, cid, zoom, distForMergeCol = 30) => {
    const startAngle = column.angle;
    const yWidth = column.depth;
    const xWidth = column.width;

    let x = point.x,
        y = point.y,
        angle = startAngle,
        perpV,
        wallSide = '',
        wallIndex = -1;

    walls.forEach((wall, index) => {
        if (wall) {
            wall.removeColumns(cid);

            const { mainLink, innerLink, parallelLink } = wall;

            const wallVector = mainLink.a.clone().sub(mainLink.b);
            const { cross1, cross2 } = getCrossPointsWall(wall, point, zoom);

            if (cross1 && cross1.length < distForMergeCol) {
                x = cross1.crossPoint.x;
                y = cross1.crossPoint.y;
                angle = wallVector.angle();
                perpV = wallVector.clone().rotateAround(new Vector2(0, 0), -90 * (Math.PI / 180)).setLength(yWidth / 2);

                const link = mainLink.lrBuild === 'right' ? innerLink : parallelLink;

                if (Math.abs(x - link.a.x) < xWidth / 2 && Math.abs(y - link.a.y) < xWidth / 2) {
                    const offset = wallVector.clone().setLength(xWidth / 2);
                    x = link.a.x - offset.x;
                    y = link.a.y - offset.y;
                }

                if (Math.abs(x - link.b.x) < xWidth / 2 && Math.abs(y - link.b.y) < xWidth / 2) {
                    const offset = wallVector.clone().negate().setLength(xWidth / 2);
                    x = link.b.x - offset.x;
                    y = link.b.y - offset.y;
                }

                wallIndex = index;
                wallSide = 'left';
            }
            if (cross2 && cross2.length < distForMergeCol && (cross1 && cross1.length > cross2.length)) {
                x = cross2.crossPoint.x;
                y = cross2.crossPoint.y;
                angle = wallVector.angle();
                perpV = wallVector.clone().rotateAround(new Vector2(0, 0), 90 * (Math.PI / 180)).setLength(yWidth / 2);

                const link = mainLink.lrBuild === 'right' ? parallelLink : innerLink;

                if (Math.abs(x - link.a.x) < xWidth / 2 && Math.abs(y - link.a.y) < xWidth / 2) {
                    const offset = wallVector.clone().setLength(xWidth / 2);
                    x = link.a.x - offset.x;
                    y = link.a.y - offset.y;
                }

                if (Math.abs(x - link.b.x) < xWidth / 2 && Math.abs(y - link.b.y) < xWidth / 2) {
                    const offset = wallVector.clone().negate().setLength(xWidth / 2);
                    x = link.b.x - offset.x;
                    y = link.b.y - offset.y;
                }

                wallIndex = index;
                wallSide = 'right';
            }
        }
    });

    column.removeSideObjects(column.noSizeSide);

    if (point.x !== x || point.y !== y) {
        if (wallSide === 'left') {
            angle = -angle + Math.PI;
        } else {
            angle = -angle;
        }

        const newPoint = new Vector2(x, y);

        newPoint.x = newPoint.x - perpV.x;
        newPoint.y = newPoint.y - perpV.y

        return { x: newPoint.x, y: newPoint.y, angle, wallIndex, wallSide }
    }

    return null
}

export const initColumnPoints = (column) => {
    let height, width, angelFix;
    if (column.width > column.depth || column.width === column.depth) { // horizontal
        width = column.width;
        height = column.depth;
        angelFix = 0;
    } else if (column.depth > column.width) { // vertical
        width = column.depth;
        height = column.width;
        angelFix = (90 * (Math.PI / 180));
    }

    const pointA = new Vector2(column.x, column.y);
    const pointB = new Vector2(column.x, column.y);
    const v = (new Vector2(column.x, column.y)).normalize()
        .rotateAround(new Vector2(0, 0), -column.angle - (new Vector2(column.x, column.y)).angle() - angelFix)
        .setLength(width / 2);

    pointA.x = pointA.x + v.x;
    pointA.y = pointA.y + v.y;
    pointB.x = pointB.x - v.x;
    pointB.y = pointB.y - v.y;

    const pointA1 = pointA.clone();
    const pointA2 = pointA.clone();
    const pointB1 = pointB.clone();
    const pointB2 = pointB.clone();

    const v1 = pointA.clone().sub(pointB)
        .rotateAround(new Vector2(0, 0), 90 * (Math.PI / 180))
        .setLength(height / 2);
    const v2 = pointA.clone().sub(pointB)
        .rotateAround(new Vector2(0, 0), -90 * (Math.PI / 180))
        .setLength(height / 2);

    pointA1.x = pointA1.x + v1.x;
    pointA1.y = pointA1.y + v1.y;
    pointB1.x = pointB1.x + v1.x;
    pointB1.y = pointB1.y + v1.y;
    pointA2.x = pointA2.x + v2.x;
    pointA2.y = pointA2.y + v2.y;
    pointB2.x = pointB2.x + v2.x;
    pointB2.y = pointB2.y + v2.y;

    const points = [];

    if (column.x === 0 && column.y === 0) {
        points.push(new Vector2(column.x + column.width / 2, column.y + column.depth / 2));
        points.push(new Vector2(column.x + column.width / 2, column.y - column.depth / 2 ));
        points.push(new Vector2(column.x - column.width / 2, column.y - column.depth / 2));
        points.push(new Vector2(column.x - column.width / 2, column.y + column.depth / 2 ));
    } else  {
        points.push(pointA1);
        points.push(pointB1);
        points.push(pointB2);
        points.push(pointA2);
    }

    column.points = points;

    return points;
}

export const checkHoverColumn = (column, point) => {
    const points = initColumnPoints(column);
    return polyPoint(points, point.x, point.y)
}

export const getParallelEdgeLine3 = (column, columnSizingDirection) => {
    let sizeLine;
    if (columnSizingDirection === 'right' || columnSizingDirection === 'left') {
        let parallelEdgePointsIndexes;

        // индексы меняются в зависимости от того, какая сторона длиннее
        // смотри checkHoverColumn ¯\_(ツ)_/¯
        if (column.width >= column.depth) {
            parallelEdgePointsIndexes = [0, 1];
        }
        if (column.depth > column.width) {
            parallelEdgePointsIndexes = [1, 2];
        }

        // делаем так, чтобы около манипулятора всегда было начало линии
        const startPointIndex = columnSizingDirection === 'right' ? parallelEdgePointsIndexes[0] :
            parallelEdgePointsIndexes[1];
        const endPointIndex = columnSizingDirection === 'right' ?
            parallelEdgePointsIndexes[1] :
            parallelEdgePointsIndexes[0];

        const start = new Vector3(
            column.points[startPointIndex].x,
            column.points[startPointIndex].y,
            0
        );
        const end = new Vector3(
            column.points[endPointIndex].x,
            column.points[endPointIndex].y,
            0
        );
        sizeLine = new Line3(start, end);
    }

    if (columnSizingDirection === 'top' || columnSizingDirection === 'bottom') {
        let parallelEdgePointsIndexes;

        // индексы меняются в зависимости от того, какая сторона длиннее
        // смотри checkHoverColumn ¯\_(ツ)_/¯
        if (column.width >= column.depth) {
            parallelEdgePointsIndexes = [1, 2];
        }
        if (column.depth > column.width) {
            parallelEdgePointsIndexes = [1, 0];
        }

        // делаем так, чтобы около манипулятора всегда было начало линии
        const startPointIndex = columnSizingDirection === 'top' ?
            parallelEdgePointsIndexes[1] :
            parallelEdgePointsIndexes[0];
        const endPointIndex = columnSizingDirection === 'bottom' ?
            parallelEdgePointsIndexes[1] :
            parallelEdgePointsIndexes[0];

        const start = new Vector3(
            column.points[startPointIndex].x,
            column.points[startPointIndex].y,
            0
        );
        const end = new Vector3(
            column.points[endPointIndex].x,
            column.points[endPointIndex].y,
            0
        );
        sizeLine = new Line3(start, end);
    }

    return sizeLine;
};
