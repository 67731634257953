import React from 'react';
import ModalWrapper from "../ModalWrapper";
import {useDispatch, useSelector} from "react-redux";
import {actionsState as projectState} from "../../Redux/project";

const VersionsInfo = ({ isAutoSave })=>{

    const dispatch = useDispatch();
    const versions = useSelector(state=>state.project.versions);

    const openVersion = (id)=>{
        dispatch(projectState.addPreloader())
        window.location = window.location.href + '&vid=' + id
        dispatch(projectState.decPreloader())
    }

    const close = ()=>{
        dispatch(projectState.setModal(''));
    }

    return  <ModalWrapper
            onClose={()=>close()}
            title={'Версии проекта'}
            isBlock={false}
        >
        <div className="modal-body">
            <div className={'all-modules-body'}>
                {
                    versions.map((version, index)=>{
                        return (isAutoSave ? version.AUTOSAVE : !version.AUTOSAVE) && (
                            <div key={index} className={'versions-el'} onClick={()=>openVersion(version.ID)}>
                                <div>{ version.NAME }</div>
                                <div>{ version.CRM_USER } <span>({ version.CRM_RIGHTS })</span></div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        </ModalWrapper>
}

export default VersionsInfo
