import React, { useState } from 'react';
import ModalWrapper from "./ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { actionsState as projectState } from "../Redux/project";
import { sendGetHugeImage, sendRedrawEvent } from "../Helpers/Events";
import ReactSlider from "react-slider";
import { removeImage, uploadImage } from '../api';
import { Vector2 } from 'three';

const ImageBG = () => {

    const dispatch = useDispatch();
    const imageBG = useSelector(state => state.project.ImageBG);
    const tokenRID = useSelector((store) => store.project.saveResult.tokenRID);
    const [opacity, setOpacity] = useState(imageBG.opacity);
    const [scale, setScale] = useState(imageBG.scale);
    const [lineVal, setLineVal] = useState('');
    const [squareVal, setSquareVal] = useState('');
    const handlerOnChangeLineVal = event => {
        setLineVal(Number.parseInt(event.target.value));
    }
    const handlerOnChangeSquareVal = event => {
        if (isFinite(event.target.value.at(-1)) || !squareVal.includes(',')) {
            setSquareVal(event.target.value);
        }
    }
    const setImageBGProp = (props) => dispatch(projectState.setImageBG({ ...imageBG, ...props }))
    const appendImg = async event => {
        event.persist();

        if (event.target.files && event.target.files[0]) {
            dispatch(projectState.addPreloader());
            const imageData = event.target.files[0];
            const result = await uploadImage(imageData, tokenRID);

            if (result.status === 'ok') {
                const img = new Image();
                img.src = result.imageUrl;
                img.onload = function () {
                    if (!imageBG.points) {
                        imageBG.points = [];
                        imageBG.points.push(new Vector2(imageBG.posX + 500, imageBG.posY - 500));
                        imageBG.points.push(new Vector2(imageBG.posX - 500, imageBG.posY + 500));
                    }
                    setImageBGProp({
                        dataUrl: result.imageUrl,
                        width: img.naturalWidth,
                        height: img.naturalHeight,
                        id: result.fileID,
                        posX: 0,
                        posY: 0,
                        points: imageBG.points,
                        isEditing: true
                    });
                    dispatch(projectState.decPreloader());
                }
            } else {
                dispatch(projectState.decPreloader());
            }
        } else {
            setImageBGProp({
                dataUrl: '',
                width: 0,
                height: 0,
                id: undefined,
            });
            dispatch(projectState.decPreloader());
        }
    }

    const handlerScale = (val) => {
        setScale(val);
        setImageBGProp({
            scale: val,
        });
    }

    const handlerOpacity = (val) => {
        setOpacity(val);
        setImageBGProp({
            opacity: 100 - val,
        });
    }

    const handlerScaleImage = () => {
        if (!imageBG.points[0].clone) {
            imageBG.points = imageBG.points.map(p => new Vector2(p.x, p.y));
            setImageBGProp({
                points: imageBG.points
            });
        }
        let scale;
        if (imageBG.mode === 'line' && lineVal > 0) {
            const length = Math.round(imageBG.points[0].clone().sub(imageBG.points[1]).length()) * 10;
            scale = 10000 * (lineVal / length);
            setScale(scale);
            setImageBGProp({
                scale
            });
        } else if (imageBG.mode === 'square' && (squareVal.replace(',', '.') * 1000000) > 0) {
            const pointC = new Vector2(imageBG.points[0].x, imageBG.points[1].y);
            const length1 = Math.round(imageBG.points[0].clone().sub(pointC).length()) * 10;
            const length2 = Math.round(imageBG.points[1].clone().sub(pointC).length()) * 10;

            scale = 10000 * Math.sqrt(squareVal.replace(',', '.') * 1000000 / (length1 * length2));

            setScale(scale);
            setImageBGProp({
                scale
            });
        }
    }

    const remove = () => {
        if (imageBG.id) {
            removeImage(imageBG.id);
        }
        setImageBGProp({
            dataUrl: '',
            scale: 1000,
            opacity: 50,
            posX: 0,
            posY: 0,
            points: undefined,
            mode: '',
            id: undefined,
        });
        dispatch(projectState.setModal(''));
    }

    const close = () => {
        dispatch(projectState.setModal(''));
    }

    const setMode = (mode) => {
        setImageBGProp({
            mode
        });
    }

    return <ModalWrapper
        onClose={() => close()}
        onDelete={() => remove()}
        title="Изображение плана"
    >
        <div className="modal-body">
            <div className="options-title">Изображение</div>
            {/*<img className={'bl-images-append-btn2-image'} src={ImageBG.dataUrl} />*/}
            <label className="bl-images-append-btn2">
                {imageBG.dataUrl === '' && <span className={'btn btn-icon'}>Добавить</span>}
                {imageBG.dataUrl !== '' && <span className={'btn btn-icon'}>Изменить</span>}
                <input type="file" onChange={appendImg} />
            </label>

            <div className="options-title">Параметры</div>
            Размер:
            <input value={scale / 10} onChange={(e) => handlerScale(+e.target.value * 10)} type="number" className={'form-control'} />
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                min={1}
                max={3000}
                value={scale}
                onChange={handlerScale}
                renderThumb={(props, state) => <div {...props}>{/*state.valueNow*/}</div>}
            />
            <br />
            Прозрачность:
            <input value={opacity} onChange={(e) => handlerOpacity(e.target.value)} type="number" className={'form-control'} />
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                min={0}
                max={100}
                value={opacity}
                onChange={handlerOpacity}
                renderThumb={(props, state) => <div {...props}>{/*state.valueNow*/}</div>}
            />
            {imageBG.mode === '' && <div>
                <h4>Маштабирование:</h4>
                <p>Чтобы программа правильно рассчитала ваш план, либо размер любого одного расстояния на картинке, либо площадь любой из комнат.</p>
                <div className='scale-controls'>
                    <button onClick={() => setMode('square')} className='btn btn-icon'>Указать площадь</button>
                    <button onClick={() => setMode('line')} className='btn btn-icon'>Указать расстояние</button>
                </div>
            </div>
            }
            {imageBG.mode === 'line' && <div>
                <div className={'ImageBG-scale-title'}>Шаг 2. Отметьте расстояние на чертеже</div>
                <p>Переместите зеленую линейку, перетащив её концы так, чтобы линейка соответствовала любому указанному на картинке расстоянию.</p>
                <div className={'ImageBG-scale-title'}>Затем укажите её размер в мм:</div>
                <div className={'ImageBG-scale-input'}>
                    <input
                        value={lineVal}
                        onChange={handlerOnChangeLineVal}
                        type="text"
                        className={'form-control'}
                    />
                </div>
                <div className={'scale-controls'}>
                    <button
                        className={'btn btn-icon'}
                        onClick={() => { handlerScaleImage() }}
                    >
                        Масштабировать
                    </button>
                    <button
                        className={'btn btn-icon'}
                        onClick={() => { setMode('') }}
                    >
                        Отмена
                    </button>
                </div>
            </div>}

            {imageBG.mode === 'square' && <div>
                <div className={'ImageBG-scale-title'}>Шаг 2. Отметьте площадь на чертеже</div>
                <p>Установите зеленый прямоугольник, перетащив его углы так, чтобы прямоугольник соответствовал любой указанной на картинке комнате.</p>
                <div className={'ImageBG-scale-title'}>Затем укажите его площадь в м<sup>2</sup>:</div>
                <div className={'ImageBG-scale-input'}>
                    <input
                        value={squareVal}
                        onChange={handlerOnChangeSquareVal}
                        type="text"
                        className={'form-control'}
                    />
                </div>
                <div className='scale-controls'>
                    <button
                        className={'btn btn-icon'}
                        onClick={() => { handlerScaleImage() }}
                    >
                        Масштабировать
                    </button>
                    <button
                        className={'btn btn-icon'}
                        onClick={() => { setMode('') }}
                    >
                        Отмена
                    </button>
                </div>
            </div>}
        </div>
    </ModalWrapper>
}

export default ImageBG
