import React from 'react';
import clsx from 'clsx';
import style from './Button.module.scss';

export const Button = ({ children, onClick, styles, variant = "contain", size = 'base' }) => {
    return (
        <button
            className={clsx(style?.[`${variant}-button`], variant === 'contain' ? style?.[size] : {})}
            style={styles}
            onClick={onClick}
        >
            {children}
        </button>
    )
}
