import React from 'react';
import style from './Switch.module.scss';

export const Switch = ({ value, label, onChange }) => {
    return (
        <div className={style.container}>
            <div className={style.switcher}>
                <input
                    type="checkbox"
                    className={style.checkbox}
                    id="switcher"
                    checked={value}
                    onChange={onChange}
                />
                <label className={style.label} htmlFor="switcher"></label>
            </div>
            <label onClick={onChange} className={style?.["switcher-label"]}>{label}</label>
        </div>
    )
}