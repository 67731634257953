import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import Slider from '@material-ui/core/Slider';
import {actionsState as projectState} from "../Redux/project";
import {actionsState as modulesState} from "../Redux/modules";
import VariantDropBoxSimple from "./widgets/VariantDropBoxSimple";
import {sendRedrawEvent} from "../Helpers/Events";
function valueSquareText(value) {
    return `${value}м2`;
}
const Projects = ()=>{
    const dispatch = useDispatch();
    const allModules = useSelector(store=>store.modules.allModules);
    const sections = useSelector(store=>store.modules.sections);
    const [activeSection,setActiveSection] = useState(sections.length>0?sections[0]:null);
    const modules = activeSection?allModules.filter(m=>m.SECTION===116 && m.SHOW):allModules.filter(m=>m.SECTION>0 && m.SHOW);



    // console.log('window.projects.plans',window.projects.plans)
    const projSquares = []
    const projTypes = []
    window.projects.plans.forEach(proj => {
        if (!projTypes.includes(proj.PROPERTY_TYPE_VALUE) && proj.PROPERTY_TYPE_VALUE !== null) {
            projTypes.push(proj.PROPERTY_TYPE_VALUE)
        }
        if (proj.PROPERTY_AREA_VALUE !== null)
            projSquares.push(proj.PROPERTY_AREA_VALUE*1)
    })
    projTypes.sort()
    projSquares.sort()
    // console.log('projSquares',projSquares)
    // console.log('projTypes',projTypes)


    const [hash,setHash] = useState('')
    const [hashErr,setHashErr] = useState(false)
    const handlerHash = event=>{
        setHash(event.target.value)
        setHashErr(false)
    }

    const [zastroi,setZastroi] = useState(window.projects.zastroi[0].ID)
    const handlerZastroi = val => {
        setZastroi(val)
    }
    const [section,setSection] = useState(window.projects.sections[0].ID)
    const handlerSection = (sectionID, zastroiID) => {
        setSection(sectionID)
        setZastroi(zastroiID)
        setFilter(false)
    }

    const [plans,setPlans] = useState( window.projects.plans )
    const handlerPlans = (val) => {
        let _plans
        if (val===1)
            _plans = window.projects.plans.sort((a,b)=>a.PROPERTY_AREA_VALUE*1-b.PROPERTY_AREA_VALUE*1)
        else
            _plans = window.projects.plans.sort((a,b)=>b.PROPERTY_AREA_VALUE*1-a.PROPERTY_AREA_VALUE*1)
        setPlans(_plans)
    }

    const [sort1,setSort1] = useState(1)
    const handlerSort1 = (val) => {
        handlerPlans(val)
        setSort1(val)
    }

    const [filter,setFilter] = useState([])
    const handlerFilter = (filterID) => {
        let newFilter
        if (filter.includes(filterID)) {
            newFilter = filter.filter(id => filterID !== id)
        } else {
            newFilter = [...filter, filterID]
        }
        setFilter(newFilter)
    }

    const [filter2,setFilter2] = useState([])
    const handlerFilter2 = (filterID) => {
        let newFilter
        if (filter2.includes(filterID)) {
            newFilter = filter2.filter(id => filterID !== id)
        } else {
            newFilter = [...filter2, filterID]
        }
        setFilter2(newFilter)
    }

    const [valueSquare, setValueSquare] = useState([projSquares[0], projSquares[projSquares.length-1]]);
    const handlerFilterSquare = (event, newValue) => {
        setValueSquare(newValue);
    }
    const [valueSquareRange, setValueSquareRange] = useState([projSquares[0], projSquares[projSquares.length-1]]);
    const handlerFilterSquareRange = (event, newValue) => {
        setValueSquareRange(newValue);
    }

    const newProject = ()=>{
        window.location = window.location.origin+window.location.pathname+'?token=new'
    }
    const loadProject = ()=>{
        dispatch(projectState.addPreloader())
        if (hash.match(/[A-Z0-9]{8}/)) {
            setHashErr(false)
            // console.log('hash',hash)
            // console.log('window.location',window.location)

            fetch(window.confAjaxUrl, {
                method: 'POST',
                body: JSON.stringify({method:"loadProject",hash,token:window.rr_token}),
                headers: {
                    'Content-Type': 'application/json',
                    'application':'x-www-form-urlencoded'
                }})
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log('result',result)
                        if (result.status === 'ok') {
                            window.location = window.location.origin+window.location.pathname+'?hash='+hash
                        } else {
                            setHashErr(true)
                            dispatch(projectState.decPreloader())
                        }
                    },
                    (error) => {
                        setHashErr(true)
                        dispatch(projectState.decPreloader())
                        console.log('error',error)
                    }
                )
        } else {
            setHashErr(true)
            dispatch(projectState.decPreloader())
        }
    }

    // console.log('window.projects',window.projects)
    const filters = []
    window.projects.plans.map(plan=>{
        if (plan.IBLOCK_SECTION_ID === section) {
            if (!filters.find(el=>el===plan.PROPERTY_FILTER_VALUE))
                filters.push(plan.PROPERTY_FILTER_VALUE)
        }
    })

    useEffect(()=>{
        // loadProjects()
        handlerPlans(1)
    },[]);

    return <div className="app__fullmodal">
        <div className="fullmodal-menu">
            <div className={'save-block'}>
                <div className="save-block__lable">Открыть сохраненный проект по коду:</div>
                <div className="save-block__hash-block">
                    <input className={(hashErr)?'save-block__hashcode err':'save-block__hashcode'} type="text" value={hash} placeholder={'00000000'} onChange={handlerHash} />
                    <button className="save-block__copy-btn" onClick={loadProject}>Открыть</button>
                </div>
            </div>
            <button className="save-block__new-btn" onClick={newProject}>Создать свой проект</button>
            <h2 className="fullmodal-menu_title">Готовые проекты</h2>
            {window.projects.zastroi.map(zastroi=>{
                return <div key={zastroi.ID}><h3 className="fullmodal-menu_subtitle">{zastroi.NAME}</h3><ul>{
                    window.projects.sections.map(sec=>{
                        return <li className={(sec.ID == section)?'active':''} key={sec.ID} onClick={()=>{handlerSection(sec.ID, zastroi.ID)}}>{sec.NAME}</li>
                    })
                }</ul><br /><br /></div>
            })}

            <div className="fullmodal-filter-wrap">
                <div className="fullmodal-filter-name">Сортировка по метражу</div>
                <div className="fullmodal-filter large">
                    <span className={(sort1 === 1)?'active':''} onClick={()=>{handlerSort1(1)}}>По возрастанию</span>
                    <span className={(sort1 === 2)?'active':''} onClick={()=>{handlerSort1(2)}}>По убыванию</span>
                </div>
            </div>

            <div className="fullmodal-filter-wrap">
                <div className="fullmodal-filter-name">Комнатность</div>
                <div className="fullmodal-filter large">
                    {/*
                    <span className={(filter.includes(0))?'active':''} onClick={()=>{handlerFilter(0)}}>Студия</span>
                    <span className={(filter.includes(1))?'active':''} onClick={()=>{handlerFilter(1)}}>1</span>
                    <span className={(filter.includes(2))?'active':''} onClick={()=>{handlerFilter(2)}}>2</span>
                    <span className={(filter.includes(3))?'active':''} onClick={()=>{handlerFilter(3)}}>3+</span>
                    */}
                    {projTypes.map(element=>{
                        return <span className={(filter2.includes(element))?'active':''} onClick={()=>{handlerFilter2(element)}}>{element}</span>
                    })}
                </div>
            </div>
            <div className="fullmodal-filter-wrap">
                <div className="fullmodal-filter-name">Общая площадь <span>{valueSquareRange[0]}-{valueSquareRange[1]} м<sup>2</sup></span></div>
                <Slider
                    value={valueSquare}
                    onChange={handlerFilterSquare}
                    min={valueSquareRange[0]}
                    step={.1}
                    max={valueSquareRange[1]}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valueSquareText}
                />
            </div>
            <div className="fullmodal-filter-wrap">
                <div className="fullmodal-filter-name">Жильцы</div>
                <div className="fullmodal-filter">
                    {filters.map(element=>{
                        return <span className={(filter.includes(element))?'active':''} onClick={()=>{handlerFilter(element)}}>{element}</span>
                    })}
                </div>
            </div>
        </div>

        <div className="fullmodal-block">
            <div className="fullmodal-header">
                <h2 className="fullmodal-header_title firm-gk-title hom"><img src={window.projects.zastroi.find(el=>el.ID===zastroi).UF_SVG} className={'firm-logo'} /> {window.projects.sections.find(el=>el.ID===section).NAME}</h2>
                <div className="fullmodal-header_btn" onClick={()=>{dispatch(projectState.setModal(''))}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                        <g stroke="currentColor">
                            <line x1="1" y1="1" x2="18" y2="18"></line>
                            <line x1="18" y1="1" x2="1" y2="18"></line>
                        </g>
                    </svg>
                </div>
            </div>
            <div className="fullmodal-body">
                {
                    plans.map(plan=>{
                        if (plan.IBLOCK_SECTION_ID === section) {
                            let needReturn = true
                            if (filter.length > 0 && !filter.includes(plan.PROPERTY_FILTER_VALUE)) {
                                needReturn = false
                            }
                            if (filter2.length > 0 && !filter2.includes(plan.PROPERTY_TYPE_VALUE)) {
                                needReturn = false
                            }
                            if (
                                valueSquare[0]*1 > plan.PROPERTY_AREA_VALUE*1 ||
                                valueSquare[1]*1 < plan.PROPERTY_AREA_VALUE*1
                            ) {
                                needReturn = false
                            }
                            if (needReturn) {
                                return <div key={plan.ID} className="module-card_one-col">
                                    <div className="card-img"><img src={plan.PREVIEW_PICTURE} alt=""/></div>
                                    <div className="card-min">
                                        <div className="card-type">{plan.NAME}</div>
                                        <div className="card-square">{plan.PROPERTY_AREA_VALUE} м<sup>2</sup></div>
                                        <div className="card-filter">{plan.PROPERTY_FILTER_VALUE}</div>
                                    </div>
                                    <div className="card-right">
                                        <div className="card-price-label">Стоимость мебели в проекте</div>
                                        <div className="card-price">195 400 .-</div>
                                        <div className="save-block">
                                            <div className="save-block__lable">Открыть сохраненный проект по коду:</div>
                                            <div className="save-block__hash-block">
                                                <input className={'save-block__hashcode'} type="text" value={plan.PROPERTY_HASH_CODE_VALUE} />
                                                <a className="save-block__copy-btn" href={'?hash='+plan.PROPERTY_HASH_CODE_VALUE}>Открыть</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                        }
                    })
                }
            </div>
        </div>
    </div>
}
export default Projects;
