import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { actionsState as projectState } from ".././Redux/project";
import { is_touch_device } from "../Helpers/functions";
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';

const ModalWrapper = ({
  title,
  isSideMenu,
  width = 400,
  isBlock = true,
  onClose,
  onCopy,
  onLock,
  onDelete,
  isLocked,
  children,
  styles = {},
}) => {
    const sideMenuOpened = useSelector(store => store.project.sideMenuOpened);
    const tool = useSelector(store => store.project.tool);
    const modal = useSelector(store => store.project.modal);
    const dispatch = useDispatch();

    const menu = useRef(null);
    const startMouse = useRef({ x: 0, y: 0 });
    const side = useRef(null);

    const initialState = useMemo(() => ({
        bottom: 0,
        right: -width,
        transition: 0
    }), [width]);

    const [active, setActive] = useState(false);
    const [style, setStyle] = useState(() => {
        if (window.innerWidth <= 800) {
            initialState.bottom = -window.innerHeight;
            side.current = 'bottom';
            return {
                ...style,
                right: 0,
                bottom: sideMenuOpened ? 0 : -window.innerHeight,
            };
        } else {
            side.current = 'right';
            return {
                ...initialState,
                right: sideMenuOpened ? 0 : initialState.right
            };
        }
    });

    useEffect(() => {
        return () => {
            if (!modal && is_touch_device()) {
                dispatch(projectState.setSideMenuOpened(false));
            }
        }
    }, [modal]);

    useEffect(() => {
        const updatedStyle = { ...style };

        const onMove = (event) => {
            if (!active) return null;
            if (!event.touches && is_touch_device()) return null;

            const currentEvent =
                    is_touch_device() ?
                        {
                            x: event.touches[0].clientX,
                            y: event.touches[0].clientY
                        } :
                        {
                            x: event.clientX,
                            y: event.clientY
                        }

            const clientPosition = side.current === 'right' ? currentEvent.x : currentEvent.y;
            const clientStartPosition = side.current === 'right' ? startMouse.current.x : startMouse.current.y;
            const newPosition = style[side.current] + (clientStartPosition - clientPosition);

            if (newPosition > 0) updatedStyle[side.current] = 0
            else if (newPosition < initialState[side.current]) updatedStyle[side.current] = initialState[side.current]
            else updatedStyle[side.current] = newPosition;

            setStyle({ ...updatedStyle, transition: 0 });

            startMouse.current = currentEvent;
        }

        const onEnd = () => {
            if (active) {
                updatedStyle[side.current] = sideMenuOpened ? initialState[side.current] : 0;
                setStyle({ ...updatedStyle, transition: 0.3 });
                dispatch(projectState.setSideMenuOpened(updatedStyle[side.current] === 0));
            }
            setActive(false);
        }

        document.body.addEventListener('touchend', onEnd);
        document.body.addEventListener('touchmove', onMove);
        document.body.addEventListener('mouseup', onEnd);
        document.body.addEventListener('mousemove', onMove);

        return () => {
            document.body.removeEventListener('touchend', onEnd);
            document.body.removeEventListener('touchmove', onMove);
            document.body.removeEventListener('mouseup', onEnd);
            document.body.removeEventListener('mousemove', onMove);
        }
    }, [menu, active, side, style, startMouse])

    const onLabelSelect = (event) => {
        event.preventDefault();
        if (!event.touches && is_touch_device()) return null;

        setActive(true);
        startMouse.current =
            is_touch_device() ?
                {
                    x: event.touches[0].clientX,
                    y: event.touches[0].clientY
                } :
                {
                    x: event.clientX,
                    y: event.clientY
                }
    }

    const onScroll = (e) => {
        const modals = document.getElementsByClassName("modal-body");
        for (let i = 0; i < modals.length; i++) {
            modals[i].scrollTo({
                top: modals[i].scrollTop += e.deltaY,
            });
        }
    }

    if (side.current === null && window.innerWidth <= 800) return null;

    return (
        <div
            className="modal-block"
            style={{
                ...(isSideMenu ?
                    {right: `${style.right}px`, bottom: `${style.bottom}px`, transition: `${style.transition}s`} :
                    {right: 0}),
                width: `${width}px`,
                ...styles,
            }}>
            {isSideMenu &&
                <div className="modal-label"
                     ref={menu}
                     onTouchStart={onLabelSelect}
                     onMouseDown={onLabelSelect}
                >
                    <h3>Настроить параметры</h3>
                    <div className="modal-label_icon">
                        <Icon
                            path={mdiChevronLeft}
                            size={0.8} />
                        <Icon
                            path={mdiChevronRight}
                            size={0.8} />
                </div>
            </div>}
            <div className="modal-header">
                <h2>{title}</h2>
                {!!onLock === true &&
                    <div className={(isLocked) ? "modal-header_btn lockIcon active" : "modal-header_btn lockIcon"} onClick={onLock}>
                        <svg viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                            <g fill="currentColor">
                                <path d="m18.75 24h-13.5c-1.24 0-2.25-1.009-2.25-2.25v-10.5c0-1.241 1.01-2.25 2.25-2.25h13.5c1.24 0 2.25 1.009 2.25 2.25v10.5c0 1.241-1.01 2.25-2.25 2.25zm-13.5-13.5c-.413 0-.75.336-.75.75v10.5c0 .414.337.75.75.75h13.5c.413 0 .75-.336.75-.75v-10.5c0-.414-.337-.75-.75-.75z" /><path d="m17.25 10.5c-.414 0-.75-.336-.75-.75v-3.75c0-2.481-2.019-4.5-4.5-4.5s-4.5 2.019-4.5 4.5v3.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-3.75c0-3.309 2.691-6 6-6s6 2.691 6 6v3.75c0 .414-.336.75-.75.75z" /><path d="m12 17c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-2.5c-.275 0-.5.224-.5.5s.225.5.5.5.5-.224.5-.5-.225-.5-.5-.5z" />
                                <path d="m12 20c-.414 0-.75-.336-.75-.75v-2.75c0-.414.336-.75.75-.75s.75.336.75.75v2.75c0 .414-.336.75-.75.75z" />
                            </g>
                        </svg>
                    </div>
                }

                {!!onCopy === true &&
                    <div className="modal-header_btn" onClick={onCopy}>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                            <g fill="currentColor">
                                <path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
                            </g>
                        </svg>
                    </div>
                }

                {!!onDelete === true &&
                    <div className="modal-header_btn" onClick={onDelete}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 25">
                            <g fill="currentColor">
                                <path d="M18.08,3.08H13.85V2.31A2.32,2.32,0,0,0,11.54,0H8.46A2.32,2.32,0,0,0,6.15,2.31v.77H1.92A1.92,1.92,0,0,0,0,5V7.69a.76.76,0,0,0,.77.77h.42l.66,14a2.32,2.32,0,0,0,2.31,2.2H15.84a2.32,2.32,0,0,0,2.31-2.2l.66-14h.42A.76.76,0,0,0,20,7.69V5a1.92,1.92,0,0,0-1.92-1.92ZM7.69,2.31a.77.77,0,0,1,.77-.77h3.08a.77.77,0,0,1,.77.77v.77H7.69ZM1.54,5a.38.38,0,0,1,.38-.38H18.08a.38.38,0,0,1,.38.38V6.92H1.54ZM16.61,22.34a.77.77,0,0,1-.77.74H4.16a.77.77,0,0,1-.77-.74L2.73,8.46H17.27Z"></path>
                                <path d="M10,21.54a.78.78,0,0,0,.77-.77v-10a.77.77,0,0,0-1.54,0v10A.78.78,0,0,0,10,21.54Z"></path>
                                <path d="M13.85,21.54a.78.78,0,0,0,.77-.77v-10a.77.77,0,0,0-1.54,0v10A.77.77,0,0,0,13.85,21.54Z"></path>
                                <path d="M6.15,21.54a.77.77,0,0,0,.77-.77v-10a.77.77,0,0,0-1.54,0v10A.78.78,0,0,0,6.15,21.54Z"></path>
                            </g>
                        </svg>
                    </div>
                }
                {!!onClose === true &&
                    <div
                        style={{ zIndex: 123 }}
                        className="modal-header_btn"
                        onClick={onClose}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                            <g stroke="currentColor">
                                <line x1="1" y1="1" x2="18" y2="18"></line>
                                <line x1="18" y1="1" x2="1" y2="18"></line>
                            </g>
                        </svg>
                    </div>
                }
            </div>
            {children}
            {tool === 'view' && isBlock ?
                <div
                    onWheel={onScroll}
                    onTouchMove={onScroll}
                    className={'modal-blocker'}>
                </div> : null }
        </div>
    )}

export default ModalWrapper
