import React from 'react';
import Select from 'react-select';

const option = {
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? '#F5F5F5' : '#FFF',
        color: state.isSelected ? '#59DA28' : "#000",
        cursor: 'pointer',
        transition: '0.2s',
        fontWeight: !!state.data.isSpecial ? 'bold' : 'normal',
        '&:active': {
            background: '#59DA28',
            color: '#FFF',
        },
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 300,
    })
}

const textStyles = {
    control: (style, state) => ({
        ...style,
        width: '200px',
        border: 'none',
        borderRadius: 'none',
        cursor: 'pointer',
        boxShadow: null,
        background: 'none',
        zIndex: 1500,
        fontWeight: !!state.getValue()[0]?.isSpecial ? 'bold' : 'normal',
    }),
    ...option,
    singleValue: (style) => ({
        ...style,
        color: '#59DA28',
        fontSize: '16px',
    }),
    indicatorSeparator: (style) => ({
        ...style,
        display: 'none',
    }),
    dropdownIndicator: (style) => ({
        ...style,
        color: '#59DA28',
        '&:hover': {
            color: '#59DA28',
        },
    }),
    valueContainer: (style) => ({
        ...style,
        padding: '0',
    })
};

const containStyles = {
    ...option,
    control: (style, state) => ({
        ...style,
        minWidth: '200px',
        border: '1px solid #D7D7D7',
        boxShadow: null,
        fontWeight: !!state.getValue()[0]?.isSpecial ? 'bold' : 'normal',
        '&:hover': {
            border: '1px solid #59DA28',
        },
    }),
}

export const CustomSelect = ({ options, value, type = 'contain', onChange, isDisabled = false }) => {
    return (
        <Select
            isDisabled={isDisabled}
            options={options}
            styles={type === 'contain' ? containStyles : textStyles}
            placeholder=''
            isSearchable={false}
            onChange={onChange}
            value={value}
            defaultValue={options[0]}
        />
    )
}
