export const getTypeObjectOnWall = (object) => {
    if (object.isHole === true) {
        return 'isHole';
    }
    if (object.isDoor === true) {
        return 'isDoor';
    }
    if (object.isWindow === true) {
        return 'isWindow';
    }
    if (object.isElectricSocket === true) {
        return 'isElectricSocket';
    }
    if (object.outletElectricalWire === true) {
        return 'outletElectricalWire';
    }
    if (object.isSwitch === true) {
        return 'isSwitch';
    }
    if (object.isHeatingBattery === true) {
        return 'isHeatingBattery';
    }
    if (object.isElectricPanel === true) {
        return 'isElectricPanel';
    }
    if (object.isRedCube === true) {
        return 'isRedCube';
    }
    if (object.isCylinder === true) {
        return 'isCylinder';
    }
}
