import Node from "../../../Classes/Node";

export const Convert = {
    /**
    *  Переводить координаты в сантиметрах в координаты в пиксели
     * @param {node} p тока в сантиметрах
     * @param {ctx} ctx 
     * @param {number} zoom 
     * @param {number} offsetX 
     * @param {number} offsetY 
     * @returns Node - тока в пикселях
     */
    toPixel: (p, ctx, zoom, offsetX, offsetY) => {
            const x = Math.ceil(ctx.width / 2 + p.x * zoom - offsetX)
            const y = Math.ceil(ctx.height / 2 + p.y * zoom - offsetY)
            return new Node(x, y)
    },
    /**
     *  Переводить координаты в пикселях в координаты в сантиметрах
     * @param {node} p тока в пикселя
     * @param {ctx} ctx 
     * @param {number} zoom 
     * @param {number} offsetX 
     * @param {number} offsetY 
     * @returns Node - тока в сантиметрах
     */
    toSM: (p, ctx, zoom, offsetX, offsetY) => {
        const x = Math.ceil((p.x - ctx.width / 2 + offsetX) / zoom)
        const y = Math.ceil((-ctx.height / 2 + p.y + offsetY) / zoom)
        return new Node(x, y)
    },
    /**
     * Берет точку середины ctx
     * @param {ctx} ctx 
     * @param {number} ratio 
     * @returns Node тока центра
     */
    getCanvasCenterPixel: (ctx, ratio) => {
        const x = Math.ceil((ctx.width - (ctx.width / 2)) / ratio)
        const y = Math.ceil((ctx.height - (ctx.height / 2)) / ratio)
        return new Node(x, y)
    }
}