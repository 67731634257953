import { createPortal } from "react-dom";
import clsx from 'clsx';
import styles from './error.module.scss';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


export const MainErrorComponent = ({ error, resetErrorBoundary }) => {
    const queryParams = new URLSearchParams(window.location.search)
    const bugTrap = queryParams.get("bugTrap");

    if (bugTrap === 'false') {
        throw error
    }
    let planIsDown = false;
    const token = queryParams.get("token");
    let plan, objects, tokenRID;
    try {
        const storePlan = useSelector(store => store.project.plan);
        plan = storePlan;
    } catch (err) {
        plan = { text: "Получить plan не удалось.", error: err };
        planIsDown = true;
    }
    try {
        const storeModules = useSelector(store => store.modules.modules);
        const storeObjects = storeModules.map(m => ({
            id: m.id,
            ordinalNumber: m.ordinalNumber,
            position: { x: m.position.x, y: m.position.y },
            size: m.size,
            sizeDef: m.sizeDef,
            heightFromFloor: m.heightFromFloor,
            angle: m.angle,
            corp: m.corp ? m.corp.userData.ID : null,
            face: m.face ? m.face.userData.ID : null,
            cloth: m.cloth ? m.cloth.userData.ID : null,
            option: m.optionStatus,
            objTitle: m.objTitle,
            objComment: m.objComment,
            objImages: m.objImages,
            rgb: m.rgb,
            rgbColor: m.rgbColor,
        }));
        objects = storeObjects;
    } catch (err) {
        objects = { text: "Получить modules не удалось.", error: err }
    }
    try {
        const storeTokenRID = useSelector(store => store.project.saveResult.tokenRID);
        tokenRID = storeTokenRID;
    } catch (err) {
        tokenRID = { text: "Получить tokenRID не удалось.", error: err }
    }

    const [showFormSubmission, setShowFormSubmission] = useState(false);
    const [messageSend, setMessageSend] = useState(false);
    const [messageDelivered, setMessageDelivered] = useState(false);
    const [response, setResponse] = useState(null);

    const fullError = JSON.stringify(error.stack);
    const stringError = fullError.split('\\n');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessageSend(true);
        const messageFromUser = e.target.formTextarea.value;


        const sendObj = {
            token,
            plan: planIsDown ? plan : plan.toOBJ(),
            objects,
            messageFromUser,
            tokenRID,
            error: {
                message: error.message,
                stack: stringError,
            }
        }

        const resp = await fetch(window.confAjaxUrl, {
            method: 'POST',
            body: JSON.stringify({ method: "sendError", sendObj, token: window.rr_token }),
            headers: {
                'Content-Type': 'application/json',
                'application': 'x-www-form-urlencoded'
            }
        });
        const answer = await resp.json();
        if (answer.status === "ok") {
            setMessageDelivered(true);
        } else {
            setResponse(Object.values(answer)[0]);
        }
    }

    const pressFirstButton = () => {
        setShowFormSubmission(true)
    }

    useEffect(() => {
        const submitButton = document.getElementById("submitButton");
        if (submitButton) {
            submitButton.scrollIntoView({ block: "end", behavior: 'smooth' });
        }
    }, [showFormSubmission]);

    useEffect(() => {
        const lastMSG = document.getElementById("lastMSG");
        if (lastMSG) {
            lastMSG.scrollIntoView({ block: "end", behavior: 'smooth' });
        }
    }, [messageSend, messageDelivered]);


    return createPortal(
        <div className={styles.mainErrorComponent_main}>
            <img src={window.confComponentUrl + "assets/logo.svg"} alt="header-logo" />
            <div className={styles.mainErrorComponent_header}>Извините, что то пошло не так и всё сломалось.</div>

            <div className={styles.mainErrorComponent_line} >Что именно пошло не так?</div>
            <div className={clsx(styles.mainErrorComponent_line, styles.mainErrorComponent_secondLine)}>
                {error.message}
            </div>
            <div className={styles.mainErrorComponent_line} >Больше информации.</div>
            <div className={clsx(styles.mainErrorComponent_line, styles.mainErrorComponent_secondLine, styles.mainErrorComponent_fullLine)}>
                {stringError.map((string, index) => <div key={index} className={styles.mainErrorComponent_mineLine}>{string}</div>)}
            </div>
            <div className={styles.mainErrorComponent_line} >Что теперь делать?</div>
            <div className={clsx(styles.mainErrorComponent_line, styles.mainErrorComponent_secondLine)}>
                Можно сделать снимок экрана и отправить его разработчика с кратким описание, что Вы делали (это очень поможет).
            </div>
            <div className={clsx(styles.mainErrorComponent_line, styles.mainErrorComponent_secondLine)}>
                <span>Или отправить отчет нажав на эту </span>
                <button className={styles.mainErrorComponent_button} onClick={pressFirstButton}>Кнопку</button>
            </div>
            {showFormSubmission && <div className={clsx(styles.mainErrorComponent_line, styles.mainErrorComponent_secondLine)}>
                Отлично, заполните пожалуйста сообщение, опишите что Вы делали в приложение до того как увидели это окно?
            </div>}
            {showFormSubmission && <form method="post" onSubmit={handleSubmit} className={styles.mainErrorComponent_form}>
                <textarea disabled={messageSend} id='formTextarea' />
                {!messageSend && <button className={styles.mainErrorComponent_button} type="submit" id="submitButton" disabled={messageSend}>Отправить</button>}
            </form>}
            {messageSend && <div className={clsx(styles.mainErrorComponent_line, styles.mainErrorComponent_secondLine)}>
                Сообщение отправляется...
            </div>}
            {messageSend && messageDelivered && <div className={clsx(styles.mainErrorComponent_line, styles.mainErrorComponent_secondLine)} id="lastMSG">
                <div>Спасибо за сообщение. Мы его получили.</div>
                <div>Перезагрузите страницу.</div>
                <div>Надеемся что больше не будет ошибок.</div>
                <div>Всего хорошего.</div>
            </div>}
            {messageSend && !messageDelivered && <div className={clsx(styles.mainErrorComponent_line, styles.mainErrorComponent_secondLine)} id="lastMSG">
                <div>Сообщение не доставлено. Проблемы со связью или сервером.</div>
                {response && <div><span>Вот что ответил сервер </span>"<span className={styles.color_white}>{response}</span>".</div>}
                <div>Сделайте, пожалуйста, снимок экрана и отправьте его команде разработчиков.</div>
            </div>}
        </div>, document.getElementById('root')
    )
}
