import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { checkRoom } from '../utils';
import { checkColumn } from '../utils/checkRoom';
import { calculateMaterialAmount, calculateMaterialQuantity, roundValue } from '../../../../../Helpers/estimate';

const getEstimateAmount = (estimate) => {
    return estimate.reduce((acc, curr) => {
        acc += Number(curr.amount || 0);
        return acc;
    }, 0) || 0;
}

const collectEstimate = ({ modules, cycles, walls, columns, type, fractionDigits = 0 }) => {
    const typeMaterial = type === 'draft' ? 'Черновые' : 'Чистовые';

    const processEstimates = (items) =>
        items.flatMap(item =>
            item?.estimate.map(estimate => ({
                ...estimate,
                amount: Math.round(estimate.volume * estimate.cost * Math.pow(10, fractionDigits)) / Math.pow(10, fractionDigits),
                cost: Math.round(estimate.cost * Math.pow(10, fractionDigits)) / Math.pow(10, fractionDigits),
                parent: item
            }))
        );

    const modulesEstimate = processEstimates(modules);
    const columnsEstimate = columns.flatMap((column) =>
        processEstimates([column]).concat(processEstimates(column.objects))
    );
    const floorEstimate = processEstimates(cycles);
    const wallEstimate = walls.flatMap((wall) =>
        processEstimates([wall]).concat(processEstimates(wall.objects))
    );

    const result = {};

    const collectedEstimate = [...modulesEstimate, ...floorEstimate, ...wallEstimate, ...columnsEstimate];
    if (type === 'draft' || type === 'final') {
        const copyEstimate = [...collectedEstimate];
        copyEstimate.forEach((estimate) => {
            if (!result[estimate.object]) {
                result[estimate.object] = { estimate: [], amount: 0 };
            }

            estimate.materials.filter((material) => material.type === typeMaterial).forEach((material) => {
                const existingMaterial = result[estimate.object].estimate.find((element) => (
                    element.code === material.code && element.jobId === estimate.id
                ));

                if (existingMaterial) {
                    existingMaterial.volume = Number(existingMaterial.volume) + Number(material.volume);
                    existingMaterial.quantity = calculateMaterialQuantity(estimate, existingMaterial);
                    existingMaterial.amount = calculateMaterialAmount(existingMaterial);
                } else {
                    material.cost = roundValue(material.cost);
                    material.quantity = calculateMaterialQuantity(estimate, material);
                    material.amount = calculateMaterialAmount(material);

                    result[estimate.object].estimate.push({ ...material, jobId: estimate.id });
                }
            })
        });

        Object.values(result).forEach((value) => {
            const indexes = [];
            value.estimate.forEach((element, index, array) => {
                const sameElementIndex = array.findIndex((el) => el.code === element.code);
                if (sameElementIndex !== -1 && sameElementIndex !== index) {
                    const sameElement = array[sameElementIndex];
                    element.volume = Number(sameElement.volume) + Number(element.volume);
                    element.quantity = Number(sameElement.quantity) + Number(element.quantity);
                    element.amount = Number(sameElement.amount) + Number(element.amount);
                    indexes.push(sameElementIndex);
                }
            });
            value.estimate = value.estimate.filter((_, index) => !indexes.includes(index));
        });

    } else {
        collectedEstimate.forEach((estimate) => {
            if (!result[estimate.object]) {
                result[estimate.object] = { estimate: [], amount: 0 };
            }

            const existingEstimateIndex = result[estimate.object].estimate.findIndex((element) => element.code === estimate.code);
            if (existingEstimateIndex >= 0) {
                const existingEstimate = result[estimate.object].estimate[existingEstimateIndex];
                existingEstimate.volume = Number(existingEstimate.volume) + Number(estimate.volume);
                existingEstimate.amount = roundValue(Number(existingEstimate.amount) + Number(estimate.amount), fractionDigits);

                if (estimate?.unit === 'шт.') {
                    existingEstimate.sizes.quantity = existingEstimate.sizes.quantity + estimate.volume;
                    existingEstimate.sizes.valuation = existingEstimate.sizes.quantity;
                }
                if (estimate?.unit === 'п.м.') {
                    if (estimate.parent?.isWall) {
                        existingEstimate.sizes.length = existingEstimate.sizes.length + estimate.parent.innerLink.length;
                        existingEstimate.sizes.width = existingEstimate.sizes.width + estimate.parent.innerLink.height;
                    } else {
                        existingEstimate.sizes.length = existingEstimate.sizes.length + estimate.volume;
                        existingEstimate.sizes.width = existingEstimate.sizes.width + estimate.volume;
                    }
                    existingEstimate.sizes.valuation = (+existingEstimate.sizes.valuation || 0) + (+estimate.volume || 0);
                }
                if (estimate?.unit === 'кв.м.') {
                    existingEstimate.sizes.valuation = (+existingEstimate.sizes.valuation || 0) + (+estimate.volume || 0);
                }

                if (existingEstimate.materials?.length) {
                    existingEstimate.materials = existingEstimate.materials.map((material) => {
                        const newMaterial = { ...material };
                        const currentMaterial = estimate.materials.find((element) => element.code === newMaterial.code);

                        if (currentMaterial) {
                            newMaterial.volume = existingEstimate.volume;
                            newMaterial.quantity = calculateMaterialQuantity(existingEstimate, newMaterial);
                            newMaterial.amount = calculateMaterialAmount(newMaterial);
                        }

                        return newMaterial;
                    })
                }
            } else {
                let formula = "";
                let width = "";
                let length = "";
                let valuation = "";
                let quantity = "";

                if (estimate?.unit === 'шт.') {
                    formula = "quantity";
                    quantity = estimate.volume;
                    valuation = quantity;
                }
                if (estimate?.unit === 'п.м.') {
                    formula = "(length+width)*2";
                    if (estimate.parent?.isWall) {
                        length = estimate.parent.innerLink.length;
                        width = estimate.parent.mainLink.height;
                    } else {
                        length = estimate.volume;
                        width = estimate.volume;
                    }

                    valuation = estimate.volume;
                }
                if (estimate?.unit === 'кв.м.') {
                    formula = "length*width";
                    valuation = estimate.volume;
                }

                result[estimate.object].estimate.push({
                    ...estimate,
                    formula,
                    sizes: { width, length, valuation, quantity }
                });
            }

        });
    }

    result.amount = Object.values(result).reduce((acc, curr) => {
        curr.amount = getEstimateAmount(curr.estimate);
        acc += Number(curr.amount || 0);
        return acc;
    }, 0) || 0;

    Object.values(result).forEach((value) => {
        if (value?.estimate) {
            value.estimate = value.estimate.map((element) => ({
                ...element,
                volume: roundValue(+element.volume),
                sizes: {
                    ...element.sizes,
                    ...(element?.sizes?.valuation ? { valuation: String(roundValue(element.sizes.valuation)) } : {}),
                }
            }))
        }
    })

    return result;
}

export const useCollectEstimate = (type) => {
    const plan = useSelector((state) => state.project.plan);
    const modules = useSelector((state) => state.modules.modules);
    const coefficient = useSelector((state) => state.estimate.coefficient);

    const cycles = plan.cycles;
    const walls = plan.bWalls;
    const columns = plan.columns;

    const collectEstimateByRooms = useCallback((type, options) => {
        let roomAmount = 0;

        const roomWalls = [];
        const roomModules = [];
        const roomColumns = [];

        const rooms = cycles.map((cycle, index) => {
            const currentWalls = walls.filter((wall) => {
                if (!cycle._links.includes(wall.mainLink)) return false;
                roomWalls.push(wall);
                return true;
            });
            const currentModules = modules.filter((module) => {
                if (checkRoom(module, cycle)) {
                    roomModules.push(module);
                    return true;
                }
                return false;
            });
            const currentColumns = columns.filter((column) => {
                if (checkColumn(column, cycle)) {
                    roomColumns.push(column);
                    return true;
                }
                return false;
            });

            const collectedEstimate = collectEstimate({
                cycles: [cycle],
                walls: currentWalls,
                modules: currentModules,
                columns: currentColumns,
                fractionDigits: options?.fractionDigits || 0,
                type
            });

            roomAmount += collectedEstimate.amount;

            return {
                ...collectedEstimate,
                title: cycle.objTitle || `Комната ${index + 1}`,
            }
        })

        const freeWalls = walls.filter((wall) => !roomWalls.includes(wall));
        const freeModules = modules.filter((module) => !roomModules.includes(module));
        const freeColumns = columns.filter((column) => !roomColumns.includes(column));

        const { ...collectedEstimate } = collectEstimate({
            cycles: [],
            walls: freeWalls,
            modules: freeModules,
            columns: freeColumns,
            fractionDigits: options?.fractionDigits || 0,
            type
        });

        return {
            rooms,
            ...collectedEstimate,
            amount: roomAmount + collectedEstimate.amount,
            otherAmount: collectedEstimate.amount,
        }
    }, [columns, cycles, walls, modules])

    const estimateByRooms = useMemo(() => {
        return collectEstimateByRooms(type, { fractionDigits: 2 });
    }, [type, collectEstimateByRooms])

    const estimateByItems = useMemo(() => {
        return collectEstimate({ walls, cycles, modules, columns, type, fractionDigits: 2 });
    }, [type, columns, cycles, walls, modules])

    const resultAmount = useMemo(() => {
        const jobsAmount = collectEstimate({ walls, cycles, modules, columns, type: 'jobs', fractionDigits: 2 }).amount;
        const draftAmount = collectEstimate({ walls, cycles, modules, columns, type: 'draft', fractionDigits: 2 }).amount;
        const finalAmount = collectEstimate({ walls, cycles, modules, columns, type: 'final', fractionDigits: 2 }).amount;
        return jobsAmount + draftAmount + finalAmount;
    }, [columns, walls, cycles, modules]);

    const estimateJson = useMemo(() => {
        let resultAmount = 0;

        const collectedEstimateByJobs = collectEstimateByRooms("jobs", { fractionDigits: 2 });
        const { rooms: collectedRooms, otherAmount, ...other } = collectedEstimateByJobs;
        if (otherAmount > 0) {
            collectedEstimateByJobs.rooms.push({
                ...other,
                amount: otherAmount,
                title: 'Остальное',
            })
        }
        const rooms = collectedEstimateByJobs.rooms.filter((room) => room.amount > 0).map((room, index) => {
            const jobs = {};

            const fillRoomEstimate = (data) => {
                const { tariff, parent, externalId, ...estimate } = data;
                const materials = {};

                const formatNumber = (value) => {
                    if (Number.isInteger(value)) {
                        return value.toFixed(1);
                    }

                    return value.toString();
                }

                estimate.materials?.forEach((mat) => {
                    const { externalId, type, cost, ...material } = mat;
                    materials[mat.externalId] = {
                        ...material,
                        id: +mat.externalId,
                        volume: +material.volume,
                        norm: formatNumber(material.norm),
                    }
                })

                jobs[data.externalId] = {
                    ...estimate,
                    id: +data.externalId,
                    group: +estimate.group,
                    volume: +estimate.volume,
                    stage: +estimate.stage,
                    price: Number(estimate.price).toFixed(1),
                    materials,
                };
            };

            Object.values(room).forEach((value) => value.estimate?.forEach(fillRoomEstimate));

            resultAmount += Math.round((room.amount) * Math.pow(10, 2)) / Math.pow(10, 2);

            return {
                id: index + 1,
                jobs,
                materials: false,
                name: room.title,
                sizes: {
                    width: 0,
                    height: 0,
                    length: 0,
                },
                amount: Math.round((room.amount) * Math.pow(10, 2)) / Math.pow(10, 2),
            }
        })

        return {
            rooms,
            amount: Math.round(resultAmount * Math.pow(10, 2)) / Math.pow(10, 2),
            coefficient: +coefficient,
        }
    }, [collectEstimateByRooms, coefficient]);

    return { estimateByRooms, estimateByItems, resultAmount, estimateJson }
}
