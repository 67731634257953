import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import style from './Menu.module.scss';
import { actionsState as projectState } from '../../../../Redux/project';
import { useOutside } from '../../../Hooks';
import { createPortal } from 'react-dom';

const SubSaveButtons = React.forwardRef(({
     onClose,
     onSave,
     onPublish,
     onCreateVariant,
     styles,
 }, ref) => {
    const enableCreateVariant = useSelector(state => state.project.userSetup.enableCreateVariant);
    const enableSaveProject = useSelector(state => state.project.userSetup.enableSaveProject);
    const variants = useSelector(store => store.project.variants);
    const cur_variant = variants.filter(variant => variant.CUR_VARIANT)[0];
    const enablePublishing = useSelector(state => state.project.userSetup.enablePublishing);

    return (
        <div
            className={clsx("ctx-menu", style.subSaveButtons)}
            ref={ref}
            onClick={onClose}
            style={{ position: 'absolute', top: styles.top, left: styles.left }}
        >
            <ul className="ctx-list">
                {enableSaveProject && <li onClick={onSave} className={clsx("ctx-item", style.subSaveButton)}>Сохранить</li>}
                {!cur_variant?.PUBLISHED && enablePublishing && <li onClick={onPublish} className={clsx("ctx-item", style.subSaveButton)}>Опубликовать</li>}
                {enableCreateVariant && <li onClick={onCreateVariant} className={clsx("ctx-item", style.subSaveButton)}>Создать вариант</li>}
            </ul>
        </div>
    )
})

export const SaveButton = () => {
    const [showButtons, setShowButtons] = useState(false);
    const { ref } = useOutside(() => setShowButtons(false));
    const dispatch = useDispatch();

    const [position, setPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef(null);

    const handleShowButtons = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX
            });
        }
        setShowButtons(!showButtons);
    };

    useEffect(() => {
        const hideButtons = () => setShowButtons(false);

        window.addEventListener('wheel', hideButtons);
        return () => {
            window.removeEventListener('wheel', hideButtons);
        };
    }, [showButtons]);

    return (
        <div>
            <div ref={buttonRef} onClick={handleShowButtons} className={'view-mode active'}>
                <svg style={{ height: '18px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
                    <path fill="currentColor" d="M4.86,9.73h5.28a.44.44,0,0,0,.44-.44.44.44,0,0,0-.44-.44H4.86a.44.44,0,0,0-.44.44A.44.44,0,0,0,4.86,9.73Z"></path>
                    <path fill="currentColor" d="M4.86,11.48h5.28a.44.44,0,1,0,0-.87H4.86a.44.44,0,1,0,0,.87Z"></path>
                    <path fill="currentColor" d="M14.87,2.47,12.53.13A.46.46,0,0,0,12.22,0H.44A.44.44,0,0,0,0,.44V14.56A.44.44,0,0,0,.44,15H14.56a.44.44,0,0,0,.44-.44V2.78A.46.46,0,0,0,14.87,2.47ZM3.54.88H9.7V3.81H3.54Zm7.92,13.24H3.54V8h7.92Zm2.66,0H12.33V7.53a.44.44,0,0,0-.44-.44H3.11a.44.44,0,0,0-.44.44v6.59H.88V.88H2.67V4.25a.44.44,0,0,0,.44.44h7a.44.44,0,0,0,.44-.44V.88H12L14.12,3Z"></path>
                    <path fill="currentColor" d="M4.86,13.24h5.28a.44.44,0,0,0,.44-.44.44.44,0,0,0-.44-.44H4.86a.44.44,0,0,0-.44.44A.44.44,0,0,0,4.86,13.24Z"></path>
                </svg>
                <span>Сохранить</span>
            </div>
            {showButtons &&
                createPortal(
                    <SubSaveButtons
                        ref={ref}
                        styles={position}
                        onClose={() => setShowButtons(false)}
                        onSave={() => dispatch({ type: 'SAVE_PROJECT' })}
                        onPublish={() => dispatch(projectState.setModal('publish'))}
                        onCreateVariant={() => dispatch(projectState.setModal('variantCreation'))}
                    />,
                    document.body
                )
            }
        </div>
    )
}
