import React from 'react';
import { Button } from '../../../UI';
import style from './Estimate.module.scss';

export const EstimateSummary = ({ amount, onSave }) => {
    return (
        <div className={style.summary}>
            <div>
                <p className={style?.["summary-title"]}>Выбрано работ на сумму</p>
                <p className={style?.["summary-cost"]}>{Math.round(amount).toLocaleString('fr-FR')} руб.</p>
            </div>
            <div>
                <Button variant="contain" onClick={onSave}>Сохранить</Button>
            </div>
        </div>
    )
}
