import React, { useEffect, useRef } from 'react';

const SizeBlock = ({ value, onChange, min, max, isLocked, step = 1 }) => {
    const ref = useRef(null);
    const initialValue = useRef(value);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (value === 0 && !isFirstRender) {
            ref.current.type = 'text';
            ref.current?.setSelectionRange?.(1, 1);
            ref.current.type = 'number';
        }
        isFirstRender.current = false;
    }, [value]);

    const handlerOnChange = (event) => {
        const { value } = event.target;
        if (/^\d*$/.test(value)) {
            const valueNum = value === "" ? 0 : parseInt(value, 10);
            onChange(valueNum);
        }
    }

    const handlerFocus = () => {
        initialValue.current = value
    };

    const handlerBlur = (event) => {
        const { value } = event.target;
        if (+value < min && initialValue.current) {
            onChange(initialValue.current);
        }
    }

    const onPlus = () => {
        if (max !== false && max < value + step) {
            return;
        }
        onChange(value + step);
    }

    const onMinus = () => {
        if (min !== false && min > value - step) {
            return;
        }
        onChange(value - step);
    }

    return (
        <div className="size-block-input">
            <div className={(isLocked) ? "size-minus disabled" : "size-minus"} onClick={onMinus}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34"><g strokeWidth="2px" stroke="currentColor"><line x1="8.5" y1="17.5" x2="24.5" y2="17.5" /></g></svg>
            </div>
            <input ref={ref} value={String(value)} onChange={handlerOnChange} onFocus={handlerFocus} onBlur={handlerBlur} type="number" disabled={isLocked} />
            <div className={(isLocked) ? "size-plus disabled" : "size-plus"} onClick={onPlus}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34"><g strokeWidth="2px" stroke="currentColor"><line x1="8.5" y1="17.5" x2="24.5" y2="17.5" /><line x1="16.5" y1="9.5" x2="16.5" y2="25.5" /></g></svg>
            </div>
        </div>
    )
}

export default SizeBlock;
