import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendRedrawEvent,
  sendRedrawSimpleEvent, sendSelectObjectEvent,
  sendUnselectEvent,
} from '../Helpers/Events';
import { actionsState as projectState } from "../Redux/project";
import { DistanceButton } from "./BluetoothRoulette/DistanceButton";
import { distance } from "./BluetoothRoulette/utils/distance";
import { useShortcuts } from "./Features/Shortcuts";
import { EstimateInfo, ObjectList } from './Features/SideMenu';
import { MovingPanel } from "./Generic";
import ModalWrapper from "./ModalWrapper";
import { columnParams } from "./Utils";
import SizeBlock from "./widgets/SizeBlock";
import { stickColumnToWall } from './Utils/columnsCalculation';
import { Vector2 } from "three";

const sides = ['Сверху', 'Слева', 'Снизу', 'Справа'];
const getSideDirections = (angle, side = 0) => {
  let angleNormalized = angle % (2 * Math.PI);
  if (angleNormalized < 0) {
    angleNormalized += 2 * Math.PI;
  }
  const gap = Math.PI / 4;
  if (angleNormalized > gap * 7 || angleNormalized < gap) {
    side += 0;
  } else if (angleNormalized >= gap && angleNormalized < gap * 3) {
    side += 1;
  } else if (angleNormalized >= gap * 3 && angleNormalized < gap * 5) {
    side += 2;
  } else {
    side += 3;
  }

  return side > 3 ? side - 4 : side;
}

const makeFirstLower = (text) => text.charAt(0).toLowerCase() + text.slice(1)

export const ColumnInfo = ({ plan, column }) => {
  const dispatch = useDispatch();

  const bluetoothStatus = useSelector(
    (store) => store.project.devices.bluetooth.status
  );
  const planeEdit = useSelector((state) => state.project.planeEdit);

  const canvasSelector = planeEdit ? "#plan" : "#scene";

  const [position, setPosition] = useState({ x: column.x, y: column.y });
  const [depth, setDepth] = useState(column.depth);
  const [width, setWidth] = useState(column.width);
  const [height, setHeight] = useState(column.height);
  const [location, setLocation] = useState(column.location);
  const [angle, setAngle] = useState(column.angle);
  const [type, setType] = useState(column.type);
  const [distanceButton, setDistanceButton] = useState({
    property: { width: true, depth: false },
    action: handlerWidth,
  });

  const setStatusDistanceButton = ({ property, action }) => {
    const newDistanceButton = {
      property: {},
      action: {},
    };
    for (let key in distanceButton.property) {
      newDistanceButton.property[key] = property === key ? true : false;
    }
    newDistanceButton.action = action;
    setDistanceButton(newDistanceButton);
  };

  useEffect(() => {
    setDepth(column.depth);
    setWidth(column.width);
    setHeight(column.height);
    setLocation(column.location);
    setAngle(column.angle);
    setType(column.type);
    setPosition({ x: column.x, y: column.y });
  }, [
    column.type,
    column.depth,
    column.width,
    column.height,
    column.location,
    column.angle,
    column.x,
    column.y,
  ]);

  const moveAbsolutely = (deltaX, deltaY) => {
    column.x += deltaX;
    column.y += deltaY;

    setPosition({ x: column.x, y: column.y });
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  };

  const moveRelatively = (side, value) => {
    const multiplyX = (side === "bottom" || side === "right" || side === 'a') ? 1 : -1;
    const multiplyY = (side === "bottom" || side === "left" || side === 'b') ? 1 : -1;
    const sin = (value) =>
      (side === "bottom" || side === "top") ? Math.sin(value) : Math.cos(value);
    const cos = (value) =>
      (side === "bottom" || side === "top") ? Math.cos(value) : Math.sin(value);

    column.x += multiplyX * (column.location[side] - value) * sin(column.angle);
    column.y += multiplyY * (column.location[side] - value) * cos(column.angle);

    setLocation({ ...location, [side]: value });

    if (value === 0 && side !== 'a' && side !== 'b') {
      const cid = plan.columns.findIndex((col) => col === column);

      const stickPoint = stickColumnToWall(
        { x: column.x, y: column.y },
        plan.bWalls,
        column,
        cid,
        0.05,
      );

      if (stickPoint) {
        const { x, y, angle, wallIndex, wallSide } = stickPoint;

        column.x = x;
        column.y = y;
        column.angle = angle;

        column.setParentWallID(wallIndex, wallSide);
        plan.bWalls[wallIndex].setColumns(cid, wallSide);
      }
    }

    sendRedrawEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  };

  const handlerHeight = (value) => {
    setHeight(value);
    column.isFixed = true;
    column.height = value;

    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  };

  const handlerType = (value) => {
    column.type = value;
    setType(value);
    plan.setFloors("floor");

    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  };

  function handlerWidth(value) {
    setWidth(value);
    column.width = value;
    plan.setFloors("floor");

    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  }

  function handlerDepth(value) {
    setDepth(value);
    if (column.parentWallID !== -1) {
      const pointA = new Vector2(column.x, column.y);
      const pointB = new Vector2(column.x, column.y);
      const v2 = new Vector2(column.x, column.y)
        .normalize()
        .rotateAround(
          new Vector2(0, 0),
          -column.angle - new Vector2(column.x, column.y).angle() + 90 * (Math.PI / 180)
        )
        .setLength(100000);

      pointA.x = pointA.x + v2.x;
      pointA.y = pointA.y + v2.y;
      pointB.x = pointB.x - v2.x;
      pointB.y = pointB.y - v2.y;
      const v3 = pointA
        .clone()
        .sub(pointB)
        .setLength((column.depth - value) / 2);
      column.depth = value;
      column.x = column.x + v3.x;
      column.y = column.y + v3.y;
    }
    column.depth = value;
    plan.setFloors("floor");

    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  }

  const addObjectOnColumn = (side) => {
    const columnObjects = column.addObject(side);
    sendSelectObjectEvent(
      document.querySelector(canvasSelector),
      columnObjects[columnObjects.length - 1],
      column
    );
    plan.setActionUndo({ type: "plan" });
  };


  const close = () => {
    dispatch(projectState.setModal(""));
    sendUnselectEvent(document.querySelector(canvasSelector));
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
  };

  const remove = () => {
    const cid = plan.columns.findIndex((el) => el === column);
    if (column.parentWallID > -1) {
      plan.bWalls[column.parentWallID].removeColumns(cid);
    }
    plan.setFloors("floor");

    plan.removeColumn(column);
    sendUnselectEvent(document.querySelector(canvasSelector));
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  };

  useShortcuts({ onDelete: remove });

  useEffect(() => {
    if (bluetoothStatus !== "connected") {
      return;
    }
    if (Object.keys(distance.setCallBack)) {
      distance.setCallBack(distanceButton.action);
    } else {
      distance.start(distanceButton.action);
    }
    return () => distance.setCallBack({});
  }, [bluetoothStatus, distanceButton]);

  return (
    <ModalWrapper
      isSideMenu={true}
      title={"Настройки колонны"}
      onDelete={remove}
      onClose={close}
    >
      <div className="modal-body">
        <MovingPanel motionFunction={moveAbsolutely} />
        <br />
        <div className={"options-title-wrap"}>
          <div className={"options-title"}>Прикрепить к потолку</div>
          <div className={"options-title__option"}>
            <div
              className={
                type === "roof"
                  ? "oval-checkbox oval-checkbox_active"
                  : "oval-checkbox"
              }
              onClick={() => handlerType(type === "roof" ? "floor" : "roof")}
            >
              &nbsp;
            </div>
          </div>
        </div>
        {location && column.parentWallID === -1 && (
          <>
            <div className="options-title">Расположение</div>
            {location?.left !== null && (
              <div className="size-block">
                <h2 className="title-E_direction">{sides[getSideDirections(angle, 1)]}</h2>
                <SizeBlock
                  value={location.left}
                  onChange={(value) => moveRelatively("left", value)}
                  min={0}
                  max={false}
                  step={5}
                  isLocked={false}
                />
              </div>
            )}
            {location?.right !== null && (
              <div className="size-block">
                <h2 className="title-W_direction">{sides[getSideDirections(angle, 3)]}</h2>
                <SizeBlock
                  value={location.right}
                  onChange={(value) => moveRelatively("right", value)}
                  min={0}
                  max={false}
                  step={5}
                  isLocked={false}
                />
              </div>
            )}
            {location?.top !== null && (
              <div className="size-block">
                <h2 className="title-N_direction">{sides[getSideDirections(angle)]}</h2>
                <SizeBlock
                  value={location.top}
                  onChange={(value) => moveRelatively("top", value)}
                  min={0}
                  max={false}
                  step={5}
                  isLocked={false}
                />
              </div>
            )}
            {location?.bottom !== null && (
              <div className="size-block">
                <h2 className="title-S_direction">{sides[getSideDirections(angle, 2)]}</h2>
                <SizeBlock
                  value={location.bottom}
                  onChange={(value) => moveRelatively("bottom", value)}
                  min={0}
                  max={false}
                  step={5}
                  isLocked={false}
                />
              </div>
            )}
          </>
        )}
        {(location && column.parentWallID > -1) && (
          <>
            <div className="options-title">Расположение вдоль стены</div>
            {location?.b !== null && (
              <div className="size-block">
                <h2 className={'title-E_direction'}>Отступ {makeFirstLower(sides[getSideDirections(angle, 1)])}</h2>
                <SizeBlock
                  value={location.b}
                  onChange={(value) => moveRelatively('b', value)}
                  min={0}
                  max={false}
                  step={5}
                  isLocked={false}
                />
              </div>
            )}
            {location?.a !== null && (
              <div className="size-block">
                <h2 className={'title-W_direction'}>Отступ {makeFirstLower(sides[getSideDirections(angle, 3)])}</h2>
                <SizeBlock
                  value={location.a}
                  onChange={(value) => moveRelatively('a', value)}
                  min={0}
                  max={false}
                  step={5}
                  isLocked={false}
                />
              </div>
            )}
          </>
        )}
        <br />
        <div className="size-block">
          <h2 className={"title_column"}>Ширина</h2>
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"width"}
              action={handlerWidth}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
          <SizeBlock
            value={width}
            onChange={handlerWidth}
            min={columnParams.minWidth}
            step={5}
            isLocked={false}
          />
        </div>
        <div className="size-block">
          <h2 className={"title_column"}>Глубина</h2>
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"depth"}
              action={handlerDepth}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
          <SizeBlock
            value={depth}
            onChange={handlerDepth}
            min={columnParams.minDepth}
            step={5}
            isLocked={false}
          />
        </div>
        <div className="size-block">
          <h2 className={"title_column"}>Высота</h2>
          <SizeBlock
            value={height}
            onChange={handlerHeight}
            min={5}
            step={5}
            isLocked={false}
          />
        </div>
        <div className="options-title">Настенные объекты</div>
        <div className="block">
          <div className="size-block">
            <h2 className="title-E_direction">{sides[getSideDirections(angle, 1)]}</h2>
            <div
              className="btn btn-icon"
              onClick={() => addObjectOnColumn('left')}
            >
              Добавить объект
            </div>
          </div>
          <ObjectList plan={plan} canvasSelector={canvasSelector} parent={column}
            objects={column.objects.filter((object) => object.side === 'left')} />
          <div className="size-block">
            <h2 className="title-W_direction">{sides[getSideDirections(angle, 3)]}</h2>
            <div
              className="btn btn-icon"
              onClick={() => addObjectOnColumn('right')}
            >
              Добавить объект
            </div>
          </div>
          <ObjectList plan={plan} canvasSelector={canvasSelector} parent={column}
            objects={column.objects.filter((object) => object.side === 'right')} />
          {column.noSizeSide !== 'top' &&
            <>
              <div className="size-block">
                <h2 className="title-N_direction">{sides[getSideDirections(angle)]}</h2>
                <div
                  className="btn btn-icon"
                  onClick={() => addObjectOnColumn('top')}
                >
                  Добавить объект
                </div>
              </div>
              <ObjectList plan={plan} canvasSelector={canvasSelector} parent={column}
                objects={column.objects.filter((object) => object.side === 'top')} />
            </>
          }
          {column.noSizeSide !== 'bottom' &&
            <>
              <div className="size-block">
                <h2 className="title-S_direction">{sides[getSideDirections(angle, 2)]}</h2>
                <div
                  className="btn btn-icon"
                  onClick={() => addObjectOnColumn('bottom')}
                >
                  Добавить объект
                </div>
              </div>
              <ObjectList plan={plan} canvasSelector={canvasSelector} parent={column}
                objects={column.objects.filter((object) => object.side === 'bottom')} />
            </>
          }
        </div>
        <br />
        <EstimateInfo object={column} />
      </div>
    </ModalWrapper>
  );
};
