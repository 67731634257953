import React, { useEffect, useState } from 'react';
import ModalWrapper from "./ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { actionsState as projectState } from "../Redux/project";
import {
    sendCenterModuleEvent,
    sendRedrawEvent,
    sendRedrawSimpleEvent,
    sendUnselectEvent
} from "../Helpers/Events";
import SizeBlock from "./widgets/SizeBlock";
import ObjectInfoBlock from "./widgets/ObjectInfoBlock";
import ColorRBG from "./widgets/ColorRBG";
import { PopUpDialog } from './popUpDialog';
import { MovingPanel } from './Generic';

import { selectedRoom } from './PlanEditor';
import { EstimateInfo } from './Features/SideMenu';
import { useShortcuts } from './Features/Shortcuts';
import MaterialSelect from './MaterialSelect';

const FloorInfo = ({ floor }) => {
    const dispatch = useDispatch();
    const [floorMaterialWnd, setFloorMaterialWnd] = useState(false);
    const [rgb, setRgb] = useState(floor.rgb);
    const [rgbColor, setRgbColor] = useState(floor.rgbColor);
    const [floorMaterial, setFloorMaterial] = useState(floor.material);
    const [floorMaterialRotation, setFloorMaterialRotation] = useState(floor.rotation);
    const [floorHeight, setFloorHeight] = useState(floor.height);
    const [heightFromFloor, setHeightFromFloor] = useState(floor.heightFromFloor);
    const [showModule, setShowModule] = useState(floor.showModule);
    const [materialWnd, setMaterialWnd] = useState(false);

    const modules = useSelector(store => store.modules.modules);
    const plan = useSelector(store => store.project.plan);
    const level = useSelector(store => store.project.level);
    const planeEdit = useSelector(state => state.project.planeEdit);
    const canvasSelector = (planeEdit) ? '#plan' : '#scene';
    // console.log('plan',plan)

    const [popUpDialogVisible, setPopUpDialogVisible] = useState(false)

    const handlerShowModule = () => {
        const value = !showModule;
        setShowModule(value);
        floor.showModule = value;
        sendRedrawEvent(document.querySelector(canvasSelector));
    }

    const centerModule = () => {
        sendCenterModuleEvent(document.querySelector(canvasSelector), floor, 'floor');
    }

    const _setFloorMaterial = (m) => {
        floor.rgbColor = false;
        setRgbColor(false);
        floor.material = m;
        setFloorMaterial(m);
        plan.setFloorMaterials([floor]);

        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    }

    const _setRgbColor = (rgb) => {
        floor.rgbColor = true;
        setRgbColor(true);
        floor.rgb = rgb;
        setRgb(rgb);

        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    }

    const handlerFloorHeight = (value) => {
        setFloorHeight(value);
        floor.height = value;
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    }

    const handlerHeightFromFloor = (value) => {
        setHeightFromFloor(value);
        floor.heightFromFloor = value;
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    }


    const remove = () => {
        ///dispatch(modulesState.remove(module))
    }

    const showPopUpDeleteDialog = () => {
        setPopUpDialogVisible(true);
    }
    const hidePopUpDeleteDialog = () => {
        setPopUpDialogVisible(false);
    }

    const onYesHandler = () => {
        deleteCycle();
        setPopUpDialogVisible(false);
    }

    const deleteCycle = () => {
        plan.removeWalls(plan.activeObject.object.links);
        plan.clearEmptyCycles();
        dispatch(projectState.setModal(''));
        sendUnselectEvent(document.querySelector(canvasSelector));
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
        plan.setActionUndo({ type: 'plan' });
    }
    const copyCycle = () => {
        plan.copyActiveCycle();
        dispatch(projectState.setModal(''));
        sendUnselectEvent(document.querySelector(canvasSelector));
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));

    }


    const close = () => {
        dispatch(projectState.setModal(''));
        sendUnselectEvent(document.querySelector(canvasSelector));
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    }

    const moveFloor = (deltaX, deltaY) => {
        selectedRoom.move(plan, modules, plan.activeObject.object, deltaX, deltaY);
        // sendRedrawEvent(document.querySelector(canvasSelector));
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    }

    useShortcuts({
        onDelete: deleteCycle,
        onCopy: copyCycle,
    });

    useEffect(() => {
        setFloorHeight(floor.height);
        setHeightFromFloor(floor.heightFromFloor);
        setRgb(floor.rgb);
        setRgbColor(floor.rgbColor);

        setShowModule(floor.showModule);
        setFloorMaterialRotation(floor?.rotation);
        // setH(floor.h);
        // setHeight(floor.height);
        // sendRedrawEvent(document.querySelector(canvasSelector));
    }, [floor, floor?.rotation, floor.showModule, floor.material])


    return <ModalWrapper
        isSideMenu={true}
        title={(level === 'roof') ? "Настройки потолка" : 'Настройки пола'}
        onCopy={(level === 'roof') ? undefined : () => copyCycle()}
        onDelete={(level === 'roof') ? undefined : () => showPopUpDeleteDialog()}
        onClose={() => close()}
    >
        <div className="modal-body">

            {!planeEdit > 0 && <div className="module-btns">

                <div className="btn btn-icon" onClick={handlerShowModule}>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g fill="currentColor">
                            <path
                                d="M21.8602 10.5721C21.6636 10.3032 16.9808 3.98898 10.9999 3.98898C5.01902 3.98898 0.33593 10.3032 0.139606 10.5718C-0.0465352 10.8269 -0.0465352 11.1728 0.139606 11.4279C0.33593 11.6967 5.01902 18.011 10.9999 18.011C16.9808 18.011 21.6636 11.6967 21.8602 11.4281C22.0466 11.1731 22.0466 10.8269 21.8602 10.5721ZM10.9999 16.5604C6.59434 16.5604 2.77867 12.3695 1.64914 10.9995C2.7772 9.62821 6.58489 5.43953 10.9999 5.43953C15.4052 5.43953 19.2206 9.62967 20.3506 11.0005C19.2226 12.3717 15.4149 16.5604 10.9999 16.5604Z"/>
                            <path
                                d="M11.0001 6.64835C8.60064 6.64835 6.64844 8.60055 6.64844 11C6.64844 13.3995 8.60064 15.3517 11.0001 15.3517C13.3996 15.3517 15.3518 13.3995 15.3518 11C15.3518 8.60055 13.3996 6.64835 11.0001 6.64835ZM11.0001 13.9011C9.40038 13.9011 8.09902 12.5997 8.09902 11C8.09902 9.40033 9.40042 8.09893 11.0001 8.09893C12.5998 8.09893 13.9012 9.40033 13.9012 11C13.9012 12.5997 12.5998 13.9011 11.0001 13.9011Z"/>
                        </g>
                    </svg>
                    {showModule && <span>Спрятать</span>}
                    {!showModule && <span>Показать</span>}
                </div>
                {showModule && <div className="btn btn-icon" onClick={centerModule}>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 492.001 492.001">
                        <g fill="currentColor">
                            <path d="M487.97,237.06l-58.82-58.82c-5.224-5.228-14.376-5.228-19.592,0l-7.436,7.432c-5.4,5.4-5.4,14.064,0,19.46l21.872,21.74
                            H265.206V68.396l21.808,22.132c5.224,5.22,14.216,5.22,19.428,0l7.36-7.432c5.404-5.404,5.356-14.196-0.044-19.596L254.846,4.444
                            c-2.6-2.592-6.088-4.184-9.804-4.184h-0.404c-3.712,0-7.188,1.588-9.784,4.184l-57.688,57.772
                            c-2.612,2.608-4.052,6.124-4.052,9.836c0,3.704,1.44,7.208,4.052,9.816l7.432,7.444c5.224,5.22,14.612,5.228,19.828,0.004
                            l22.368-22.132v159.688H67.814l22.14-22.008c2.608-2.608,4.048-6.028,4.048-9.732s-1.44-7.16-4.052-9.76l-7.436-7.42
                            c-5.22-5.216-14.372-5.2-19.584,0.008L4.034,236.856c-2.672,2.672-4.1,6.244-4.032,9.92c-0.068,3.816,1.356,7.388,4.028,10.056
                            l57.68,57.692c5.224,5.22,14.38,5.22,19.596,0l7.44-7.44c2.604-2.6,4.044-6.084,4.044-9.788c0-3.716-1.44-7.232-4.044-9.836
                            l-22.14-22.172H226.79V425.32l-23.336-23.088c-5.212-5.22-14.488-5.22-19.7,0l-7.5,7.44c-2.604,2.6-4.072,6.084-4.072,9.792
                            c0,3.704,1.424,7.184,4.028,9.792l58.448,58.456c2.596,2.592,6.068,4.028,9.9,4.028c0.024-0.016,0.24,0,0.272,0
                            c3.712,0,7.192-1.432,9.792-4.028l58.828-58.832c2.6-2.604,4.044-6.088,4.044-9.792c0-3.712-1.44-7.192-4.044-9.796l-7.44-7.44
                            c-5.216-5.22-14.044-5.22-19.264,0l-21.54,21.868V265.284H425.59l-23.096,23.132c-2.612,2.608-4.048,6.112-4.048,9.82
                            s1.432,7.192,4.048,9.8l7.44,7.444c5.212,5.224,14.372,5.224,19.584,0l58.452-58.452c2.672-2.664,4.096-6.244,4.028-9.916
                            C492.07,243.296,490.642,239.728,487.97,237.06z"/>
                        </g>
                    </svg>
                    Центрировать
                </div>}
            </div>}
            {(level !== 'roof') && <MovingPanel motionFunction={moveFloor}/>}
            {floor.isFigure && <div>
                <div className="options-title">{(level === 'roof') ? "Настройки уровня" : 'Настройки уровня'}</div>
                <div className="size-block">
                    <h2>Высота</h2>
                    <SizeBlock
                        value={floorHeight}
                        onChange={handlerFloorHeight}
                        min={1}
                        max={false}
                        step={5}
                        isLocked={false}
                    />
                </div>
                <div className="size-block">
                    <h2>Отступ от пола</h2>
                    <SizeBlock
                        value={heightFromFloor}
                        onChange={handlerHeightFromFloor}
                        min={0}
                        max={false}
                        step={5}
                        isLocked={false}
                    />
                </div>
            </div>}
            <div className="materials-block">
                <div className="options-title">Материал пола</div>
                <div className="material-select">
                    {!floor.rgbColor && (
                        <div
                            className="material-select__select"
                            onClick={() => setMaterialWnd(true)}
                            style={{
                                backgroundImage: "url(" + floor.material.userData.PICT + ")",
                                cursor: "pointer",
                            }}
                        >
                            <span>{floor.material.userData.NAME}</span>
                        </div>
                    )}
                    {floor.rgbColor && (
                        <div
                            className="material-select__select"
                            onClick={() => setMaterialWnd(true)}
                            style={{
                                background:
                                    "rgba(" +
                                    floor.rgb.r +
                                    "," +
                                    floor.rgb.g +
                                    "," +
                                    floor.rgb.b +
                                    "," +
                                    floor.rgb.a +
                                    ")",
                                cursor: "pointer",
                            }}
                        >
                            <span>RGB цвет</span>
                        </div>
                    )}
                </div>
                {!floor.rgbColor &&
                    <div className="size-block">
                        <h2>Поворот текстуры</h2>
                        <SizeBlock
                            value={floorMaterialRotation}
                            onChange={(value) => {
                                floor.rotation = value;
                                setFloorMaterialRotation(value);
                                plan.setFloorMaterials([floor]);
                            }}
                            min={0}
                            max={180}
                            step={5}
                            isLocked={false}
                        />
                    </div>}
                {materialWnd &&
                    <MaterialSelect
                        onClose={() => setMaterialWnd(false)}
                        active={window.materials.floor.includes(floor.material) ? floor.material : window.materials.floor[0]}
                        list={window.materials.floor}
                        setMaterial={_setFloorMaterial}
                        title="Материал пола"
                        rgbColor={true}
                        _setRgbColor={_setRgbColor}
                        activeRGB={floor.rgbColor}
                        colorDef={floor.rgb}
                    />}
            </div>
            <ObjectInfoBlock obj={floor}/>
            <EstimateInfo object={floor}/>
        </div>
        {popUpDialogVisible && <PopUpDialog
            title={"Удалить помещение?"}
            onYes={() => {
                onYesHandler()
            }}
            onNo={() => {
                hidePopUpDeleteDialog()
            }}
        />}
    </ModalWrapper>

}

export default FloorInfo
