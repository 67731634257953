import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Estimate.module.scss';
import { actionsState as projectState } from '../../../../Redux/project';
import { Button } from '../../../UI';
import { useEstimate } from './hooks';
import { sendRedrawSimpleEvent, sendUnselectEvent } from '../../../../Helpers/Events';
import { getWallCycle } from '../../../Utils';
import { getLength } from "./hooks/useEstimate";

export const EstimateInfo = ({ object }) => {
    const dispatch = useDispatch();
    const [, setUpdate] = useState();

    const formatMode = useSelector(state => state.project.formatMode);
    const formatEstimate = useSelector(state => state.project.formatEstimate);
    const planeEdit = useSelector(state => state.project.planeEdit);
    const plan = useSelector(state => state.project.plan);

    const cycles = plan.cycles;
    const walls = plan.bWalls;
    const canvasSelector = (planeEdit) ? '#plan' : '#scene';
    const wallCycle = object?.isWall ? getWallCycle(object, cycles) : false;

    const {
        estimate,
        setEstimate,
        estimateData,
        getEstimateData,
        amount,
        square
    } = useEstimate({ object });

    useEffect(() => {
        if (formatMode && object.estimate !== formatEstimate && estimateData.length) {
            const copyEstimate = formatEstimate.map((estimate) => estimateData.find((data) => data.id === estimate.id));
            if (!object?.isWall || (!!object?.isWall && getLength(object) >= 0.5)) {
                setEstimate(copyEstimate);
            }
            setUpdate(copyEstimate);
        }
    }, [formatMode, formatEstimate, estimateData, setEstimate]);

    const applyEstimateToCommonObject = () => {
        if (estimate && estimate.length) {
            const roomWalls = walls.filter((wall) => {
                return wallCycle && wallCycle._links.includes(wall.mainLink);
            });

            roomWalls.forEach((wall) => {
                if (getLength(wall) >= 0.5) {
                    const copyEstimate = estimate.map((element) => getEstimateData(wall)?.find((data) => data?.id === element?.id));
                    wall.setEstimate(copyEstimate);
                }
            })
        }
    }

    return (
        <div className="block" style={{ paddingBottom: "12px" }}>
            <div className="options-title">Работы</div>
            <div className="size-block" style={{ justifyContent: "flex-start", gap: '10px' }}>
                <div
                    className="btn btn-icon"
                    onClick={() => { dispatch(projectState.setModal('estimate')); }}
                >
                    Редактировать
                </div>
                <div
                    className="btn modal-header_btn"
                    onClick={() => {
                        dispatch(projectState.setModal(''));
                        sendUnselectEvent(document.querySelector(canvasSelector));
                        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
                        dispatch(projectState.setFormatMode(true));
                        dispatch(projectState.setFormatEstimate(estimate));
                    }}
                >
                    <svg width="24px" height="24px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" strokeWidth={5}>
                        <rect x="12.26" y="7.24" width="37.8" height="13.05" rx="1"/>
                        <path d="M31.16,38.27V34a.46.46,0,0,1,.3-.43l24.16-9a.44.44,0,0,0,.3-.42V14.22a.45.45,0,0,0-.45-.45h-5.4"/>
                        <rect x="27.24" y="38.27" width="8.24" height="18.48" rx="1"/>
                        <path d="M9.08,10.59h3.18a0,0,0,0,1,0,0v6a0,0,0,0,1,0,0H9.08a1,1,0,0,1-1-1v-4A1,1,0,0,1,9.08,10.59Z"/>
                    </svg>
                </div>
                {wallCycle &&
                    <div
                        className="btn modal-header_btn"
                        onClick={applyEstimateToCommonObject}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24"
                             viewBox="0 0 24 24">
                            <g fill="currentColor">
                                <path
                                    d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"/>
                            </g>
                        </svg>
                    </div>
                }
            </div>
            {estimate?.length ?
                <div className={styles.info}>
                    <div className={styles?.["info-positions"]}>
                        <p>{estimate.length} позиций</p>
                        <Button
                            variant="text"
                            onClick={() => dispatch(projectState.setModal('estimateList'))}
                        >
                            Посмотреть
                        </Button>
                    </div>
                    <div className={styles?.["info-summary"]}>
                        <p className={styles?.["info-square"]}>{square} кв.м</p>
                        <p className={styles?.["info-cost"]}>{Math.round(amount).toLocaleString('fr-FR')} руб.</p>
                    </div>
                </div> :
                <div>
                    <p className={styles?.["blank-text"]}>0 позиций: 0 рублей</p>
                </div>
            }
        </div>
    )
}
