import { Vector3, Triangle } from 'three';

export const checkRightDirectionWall = (cycles, wallMainLink) => {
    let breakFlag = false;
    let rightDirectionWall = true;
    for (let i = 0; i < cycles.length; i++) {
        for (let j = 0; j < cycles[i].links.length; j++) {
            if (cycles[i].links[j] === wallMainLink) {
                const normalTriangle = new Vector3();
                const triangle = new Triangle(new Vector3(cycles[i].diagonalCenter.x, cycles[i].diagonalCenter.y, 0),
                    new Vector3(cycles[i].links[j].a.x, cycles[i].links[j].a.y, 0),
                    new Vector3(cycles[i].links[j].b.x, cycles[i].links[j].b.y, 0));
                triangle.getNormal(normalTriangle);
                if (normalTriangle.z < 0) { rightDirectionWall = false }
                breakFlag = true
                break
            }
        }
        if (breakFlag) { break }
    }
    return rightDirectionWall;
}