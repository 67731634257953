import React, { useState } from 'react';
import ModalWrapper from "../ModalWrapper";
import { useDispatch } from "react-redux";
import { actionsState as projectState } from "../../Redux/project";

const ConfirmPublicVariant = ()=>{
    const dispatch = useDispatch();

    const publicVariant = () => {
        close();
        dispatch({ type: 'PUBLISH_VARIANT' });
    }

    const close = () => {
        dispatch(projectState.setModal(''));
    }

    return  <ModalWrapper
        onClose={close}
        title={'Опубликовать вариант проекта'}
        isBlock={false}
    >
        <div className="modal-body">
            <div className={'create-variant-body'}>
                <button onClick={publicVariant} className={'create-variant-btn'}>Опубликовать</button>
                <button onClick={close} className={'cancel-variant-btn'}>Отмена</button>
            </div>
        </div>
    </ModalWrapper>
}

export default ConfirmPublicVariant;
