import React from 'react'
import ModalWrapper from "./ModalWrapper";
import {useDispatch, useSelector} from "react-redux";
import {actionsState as ProjectStore} from "../Redux/project";
import MaterialSelect from "./MaterialSelect";
import VariantDropBox from "./widgets/VariantDropBox";
const SaveInfo = ()=>{
    const dispatch = useDispatch();
    const message = useSelector(store=>store.project.saveResult.message);
    return <div><div className='modal-bg' onClick={()=>dispatch(ProjectStore.setModal(''))}></div><div className='modal-info'>
        <ModalWrapper
            title={""}
            onClose={()=>dispatch(ProjectStore.setModal(''))}
        >
            <div className={"modal-info-block"}>{message}</div>
        </ModalWrapper>
    </div></div>
}
export default SaveInfo
