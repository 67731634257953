import React, {useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalWrapper from '../../../ModalWrapper';
import { Checkbox, Search, Select, Switch, Table, TextField } from '../../../UI';
import { actionsState as projectState } from '../../../../Redux/project';
import { EstimateSummary } from './EstimateSummary';
import { useEstimate } from './hooks';
import style from './Estimate.module.scss';
import { convertQuotes } from '../../../Utils';

const options = [
    {label: 'Монтаж', value: 'Монтаж'},
    {label: 'Демонтаж', value: 'Демонтаж'},
]

const columns = [
    {
        id: 'select',
        cell: ({ row }) => (
            <Checkbox
                value={row.getIsSelected()}
                onChange={row.getToggleSelectedHandler()}
            />
        ),
    },
    {
        size: 330,
        header: 'наименование',
        accessorKey: 'name',
    },
    {
        size: 160,
        header: 'цена за ед.',
        accessorKey: 'cost',
    },
    {
        size: 160,
        header: 'объем',
        accessorKey: 'volume',
        cell: ({ getValue, table, row, column }) => {
            return (
                <TextField
                    value={getValue()}
                    adornment={row.original.unit}
                    type="text"
                    onBlur={(e) => {
                        const { value } = e.target;
                        const valueNum = value === "" ? 0 : parseFloat(value);
                        table.options.meta?.updateData(row.index, {
                            [column.id]: String(valueNum) || '0',
                            amount: Math.round(valueNum * row.original.cost)
                        });
                    }}
                    onChange={(e) => {
                        let { value } = e.target;
                        value = value.replace(',', '.');

                        if (/^0+$/.test(value)) {
                            value = '0';
                        }

                        if (/^0+(?=\d)/.test(value)) {
                            value = value.replace(/^0+/, '');
                        }

                        if (/^\d*([.]\d{0,2})?$/.test(value)) {
                            const valueNum = value === "" ? 0 : parseFloat(value);
                            table.options.meta?.updateData(row.index, {
                                [column.id]: String(value) || '0',
                                amount: Math.round(valueNum * row.original.cost)
                            });
                        }
                    }}
                />
            );
        }
    },
    {
        size: 160,
        header: 'стоимость, руб.',
        accessorKey: 'amount',
        cell: ({ getValue }) => {
            const value = Math.round(getValue());
            return (
                <p className={style?.["table-summary"]}>
                    {value?.toLocaleString('fr-FR')}
                </p>
            );
        },
    },
]

export const EstimateEdit = ({ object }) => {
    const dispatch = useDispatch();
    const level = useSelector(state => state.project.level);
    const {
        estimate,
        setEstimate,
        availableObjects,
        estimateData,
        currentTariff,
        amount
    } = useEstimate({ object });

    const [tableData, setTableData] = useState(estimateData);
    const [objectUpd, setObjectUpd] = useState(false);

    const [search, setSearch] = useState('');
    const [estimateType, setEstimateType] = useState(options[0]);
    const [onlySelected, setOnlySelected] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [objectsType, setObjectsType] = useState(() => {
        let objectType = 'стен';
        if (object?.isModule) objectType = 'сантех';
        if (object?.isCycle && level === 'floor') objectType = 'пол';
        if (object?.isCycle && level === 'roof') objectType = 'потол';
        if (object?.isDoor || object?.isHole || object?.isWindow || object?.isRedCube || object?.isCylinder) objectType = 'окн';
        if (object?.isElectricSocket || object?.isSwitch || object?.isHeatingBattery || object?.isElectricPanel) objectType = 'элект';
        return availableObjects.find((object) => object.value.toLowerCase().includes(objectType)) || availableObjects[0];
    });
    const selectObjectName = useMemo(() => {
        availableObjects.forEach((availableObject) => {
            delete availableObject.isSpecial;
        });
        objectsType.isSpecial = true;

        let name = '';
        if (object.isWindow) {
            name = 'Окно';
        }
        if (object.isDoor) {
            name = 'Дверь';
        }
        if (object.isHole) {
            name = 'Проем';
        }
        if (object.isFloor) {
            name = 'Пол';
        }
        if (object.isWall) {
            name = 'Стена';
        }
        if (object.isElectricSocket) {
            name = 'Розетка';
        }
        if (object.isSwitch) {
            name = 'Выключатель';
        }
        if (object.outletElectricalWire) {
            name = 'Вывод электропровода';
        }
        if (object.isHeatingBattery) {
            name = 'Батарея отопления';
        }
        if (object.isElectricPanel) {
            name = 'Электрощит';
        }
        if (object.isRedCube) {
            name = 'Куб';
        }
        if (object.isCylinder) {
            name = 'Цилиндр';
        }
        if (object?.isModule && object?.name) {
            name = convertQuotes(object.name);
        }
        if (object?.isFigure) {
            name = 'Фигура';
        }
        if (object?.isColumn) {
            name = 'Колонна';
        }

        return name;
    }, [object]);


    useEffect(() => {
        const selectedPositions = estimate?.map((estimate) => ({ [estimate.id]: true })) || [];
        setSelectedRow(Object.assign({}, ...selectedPositions));

        return () => {
            availableObjects.forEach((availableObject) => {
                delete availableObject.isSpecial;
            });
        }
    }, [object]);

    useEffect(() => {
        let data = estimateData.filter((estimate) => estimate?.tariff === currentTariff?.value);
        if (onlySelected) {
            data = data.filter((estimate) => selectedRow?.[estimate.id]);
        }
        if (estimateType?.value) {
            data = data.filter((estimate) => estimate.type === estimateType?.value);
        }
        if (objectsType?.value) {
            data = data.filter((estimate) => estimate.object === objectsType?.value);
        }
        if (search !== '') {
            data = data.filter((estimate) => estimate.name.toLowerCase().includes(search.toLowerCase()));
        }
        setTableData(data);
    }, [estimateData, currentTariff, onlySelected, estimateType, objectsType, search])

    useEffect(() => {
        setEstimate(estimateData?.map((estimate) => {
            const updatedData = tableData.find((data) => data.id === estimate.id);
            if (updatedData) {
                if (Object.keys(selectedRow).includes(estimate.id)) {
                    return {
                        ...estimate,
                        ...updatedData,
                    }
                }
            }
            const existed = object.estimate.find((est) => estimate.id === est.id);
            if (existed) {
                return {
                    ...estimate,
                    ...existed
                }
            }
            return estimate;
        }).filter((estimate) => Object.keys(selectedRow).includes(estimate.id)));
        setObjectUpd(!objectUpd);
    }, [estimateData, tableData, selectedRow]);

    useEffect(() => {
        setEstimate(estimate?.map((estimate) => {
            const updatedData = tableData.find((data) => data.id === estimate.id);
            if (Object.keys(selectedRow).includes(estimate.id)) {
                return {
                    ...estimate,
                    ...updatedData,
                }
            }
            return estimate;
        }));
        setObjectUpd(!objectUpd);
    }, [tableData, setEstimate]);

    const onClose = () => dispatch(projectState.setModal(''));

    return (
        <ModalWrapper
            isSideMenu={true}
            title={`Добавить работу ${selectObjectName}`}
            onClose={onClose}
            width={800}
        >
            <div className={style?.["table-search"]}>
                <div className={style?.["table-filter"]}>
                    <Select
                        options={availableObjects}
                        onChange={(option) => setObjectsType(option)}
                        value={objectsType}
                    />
                    <Select
                        options={options}
                        onChange={(option) => setEstimateType(option)}
                        value={estimateType}
                    />
                    <Search
                        placeholder={"Найти работу"}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <Switch
                    value={onlySelected}
                    label={"Только отмеченные"}
                    onChange={() => setOnlySelected(!onlySelected)}
                />
            </div>
            <div className={style.table}>
                <Table
                    data={tableData}
                    columns={columns}
                    selectedRow={selectedRow}
                    onSelectRow={setSelectedRow}
                    onChangeData={setTableData}
                />
            </div>
            <EstimateSummary
                amount={amount}
                onSave={onClose}
            />
        </ModalWrapper>
    )
}
