import React from 'react';
import styles from './Modal.module.scss';

export const Modal = ({ modalRef, open, title, children }) => {
    if (!open) return null;

    return (
        <div className={styles.modal}>
            <div className={styles.overlay} ref={modalRef}>
                <div className={styles.modalHeader}>
                    <div className={styles.title}>{title}</div>
                </div>
                <div className={styles.modalContent}>
                    {children}
                </div>
            </div>
        </div>
    );
}
