import { calculateMaterialAmount, calculateMaterialQuantity } from '../Helpers/estimate';

export default class Hole {
    constructor(a){
        if(typeof a === 'object'){
            this.height = 1400
            this.width = a.width || 300
            // this.position = a.position || 60
            this.isHole = true
            this.isDoor = false
            this.isWindow = false
            this.padding = 100
            this.heightFromFloor = 0
            this.depth = 100
            this.depthIndent = 0
            this.id = ''
            this.estimate = [];

            this.objTitle = '';
            this.objComment = '';
            this.objImages = [];
        }
        else{
            this.height = 1400
            this.width = 300
            // this.position = 60
            this.isHole = true
            this.isDoor = false
            this.isWindow = false
            this.padding = 100
            this.heightFromFloor = 0
            this.depth = 100
            this.depthIndent = 0
            this.id = ''
            this.estimate = [];

            this.objTitle = '';
            this.objComment = '';
            this.objImages = [];
        }
    }
    setEstimate(estimate) {
        this.estimate = estimate.filter((est, index, self) => (
            index === self.findIndex((selfJob) => selfJob.id === est.id)
        )).map((estimate) => {
            return {
                id: estimate.id,
                externalId: estimate.externalId,
                group: estimate.group,
                stage: estimate.stage,
                price: estimate.price,
                code: estimate.code,
                name: estimate.name,
                cost: estimate.cost,
                unit: estimate.unit,
                object: estimate.object,
                volume: estimate.volume,
                amount: estimate.amount,
                materials: estimate.materials.map((material) => {
                    material.volume = estimate.volume;
                    material.quantity = calculateMaterialQuantity(estimate, material);
                    material.amount = calculateMaterialAmount(material);
                    return { ...material }
                })
            }
        });
    }
}
