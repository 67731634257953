import {applyMiddleware, combineReducers, createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../Sagas/rootSaga'
import materialReducer from './material'
import projectReducer from './project'
import modulesReducer from './modules'
import estimateReducer from './estimate'

const rootReducer = combineReducers({
    material: materialReducer,
    project: projectReducer,
    modules: modulesReducer,
    estimate: estimateReducer
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

export default store
