import { getImage } from './saveFile';
import { drawImage, drawText } from './primitives';
import { BIG_DIM_SIZE, DIM_SIZE, settingsDxf } from './index';
import { calculateLocation } from './modulesUtils';

export const drawModules = async (dxf, plan, modules) => {
    const saved = [];
    const images = [];

    if (settingsDxf.showModules) {
        for (const module of modules) {
            calculateLocation(plan, module, modules);
            const { angle, heightFromFloor, size, _position, locationInRooms } = module;
            const name = module.name.replaceAll('\"', ' ').replaceAll('&quot;', ' ');

            const angleRad = -angle;

            const width = size.width / 2;
            const height = size.height / 2;

            const pivotX = -module.size.pivot.x;
            const pivotY = size.height - module.size.pivot.y;

            const rotatedX = pivotX * Math.cos(angleRad) - pivotY * Math.sin(angleRad);
            const rotatedY = pivotX * Math.sin(angleRad) + pivotY * Math.cos(angleRad);

            const centerX = _position.x + rotatedX;
            const centerY = -_position.y - rotatedY;

            const img = drawImage(dxf, { name, width, height, angle: angleRad * 180 / Math.PI, size, x: centerX, y: centerY });

            let offset = 0;
            if (locationInRooms?.up) {
                drawText(
                    dxf,
                    { x: _position.x, y: _position.y + height + offset },
                    `Сверху=${locationInRooms?.up}`,
                    { size: DIM_SIZE }
                );
                offset += BIG_DIM_SIZE;
            }
            if (locationInRooms?.right) {
                drawText(
                    dxf,
                    { x: _position.x, y: _position.y + height + offset },
                    `Справа=${locationInRooms?.right}`,
                    { size: DIM_SIZE }
                );
                offset += BIG_DIM_SIZE;
            }
            if (locationInRooms?.bottom) {
                drawText(
                    dxf,
                    { x: _position.x, y: _position.y + height + offset },
                    `Снизу=${locationInRooms?.bottom}`,
                    { size: DIM_SIZE }
                );
                offset += BIG_DIM_SIZE;
            }
            if (locationInRooms?.left) {
                drawText(
                    dxf,
                    { x: _position.x, y: _position.y + height + offset },
                    `Слева=${locationInRooms?.left}`,
                    { size: DIM_SIZE }
                );
                offset += BIG_DIM_SIZE;
            }
            if (size?.sizeX) {
                drawText(
                    dxf,
                    { x: _position.x, y: _position.y + height + offset },
                    `Width=${size?.sizeX}`,
                    { size: DIM_SIZE }
                );
                offset += BIG_DIM_SIZE;
            }
            if (size?.sizeY) {
                drawText(
                    dxf,
                    { x: _position.x, y: _position.y + height + offset },
                    `Height=${size?.sizeY}`,
                    { size: DIM_SIZE }
                );
                offset += BIG_DIM_SIZE;
            }
            if (size?.sizeZ) {
                drawText(
                    dxf,
                    { x: _position.x, y: _position.y + height + offset },
                    `Depth=${size?.sizeZ}`,
                    { size: DIM_SIZE }
                );
                offset += BIG_DIM_SIZE;
            }
            if (heightFromFloor && heightFromFloor !== 0) {
                drawText(
                    dxf,
                    { x: _position.x, y: _position.y + height + offset },
                    `h=${heightFromFloor}`,
                    { size: DIM_SIZE }
                );
                offset += BIG_DIM_SIZE;
            }
            if (!saved.includes(name)) {
                const blob = await getImage(module, `${name}.png`);
                saved.push(name);
                images.push({ ...img, name, blob });
            }
        }
    }

    return images;
}
