import { calculateMaterialAmount, calculateMaterialQuantity } from '../Helpers/estimate';

class Cycle{
    constructor(points=[], links=[], type = ''){
        this._points = points;
        this._links = links;
        this.isCycle = true;
        this.isFloor = type==='floor';
        this.isFigure = type==='figure';
        this.height = 0;
        this.heightFromFloor = 0;
        this.material = window.materials.floor[0].clone();
        this.showModule = true;
        this.rgbColor = false;
        this.rgb = {
            r: '255',
            g: '255',
            b: '255',
            a: '1',
        };
        this.rotation = 0;
        this.objTitle = '';
        this.objComment = '';
        this.objImages = [];
        this.estimate = [];
    }

    get length(){
        return this._points.length;
    }

    set points(points){
        this._points = points;
    }

    get points(){
        return this._points;
    }

    set links(links){
        this._links = links;
    }

    get links(){
        return this._links;
    }

    equals(cycle) {

        const links1 = this._links;
        const links2 = cycle._links;

        if (links1.length === links2.length) {
            let cnt = 0;
            links1.forEach(l1=>{
                links2.forEach(l2=>{
                    if (l1 === l2) {
                        cnt++;
                    }
                });
            });
            if (cnt === links1.length) {
                return true;
            }
        }

        return false;
    };

    setEstimate(estimate, level) {
        const otherLevelEstimate = this.estimate.filter((element) => element.level !== level);
        this.estimate = estimate.filter((est, index, self) => (
            index === self.findIndex((selfJob) => selfJob.id === est.id)
        )).map((estimate) => {
            return {
                id: estimate.id,
                externalId: estimate.externalId,
                group: estimate.group,
                stage: estimate.stage,
                price: estimate.price,
                code: estimate.code,
                name: estimate.name,
                cost: estimate.cost,
                unit: estimate.unit,
                object: estimate.object,
                volume: estimate.volume,
                amount: estimate.amount,
                level,
                materials: estimate.materials.map((material) => {
                    material.volume = estimate.volume;
                    material.quantity = calculateMaterialQuantity(estimate, material);
                    material.amount = calculateMaterialAmount(material);
                    return { ...material }
                })
            }
        });
        this.estimate.push(...otherLevelEstimate);
    }
}
export default Cycle;
