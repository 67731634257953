import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PlanEditor from "./Components/PlanEditor";
import Control from "./Components/Controll";
import ThreeScene from "./Components/ThreeScene";
import Preloader from "./Components/Preloader";
import { actionsState as projectState } from "./Redux/project";
import ShowHelp from "./Components/ShowHelp";
import {
    sendGetCollada,
    sendGetGLTF,
    sendGetHugeImage, sendGetOBJ,
    sendRedrawSimpleEvent,
    sendUnselectEvent
} from "./Helpers/Events";
import HugeImageInfo from "./Components/hugeImageInfo";
import SaveInfo from "./Components/SaveInfo";
import ErrorInfo from "./Components/ErrorInfo";
import PagesInfo from "./Components/PagesInfo";
import VersionsInfo from "./Components/Modals/VersionsInfo";
import Materials from "./Components/Materials";
import ModulesList from "./Components/ModulesList";
import VariantsInfo from './Components/Modals/VariantsInfo';
import VariantCreation from './Components/Modals/VariantCreation';
import ConfirmPublicVariant from './Components/Modals/ConfirmPublicVariant';
import Modules from "./Components/Modules";
import { useInterval } from "react-interval-hook";
import { MainErrorComponent } from './Components/Error/MainErrorComponent';
import { EstimateResult } from './Components/Features/SideMenu';
import { TariffSelect } from './Components/Features/Menu';
import { ConnectionMenu } from './Components/BluetoothRoulette';
import { exportDxf, ExportSettings } from './Components/Features/Export';

function App() {
    const dispatch = useDispatch();
    const applicationCrashError = useSelector(store => store.project.applicationCrashError);
    window.onerror = function (message, url, line, col, error) {
        dispatch(projectState.setApplicationCrashError(error))
    };

    const [planeEdit, plan, modules] = useSelector(state => ([
        state.project.planeEdit,
        state.project.plan,
        state.modules.modules
    ]));

    const tariffs = useSelector(state => state.estimate.tariffs);
    const currentTariff = useSelector(state => state.estimate.currentTariff);

    const tool = useSelector(state => state.project.tool);
    const hugeImage = useSelector(state => state.project.hugeImage);
    const editMode = useSelector(state => state.project.editMode);
    const modal = useSelector(store => store.project.modal);
    const menuOpened = useSelector(store => store.project.menuOpened);
    const tokenRID = useSelector(store => store.project.saveResult.tokenRID);
    const tokenROLE = useSelector(state => state.project.saveResult.tokenROLE);

    const enableViewVariants = useSelector(state => state.project.userSetup.enableViewVariants);
    const enableViewSaves = useSelector(state => state.project.userSetup.enableViewSaves);
    const enableCreateVariant = useSelector(state => state.project.userSetup.enableCreateVariant);
    const enableSaveProject = useSelector(state => state.project.userSetup.enableSaveProject);
    const enablePublishing = useSelector(state => state.project.userSetup.enablePublishing);
    const enableAddModules = useSelector(state => state.project.userSetup.enableAddModules);
    const enableViewMenu = useSelector(state => state.project.userSetup.enableViewMenu);

    const sections = useSelector(store => store.modules.sections);
    const [activeSection, setActiveSection] = useState(sections.length > 0 ? sections[0] : null);
    const helpLinks = useSelector(store => store.modules.helpLinks);

    const loadCompleted = useSelector(store => store.project.loadCompleted);
    const errorCount = useSelector(store => store.project.errorCount);
    const variants = useSelector(store => store.project.variants);
    const cur_variant = variants.filter(variant => variant.CUR_VARIANT)[0];

    const [autoSave, setAutoSave] = useState(false);
    const [versionsModalAutoSave, setVersionsModalAutoSave] = useState();
    const [showExportSettings, setShowExportSettings] = useState(false);

    const headerRef = useRef(null);

    const { start: startCheckBlocker, stop: stopCheckBlocker } = useInterval(() => {
        if (errorCount >= 3) {
            dispatch(projectState.setModal('error'));
        }

        if (!loadCompleted.isCompleted) {
            const currentTime = new Date().getTime();
            const startBlockerTime = loadCompleted.startTime.getTime();

            if (currentTime - startBlockerTime > 60000) {
                dispatch({
                    type: 'LOAD_COMPLETED',
                    value: {
                        isCompleted: true,
                        startTime: null
                    }
                })
            }
        }

    }, 60000,
        {
            autoStart: false,
            immediate: false,
            selfCorrecting: true,
            onFinish: () => { },
        });

    const { start, stop } = useInterval(() => {
        console.info('AUTOSAVE_PROJECT', new Date());
        dispatch({ type: 'AUTOSAVE_PROJECT' });

    }, 15000,
        {
            autoStart: false,
            immediate: false,
            selfCorrecting: true,
            onFinish: () => { },
        });

    const handlerSaveInterval = (launch, msg) => {
        msg && console.info(msg);
        if (launch) {
            start();
        } else {
            stop();
        }
    }

    const toggleAutoSave = () => {
        localStorage.setItem('autoSave', !autoSave);
        setAutoSave(!autoSave);
        if (!autoSave) {
            handlerSaveInterval(true, 'Автосохранение включено пользователем.');
        } else {
            handlerSaveInterval(false, 'Автосохранение отключено пользователем.');
        }
    }
    const setImageBG = () => {
        dispatch(projectState.setMenuOpened(0));
        dispatch(projectState.setModal('ImageBG'));
    }

    const setMaterialsModal = () => {
        dispatch(projectState.setMenuOpened(0));
        dispatch(projectState.setModal('materials'));
    }

    const setModulesListModal = () => {
        dispatch(projectState.setMenuOpened(0));
        dispatch(projectState.setModal('modulesList'));
    }

    const setModulesModal = (actSec) => {
        setActiveSection(actSec);
        dispatch(projectState.setModal('modules'));
    }

    const setVersionsModal = (isAutoSave) => {
        setVersionsModalAutoSave(isAutoSave);
        dispatch(projectState.setModal('versions'));
    }

    const setVariantsModal = () => {
        dispatch(projectState.setModal('variants'));
    }

    const setVariantCreationModal = () => {
        dispatch(projectState.setModal('variantCreation'));
    }

    const setPublishModal = () => {
        dispatch(projectState.setModal('publish'));
    }

    const setProjectsModal = () => {
        dispatch(projectState.setModal('projects'));
    }

    const setSettingsModal = () => {
        dispatch(projectState.setModal('settings'));
    }

    const setShowHelpModal = () => {
        dispatch(projectState.setModal('showHelp'));
    }

    const toggleClass = () => {
        const nMenuOpened = (menuOpened) ? 0 : 1;
        dispatch(projectState.setMenuOpened(nMenuOpened));
    };

    const toggleEditMode = () => {
        dispatch(projectState.setTool('move'))

        dispatch(projectState.setModal(''));
        dispatch(projectState.setEditMode(!editMode));
        if (editMode) {
            sendUnselectEvent(document.querySelector('#plan'));
            sendRedrawSimpleEvent(document.querySelector('#plan'));
        }
    }

    const getHugeImage = () => {
        if (planeEdit) {
            dispatch(projectState.setEditMode(false));
            dispatch(projectState.showPlaneEdit(false));

            dispatch(projectState.setMenuOpened(0));
            dispatch(projectState.setModal('hugeImage'));

            dispatch(projectState.addPreloader())
            dispatch(projectState.setGetFileType('getHugeImage'));
        } else {

            dispatch(projectState.setMenuOpened(0));
            dispatch(projectState.setModal('hugeImage'));

            dispatch(projectState.addPreloader())

            sendGetHugeImage(document.querySelector('#scene'));
        }
    }

    const getCollada = () => {
        if (planeEdit) {
            dispatch(projectState.setEditMode(false));
            dispatch(projectState.showPlaneEdit(false));
            dispatch(projectState.addPreloader())
            dispatch(projectState.setGetFileType('getCollada'));
        } else {
            sendGetCollada(document.querySelector('#scene'));
        }
    }

    const getGLTF = () => {
        if (planeEdit) {
            dispatch(projectState.setEditMode(false));
            dispatch(projectState.showPlaneEdit(false));
            dispatch(projectState.addPreloader())
            dispatch(projectState.setGetFileType('getGLTF'));
        } else {
            dispatch(projectState.addPreloader())
            sendGetGLTF(document.querySelector('#scene'));
        }
    }

    const getDXF = () => {
        dispatch(projectState.setEditMode(false));
        dispatch(projectState.addPreloader())
        exportDxf(plan, modules, tokenRID).then(() => dispatch(projectState.decPreloader()));
    }

    const getOBJ = () => {
        if (planeEdit) {
            dispatch(projectState.setEditMode(false));
            dispatch(projectState.showPlaneEdit(false));
            dispatch(projectState.addPreloader())
            dispatch(projectState.setGetFileType('getOBJ'));
        } else {
            dispatch(projectState.addPreloader())
            sendGetOBJ(document.querySelector('#scene'));
        }
    }

    const setStartAutoSave = () => {
        if (localStorage.autoSave) {
            return (localStorage.getItem('autoSave') === 'true')
        }
        if (process.env.NODE_ENV === 'development') {
            console.info('Авто сохранение по умолчанию отключено, режим разработчика.');
            return false
        }
        return true
    }

    useEffect(() => {
        plan.setPlanMode(tool);
    }, [tool])

    useEffect(() => {
        setAutoSave(setStartAutoSave());
        dispatch({ type: 'GET_DATA' });
    }, []);

    useEffect(() => {
        const handleWheel = (e) => {
            e.preventDefault();
            if (headerRef.current) {
                headerRef.current.scrollLeft += e.deltaY;
            }
        };

        const headerElement = headerRef.current;
        if (headerElement) {
            headerElement.addEventListener('wheel', handleWheel, { passive: false });
        }

        return () => {
            if (headerElement) {
                headerElement.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    useEffect(() => {
        localStorage[`openPages-${tokenRID}`] = Date.now();

        const checkOpenedPages = (e) => {
            if (e.key === `openPages-${tokenRID}`) localStorage[`pageAvailable-${tokenRID}`] = Date.now();
            if (e.key === `pageAvailable-${tokenRID}`) dispatch(projectState.setModal('pages'));
        }
        window.addEventListener('storage', checkOpenedPages, false);

        const enableAutoSave = () => setAutoSave(setStartAutoSave());;
        window.addEventListener('focus', enableAutoSave);

        const disableAutoSave = () => {
            loadCompleted.isCompleted && dispatch({ type: 'AUTOSAVE_PROJECT', modal: false });
            setAutoSave(false);
        }
        window.addEventListener('blur', disableAutoSave);

        return () => {
            window.removeEventListener('focus', enableAutoSave);
            window.removeEventListener('blur', disableAutoSave);
            window.removeEventListener('storage', checkOpenedPages, false);
        }
    }, [tokenRID, loadCompleted]);

    useEffect(() => {
        autoSave ? startCheckBlocker() : stopCheckBlocker();
    }, [autoSave])

    useEffect(() => {
        if (loadCompleted.isCompleted) {
            if (autoSave) {
                handlerSaveInterval(true);
            } else {
                handlerSaveInterval(false);
            }
        } else {
            if (autoSave) {
                handlerSaveInterval(false);
            }
        }
    }, [loadCompleted]);

    if (applicationCrashError) {
        return <MainErrorComponent error={applicationCrashError} />
    }
    return (
        <div className="App">
            <div className={menuOpened ? 'blur-container opened' : 'blur-container'}>
                <div>
                    {planeEdit === false && <ThreeScene plan={plan} />}
                    {planeEdit === true && <PlanEditor plan={plan} modules={modules} />}
                </div>
                <div className="conf-header-bg"></div>
                <div
                    className="conf-header"
                    ref={headerRef}
                >
                    {enableViewMenu && <div id="header-conf-menu-btn">
                        <button className={menuOpened === 1 ? 'mobile-menu-btn opened' : 'mobile-menu-btn'}
                            onClick={() => { toggleClass() }}>
                            <div className="mobile-menu-icon"></div>
                        </button>
                    </div>}
                    <img className="header-logo" src={window.confComponentUrl + "assets/logo.svg"} alt="" />
                    <div className="header-project-id">{tokenRID}</div>
                    <Control />
                    {/*
            <div className="project-menu">
                {planeEdit === true && <div>
                    <a href="#" onClick={()=>{setProjectsModal()}}>Открыть проект</a>|
                    <a href="#" onClick={()=>{setModulesModal()}}>Каталог мебели</a>|
                    <a href="#" onClick={()=>{setMaterialsModal()}}>Отделка/материалы</a>
                </div>}
            </div>
            <div className="top-menu">
                <a href="#" onClick={()=>{setSettingsModal()}}>Настройки</a>|
                <a href="#" onClick={()=>{setShowHelpModal()}}>Обратная связь</a>
                <a href="/" className="top-menu-back">Назад</a>
            </div>*/}
                    {/*planeEdit === true && (<div
                className={editMode?'control-hint active':'control-hint'}
                onClick={()=>{toggleEditMode()}}>
                <span className={!is_touch_device()?'active':''}>Для прекращения рисования нажмите правую кнопку мыши</span>
                <span className={is_touch_device()?'active':''}>Нажмите сюда для прекращения рисования</span>
            </div>)*/}
                </div>
                {modal === 'notoken' && <div className="modalInfo">
                    <div className="modalInfo-inner">
                        <img className="modalInfo-logo" src={window.confComponentUrl + "assets/logo.svg"} alt="" />
                        <h2>Нет хеша</h2>
                        <p>Не указан код проекта.</p>
                    </div>
                </div>}
                {modal === 'nodecodetoken' && <div className="modalInfo">
                    <div className="modalInfo-inner">
                        <img className="modalInfo-logo" src={window.confComponentUrl + "assets/logo.svg"} alt="" />
                        <h2>Хеш не декодируется</h2>
                        <p>Не удалось дешифровать код проекта.</p>
                    </div>
                </div>}
                {modal === 'errorload' && <div className="modalInfo">
                    <div className="modalInfo-inner">
                        <img className="modalInfo-logo" src={window.confComponentUrl + "assets/logo.svg"} alt="" />
                        <h2>Ошибка загрузки</h2>
                        <p>
                            Не удалось загрузить проект.
                            Попробуйте перезагрузить страницу.
                        </p>
                    </div>
                </div>}
                {/*modal === 'hugeImage' && <div><div className='modal-bg' onClick={()=>dispatch(ProjectStore.setModal(''))}></div><div className='modal-info'>
                <ModalWrapper
                    title={"Рендер крупный"}
                    onClose={()=>dispatch(ProjectStore.setModal(''))}
                >
                    <div className={"modal-info-block"}>
                        <a href={hugeImage} download="conf3d_4000x3000.png" className="hugeImage"><img src={hugeImage} /></a>
                    </div>
                </ModalWrapper>
            </div></div>*/}
                {modal === 'hugeImage' && <HugeImageInfo />}
                {modal === 'showHelp' && <ShowHelp />}
            </div>

            <div className={menuOpened ? 'MainMenu opened' : 'MainMenu'}>
                <div className="conf-header">
                    <div id="header-conf-menu-btn">
                        <button className={menuOpened === 1 ? 'mobile-menu-btn opened' : 'mobile-menu-btn'}
                            onClick={() => { toggleClass() }}>
                            <div className="mobile-menu-icon"></div>
                        </button>
                    </div>
                </div>
                {menuOpened &&
                    <div className="MainMenu-content">
                        <div>
                            <div className="MainMenu-row">
                                <div className="MainMenu-bl">
                                    <div className="MainMenu-bl-title">Проект</div>
                                    <ul>
                                        <li onClick={() => { if (enableSaveProject) dispatch({ type: 'SAVE_PROJECT' }) }}>Сохранить</li>
                                        <li onClick={() => { if (enableViewSaves) setVersionsModal(false) }}>История изменений</li>
                                        <li onClick={() => { if (enableViewSaves) setVersionsModal(true) }}>История автосохранений</li>
                                        <li
                                            className={autoSave ? 'control__item_onOff control__item_onOff_active' : 'control__item_onOff'}
                                            style={{ justifyContent: "start", height: '38px' }}
                                            onClick={toggleAutoSave}
                                        >
                                            <span>Автосохранение</span>
                                            <span></span>
                                        </li>
                                        <li onClick={() => { if (enableViewVariants) setVariantsModal() }}>Варианты проекта</li>
                                        <li onClick={() => { if (enableCreateVariant) setVariantCreationModal() }}>Создать вариант проекта</li>
                                        {cur_variant?.PUBLISHED &&
                                            <li style={{ cursor: 'auto', color: '#59DA28' }}>Опубликован {cur_variant?.PUBLISHED_DATE}</li>
                                        }
                                        {!cur_variant?.PUBLISHED && enablePublishing &&
                                            <li onClick={() => setPublishModal()}>Опубликовать</li>
                                        }
                                        <li onClick={() => { getOBJ() }}>Экспорт проекта (.obj)</li>
                                        <li onClick={() => { getCollada() }}>Экспорт проекта (.dae)</li>
                                        <li onClick={() => { getGLTF() }}>Экспорт проекта (.glb)</li>
                                        <li onClick={() => { setShowExportSettings(true) }}>Экспорт проекта (.dxf)</li>
                                        <li onClick={() => { getHugeImage() }}>Рендер крупный (.png)</li>
                                    </ul>
                                </div>
                                {enableAddModules &&
                                    <>
                                        <div className="MainMenu-bl">
                                            <TariffSelect />
                                            <div className="MainMenu-bl-title">Помещение</div>
                                            <ul>
                                                <li onClick={() => { setImageBG() }}>Вспомогательное изображение плана</li>
                                                <li onClick={() => { setMaterialsModal() }}>Отделка/материалы</li>
                                                <li onClick={() => { setModulesListModal() }}>Объекты в проекте</li>
                                                {/*<li>Материал стен</li>*/}
                                            </ul>
                                            <div className="MainMenu-row">
                                                <div className="MainMenu-bl" style={{ marginTop: "38px" }}>
                                                    <div className="MainMenu-bl-title">Помощь</div>
                                                    <ul>
                                                        {helpLinks.map((link, index) => <li
                                                            key={index}
                                                        ><a target={'_blank'} href={link.VIDEO_LINK}>{link.NAME}</a></li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="MainMenu-bl">
                                            <div className="MainMenu-bl-title">Дополнительные объекты</div>
                                            <ul>
                                                {sections.map(s => <li
                                                    className={activeSection === s ? 'active' : ''}
                                                    key={s.ID}
                                                    onClick={() => setModulesModal(s)}
                                                >{s.NAME}</li>)}
                                            </ul>
                                        </div>
                                        <div className="MainMenu-bl">
                                            <div className="MainMenu-bl-title">Оборудование</div>
                                            <ConnectionMenu />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                        {/*
                <div className="MainMenu-row">
                    <div className="MainMenu-bl">
                        <div className="MainMenu-bl-title">Каталог</div>
                        <div className="MainMenu-row2">
                            <div className="MainMenu-bl2">
                                <div className="MainMenu-bl-title2">Категория каталога 1</div>
                                <ul>
                                    <li><a href="#">Подкатегория 1-1</a></li>
                                    <li>Подкатегория 1-2</li>
                                    <li>Подкатегория 1-3</li>
                                </ul>
                            </div>
                            <div className="MainMenu-bl2">
                                <div className="MainMenu-bl-title2">Категория каталога 2</div>
                                <ul>
                                    <li><a href="#">Подкатегория 1-1</a></li>
                                    <li>Подкатегория 1-2</li>
                                    <li>Подкатегория 1-3</li>
                                </ul>
                            </div>
                            <div className="MainMenu-bl2">
                                <div className="MainMenu-bl-title2">Категория каталога 3</div>
                                <ul>
                                    <li><a href="#">Подкатегория 1-1</a></li>
                                    <li>Подкатегория 1-2</li>
                                    <li>Подкатегория 1-3</li>
                                </ul>
                            </div>
                            <div className="MainMenu-bl2">
                                <div className="MainMenu-bl-title2">Категория каталога 4</div>
                                <ul>
                                    <li><a href="#">Подкатегория 1-1</a></li>
                                    <li>Подкатегория 1-2</li>
                                    <li>Подкатегория 1-3</li>
                                </ul>
                            </div>
                            <div className="MainMenu-bl2">
                                <div className="MainMenu-bl-title2">Категория каталога 5</div>
                                <ul>
                                    <li><a href="#">Подкатегория 1-1</a></li>
                                    <li>Подкатегория 1-2</li>
                                    <li>Подкатегория 1-3</li>
                                </ul>
                            </div>
                            <div className="MainMenu-bl2">
                                <div className="MainMenu-bl-title2">Категория каталога 6</div>
                                <ul>
                                    <li><a href="#">Подкатегория 1-1</a></li>
                                    <li>Подкатегория 1-2</li>
                                    <li>Подкатегория 1-3</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                */}
                    </div>}
            </div>

            {modal === 'publish' && <ConfirmPublicVariant />}
            {modal === 'variantCreation' && <VariantCreation />}
            {modal === 'versions' && <VersionsInfo isAutoSave={versionsModalAutoSave} />}
            {modal === 'variants' && <VariantsInfo />}
            {modal === 'materials' && <Materials />}
            {modal === 'save' && <SaveInfo />}
            {modal === 'error' && <ErrorInfo />}
            {modal === 'pages' && <PagesInfo />}
            {modal === 'modulesList' && <ModulesList />}
            {modal === 'estimateResult' && <EstimateResult />}
            {modal === 'modules' && <Modules
                activeSection={activeSection}
                setActiveSection={setActiveSection}
            />}
            <ExportSettings open={showExportSettings} onClose={() => setShowExportSettings(false)} onSubmit={getDXF} />
            <Preloader />
        </div>
    );
}

export default App;
