/**
 * Проверяет стену на вхождение в комнату.
 * @param {wall} wall
 * @param {cycle[]} cycles 
 * @returns возвращает комнату
 */
export const getWallCycle = (wall, cycles) => {
    for (let q = 0; cycles.length > q; q++) {
        for (let w = 0; cycles[q]._links.length > w; w++) {
            if (wall.mainLink === cycles[q]._links[w]) {
                return cycles[q];
            }
        }
    }
return null
}