import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { lengthBezierCurves } from '../../../../../Helpers/GeometryAlgoritm';

export const getSquare = (object, plan) => {
    if (object?.isHole || object?.isWindow || object?.isDoor) {
        const square = (object.height * object.width) / Math.pow(10, 6);
        return Math.round(square * 100) / 100;
    } else if (object?.isWall || object?.isLink) {
        const wallSquare = (object.mainLink.height * object.innerLink.length) / Math.pow(10, 6);
        const objectsSquare = object.objects.reduce((acc, curr) => {
            if (curr?.isHole || curr?.isWindow || curr?.isDoor) {
                acc += (curr.height * curr.width) / Math.pow(10, 6);
            }
            return acc;
        }, 0) || 0;
        let columnSquare = 0;
        if (object?.rightCols?.length || object?.leftCols?.length) {
            const calcColumnSquare = (col) => {
                if (plan?.columns?.[col]) {
                    const column = plan?.columns?.[col];
                    columnSquare += (column.width * column.height) / Math.pow(10, 6);
                }
            }
            object?.rightCols?.forEach(calcColumnSquare);
            object?.leftCols?.forEach(calcColumnSquare);
        }
        return Math.round((wallSquare - objectsSquare - columnSquare) * 100) / 100;
    }
    return Math.round((object?.square || 1) * 100) / 100;
}

export const getLength = (object, plan) => {
    if (object?.isWall || object?.isLink) {
        const length = object.isBezier ? lengthBezierCurves(object.inlineBezier) : object.innerLink.length;
        return Math.round((length / Math.pow(10, 3)) * 100) / 100;
    } else if (object?.isHole || object?.isWindow || object?.isDoor) {
        return Math.round(((object.height * 2 + object.width) / Math.pow(10, 3)) * 100) / 100;
    } else if (object?.isCycle) {
        const result = object._links.reduce((acc, cur) => {
            const currentWall = plan.bWalls.find((wall) => wall.mainLink === cur);
            if (currentWall) {
                acc += currentWall.isBezier ? lengthBezierCurves(currentWall.inlineBezier) : currentWall.innerLink.length;
                currentWall.objects.forEach((obj) => {
                    if (!obj.heightFromFloor || obj.heightFromFloor === 0) {
                        acc -= obj.width;
                    }
                })
            } else {
                acc += cur?.length || 0;
            }
            return acc;
        }, 0) || 0;
        return Math.round((result / Math.pow(10, 3)) * 100) / 100;
    }
    return 1;
}

export const useEstimate = ({ object }) => {
    const [estimateData, setEstimateData] = useState([]);

    const level = useSelector(state => state.project.level);
    const plan = useSelector(state => state.project.plan);
    const coefficient = useSelector((state) => state.estimate.coefficient);
    const allEstimate = useSelector((state) => state.estimate.allEstimate);
    const currentTariff = useSelector((state) => state.estimate.currentTariff);
    const objectsByTariff = useSelector((state) => state.estimate.objectsByTariff);

    const availableObjects = objectsByTariff[currentTariff.value];

    const estimate = useMemo(() => {
        if (object?.estimate && object?.estimate?.length) {
            if (object?.isCycle) {
                return object.estimate.filter((element) => element?.level === level);
            }
            return object.estimate;
        }
        return []
    }, [object, object?.estimate, level]);

    const setEstimate = useCallback((estimate) => {
        object.setEstimate(estimate, level);
    }, [object, level])

    const square = useMemo(() => {
       return getSquare(object, plan);
    }, [object, plan]);

    const length = useMemo(() => {
        return getLength(object, plan);
    }, [object, plan]);

    const amount = useMemo(() => {
        if (estimate && Array.isArray(estimate)) {
            return estimate.reduce((acc, prev) => {
                acc += Number(prev.amount);
                return acc;
            }, 0);
        }
        return 0;
    }, [estimate]);

    const getEstimateData = useCallback((obj) => {
        const length = getLength(obj, plan);
        const square = getSquare(obj, plan);

        const getInitialVolume = (unit) => {
            if (unit === 'шт.') return 1;
            if (unit === 'п.м.') return length;
            if (unit === 'кв.м.') return square;
            return 0;
        };

        const includedEstimate = obj?.estimate;
        return allEstimate.map((estimate) => {
            const addedEstimate = includedEstimate?.find((searchEstimate) => searchEstimate.id === estimate.id);
            if (addedEstimate) {
                return {
                    ...estimate,
                    ...addedEstimate,
                };
            }
            return {
                ...estimate,
                volume: String(getInitialVolume(estimate.unit, obj)),
                cost: Math.round(Number(estimate.cost * (coefficient || 1)) * 100) / 100,
                amount: Math.round(getInitialVolume(estimate.unit, obj) * (Math.round(Number(estimate.cost * (coefficient || 1)) * 100) / 100) * 100) / 100,
            }
        })
    }, [coefficient, allEstimate, plan]);

    useEffect(() => {
        setEstimateData(getEstimateData(object));
    }, [object, coefficient, allEstimate, getEstimateData, square, length]);

    return {
        estimate,
        setEstimate,
        availableObjects,
        estimateData,
        getEstimateData,
        currentTariff,
        square,
        length,
        amount
    }
}
