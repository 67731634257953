import Node from "../../../Classes/Node";
/**
 * Проверяет линии на продолжении друг друга
 * @param {{a:{x,y},b:{x,y}}} line1 
 * @param {{a:{x,y},b:{x,y}}} line2 
 * @returns Возвращает точку соединения, false - если точки нет
 */
export const getCommonPoint = (line1, line2) => {

    if (line1.a.x + line1.a.y === line2.a.x + line2.a.y
        || line1.a.x + line1.a.y === line2.b.x + line2.b.y) {
        return (new Node(line1.a.x, line1.a.y))
    }
    if (line1.b.x + line1.b.y === line2.a.x + line2.a.y
        || line1.b.x + line1.b.y === line2.b.x + line2.b.y) {
        return (new Node(line1.b.x, line1.b.y))
    }

    return false
}