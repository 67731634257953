import { Vector2 } from 'three';
import { getIntersection } from '../Helpers/functions';
import ObjectOnWall from './ObjectOnWall';
import LocalStorageParams from './LocalStorageParams';
import { getTypeObjectOnWall } from '../Components/Utils';
import { calculateMaterialAmount, calculateMaterialQuantity } from '../Helpers/estimate';

class Column {
    constructor(point) {
        this.isColumn = true;
        this.isShow = true;
        this.type = 'floor';
        this.isFixed = false;
        this.maxHeight = 0;
        this.x = point.x;
        this.showModule = true;
        this.y = point.y;
        this.angle = 0;
        this.width = 0;
        this.depth = 0;
        this.height = 2700;
        this.padding = 1;
        this.points = [];
        this.parentWallID = -1;
        this.parentWallSide = "";
        this.leftSide = window.materials.wall[0];
        this.leftSideRGB = {
            rgbColor: false,
            rgb: {
                r: "255",
                g: "255",
                b: "255",
                a: "1",
            },
        };
        this.topSide = window.materials.wall[0];
        this.topSideRGB = {
            rgbColor: false,
            rgb: {
                r: "255",
                g: "255",
                b: "255",
                a: "1",
            },
        };
        this.rightSide = window.materials.wall[0];
        this.rightSideRGB = {
            rgbColor: false,
            rgb: {
                r: "255",
                g: "255",
                b: "255",
                a: "1",
            },
        };
        this.bottomSide = window.materials.wall[0];
        this.bottomSideRGB = {
            rgbColor: false,
            rgb: {
                r: "255",
                g: "255",
                b: "255",
                a: "1",
            },
        };

        this.objects = [];
        this.estimate = [];

        this.objTitle = "";
        this.objComment = "";
        this.objImages = [];
    }
    setParentWallID(wallID, wallSide) {
        this.parentWallID = wallID;
        this.parentWallSide = wallSide;
    }
    isHoverRotateControl(point, zoom) {
        const position = new Vector2(this.x, this.y);
        const rs = 12 / zoom;

        const vp = new Vector2(point.x, point.y);
        const vc = new Vector2(
            position.x - this.width / 2 - rs,
            position.y - this.depth / 2 - rs
        ).rotateAround(position, -this.angle);

        return vc.sub(vp).length() < rs * 1.5;
    }
    isHoverSizingControl(point, zoom) {
        const result = { status: false, direction: "" };

        const position = new Vector2(this.x, this.y);
        const rs = 12 / zoom;

        const vp = new Vector2(point.x, point.y);
        const vcT = new Vector2(position.x, position.y - this.depth / 2 - rs * 1.5).rotateAround(position, -this.angle);
        const vcB = new Vector2(position.x, position.y + this.depth / 2 + rs * 1.5).rotateAround(position, -this.angle);
        const vcR = new Vector2(position.x + this.width / 2 + rs * 1.5, position.y).rotateAround(position, -this.angle);
        const vcL = new Vector2(position.x - this.width / 2 - rs * 1.5, position.y).rotateAround(position, -this.angle);

        if (vcT.sub(vp).length() < rs * 1.5) {
            result.status = true;
            result.direction = "top";
        } else if (vcB.sub(vp).length() < rs * 1.5) {
            result.status = true;
            result.direction = "bottom";
        } else if (vcR.sub(vp).length() < rs * 1.5) {
            result.status = true;
            result.direction = "right";
        } else if (vcL.sub(vp).length() < rs * 1.5) {
            result.status = true;
            result.direction = "left";
        }
        return result;
    }
    lookTo(point, clickMousePoint, startAngle) {
        const position = new Vector2(this.x, this.y);

        let angle1 = point.clone().sub(position).angle();
        let angle2 = clickMousePoint.clone().sub(position).angle();

        let diff = angle1 - angle2;
        let angle = startAngle - diff;
        let angleD = angle * (360 / (Math.PI * 2));
        angleD = Math.round(angleD / 5) * 5;
        angle = angleD / (180 / Math.PI);
        this.angle = angle;
    }
    isHoverObject(point) {
        const leftTopX = this.x - this.width / 2;
        const leftTopY = this.y - this.depth / 2;

        const rightBottomX = this.x + this.width / 2;
        const rightBottomY = this.y + this.depth / 2;

        return point.x >= leftTopX && point.x <= rightBottomX &&
            point.y >= leftTopY && point.y <= rightBottomY;
    }
    canAddObject(obj, point) {
        const result = {
            dist: -1,
            left: 0,
            right: 0,
        };

        let width, angelFix;
        if (this.width > this.depth) {
            // horizontal
            width = this.width;
            angelFix = 0;
        } else if (this.depth > this.width) {
            // vertical
            width = this.depth;
            angelFix = 90 * (Math.PI / 180);
        }

        if (obj && width > 10) {
            const pointA = new Vector2(this.x, this.y);
            const pointB = new Vector2(this.x, this.y);
            const v = new Vector2(this.x, this.y)
                .normalize()
                .rotateAround(
                    new Vector2(0, 0),
                    -this.angle - new Vector2(this.x, this.y).angle() - angelFix
                )
                .setLength(width / 2);

            pointA.x = pointA.x + v.x;
            pointA.y = pointA.y + v.y;
            pointB.x = pointB.x - v.x;
            pointB.y = pointB.y - v.y;

            const pointC = new Vector2(point.x, point.y);
            const pointD = new Vector2(point.x, point.y);
            const v2 = v
                .clone()
                .normalize()
                .rotateAround(new Vector2(0, 0), -(90 * (Math.PI / 180)))
                .setLength(100000000);
            pointC.x = pointC.x + v2.x;
            pointC.y = pointC.y + v2.y;
            pointD.x = pointD.x - v2.x;
            pointD.y = pointD.y - v2.y;

            const crossPoint = getIntersection(
                { x: pointA, y: pointB },
                { x: pointC, y: pointD }
            );

            let distance = Math.round(pointA.distanceTo(crossPoint) - obj.width / 2);
            if (distance + obj.width / 2 > width - obj.width / 2) {
                distance = width - obj.width;
            } else if (distance < 0 && distance >= -obj.width / 2) {
                distance = 0;
            }

            result.dist = distance;
            result.left = Math.round(distance * 10) / 10;
            result.right = Math.round((width - distance - obj.width) * 10) / 10;

            if (this.objects.length > 0) {
                this.objects.forEach((_obj) => {
                    const pointLeft = distance;
                    const pointRight = distance + obj.width;

                    if (_obj.pos - obj.width > result.dist) {
                        result.right = Math.round((_obj.pos - obj.width - result.dist) * 10) / 10;
                    }
                    if (
                        _obj.pos < result.dist - _obj.width &&
                        result.left > result.dist - _obj.width - _obj.pos
                    ) {
                        result.left = Math.round((result.dist - _obj.width - _obj.pos) * 10) / 10;
                    }
                    if (
                        (pointLeft > _obj.pos && pointLeft < _obj.pos + _obj.width) ||
                        (pointRight > _obj.pos && pointRight < _obj.pos + _obj.width) ||
                        (pointLeft < _obj.pos && pointRight > _obj.pos + _obj.width)
                    ) {
                        result.dist = -1;
                    }
                    if (pointLeft === _obj.pos + _obj.width) {
                        result.left = 0;
                    }
                    if (pointRight === _obj.pos) {
                        result.right = 0;
                    }
                });
            }
        }
        return result;
    }
    addObject(side) {
        const localStorageParams = new LocalStorageParams();
        const obj = new ObjectOnWall();
        obj.side = side;

        let pos = -1
        let start = 0;
        let end = side === 'top' || side === 'bottom' ? this.width : this.depth;
        let length = end - start;
        const needWidth = obj.width + (obj.padding * 2);

        const objects = [...this.objects].filter((object) => object.side === side).sort((a, b) => a.pos - b.pos);

        for (let i = 0; i < objects.length; i++) {
            const _obj = objects[i];
            length = _obj.pos - start;
            if (length >= needWidth) {
                pos = start + obj.padding;
                break;
            }
            if (i < objects.length - 1) {
                length = objects[i + 1].pos - (_obj.pos + _obj.width);
                if (length >= needWidth) {
                    pos = _obj.pos + _obj.width + obj.padding;
                    break;
                }
            } else {
                length = end - (_obj.pos + _obj.width);
                if (length >= needWidth) {
                    pos = _obj.pos + _obj.width + obj.padding;
                    break;
                }
            }
            start = _obj.pos + _obj.width;
        }
        if (pos === -1 && length >= needWidth) {
            pos = start + obj.padding;
        }

        obj.pos = pos;
        obj.len1 = obj.pos;
        obj.len2 = Math.floor(end - obj.width - obj.pos);

        const maxDepth = (side === 'top' || side === 'bottom') ? this.depth : this.width;
        obj.depth = maxDepth < 100 ? maxDepth : 100;

        if (localStorageParams.getParams(getTypeObjectOnWall(obj))) {
            const { width, height, heightFromFloor } = localStorageParams.getParams(getTypeObjectOnWall(obj));
            if (width) obj.width = width;
            if (height) obj.height = height;
            if (heightFromFloor) obj.heightFromFloor = heightFromFloor;
        }

        this.objects.push(obj);
        this.setID();
        this.setOrdinalNumber('isElectricSocket');
        return this.objects;
    }
    removeSideObjects(side) {
        this.objects = this.objects.filter((object) => object.side !== side);
    }
    removeObject(column) {
        let filter;
        if (column.isElectricSocket === true) {
            filter = 'isElectricSocket';
        }
        if (column.isSwitch === true) {
            filter = 'isSwitch';
        }
        if (column.isHeatingBattery === true) {
            filter = 'isHeatingBattery';
        }
        if (column.isElectricPanel === true) {
            filter = 'isElectricPanel';
        }
        if (column.isRedCube === true) {
            filter = 'isRedCube';
        }
        if (column.isCylinder === true) {
            filter = 'isCylinder';
        }
        this.objects = this.objects.filter((obj, index) => index !== column.id);
        this.setID();
        this.setOrdinalNumber(filter);
    }

    resizeObjects() {
        let width;
        if (this.width > this.depth) {
            width = this.width;
        } else if (this.depth > this.width) {
            width = this.depth;
        }

        this.objects = this.objects.sort((a, b) =>
            a.pos < b.pos ? 1 : b.pos < a.pos ? -1 : 0
        );

        let prev = null;

        this.objects.forEach((_obj, index) => {
            const next = this.objects[index + 1];
            if (next) {
                _obj.left = Math.round((_obj.pos - next.width - next.pos) * 10) / 10;
            } else {
                _obj.left = Math.round(_obj.pos * 10) / 10;
            }
            if (!prev) {
                _obj.right = Math.round((width - (_obj.pos + _obj.width)) * 10) / 10;
            }
            prev = _obj;
        });
    }
    clone() {
        const newColumn = new Column(new Vector2(this.x + 10, this.y + 10));
        newColumn.width = this.width;
        newColumn.depth = this.depth;
        newColumn.height = this.height;
        newColumn.points = this.points;
        newColumn.rightSide = this.rightSide;
        newColumn.rightSideRGB = {
            rgbColor: this.rightSideRGB.rgbColor,
            rgb: {
                r: this.rightSideRGB.rgb.r,
                g: this.rightSideRGB.rgb.g,
                b: this.rightSideRGB.rgb.b,
                a: this.rightSideRGB.rgb.a,
            },
        };
        newColumn.objects = this.objects;
        newColumn.objTitle = this.objTitle;
        newColumn.objComment = this.objComment;
        newColumn.objImages = this.objImages;
        return newColumn;
    }
    setID() {
        this.objects.forEach((object, i) => {
            object.id = i;
        })
    }
    setOrdinalNumber(filter) {
        this.objects.filter(object => object[filter] === true).forEach((object, i) => {
            const maxDepth = (object?.side === 'top' || object?.side === 'bottom') ? this.depth : this.width;
            object.depth = maxDepth < 100 ? maxDepth : 100;
            object.ordinalNumber = i + 1;
        })
    }
    setEstimate(estimate) {
        this.estimate = estimate.filter((est, index, self) => (
            index === self.findIndex((selfJob) => selfJob.id === est.id)
        )).map((estimate) => {
            return {
                id: estimate.id,
                externalId: estimate.externalId,
                group: estimate.group,
                stage: estimate.stage,
                price: estimate.price,
                code: estimate.code,
                name: estimate.name,
                cost: estimate.cost,
                unit: estimate.unit,
                object: estimate.object,
                volume: estimate.volume,
                amount: estimate.amount,
                materials: estimate.materials.map((material) => {
                    material.volume = estimate.volume;
                    material.quantity = calculateMaterialQuantity(estimate, material);
                    material.amount = calculateMaterialAmount(material);
                    return { ...material }
                })
            }
        });
    }
}

export default Column;
