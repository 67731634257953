import React, { Suspense, useEffect, useRef, useState } from 'react';
import * as THREE from "three";
import ModalWrapper from "./ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { actionsState as modulesState } from "../Redux/modules";
import { actionsState as projectState } from "../Redux/project";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import MaterialSelect from "./MaterialSelect";
import VariantDropBox from "./widgets/VariantDropBox";
import {
    sendRedrawEvent,
    sendRedrawSimpleEvent,
    sendUnselectEvent
} from "../Helpers/Events";
import SizeBlock from "./widgets/SizeBlock";
import ColorRBG from "./widgets/ColorRBG";
import { convertQuotes } from './Utils';

let camera, scene, mouse = new THREE.Vector2(), controls,
    ambientLight, spotLight, spotLight2,
    renderer, raycaster, composer, rp, effectFXAA, saoPass, clock;

const ModuleInfo = ({ module, changeModule }) => {
    const [changeState, setChangeState] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (scene) {
            scene.traverse(obj => {
                if (obj.isGroup && obj.userData.type === 'MODUL') {
                    const parent = obj.parent;
                    parent.remove(obj);
                    parent.add(module.model);
                }
            });
        }
    }, [module]);

    // console.log('module.model',module.model)

    const planeEdit = useSelector(state => state.project.planeEdit);
    const canvasSelector = (planeEdit) ? '#plan' : '#scene';

    const canvas = useRef();

    const [materialCorp, setMaterialCorp] = useState(module.corp);
    const [materialFace, setMaterialFace] = useState(module.face);
    const [materialCloth, setMaterialCloth] = useState(module.cloth);
    const [option, setOption] = useState(module.optionStatus);

    const [materialCorpWnd, setMaterialCorpWnd] = useState(false);
    const [materialFaceWnd, setMaterialFaceWnd] = useState(false);
    const [materialClothWnd, setMaterialClothWnd] = useState(false);

    const [heightFromFloor, setHeightFromFloor] = useState(module.heightFromFloor)
    const [scaleX, setScaleX] = useState(module.size.sizeX)
    const [scaleY, setScaleY] = useState(module.size.sizeY)
    const [scaleZ, setScaleZ] = useState(module.size.sizeZ)

    const [rgb, setRgb] = useState(module.rgb);
    const [rgbColor, setRgbColor] = useState(module.rgbColor);
    // console.log('ModuleInfo module',module)
    // console.log('ModuleInfo module.size',module.size)

    const _setRgbColor = (rgb) => {

        module.model.traverse(property => {
            if (property.isMesh) {
                property.material.color.r = rgb.r / 255;
                property.material.color.g = rgb.g / 255;
                property.material.color.b = rgb.b / 255;
            }
        });

        module.rgbColor = true;
        setRgbColor(true);
        module.rgb = rgb;
        setRgb(rgb);
        module.updateShema();
        setChangeState(true);


        // sendRedrawEvent(document.querySelector(canvasSelector));





        // if (module.rgbColor) { //* не понятно зачем и как это работает
        //     const materialRGB = new THREE.MeshPhongMaterial({ color: new THREE.Color('rgb(' + module.rgb.r + ',' + module.rgb.g + ',' + module.rgb.b + ')'), side: THREE.DoubleSide });

        //     module.model.traverse(e => {
        //         if (e.isMesh) {
        //             if (e.material.length > 0) {
        //                 e.material.map((_m, i) => {
        //                     if (_m.name === 'keramik' || _m.name === 'cloth' || _m.name === 'face' || _m.name === 'corp' || _m.name === 'color') {
        //                         e.material[i].color = materialRGB.color.clone();
        //                     }
        //                 })
        //             }
        //             else if (e.material.name === 'keramik' || e.material.name === 'cloth' || e.material.name === 'face' || e.material.name === 'corp' || e.material.name === 'color') {
        //                 e.material.color = materialRGB.color.clone();
        //             }
        //         }
        //     })
        // }
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
        setChangeState(true);
    }

    const handlerHeightFromFloor = (value) => {
        setHeightFromFloor(value);
        module.heightFromFloor = value;
        sendRedrawEvent(document.querySelector(canvasSelector));
        setChangeState(true);

    }

    const handlerScaleX = (value) => {
        setScaleX(value);
        module.size.sizeX = value;
        sendRedrawEvent(document.querySelector(canvasSelector));
        setChangeState(true);

    }
    const handlerScaleY = (value) => {
        setScaleY(value);
        module.size.sizeY = value;
        sendRedrawEvent(document.querySelector(canvasSelector));
        setChangeState(true);

    }
    const handlerScaleZ = (value) => {
        setScaleZ(value);
        module.size.sizeZ = value;
        sendRedrawEvent(document.querySelector(canvasSelector));
        setChangeState(true);

    }

    useEffect(() => {
        dispatch(modulesState.getPrice());
    }, [option, materialCorp, materialFace, materialCloth]);

    const remove = () => {
        dispatch(modulesState.remove(module));
        dispatch(modulesState.getPrice());
        dispatch(projectState.setModal(''));
        sendUnselectEvent(document.querySelector(canvasSelector));
    }


    const close = () => {
        module.updateShema();
        dispatch(projectState.setModal(''));
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));

        if ('#scene' === canvasSelector)
            sendUnselectEvent(document.querySelector(canvasSelector));
    }


    const corpKeys = module.materials.corp ? Object.keys(module.materials.corp) : null;
    const corpMaterials = corpKeys ? window.materials.corp.filter(m => corpKeys.find(id => id === m.userData.ID.toString())) : window.materials.corp;

    const faceKeys = module.materials.face ? Object.keys(module.materials.face) : null;
    const faceMaterials = faceKeys ? window.materials.face.filter(m => faceKeys.find(id => id === m.userData.ID.toString())) : window.materials.face;

    const clothKeys = module.materials.cloth ? Object.keys(module.materials.cloth) : null;
    const clothMaterials = clothKeys ? window.materials.cloth.filter(m => clothKeys.find(id => id === m.userData.ID.toString())) : window.materials.cloth;

    const setMaterial = (material) => {
        if (material.name === 'corp') {
            module.corp = material;
            setMaterialCorp(module.corp);
        }
        else if (material.name === 'face') {
            module.face = material;
            setMaterialFace(module.face);
        }
        else if (material.name === 'cloth') {
            module.cloth = material;
            setMaterialCloth(module.cloth);
        }
    }

    const setVariant = (variant) => {
        changeModule(module, variant);
    }

    useEffect(() => {

        setRgb(module.rgb);
        setRgbColor(module.rgbColor);

        //const parent = module.parent;
        //const element = module.model.children[0].children[0];
        const onResize = () => {
            const width = canvas.current.clientWidth;
            const height = canvas.current.clientHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
            //composer.setSize(width,height);
            //effectFXAA.uniforms['resolution'].value.set(1/width,1/height);
        }
        window.addEventListener("resize", onResize);
        /*
                const generateFloor = (plan,mat)=>{
                    const result = new THREE.Group();
                    result.userData.type = "floor";
                    //милиметровка
                    // const planGmt = new THREE.PlaneBufferGeometry(100,100,1,1);
                    // planGmt.attributes.uv.array = planGmt.attributes.uv.array.map(p=>p*100);
                    // const mm = new THREE.TextureLoader().load( '/configurator/img/mm.jpg',t=>{
                    //     t.wrapS = THREE.RepeatWrapping;
                    //     t.wrapT = THREE.RepeatWrapping;
                    // } );
                    const planGmt = new THREE.PlaneBufferGeometry(100,100,100,100);
                    const planeMat = new THREE.MeshBasicMaterial({
                        color:0xFFFFFF
                    });
                    const planeMesh = new THREE.Mesh( planGmt, planeMat );
                    planeMesh.rotateX(-Math.PI/2);
                    planeMesh.receiveShadow = true;
                    planeMesh.translateZ(-0.03);
                    result.add(planeMesh);
                    return result;
                };*/

        const init = () => {
            camera = new THREE.PerspectiveCamera(
                40, canvas.current.clientWidth / canvas.current.clientHeight, 0.01, 200);

            const maxDim = Math.max(...[module.size.sizeX, module.size.sizeZ, module.size.sizeY])
            // console.log('maxDim',maxDim)
            const camPos = maxDim / 1000 * 3
            camera.position.set(-camPos, camPos, -camPos);

            scene = new THREE.Scene();
            // scene.background = new THREE.Color( 0xf9f9f9 );
            controls = new OrbitControls(camera, canvas.current);
            controls.maxPolarAngle = Math.PI / 2;
            controls.minDistance = .1;
            controls.maxDistance = 200;
            controls.zoomSpeed = .5;


            renderer = new THREE.WebGLRenderer({
                canvas: canvas.current,
                antialias: true,
                powerPreference: 'high-performance',
                preserveDrawingBuffer: true,
                alpha: true
            });
            renderer.setSize(canvas.current.clientWidth, canvas.current.clientHeight);

            renderer.setClearColor(0x000000, 0)
            renderer.toneMappingExposure = .8
            renderer.physicallyCorrectLights = false

            const GammaEncoding = 3007;
            const PCFSoftShadowMap = 2;
            const ACESFilmicToneMapping = 4;
            renderer.autoClear = true;
            renderer.outputEncoding = GammaEncoding;
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.type = PCFSoftShadowMap;
            renderer.shadowMap.autoUpdate = false;
            renderer.setPixelRatio(window.devicePixelRatio);



            const bb = new THREE.Box3()
            bb.setFromObject(module.model)
            const modelCenter = bb.getCenter()

            controls.target = new THREE.Vector3(modelCenter.x, modelCenter.y, modelCenter.z)

            // controls.target = new THREE.Vector3(0,.5,0);
            controls.update();
            clock = new THREE.Clock();


            scene.add(new THREE.AmbientLight(0xffffff, .6));

            const directionalLight = new THREE.DirectionalLight(0xffffff, .1);
            directionalLight.position.set(-5, 10, 15).normalize();
            directionalLight.castShadow = true;
            scene.add(directionalLight);

            const directionalLight2 = new THREE.DirectionalLight(0xffffff, .3);
            directionalLight2.position.set(-10, 10, -10).normalize();
            directionalLight2.castShadow = true;
            scene.add(directionalLight2);

            const directionalLight3 = new THREE.DirectionalLight(0xffffff, .3);
            directionalLight3.position.set(-10, 10, 10).normalize();
            directionalLight3.castShadow = true;
            scene.add(directionalLight3);

            scene.add(module.model);

            raycaster = new THREE.Raycaster();
            mouse = new THREE.Vector2();
            onResize();
        };

        const onClickHandler = event => {
            // console.log('onClickHandler => module',module)
            const rect = canvas.current.getBoundingClientRect();
            let e = event;
            if (event.changedTouches) e = event.changedTouches[0];
            const mouse = new THREE.Vector2();
            mouse.x = ((e.clientX - rect.left) / rect.width) * 2 - 1;
            mouse.y = -((e.clientY - rect.top) / rect.height) * 2 + 1;
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObjects([module.model], true);
            let selectedObject = null;
            if (intersects.length > 0) {
                selectedObject = intersects[0].object;
            }
            if (selectedObject) {
                const root = module;
                if (root.status === 'close') root.status = 'open';
                else root.status = 'close';
            }
        }
        canvas.current.addEventListener('click', onClickHandler);

        const animate = () => {
            requestAnimationFrame(animate);
            const mixerUpdateDelta = clock.getDelta();
            module.mixer.update(mixerUpdateDelta);
            //scene.overrideMaterial = true;

            module.model.scale.x = (module.size.sizeX / (module.sizeDef.sizeX / 100)) / 100
            module.model.scale.y = (module.size.sizeY / (module.sizeDef.sizeY / 100)) / 100
            module.model.scale.z = (module.size.sizeZ / (module.sizeDef.sizeZ / 100)) / 100


            renderer.render(scene, camera);
            //composer.render();
        }

        init();
        animate();
        // console.log('module.sizeDef',module.sizeDef)
        // console.log('module.size',module.size)
        // console.log('module.model.scale',module.model.scale)

        return () => {
            window.removeEventListener("resize", onResize);
            canvas.current.removeEventListener('click', onClickHandler);
            //parent.add(element);
        }
    }, [module]);

    // useEffect(()=>{
    // },[]);

    const toggleOption = () => {
        const newStatus = !module.optionStatus;
        if (newStatus && module.option) {
            module.optionStatus = newStatus;
            setOption(module.optionStatus);
        }
        else {
            module.optionStatus = false;
            setOption(module.optionStatus);
        }
        dispatch(modulesState.getPrice());
    }
    const modules = useSelector(store => store.modules.allModules);
    const opt = module.option ? modules.find(m => m.ID == module.option.ID) : null;
    const mod = modules.find(m => m.ID == module.id);
    const variants = mod.VARIANTS.reduce((acc, v) => {
        const item = modules.find(m => m.ID == v.ID);
        if (item) acc.push(item);
        return acc;
    }, []);

    // console.log('ModuleInfo mod',mod)

    return <div><div className='modal-bg'></div>
        <div className='module-info'>
            <div className="module-canvas">
                <canvas ref={canvas} id="modul">
                </canvas>
            </div>
            <ModalWrapper
                isSideMenu={false}
                title={convertQuotes(module.name)}
                onDelete={() => remove()}
                onClose={() => close()}
                planeEdit={planeEdit}
            >
                <div className='modal-mainBody'>
                    <div className="modal-body">
                        {mod.ICO && (
                            <div className={"module-image"}>
                                <img src={mod.ICO} alt={module.name} />
                            </div>
                        )}
                        {!mod.ICO && (
                            <br />
                        )}
                        {module.heightFromFloor > -1 && (
                            <div className="size-block">
                                <h2>Отступ от пола</h2>
                                <SizeBlock
                                    value={heightFromFloor}
                                    onChange={handlerHeightFromFloor}
                                    min={0}
                                    max={500000}
                                    isLocked={false}
                                />
                            </div>
                        )}

                        <div className={"options-title"}>РАЗМЕРЫ ОБЪЕКТА</div>
                        <div className="size-block">
                            <h2>Ширина</h2>
                            <SizeBlock
                                value={scaleX}
                                min={0}
                                step={5}
                                onChange={handlerScaleX}
                            />
                        </div>
                        <div className="size-block">
                            <h2>Глубина</h2>
                            <SizeBlock
                                value={scaleZ}
                                min={0}
                                step={5}
                                onChange={handlerScaleZ}
                            />
                        </div>
                        <div className="size-block">
                            <h2>Высота</h2>
                            <SizeBlock
                                value={scaleY}
                                min={0}
                                step={5}
                                onChange={handlerScaleY}
                            />
                        </div>

                        <div className="material-select-block material-select-block-inline">
                            <ColorRBG _setRgbColor={_setRgbColor} active={module.rgbColor} colorDef={module.rgb} />
                        </div>

                        {(module.corp !== null || module.face !== null) && <div className={"options-title"}>Материалы</div>}
                        {(module.corp !== null || module.face !== null) &&
                            <div className="modal-section">
                                {module.corp !== null &&
                                    <div className="material-select">
                                        <div className="material-select__name">Корпус</div>
                                        <div className="material-select__select" onClick={() => setMaterialCorpWnd(true)} style={{ backgroundImage: 'url(' + materialCorp.userData.PICT + ')', cursor: 'pointer' }}>
                                            <span>{materialCorp.userData.NAME}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.78 6">
                                                <polygon fill="currentColor" points="8.78 0 4.39 6 0 0 8.78 0"></polygon>
                                            </svg>
                                        </div>
                                    </div>
                                }
                                {materialCorpWnd === true && <MaterialSelect
                                    onClose={() => setMaterialCorpWnd(false)}
                                    active={materialCorp}
                                    list={corpMaterials}
                                    setMaterial={setMaterial}
                                    title="Корпус"
                                />}


                                {module.face !== null &&
                                    <div className="material-select">
                                        <div className="material-select__name">Фасад</div>
                                        <div className="material-select__select" onClick={() => setMaterialFaceWnd(true)} style={{ backgroundImage: 'url(' + materialFace.userData.PICT + ')', cursor: 'pointer' }}>
                                            <span>{materialFace.userData.NAME}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.78 6">
                                                <polygon fill="currentColor" points="8.78 0 4.39 6 0 0 8.78 0"></polygon>
                                            </svg>
                                        </div>
                                    </div>
                                }
                                {materialFaceWnd === true && <MaterialSelect
                                    onClose={() => setMaterialFaceWnd(false)}
                                    active={materialFace}
                                    list={faceMaterials}
                                    setMaterial={setMaterial}
                                    title="Фасад"
                                />}
                            </div>
                        }


                        {materialCloth &&
                            <div className="material-select">
                                <div className="material-select__name">Ткань</div>
                                <div className="material-select__select" onClick={() => setMaterialClothWnd(true)} style={{ backgroundImage: 'url(' + materialCloth.userData.PICT + ')', cursor: 'pointer' }}>
                                    <span>{materialCloth.userData.NAME}</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.78 6">
                                        <polygon fill="currentColor" points="8.78 0 4.39 6 0 0 8.78 0"></polygon>
                                    </svg>
                                </div>
                            </div>
                        }
                        {materialClothWnd === true && materialCloth && <MaterialSelect
                            onClose={() => setMaterialClothWnd(false)}
                            active={materialCloth}
                            list={clothMaterials}
                            setMaterial={setMaterial}
                            title="Диван"
                        />}


                        {variants.length > 1 && <div className={"options-title"}>РАЗМЕРЫ ОБЪЕКТА</div>}
                        {variants.length > 1 &&
                            <VariantDropBox
                                list={variants}
                                active={mod}
                                // title = {mod.REPLACEMENT.NAME}
                                title={"Спальное место"}
                                setActive={setVariant}
                            />
                        }
                        {mod.SIZE.HEIGHT !== null && (
                            <div className="size-block">
                                <span className="size-block__name">Высота</span>
                                <span className="size-block__value">{mod.SIZE.HEIGHT} см</span>
                            </div>
                        )}
                        {mod.SIZE.WIDTH !== null && (
                            <div className="size-block">
                                <span className="size-block__name">Ширина</span>
                                <span className="size-block__value">{mod.SIZE.WIDTH} см</span>
                            </div>
                        )}
                        {mod.SIZE.DEPTH !== null && (
                            <div className="size-block">
                                <span className="size-block__name">Глубина</span>
                                <span className="size-block__value">{mod.SIZE.DEPTH} см</span>
                            </div>
                        )}
                        {mod.SIZE.DEPTH2 !== null && (
                            <div className="size-block">
                                <span className="size-block__name">Глубина в открытом виде</span>
                                <span className="size-block__value">{mod.SIZE.DEPTH2} см</span>
                            </div>
                        )}


                        {module.option && materialCloth && (
                            <div className={"options-title-wrap"}>
                                <div className={"options-title"}>Размеры дивана</div>
                                <div className={"options-title__option"}>
                                    <span>Диван</span>
                                    <div className={module.optionStatus ? "oval-checkbox oval-checkbox_active" : "oval-checkbox"}
                                        onClick={toggleOption}
                                    >&nbsp;</div>
                                </div>
                            </div>
                        )}
                        {module.option && !materialCloth && (
                            <div className={"options-title-wrap"}>
                                <div className={"options-title"}>Техника для кухни</div>
                                <div className={"options-title__option"}>
                                    <span>Техника</span>
                                    <div className={module.optionStatus ? "oval-checkbox oval-checkbox_active" : "oval-checkbox"}
                                        onClick={toggleOption}
                                    >&nbsp;</div>
                                </div>
                            </div>
                        )}
                        {/*module.optionStatus && <>
                {opt.SIZE.DEPTH && (
                    <div className="size-block">
                        <span className="size-block__name">Глубина</span>
                        <span className="size-block__value">{opt.SIZE.DEPTH} см</span>
                    </div>
                )}
                {opt.SIZE.WIDTH && (
                    <div className="size-block">
                        <span className="size-block__name">Ширина</span>
                        <span className="size-block__value">{opt.SIZE.WIDTH} см</span>
                    </div>
                )}
                </>
            */}

                    </div>
                    {changeState && <div className="btn btn-icon bottom_btn" onClick={close}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 0 25 25">
                            <g fill="currentColor">
                                <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                            </g>
                        </svg>
                        Применить
                    </div>}
                </div>
            </ModalWrapper>
        </div>
    </div>
}

export default ModuleInfo
