import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { actionsState as projectState } from '../../../../Redux/project';
import ModalWrapper from '../../../ModalWrapper';
import { ImageViewer } from '../../../UI';
import { removeImage, uploadImage } from '../../../../api';
import { convertQuotes } from '../../../Utils';

const titlePath = 'Информация о ';

export const ObjectInfo = ({ obj }) => {
    const dispatch = useDispatch();

    const tokenRID = useSelector((store) => store.project.saveResult.tokenRID);

    const [showImage, setShowImage] = useState(false);

    const [objTitle, setObjTitle] = useState(obj.objTitle);
    const [objComment, setObjComment] = useState(obj.objComment);
    const [objImages, setObjImages] = useState(obj.objImages);
    const [title, setTitle] = useState('Информация');

    const handlerObjTitle = (event) => {
        obj.objTitle = event.target.value;
        setObjTitle(event.target.value);
    }
    const handlerObjComment = (event) => {
        obj.objComment = event.target.value;
        setObjComment(event.target.value);
    }
    const appendImg = async event => {
        event.persist();

        if (event.target.files && event.target.files[0]) {
            dispatch(projectState.addPreloader());

            const imageData = event.target.files[0];
            const result = await uploadImage(imageData, tokenRID);
            if (result.status === 'ok') {
                const newImages = objImages.map((img) => img);
                newImages.push({ 'fileID': result.fileID, 'imageUrl': result.imageUrl });
                obj.objImages = newImages;
                setObjImages(newImages);
                dispatch(projectState.decPreloader());
            }
        }
    }
    const removeImg = (index, fileID) => {
        const newImages = objImages.filter((img, _index) => (index !== _index));
        obj.objImages = newImages;
        setObjImages(newImages);

        const result = removeImage(fileID);
    }

    useEffect(() => {
        setObjTitle(obj.objTitle);
        setObjComment(obj.objComment);
        setObjImages(obj.objImages);
    }, [obj, obj.objImages])
    console.log("INFO", obj);
    useEffect(() => {
        if (obj.isWall) {
            setTitle(titlePath + 'стене');
        } else if (obj.isColumn) {
            setTitle(titlePath + 'колонне');
        } else if (obj.isFloor) {
            setTitle(titlePath + 'поле');
        } else if (obj.isWindow) {
            setTitle(titlePath + 'окне');
        } else if (obj.isDoor) {
            setTitle(titlePath + 'двери');
        } else if (obj.isHole) {
            setTitle(titlePath + 'проеме');
        } else if (obj.isRedCube) {
            setTitle(titlePath + 'кубе');
        } else if (obj.isHeatingBattery) {
            setTitle(titlePath + 'батарее отопления');
        } else if (obj.isCylinder) {
            setTitle(titlePath + 'цилиндре');
        } else if (obj.isSwitch) {
            setTitle(titlePath + 'выключателе');
        } else if (obj.isElectricSocket) {
            setTitle(titlePath + 'розетке');
        } else if (obj.isElectricPanel) {
            setTitle(titlePath + 'электрощите');
        } else if (obj.outletElectricalWire) {
            setTitle(titlePath + 'выводе электропровода');
        } else if (obj.isModule) {
            setTitle(titlePath + convertQuotes(obj.name));
        }
    }, [obj])

    return (
        <ModalWrapper
            isSideMenu={true}
            title={title}
            onClose={() => dispatch(projectState.setModal(''))}
        >
            <div className="modal-body">
                <div className="bl-images">
                    <div className="bl-title">
                        <span>Название</span>
                        <input value={objTitle} onChange={handlerObjTitle} type="text" className={'form-control'} />
                    </div>
                    <div className="bl-comment">
                        <span>Комментарий</span>
                        <textarea value={objComment} onChange={handlerObjComment} className={'form-control'}></textarea>
                    </div>
                    <div className="bl-images">
                        <span>Изображения</span>
                        <div className="bl-images-all">
                            {objImages.map((img, index) => (
                                <div key={index}>
                                    <span className={"bl-images-remove"}
                                        onClick={() => removeImg(index, img.fileID)}>Удалить</span>
                                    <img onClick={() => setShowImage(true)} style={{ cursor: 'pointer' }} src={window.confSiteUrl + img.imageUrl} />
                                    <ImageViewer
                                        show={showImage}
                                        onClose={() => setShowImage(false)}
                                        img={window.confSiteUrl + img.imageUrl}
                                    />
                                </div>
                            ))}
                            <label className="bl-images-append-btn">
                                <span>Добавить</span>
                                <input type="file" onChange={appendImg} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    )
}
