export const sendRedrawEvent = target=>{
    const event = new Event('redraw');
    target.dispatchEvent(event);
}

export const sendRedrawSimpleEvent = target=>{
    const event = new Event('redrawSimple');
    target.dispatchEvent(event);
}

export const sendAnimationEvent = (target) => {
    const event = new Event('planAnimation');
    target.dispatchEvent(event);
}

export const sendUnselectEvent = target=>{
    const event = new Event('unselect');
    target.dispatchEvent(event);
}

export const sendReselectEvent = target=>{
    const event = new Event('reselect');
    target.dispatchEvent(event);
}

export const sendCenterModuleEvent = (target,obj,objType)=>{
    const event = new Event('centerModule');
    event.obj = obj;
    event.objType = objType;
    target.dispatchEvent(event);
}

export const sendSelectObjectEvent = (target, obj, parent) => {
    const event = new Event('selectObject');
    event.obj = obj;
    if (parent.isWall) event.wall = parent;
    if (parent.isColumn) event.column = parent;
    target.dispatchEvent(event);
}

export const sendGetHugeImage = target=>{
    const event = new Event('getHugeImage');
    target.dispatchEvent(event);
}
export const sendGetCollada = target=>{
    const event = new Event('getCollada');
    target.dispatchEvent(event);
}
export const sendGetGLTF = target=>{
    const event = new Event('getGLTF');
    target.dispatchEvent(event);
}
export const sendGetOBJ = target=>{
    const event = new Event('getOBJ');
    target.dispatchEvent(event);
}
