import React from 'react';
import { TextField } from '../../../UI';
import { useShortcuts } from '../../Shortcuts';

export const EditField = ({ value, position, onChange, onSubmit }) => {
    useShortcuts({
        onSubmit: () => onSubmit(value),
        deps: [value],
    });

    if (!value) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: `${position.y}px`,
                left: `${position.x}px`,
                width: '70px'
            }}
        >
            <TextField
                value={value}
                onChange={(e) => onChange(e.target.value)}
                type="number"
                adornment="°"
            />
        </div>

    );
}
