export const convertQuotes = (value) => {
    let open = true;
    return value.replace(/&quot;/g, () => {
        if (open) {
            open = false;
            return '«';
        } else {
            open = true;
            return '»';
        }
    });
}
