import { drawImage, drawText } from './primitives';
import { calculateAngle, movePoint, segmentLine, splitText } from './geometry';
import { BIG_DIM_SIZE, DIM_SIZE, getImageInfo, HUGE_DIM_SIZE, X_GAP } from './index';
import { TextHorizontalAlignment } from '@tarikjabiri/dxf';

export const info = {
    objects: [],
    images: [],
    offset: 0,
    number: 1,
    increment: () => { info.number++ },
    setOffset: (value) => { info.offset = value },
    addImage: (image) => { info.images.push(image) },
    addObject: (object) => { info.objects.push(object) },
    reset: () => {
        info.number = 1;
        info.images = [];
        info.objects = [];
    },
};

export const drawInfo = async (dxf, planSize) => {
    const planSizeMaxX = planSize.maxX + X_GAP

    for (const object of info.objects) {
        const isReverse = object?.isReverse || false;
        const isHole = object?.isHole || object?.isWindow || object?.isDoor;
        const isSmallObject = object?.isElectricSocket || object?.isSwitch || object?.outletElectricalWire;
        const multi = isHole ? 1 : -1;
        const depth = BIG_DIM_SIZE;
        const start = object.rA1Inner || object.rA13d;
        const end = object.rB1Inner || object.rB13d;
        const { angle, rotation } = calculateAngle(start, end);

        let objectType = '';
        if (object?.isElectricSocket) objectType = 'роз';
        if (object?.isSwitch) objectType = 'выкл';

        let offset = 0;
        if (object?.heightFromFloor) {
            drawText(
                dxf,
                movePoint(
                    segmentLine(start, end, 1 / 2),
                    angle + 90,
                    multi * (depth)
                ),
                `h${objectType}=${object.heightFromFloor}`,
                { size: DIM_SIZE, rotation }
            );
            offset += BIG_DIM_SIZE;
        }
        if (object?.width && !isHole && !isSmallObject) {
            drawText(
                dxf,
                movePoint(
                    segmentLine(start, end, 1 / 2),
                    angle + 90,
                    multi * (depth + offset)
                ),
                `W${objectType}=${object.width}`,
                { size: DIM_SIZE, rotation }
            );
            offset += BIG_DIM_SIZE;
        }
        if (object?.height && !isSmallObject) {
            drawText(
                dxf,
                movePoint(
                    segmentLine(start, end, 1 / 2),
                    angle + 90,
                    multi * (depth + offset)
                ),
                `H${objectType}=${object.height}`,
                { size: DIM_SIZE, rotation }
            );
            offset += BIG_DIM_SIZE;
        }
        if ((object?.isWindow || object?.isDoor) && object?.depthIndent) {
            drawText(
                dxf,
                movePoint(
                    segmentLine(start, end, 1 / 2),
                    angle + 90,
                    multi * (depth + offset)
                ),
                `g=${object.depthIndent}`,
                { size: DIM_SIZE, rotation }
            );
            offset += BIG_DIM_SIZE;
        }
        if (object?.depthIndentFor3D) {
            drawText(
                dxf,
                movePoint(
                    segmentLine(start, end, 1 / 2),
                    angle + 90,
                    multi * (depth + offset)
                ),
                `d${objectType}=${object?.depthIndentFor3D}`,
                { size: DIM_SIZE, rotation }
            );
            offset += BIG_DIM_SIZE;
        }
        if (!isHole) {
            drawText(
                dxf,
                movePoint(
                    segmentLine(start, end, 1 / 2),
                    angle + 90,
                    multi * (depth + offset)
                ),
                `L${objectType}=${isReverse ? object.len2 : object.len1}`,
                { size: DIM_SIZE, rotation }
            );
            offset += BIG_DIM_SIZE;
            drawText(
                dxf,
                movePoint(
                    segmentLine(start, end, 1 / 2),
                    angle + 90,
                    multi * (depth + offset)
                ),
                `R${objectType}=${isReverse ? object.len1 : object.len2}`,
                { size: DIM_SIZE, rotation }
            );
            offset += BIG_DIM_SIZE;
        }
        if (object?.count && object?.count > 1) {
            drawText(
                dxf,
                movePoint(
                    segmentLine(start, end, 1 / 2),
                    angle + 90,
                    multi * (depth + offset)
                ),
                `Кол-во${objectType ? (' ' + objectType) : objectType}=${object.count}`,
                { size: DIM_SIZE, rotation }
            )
            offset += BIG_DIM_SIZE;
        }

        if (object?.objTitle?.length < 51 && !object?.objComment && !object?.objImages?.length) {
            splitText(object.objTitle).forEach((text) => {
                drawText(
                    dxf,
                    movePoint(
                        segmentLine(start, end, 1 / 2),
                        angle + 90,
                        multi * (depth + offset)
                    ),
                    text,
                    { size: DIM_SIZE, rotation }
                );
                offset += BIG_DIM_SIZE;
            })
        } else if (object?.objTitle?.length > 50 || object?.objComment || object?.objImages?.length) {
            drawText(
                dxf,
                movePoint(
                    segmentLine(start, end, 1 / 2),
                    angle + 90,
                    multi * (depth + offset)
                ),
                `См. инф. ${info.number}`,
                { size: DIM_SIZE }
            );

            drawText(
                dxf,
                { x: planSizeMaxX, y: -planSize.maxY + info.offset },
                `Информация #${info.number}`,
                { size: BIG_DIM_SIZE, align: TextHorizontalAlignment.Left }
            );
            info.setOffset(info.offset + HUGE_DIM_SIZE);

            if (object?.objTitle) {
                drawText(
                    dxf,
                    { x: planSizeMaxX, y: -planSize.maxY + info.offset },
                    `Название: ${object.objTitle}`,
                    { size: BIG_DIM_SIZE, align: TextHorizontalAlignment.Left }
                );
                info.setOffset(info.offset + HUGE_DIM_SIZE);
            }

            if (object?.objComment) {
                drawText(
                    dxf,
                    { x: planSizeMaxX, y: -planSize.maxY + info.offset },
                    `Комментарий: ${object.objComment.replaceAll('\n', ' ')}`,
                    { size: BIG_DIM_SIZE, align: TextHorizontalAlignment.Left }
                );
                info.setOffset(info.offset + HUGE_DIM_SIZE);
            }

            if (object?.objImages?.length) {
                drawText(
                    dxf,
                    { x: planSizeMaxX, y: -planSize.maxY + info.offset },
                    `Фото:`,
                    { size: BIG_DIM_SIZE, align: TextHorizontalAlignment.Left }
                );
                info.setOffset(info.offset + HUGE_DIM_SIZE);

                for (const file of object.objImages) {
                    const result = await getImageInfo(file.imageUrl);
                    const img = drawImage(dxf, {
                        name: file.fileID,
                        width: result.width,
                        height: result.height,
                        angle: 0,
                        size: { width: result.width, height: result.height },
                        x: planSizeMaxX,
                        y: planSize.maxY - info.offset - result.height
                    });
                    info.addImage({ name: file.fileID, blob: result.blob, ...img });
                    info.setOffset(info.offset + result.height + HUGE_DIM_SIZE);
                }
            }

            info.setOffset(info.offset + HUGE_DIM_SIZE * 2);

            info.increment();
            offset += BIG_DIM_SIZE;
        }
    }

    const labels = [
        'Обозначения:',
        'h - Отступ от пола',
        'W - Ширина объекта',
        'H - Высота объекта',
        'g - Откос внутри у окон и дверей',
        'd - Отступ от стены',
        'R - Отступ справа вдоль стены',
        'L - Отступ слева вдоль стены'
    ];

    labels.forEach((label, index) => {
        const size = index === 0 ? BIG_DIM_SIZE : DIM_SIZE;
        const yPos = -planSize.maxY - BIG_DIM_SIZE * (index === 0 ? 12 : (10 - index));

        drawText(
            dxf,
            { x: planSizeMaxX, y: yPos },
            label,
            { size: size, align: TextHorizontalAlignment.Left }
        );
    });

    info.setOffset(0);

    return info.images;
}
