import { calculateMaterialAmount, calculateMaterialQuantity } from '../Helpers/estimate';

export default class ObjectOnWall {
    constructor(oow) {
        switch (oow) {
            case 'outletElectricalWire':
                this.outletElectricalWire = true
                this.height = 65
                this.width = 65
                this.isElectricSocket = false
                this.isSwitch = false
                this.isHeatingBattery = false
                this.isElectricPanel = false
                this.isRedCube = false
                this.isCylinder = false
                this.padding = 300
                this.heightFromFloor = 1000
                this.depth = 10
                this.depthFor3D = 10
                this.depthIndent = 0
                this.depthIndentFor3D = 0
                this.id = ''
                this.objTitle = '';
                this.objComment = '';
                this.objImages = [];
                this.lrBuild = 'left';
                this.side = '';
                this.estimate = [];
                this.scale = 2;
                break
            case 'electricSocket':
                this.height = 65
                this.width = 65
                this.isElectricSocket = true
                this.isSwitch = false
                this.isHeatingBattery = false
                this.isElectricPanel = false
                this.outletElectricalWire = false
                this.isCylinder = false
                this.type = 'default'
                this.count = 1
                this.padding = 300
                this.heightFromFloor = 300
                this.depth = 10
                this.depthFor3D = 10
                this.depthIndent = 0
                this.id = ''
                this.objTitle = '';
                this.objComment = '';
                this.objImages = [];
                this.lrBuild = 'left';
                this.side = '';
                this.estimate = [];
                this.scale = 2;
                break
            case 'switch':
                this.height = 65
                this.width = 65
                this.outletElectricalWire = false
                this.isElectricSocket = true
                this.isSwitch = false
                this.isHeatingBattery = false
                this.isElectricPanel = false
                this.isRedCube = false
                this.isCylinder = false
                this.padding = 300
                this.heightFromFloor = 1000
                this.depth = 10
                this.depthFor3D = 10
                this.depthIndent = 0
                this.depthIndentFor3D = 0
                this.id = ''
                this.objTitle = '';
                this.objComment = '';
                this.objImages = [];
                this.lrBuild = 'left';
                this.side = '';
                this.estimate = [];
                this.scale = 2;
                break

            case 'heatingBattery':
                this.height = 530
                this.width = 530
                this.isElectricSocket = true
                this.outletElectricalWire = false
                this.isSwitch = false
                this.isHeatingBattery = false
                this.isElectricPanel = false
                this.isRedCube = false
                this.isCylinder = false
                this.padding = 300
                this.heightFromFloor = 1000
                this.depth = 100
                this.depthFor3D = 100
                this.depthIndent = 0
                this.depthIndentFor3D = 0
                this.id = ''
                this.objTitle = '';
                this.objComment = '';
                this.objImages = [];
                this.lrBuild = 'left';
                this.side = '';
                this.estimate = [];
                this.scale = 1;
                break;

            case 'electricPanel':
                this.height = 200
                this.width = 200
                this.isElectricSocket = false
                this.outletElectricalWire = false
                this.isSwitch = false
                this.isHeatingBattery = false
                this.isElectricPanel = true
                this.isRedCube = false
                this.isCylinder = false
                this.padding = 300
                this.heightFromFloor = 1000
                this.depth = 100
                this.depthFor3D = 100
                this.depthIndent = 0
                this.depthIndentFor3D = 0
                this.id = ''
                this.objTitle = '';
                this.objComment = '';
                this.objImages = [];
                this.lrBuild = 'left';
                this.side = '';
                this.estimate = [];
                this.scale = 1;
                break;

            case 'redCube':
                this.height = 500
                this.width = 500
                this.isElectricSocket = false
                this.outletElectricalWire = false
                this.isSwitch = false
                this.isHeatingBattery = false
                this.isElectricPanel = false
                this.isRedCube = true
                this.isCylinder = false
                this.padding = 300
                this.heightFromFloor = 1000
                this.depth = 0
                this.depthFor3D = 0
                this.depthIndent = 0
                this.depthIndentFor3D = 0
                this.id = ''
                this.objTitle = '';
                this.objComment = '';
                this.objImages = [];
                this.lrBuild = 'left';
                this.side = '';
                this.estimate = [];
                this.scale = 1;
                break

            case 'cylinder':
                this.height = 500
                this.width = 500
                this.isElectricSocket = false
                this.outletElectricalWire = false
                this.isSwitch = false
                this.isHeatingBattery = false
                this.isElectricPanel = false
                this.isRedCube = false
                this.isCylinder = true
                this.padding = 300
                this.heightFromFloor = 1000
                this.depth = 500
                this.depthFor3D = 500
                this.depthIndent = 0
                this.depthIndentFor3D = 0
                this.id = ''
                this.objTitle = '';
                this.objComment = '';
                this.objImages = [];
                this.lrBuild = 'left';
                this.side = '';
                this.estimate = [];
                this.scale = 2;
                break

            default:
                this.height = 65
                this.width = 65
                this.isElectricSocket = true
                this.isSwitch = false
                this.isHeatingBattery = false
                this.isElectricPanel = false
                this.isRedCube = false
                this.outletElectricalWire = false
                this.type = 'default'
                this.count = 1
                this.padding = 100
                this.heightFromFloor = 200
                this.depth = 10
                this.depthFor3D = 10
                this.depthIndent = 0
                this.depthIndentFor3D = 0
                this.id = ''
                this.objTitle = '';
                this.objComment = '';
                this.objImages = [];
                this.lrBuild = 'left';
                this.rgb = {
                    r: '255',
                    g: '0',
                    b: '0',
                    a: '1',
                };
                this.side = '';
                this.estimate = [];
                this.scale = 1;
                break
        }
    }
    setEstimate(estimate) {
        this.estimate = estimate.filter((est, index, self) => (
            index === self.findIndex((selfJob) => selfJob.id === est.id)
        )).map((estimate) => {
            return {
                id: estimate.id,
                externalId: estimate.externalId,
                group: estimate.group,
                stage: estimate.stage,
                price: estimate.price,
                code: estimate.code,
                name: estimate.name,
                cost: estimate.cost,
                unit: estimate.unit,
                object: estimate.object,
                volume: estimate.volume,
                amount: estimate.amount,
                materials: estimate.materials.map((material) => {
                    material.volume = estimate.volume;
                    material.quantity = calculateMaterialQuantity(estimate, material);
                    material.amount = calculateMaterialAmount(material);
                    return { ...material }
                })
            }
        });
    }
}
