export const bluetoothRouletteObj = {
    // serviceID: 0xffb0, //для CONDTROL XP3 PRO
    serviceID: '0000ffb0-0000-1000-8000-00805f9b34fb', //для CONDTROL XP3 PRO
    // characteristicID: 0xffb2, //для CONDTROL XP3 PRO
    characteristicID: '0000ffb2-0000-1000-8000-00805f9b34fb', //для CONDTROL XP3 PRO


    device: {},
    server: {},
    service: {},
    characteristic: {},
    notify: {},
}