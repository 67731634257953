import { Vector3, Box3 } from "three";

/**
 *  Преобразует объект для вставки в сцену
 * @param {Object3D} templateModel
 * @param {object} object
 * @param {wall || column} parent
 * @returns Object3D
 */
export const transformObjectOnWall = (templateModel, object, parent) => {
    const box = new Box3();
    const vSize = new Vector3();
    const model = templateModel.clone();
    box.setFromObject(model);
    box.getSize(vSize);

    const l = parent?.isBezier ? 0 : object.pos;
    const parentVector = (parent?.isBezier || parent?.isColumn) ? object.objB.clone().sub(object.objA) : parent.mainLink.b.clone().sub(parent.mainLink.a);

    const __v = parentVector.clone().setLength(object.width / 2 + l);
    const __x = object.DepthPos3d.x + __v.x;

    let angle = -parentVector.angle();
    let __z;
    let zOffset = (object.depth / 2) * Math.cos(angle)
    let xOffset = (object.depth / 2) * Math.sin(angle);

    let depthIndentFor3D = (object.depthIndentFor3D + 0) / 1000;

    if (object.lrBuild === "right") {
        angle = -parentVector.angle() + Math.PI;
        zOffset = -zOffset;
        xOffset = -xOffset;
    }
    __z = object.DepthPos3d.y + __v.y + zOffset;

    model.scale.set(((object.width / vSize.x) / 1000), ((object.height / vSize.y) / 1000), (((object?.isCylinder ? object.width : object.depthFor3D) / vSize.z) / 1000));
    model.translateX((__x + xOffset) / 1000 + depthIndentFor3D * Math.sin(angle));
    model.translateY(object.heightFromFloor / 1000);
    model.translateZ(__z / 1000 + depthIndentFor3D * Math.cos(angle));
    model.rotateY(angle);
    model.name = 'wallObj';

    return model
}
